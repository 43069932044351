var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("input", {
          attrs: { type: "hidden", name: _vm.backendstuctureConfig },
          domProps: { value: _vm.jsonString },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "hidden",
            name: "Backendstructurei18nInheritance[config][inherit]",
            value: "1",
          },
        }),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "dragArea list-group",
            attrs: {
              "scroll-sensitivity": 190,
              "force-fallback": true,
              handle: ".handle",
              group: { name: "configuration", pull: true, put: ["tabs"] },
              list: _vm.listConfiguration,
            },
          },
          _vm._l(_vm.listConfiguration, function (el, idx) {
            return _c(
              "div",
              { key: idx, class: _vm.funcClassname(el) },
              [
                _c("i", { staticClass: "fa fa-fw fa-sort handle" }),
                _vm._v(" "),
                _c("i", { class: el.icon }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-times close",
                  on: {
                    click: function ($event) {
                      return _vm.removeTabAt(idx)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: el.name,
                      expression: "el.name",
                    },
                  ],
                  staticClass: "outerInput",
                  attrs: { type: "text" },
                  domProps: { value: el.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(el, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: el.icon,
                      expression: "el.icon",
                    },
                  ],
                  staticClass: "outerInputRight",
                  attrs: { type: "text", placeholder: "fa fa-file-alt" },
                  domProps: { value: el.icon },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(el, "icon", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    staticClass: "dragArea list-group",
                    attrs: {
                      list: el.tasks,
                      "scroll-sensitivity": 190,
                      "force-fallback": true,
                      handle: ".handle",
                      group: {
                        name: "fields",
                        pull: true,
                        put: ["attributes", "fields"],
                      },
                    },
                  },
                  _vm._l(el.tasks, function (el2, id2x) {
                    return _c(
                      "div",
                      {
                        key: id2x,
                        class: _vm.funcClassname(el2),
                        attrs: { "data-type": el2.type },
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw fa-sort handle" }),
                        _vm._v(" "),
                        _c("i", { class: _vm.funcIcon(el2) }),
                        _vm._v(" "),
                        el2.input !== "input" && el2.input !== "textarea"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: el2.readonly,
                                  expression: "el2.readonly",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(el2.readonly)
                                  ? _vm._i(el2.readonly, null) > -1
                                  : el2.readonly,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = el2.readonly,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          el2,
                                          "readonly",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          el2,
                                          "readonly",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(el2, "readonly", $$c)
                                  }
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el2.input !== "input" &&
                        el2.input !== "textarea" &&
                        !el2.readonly
                          ? _c("i", {
                              staticClass: "fa fa-fw fa-pencil unchecked",
                              attrs: { title: "Writeable" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el2.readonly
                          ? _c("i", {
                              staticClass: "fa fa-fw fa-ban checked",
                              attrs: { title: "Readonly" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el2.type !== "extra"
                          ? _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(el2.name) + " "),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa fa-fw fa-xs fa-times close",
                          on: {
                            click: function ($event) {
                              return _vm.removeAt(idx, id2x)
                            },
                          },
                        }),
                        _vm._v(" "),
                        el2.input === "input"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: el2.name,
                                  expression: "el2.name",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: el2.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el2, "name", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el2.input === "textarea"
                          ? _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: el2.name,
                                  expression: "el2.name",
                                },
                              ],
                              domProps: { value: el2.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el2, "name", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el2.hint
                          ? _c(
                              "small",
                              {
                                staticClass: "form-text text-muted",
                                attrs: {
                                  title: el2.hint,
                                  "data-toggle": "tooltip",
                                },
                              },
                              [_vm._v(_vm._s(el2.hint))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        el2.extraFields
                          ? _c(
                              "div",
                              _vm._l(el2.extraFields, function (extra, idx) {
                                return _c("div", [
                                  _c("label", [_vm._v(_vm._s(idx))]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: el2.extraFields[idx],
                                        expression: "el2.extraFields[idx]",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: el2.extraFields[idx] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          el2.extraFields,
                                          idx,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "sticky-top-backendstructurei18n" },
        [
          _c(
            "draggable",
            {
              staticClass: "d-flex-backendstructurei18n list-group",
              attrs: {
                "scroll-sensitivity": 190,
                "force-fallback": true,
                list: _vm.listTabs,
                disabled: false,
                sort: false,
                handle: ".handle",
                group: { name: "tabs", pull: "clone", put: false },
                "ghost-class": "ghost",
                clone: _vm.cloneTab,
              },
              on: { end: _vm.endDrag },
            },
            _vm._l(_vm.listTabs, function (element, elementId) {
              return _c(
                "div",
                { key: elementId, class: _vm.funcClassname(element) },
                [
                  _c("i", { staticClass: "fa fa-fw fa-grip-vertical handle" }),
                  _vm._v(" "),
                  _c("i", { class: _vm.funcIcon(element) }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(element.name) + " "),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "d-flex-backendstructurei18n list-group lock-height",
              attrs: {
                "scroll-sensitivity": 190,
                "force-fallback": true,
                list: _vm.listAttributes,
                disabled: false,
                sort: false,
                handle: ".handle",
                group: {
                  name: "attributes",
                  pull: _vm.pullFunctionConfiguration,
                  put: false,
                },
                clone: _vm.cloneAttribute,
                "ghost-class": "ghost",
              },
              on: { end: _vm.endDrag },
            },
            _vm._l(_vm.listAttributes, function (element, elementId) {
              return _c(
                "div",
                {
                  key: elementId,
                  class: _vm.funcClassname(element),
                  attrs: {
                    "data-type": element.type,
                    "data-allowed": element.allowed,
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-fw fa-grip-vertical handle" }),
                  _vm._v(" "),
                  _c("i", { class: _vm.funcIcon(element) }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(element.name) + " "),
                  ]),
                  _vm._v(" "),
                  element.hint
                    ? _c(
                        "small",
                        {
                          staticClass: "form-text text-muted",
                          attrs: {
                            title: element.hint,
                            "data-toggle": "tooltip",
                          },
                        },
                        [_vm._v(_vm._s(element.hint))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  element.extraFields
                    ? _c(
                        "div",
                        _vm._l(element.extraFields, function (extra, idx) {
                          return _c("div", [
                            _c("label", [_vm._v(_vm._s(idx))]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: element.extraFields[idx],
                                  expression: "element.extraFields[idx]",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: element.extraFields[idx] },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    element.extraFields,
                                    idx,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }