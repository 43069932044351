import { Extension } from 'tiptap';
import { clearMarks } from '../utils/clearMarks.js';

export default class Clear extends Extension {
    get name() {
        return 'clear';
    }
    commands() {
        return () => (state, dispatch) => {
            const tr = clearMarks(state.tr.setSelection(state.selection), state.schema);
            if (dispatch && tr.docChanged) {
                dispatch(tr);
                return true;
            }
            return false;
        };
    }
}
