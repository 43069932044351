var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.hasKenburnsEffect
    ? _c(_setup.KenburnsImage, { attrs: { src: _setup.props.src } })
    : _c("div", {
        staticClass: "image-container",
        style: "background: url(" + _vm.src + ")",
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }