import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=53ef5f24"
import script from "./checkbox.vue?vue&type=script&lang=js"
export * from "./checkbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53ef5f24')) {
      api.createRecord('53ef5f24', component.options)
    } else {
      api.reload('53ef5f24', component.options)
    }
    module.hot.accept("./checkbox.vue?vue&type=template&id=53ef5f24", function () {
      api.rerender('53ef5f24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-core-formgenerator/src/assets/vue/formeditor/sidebar/views/formparts/checkbox.vue"
export default component.exports