//import pdfjsLib from 'pdfjs-dist/webpack';

let PDFJS = require('pdfjs-dist');
PDFJS.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

//pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

//let pdfjs = require('pdfjs-dist/build/pdf');
//let PdfjsWorker = require('worker-loader!pdfjs-dist/build/pdf.worker');
//pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();

/**
 *
 * @param file
 * @param callback      function callback
 * @param pagenumber    default: 0 (first page)
 * @param thumbwidth    target size (width) for the thumbnail in pixel
 */

/*export default function getPDFThumbnail(file, callback=()=>{}, pagenumber= 1, thumbwidth= 400){

    PDFJS.getDocument('/url/to/file.pdf')

    readFileFromUpload(file,function(pdfData){
        //pdfjsLib.disableWorker = true;
        let pdf = PDFJS.getDocument({data:fileArray});
        pdf.promise.then((pdf) => {
            pdf.getPage(pagenumber).then(function(page){
                let desiredWidth = thumbwidth;
                let viewport = page.getViewport({ scale: 1, });
                let scale = desiredWidth / viewport.width;
                let scaledViewport = page.getViewport({ scale: scale, });

                let canvas = document.createElement("canvas");
                let context = canvas.getContext('2d');
                canvas.height = scaledViewport.height;
                canvas.width = scaledViewport.width;
                canvas.crossOrigin = "anonymous";   // CORS
                page.render({
                    canvasContext: context,
                    viewport: scaledViewport
                }).promise.then(function(){
                    callback(canvas.toDataURL('image/jpeg',0.9));
                });
            });
        }).catch(e=>{console.log(e)});
    });
}*/

function readFileFromUpload(file, callback) {
    let fileReader = new FileReader();
    fileReader.onload = function () {
        callback(new Uint8Array(this.result));
    };
    fileReader.readAsArrayBuffer(file);
}

export function getPDFThumbnailFromUpload(file, callback = () => {
}, pagenumber = 1, thumbwidth = 400) {
    readFileFromUpload(file, function (file) {
        let pdf = PDFJS.getDocument({data: fileArray});
        pdf.promise.then((pdf) => {
            getPdfPage(pdf, pagenumber, thumbwidth, callback);
        }).catch(e => {
            console.log(e)
        });
    });
}

export function getPDFThumbnailFromServer(url, callback = () => {
}, pagenumber = 1, thumbwidth = 400) {
    let pdf = PDFJS.getDocument(url);
    pdf.promise.then((pdf) => {
        getPdfPage(pdf, pagenumber, thumbwidth, callback);
    }).catch(e => {
        console.log(e)
    });
}

function getPdfPage(pdf, page, thumbwidth, callback) {
    pdf.getPage(page).then(function (page) {
        let desiredWidth = thumbwidth;
        let viewport = page.getViewport({scale: 1,});
        let scale = desiredWidth / viewport.width;
        let scaledViewport = page.getViewport({scale: scale,});

        let canvas = document.createElement("canvas");
        let context = canvas.getContext('2d');
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;
        canvas.crossOrigin = "anonymous";   // CORS
        page.render({
            canvasContext: context,
            viewport: scaledViewport
        }).promise.then(function () {
            callback(canvas.toDataURL('image/jpeg', 0.9));
        });
    }).catch((e) => console.log(e));
}