export default {
    "groups": [
        {
            "key": "simple",
            "label": "Einfache Felder",
            "elements": [
                {
                    "type": "formitem",
                    "key": 'input',
                    "label": "Input",
                    "icon": "text",
                    "view": 'input',
                    "inputtype": "text",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "placeholder": "",
                        "inputmaxlength": null,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "customvalidation": "",
                        "customerrormessage": "",
                        "defaultvalueclass": "",
                        "defaultvaluefunction": "",
                        "inputnumbermin": 0,
                        "inputnumbermax": 0,
                        "inputslidersteps": 1
                    }
                },
                {
                    "type": "formitem",
                    "key": "email",
                    "label": "E-Mail",
                    "icon": "envelope",
                    "view": 'input',
                    "inputtype": "email",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "placeholder": "",
                        "inputmaxlength": null,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "customvalidation": "",
                        "customerrormessage": "",
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "date",
                    "label": "Datum",
                    "icon": "calendar",
                    "view": 'input',
                    "inputtype": "date",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "placeholder": "",
                        "inputmaxlength": null,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "customvalidation": "",
                        "customerrormessage": "",
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "phone",
                    "label": "Telefon",
                    "icon": "phone",
                    "view": 'input',
                    "inputtype": "tel",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "placeholder": "",
                        "inputmaxlength": null,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "customvalidation": "",
                        "customerrormessage": "",
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "instruction",
                    "label": "Instruktion",
                    "icon": "question",
                    "view": "instruction",
                    "initData": {
                        "question": "",
                        "description": "",
                        "htmloptions": "",
                    }
                },
                {
                    "type": "formitem",
                    "key": "scala",
                    "label": "Skala",
                    "icon": "tally",
                    "view": "scale",
                    initData: {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "scaleSteps": 3,
                        "scaleMinLabel": "",
                        "scaleMaxLabel": "",
                        "scaledefaultselected": null,
                        "scaleIcon": 'star',
                        "htmloptions": "",
                        "htmlpurify": false,
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "numericscala",
                    "label": "Numerische Skala",
                    "icon": "tally",
                    "view": "numericscale",
                    initData: {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "scaleSteps": 3,
                        "scaleMinLabel": "",
                        "scaleMaxLabel": "",
                        "scaledefaultselected": 0,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                }
            ]
        },
        {
            "key": "choices",
            "label": "Auswahlfelder",
            "elements": [
                {
                    "type": "formitem",
                    "key": "singlechoice",
                    "label": "Einzelauswahl",
                    "icon": "ballot-check",
                    "view": "radio",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                            {
                                "label": "Antwort+1",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+2",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+3",
                                "is_default": false
                            }
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "",
                        "answeroptionsfunction": "",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "multichoice",
                    "label": "Mehrfachauswahl",
                    "icon": "tasks",
                    "view": "checkbox",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                            {
                                "label": "Antwort+1",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+2",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+3",
                                "is_default": false
                            }
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "",
                        "answeroptionsfunction": "",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "list",
                    "label": "Auswahlliste",
                    "icon": "list",
                    "view": "select",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                            {
                                "label": "Antwort+1",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+2",
                                "is_default": false
                            },
                            {
                                "label": "Antwort+3",
                                "is_default": false
                            }
                        ],
                        "multiselect": false,
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "",
                        "answeroptionsfunction": "",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "countrylist",
                    "label": "Länderliste",
                    "icon": "globe",
                    "view": "country",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "common\\helpers\\CheckoutHelper",
                        "answeroptionsfunction": "getCountryList",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "paymentmethodselection",
                    "label": "Auswahl Zahlungsmethode",
                    "icon": "ballot-check",
                    "view": "paymentmethod",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "common\\helpers\\CheckoutHelper",
                        "answeroptionsfunction": "getPaymentMethods",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "shippingmethodselection",
                    "label": "Auswahl Liefermethode",
                    "icon": "ballot-check",
                    "view": "shippingmethod",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "common\\helpers\\CheckoutHelper",
                        "answeroptionsfunction": "getShippingMethods",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "addressselection",
                    "label": "Auswahl Adresse",
                    "icon": "ballot-check",
                    "view": "address",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "answeroptions": [
                        ],
                        "customanswer": false,
                        "customanswerlabel": "",
                        "customanswerplaceholder": "",
                        "alphabeticalorder": false,
                        "randomorder": false,
                        "htmloptions": "",
                        "htmlpurify": false,
                        "answeroptionsclass": "common\\helpers\\CheckoutHelper",
                        "answeroptionsfunction": "getAddresses",
                        "customvalidation": "",
                        "customerrormessage": ""
                    }
                },
            ]
        },
        {
            "key": "extended",
            "label": "Erweiterte Felder",
            "elements": [
                {
                    "type": "formitem",
                    "key": "termsofuse",
                    "label": "Nutzungsbedingungen",
                    "icon": "check-square",
                    "view": "terms",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "textBox": "",
                        "confirmLabel": "",
                        "htmloptions": "",
                        "htmlpurify": false,
                        "defaultvalueclass": "",
                        "defaultvaluefunction": ""
                    }
                },
                {
                    "type": "formitem",
                    "key": "fileupload",
                    "label": "Dateiupload",
                    "icon": "upload",
                    "view": "file",
                    "initData": {
                        "mandatory": false,
                        "mandatory_warning": "",
                        "question": "",
                        "description": "",
                        "textBox": "",
                        "confirmLabel": "",
                        "filemaxuploads": 0,
                        "filemaxsize": 0,
                        "filemaxwidth": 0,
                        "filemaxheight": 0,
                        "fileacceptedmimes": "",
                        "htmloptions": ""
                    }
                }
            ]
        },
        {
            "key": "structure",
            "label": "Struktur",
            "elements": [
                {
                    "type": "formitem",
                    "key": "button",
                    "label": "Button",
                    "icon": "hand-pointer",
                    "view": "button",
                    "initData": {
                        "buttontext": "Absenden",
                        "htmloptions": "btn-primary",
                        "question": ""
                    }
                },
                {
                    "type": "formsection",
                    "key": "section",
                    "label": "Abschnitt",
                    "icon": "sitemap",
                    "view": "section"
                },
                {
                    "type": "formitem",
                    "key": "embeddedcode",
                    "label": "Embedded code",
                    "icon": "code",
                    "view": "html",
                    "initData": {
                        "embedHtml": "",
                        "htmloptions": "",
                        "question": ""
                    }
                }
            ]
        }
    ]
};