var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    _vm._b(
      {
        staticClass: "drop-area p-3 rounded",
        attrs: {
          list: _vm.structure,
          group: { name: "structure" },
          handle: ".handle",
          "empty-insert-threshold": 100,
          tag: "div",
        },
        on: { start: _vm.start, end: _vm.end },
      },
      "draggable",
      _vm.dragOptions,
      false
    ),
    [
      _c(
        "transition-group",
        { attrs: { type: "transition", name: !_vm.drag ? "flip-list" : null } },
        _vm._l(_vm.structure, function (element, index) {
          return _c("drop-element", {
            key: _vm.getKey(element),
            attrs: { element: element, "put-section-area": _vm.putSectionArea },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }