var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    class: _vm.options.alignment,
    style: _vm.style,
    attrs: {
      src: _vm.options.src,
      alt: _vm.options.label,
      "data-modal_id": _vm.options.modal_id,
    },
    on: { dblclick: _vm.handleDoubleClick, load: _vm.imgLoaded },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }