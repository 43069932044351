<template>
    <div class="tabs" v-if="props.tabs">
        <div v-for="(tab, i) in props.tabs" :key="i"
             @click="tabContainsContent(i) ?  (setActiveTab(i), emit('tab-changed', tab)) : null"
             class="tab"
             :class="{active: activeTab === i, disabled: !tabContainsContent(i)}">
            {{ tab }}
        </div>
        <div class="active-bg" :style="{ width: tabWidth, transform: `translateX(${activeTab * 100}%)` }"></div>
    </div>
</template>

<script setup>
import {computed, ref, defineProps, onMounted} from "vue";

const emit = defineEmits(['tab-changed'])

const props = defineProps({
    tabs: {
        type: Array,
        default: []
    },
    availableTabsByIndex: {
        type: Array,
        default: []
    }
})

const tabContainsContent = (index) => {
    return props.availableTabsByIndex[index]
}

const activeTab = ref(0);

const setActiveTab = (index) => {
    activeTab.value = index;
    if (index !== 0) {
        window.location.hash = `tab-${index + 1}`;
    } else {
        removeHashFromUrl()
    }
};

const checkForAnchorAndSwitchTabIfAvailable = () => {
    const hash = window.location.hash;
    if (hash && hash.startsWith('#tab-')) {
        const index = parseInt(hash.substring(5)) - 1;
        if (!isNaN(index) && index >= 1 && index < props.tabs.length && tabContainsContent(index)) {
            activeTab.value = index;
            emit('tab-changed', props.tabs[index])
        } else {
            removeHashFromUrl()
        }
    }
}

const removeHashFromUrl = () => {
    window.location.hash = ''
    history.replaceState({}, document.title, window.location.href.replace('#', ''));
}

onMounted(() => {
    checkForAnchorAndSwitchTabIfAvailable()
})

const tabWidth = computed(() => `${100 / props.tabs.length}%`);

</script>

<style scoped lang="scss">
.tabs {
    display: flex;
    position: relative;
    text-align: center;
    background: rgba(65, 65, 65, 1);
    border-radius: 12px;
    padding: 4px 5px;
    margin-top: 80px;
    font-family: Inter-Regular;
    z-index: 1;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1;
    font-family: Inter-Medium;
    min-width: 120px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #B9B9B9;
    border-radius: 12px;
    transition: .5s color ease-in-out;

    -webkit-user-select: none;
    user-select: none;

    &.active {
        color: rgb(255, 255, 255);
    }
}

.active-bg {
    position: absolute;
    bottom: 0;
    height: 100%;
    background-color: blue;
    transition: transform 0.3s ease;
    z-index: 0;
    border-radius: 12px;
    background: #555555;
    left: 0;
    border: 4px solid rgba(65, 65, 65, 1);
}

.disabled {
    opacity: 0.2;
}
</style>