<template>
    <draggable
        class="drop-area p-3 rounded"
        :list="structure"
        :group="{ name: 'structure' }"
        handle=".handle"
        v-bind="dragOptions"
        :empty-insert-threshold="100"
        tag="div"
        @start="start"
        @end="end">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <drop-element
                v-for="(element, index) in structure"
                :key="getKey(element)"
                :element="element"
                :put-section-area="putSectionArea" />
        </transition-group>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "DropArea",
    components: {
        draggable
    },
    props: {
        structure: {
            type: Array
        }
    },
    data: function() {
        return {
            drag: false,
            putSectionArea: true
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        getKey(item) {
            if(item.type === 'formsection') {
                return item.data.id;
            } else {
                // formitem
                return item.items[0].data.id;
            }
        },
        start(event) {
            this.drag = true;

            // disable put on drop area in formsection elements
            if (event.item.classList.contains('formsection')) {
                this.putSectionArea = false;
            }
        },
        end() {
            this.drag = false;
            this.putSectionArea = true;
        },
    }
};
</script>

<style lang="scss" scoped>
.drop-area {
    min-height: 400px;
    border: 2px dashed #747474;
    background-color: #ededed;

    span {
        display: block;
        min-height: 400px;
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
</style>