<template>
    <div class="date-segment-rule">
        <v-select v-model="operator" :options="operators" :reduce="label => label.key" class="segment-rule-operator" :clearable="false"></v-select>
        <datetime v-model="content" hide-backdrop="hide-backdrop" class="segment-rule-content"></datetime>
        <datetime v-if="operator === 'between'" v-model="content2" hide-backdrop="hide-backdrop" class="segment-rule-content2"></datetime>
    </div>
</template>

<script>
import SegmentRuleBase from "./SegmentRuleBase.vue"
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
import "../../scss/vue-datetime.scss"
export default {
    extends: SegmentRuleBase,
    components: {
        datetime: Datetime
    },
    data() {
        return {
            operators: [
                {
                    label: this.labels.equal,
                    key: "equal"
                },
                {
                    label: this.labels.not_equal,
                    key: "not_equal"
                },
                {
                    label: this.labels.lesser,
                    key: "lesser"
                },
                {
                    label: this.labels.greater,
                    key: "greater"
                },
                {
                    label: this.labels.between,
                    key: "between"
                }
            ]
        }
    }
};
</script>