<template>
    <div>
        <img :src="field.accessor(item)" v-if="field.accessor(item) !== ''" />
        <i class="fas fa-image" v-else></i>
    </div>
</template>

<script>
export default {
    name: 'Media',
    props: {
        field: {
            type: Object
        },
        item: {
            type: Object
        }
    }
};
</script>

<style lang="scss" scoped>
img {
    width: 40px;
    height: 45px;
    object-fit: cover;
    border-radius: 5px;
}
i {
    font-size: 2.4rem;
}
</style>