import tableDragger from "table-dragger";

/**
 * Drag&Drop for GridView columns
 */
class ColumnDrag {
    /**
     * Pass the gridView table to the class
     * @param options
     */
    constructor(options) {
        this.grid = null;
        this.table = options.table;
        this.columns = {};

        this.initDragger();
    }

    /**
     * Initialise the dragger
     */
    initDragger(table) {
        let that = this;

        if (table) this.table = table;

        this.grid = this.table.closest('.grid-view');

        let dragger = tableDragger(this.table, {
            mode: 'column',
            dragHandler: 'th',
            onlyBody: true,
        });

        this.updateColumnsPosition(this.table);

        dragger.on('drop', function (from, to, dragTable) {
            that.updateColumnsPosition(dragTable);
            that.httpUpdateColumnsPosition(that.columns);
        });
    }

    /**
     * Update column positions
     * @param table
     */
    updateColumnsPosition(table) {
        let ths = table.querySelectorAll('th[data-column]');
        this.columns[this.grid.id] = {};

        let position = 0;
        for (let th of ths) {
            this.columns[this.grid.id][th.getAttribute('data-column')] = {
                pos: position
            };
            position++;
        }
    }

    /**
     * Update column positions in the database
     * @param columns
     */
    httpUpdateColumnsPosition(columns) {
        let http = new XMLHttpRequest();
        let currentUrlParts = window.yii.getCurrentUrl().split('/');

        let url = `/${currentUrlParts[3]}/${currentUrlParts[4]}/core/gui/update-gui-settings`;

        columns[window.yii.getCsrfParam()] = window.yii.getCsrfToken();
        let params = $.param(columns);

        http.open('post', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        http.onreadystatechange = function () {//Call a function when the state changes.

            if (http.readyState == 4 && http.status == 200) {
                let res = JSON.parse(http.responseText);

                if (!res['errors'] && res['success']) {
                    console.log(res['success']);
                } else if (res['errors']) {
                    console.error(res['errors']);
                } else {
                    console.log(JSON.stringify(res));
                }
            }
        };

        http.send(params);
    }
}

/**
 * Wait for content loaded and collect all gridView tables
 */
window.addEventListener('DOMContentLoaded', () => {
    let gridTables = document.querySelectorAll('.grid-view table');

    let i = 0;
    for (let gridTable of gridTables) {
        let dragTable = new ColumnDrag({
            table: gridTable
        });

        let position = i;
        /**
         * Re-initialise the dragger after pjax success for all found gridView tables
         */
        $(document).on('ready pjax:success', function () {
            let gridTables = document.querySelectorAll('.grid-view table');
            dragTable.initDragger(gridTables[position]);
        });

        i++;
    }
});
