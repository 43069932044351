<template>
  <div>
    <div class="content">
      <Notification/>

      <div class="mb-5">
        <button class="btn btn-primary" @click="saveForm">
          {{ $translations['button.saveform'] }}
        </button>
      </div>

      <DropArea
          :structure="structure"/>
    </div>
    <div class="sidebar">
      <div v-if="!active" class="sidebar-inner">
        <SidebarFormSettings
            :csrf-token="csrfToken"
            :email-url="emailUrl"
            :form-id="formId"
            :ident="formIdent"
            :name="formName"
            @update-formname="updateFormname"
            @update-formident="updateFormIdent"
        />
        <SidebarElements :structure="structure"/>
      </div>
      <div v-else class="sidebar-inner p-2">
        <component
            :is="activeCapitalized"
            :element="active"
            :logic-options="logicOptions"/>
      </div>
    </div>
    <!-- div>
        <pre>
{{ JSON.stringify(structure, null, 2) }}
        </pre>
    </div -->
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import eventBus from "./util/EventBus";
import capitalize from "./util/capitalize";
import DropArea from "./content/DropArea.vue";
import SidebarElements from "./sidebar/SidebarElements.vue";
import SidebarFormSettings from "./sidebar/SidebarFormSettings.vue";

import Input from "./sidebar/views/Input.vue";
import Instruction from "./sidebar/views/Instruction.vue";
import Scale from "./sidebar/views/Scale.vue";
import Numericscale from "./sidebar/views/Numericscale.vue";
import Radio from "./sidebar/views/Radio.vue";
import Checkbox from "./sidebar/views/Checkbox.vue";
import Select from "./sidebar/views/Select.vue";
import Country from "./sidebar/views/Country.vue";
import Terms from "./sidebar/views/Terms.vue";
import File from "./sidebar/views/File.vue";
import Button from "./sidebar/views/Button.vue";
import Section from "./sidebar/views/Section.vue";
import Html from "./sidebar/views/Html.vue";
import Paymentmethod from "./sidebar/views/Paymentmethod.vue";
import Shippingmethod from "./sidebar/views/Shippingmethod.vue";
import Address from "./sidebar/views/Address.vue";

import Notification from "./content/Notification.vue";

export default {
  name: 'FormEditor',
  components: {
    SidebarFormSettings,
    DropArea,
    SidebarElements,
    Input,
    Instruction,
    Scale,
    Numericscale,
    Radio,
    Checkbox,
    Select,
    Country,
    Terms,
    File,
    Button,
    Section,
    Html,
    Notification,
    Paymentmethod,
    Shippingmethod,
    Address
  },
  data: function () {
    return {
      elements: [],
      structure: [],
      active: null,
      locale: null,
      formName: null,
      formId: null,
      csrfTokenName: null,
      csrfToken: null,
      emailUrl: null,
      formIdent: null,
    };
  },
  computed: {
    activeCapitalized() {
      if (this.active.type === 'formsection') {
        return "Section";
      }

      return capitalize(this.active.items[0].data.view);
    },
    logicOptions() {
      const options = [];

      const views = ['radio', 'checkbox', 'select'];

      this.structure.forEach(structureElement => {
        if (structureElement.type === 'formitem') {
          if (views.includes(structureElement.items[0].data.view)) {
            options.push({
              id: structureElement.items[0].data.id,
              label: structureElement.items[0].data.question,
              view: structureElement.items[0].data.view,
              answers: structureElement.items[0].data.answeroptions
            });
          }
        } else {
          structureElement.items.forEach(item => {
            if (views.includes(item.items[0].data.view)) {
              options.push({
                id: item.items[0].data.id,
                label: item.items[0].data.question,
                view: item.items[0].data.view,
                answers: item.items[0].data.answeroptions
              });
            }
          });
        }
      });

      return options;
    }
  },
  mounted() {
    // get init data from element attibutes
    this.structure = eval(this.$attrs['form-structure']) || [];

    this.structure.forEach((structureElement, index) => {
      if (structureElement.type === 'formsection') {
        let items = structureElement.items.map(item => ({
          type: 'formitem',
          items: [{
            ...item
          }]
        }));

        this.structure[index].items = items;
      }
    });

    this.locale = this.$attrs['locale'];
    this.formId = this.$attrs['form-id'];
    this.formName = this.$attrs['form-name'];
    this.csrfTokenName = this.$attrs['csrf-token-name'];
    this.csrfToken = this.$attrs['csrf-token'];
    this.emailUrl = this.$attrs['email-url'];
    this.formIdent = this.$attrs['form-ident'];

    // set active element for sidebar
    eventBus.$on('editElement', (item) => {
      this.active = item;
    });

    eventBus.$on('duplicateElement', (element) => {
      // get id for item to remove
      let id;

      if (element.type === 'formitem') {
        id = element.items[0].data.id;
      } else {
        id = element.data.id;
      }

      function updateItem(item) {
        const duplicate = JSON.parse(JSON.stringify(item));

        duplicate.items[0].data.id = uuidv4();

        if (duplicate.items[0].data.answeroptions) {
          duplicate.items[0].data.answeroptions.forEach((answerOption, i) => {
            duplicate.items[0].data.answeroptions[i].id = uuidv4();
          });
        }

        return duplicate;
      }

      this.structure.forEach((structureElement, index) => {
        if (structureElement.type === 'formitem') {
          if (structureElement.items[0].data.id === id) {
            const duplicate = updateItem(structureElement);

            this.structure.splice(index + 1, 0, duplicate);
          }
        } else {
          if (structureElement.data.id === id) {
            const duplicate = JSON.parse(JSON.stringify(structureElement));

            duplicate.data.id = uuidv4();

            duplicate.items.forEach((item, i) => {
              duplicate.items[i] = updateItem(duplicate.items[i]);
            });

            this.structure.splice(index + 1, 0, duplicate);
          }
        }
      });

      // item not found on first level, search for id in section items

      this.structure.forEach((structureElement, index) => {
        if (structureElement.type === 'formsection') {
          for (let i = 0; i < structureElement.items.length; i++) {
            if (structureElement.items[i].items[0].data.id === id) {
              let duplicate = JSON.parse(JSON.stringify(structureElement.items[i]));

              duplicate = updateItem(duplicate);

              this.structure[index].items.splice(i + 1, 0, duplicate);
            }
          }
        }
      });
    });

    // cancel editing: hide sidebar
    eventBus.$on('cancelElement', () => {
      this.active = null;
    });

    // remove element
    eventBus.$on('removeElement', (element) => {
      // get id for item to remove
      let id;

      if (element.type === 'formitem') {
        id = element.items[0].data.id;
      } else {
        id = element.data.id;
      }

      // look for item to delete on first level
      this.structure.forEach((structureElement, index) => {
        if (structureElement.type === 'formitem') {
          if (structureElement.items[0].data.id === id) {
            this.structure.splice(index, 1);
          }
        } else {
          if (structureElement.data.id === id) {
            this.structure.splice(index, 1);
          }
        }
      });

      // look for item to delete in sections / second level
      this.structure.forEach((structureElement, index) => {
        if (structureElement.type === 'formsection') {
          // debugger;

          for (let i = 0; i < structureElement.items.length; i++) {
            if (structureElement.items[i].items[0].data.id === id) {
              this.structure[index].items.splice(i, 1);
              break;
            }
          }
        }
      });
    });

    eventBus.$on('saveElement', (item) => {
      this.structure.forEach((element, index) => {
        if (element.type === 'formsection') {
          if (element.data.id === item.data.id) {
            this.$set(this.structure[index], 'data', item.data);
            this.$set(this.structure[index], 'logic', item.logic);
            this.active = null;
          }

          element.items.forEach((children, i) => {
            if (children.items[0].data.id === item.data.id) {
              this.$set(this.structure[index].items[i], 'data', item.data);
              this.$set(this.structure[index].items[i], 'logic', item.logic);
              this.active = null;
            }
          });
        } else {
          element.items.forEach(structureItem => {
            if (item.data.id === structureItem.data.id) {
              this.$set(this.structure[index].items[0], 'data', item.data);
              this.$set(this.structure[index].items[0], 'logic', item.logic);
              this.active = null;
            }
          });
        }
      });
    });
  },
  methods: {
    saveForm() {
      // prepare structure for body
      const structure = [];

      this.structure.forEach(structureElement => {
        if (structureElement.type === 'formitem') {
          structure.push(structureElement);
        } else {
          // type == 'formsection'
          const newElement = {
            type: structureElement.type,
            data: structureElement.data,
            items: []
          };

          structureElement.items.forEach(item => {
            newElement.items.push(item.items[0]);
          });

          structure.push(newElement);
        }
      });

      // prepare content for body
      const content = {};

      this.structure.forEach(structureElement => {
        if (structureElement.type === 'formsection') {
          // section
          structureElement.items.forEach(item => {
            content[item.items[0].data.id] = this.getContentForItem(item.items[0]);
          });
        } else {
          // formitem
          const item = structureElement.items[0];

          content[item.data.id] = this.getContentForItem(item);
        }
      });

      const formData = new FormData();

      formData.append('id', this.formId);
      formData.append('name', this.formName);
      formData.append('ident', this.formIdent);
      formData.append('structure', JSON.stringify(structure));
      formData.append('content', JSON.stringify(content));
      formData.append('locale', this.locale);

      const data = new URLSearchParams();

      for (const pair of formData) {
        data.append(pair[0], pair[1]);
      }

      fetch('saveform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'X-CSRF-Token': this.csrfToken
        },
        body: data
      })
          .then(res => {
            if (res.status === 200) {
              // alert('success');
              eventBus.$emit('add-notification', {
                type: 'success',
                message: 'Das Formular wurde erfolgreich gespeichert.'
              });
            } else {
              eventBus.$emit('add-notification', {
                type: 'danger',
                message: 'Das Formular konnte nicht gespeichert werden'
              });
            }
          });
    },
    getContentForItem(item) {
      const result = {};

      const contentFields = [
        'mandatory_warning',
        'question',
        'description',
        'placeholder',
        'customanswerlabel',
        'scaleMinLabel',
        'scaleMaxLabel',
        'textBox',
        'confirmLabel',
        'buttontext',
        'customvalidation',
        'customerrormessage'
      ];

      Object.keys(item.data).forEach(key => {
        if (contentFields.includes(key)) {
          result[key] = item.data[key];
        } else if (key === 'answeroptions') {
          item.data[key].forEach(a => {
            result[a.id] = {
              label: a.label
            }
          })
        }
      });

      return result;
    },
    updateFormname(formName) {
      this.formName = formName;
    },
    updateFormIdent(formIdent) {
      this.formIdent = formIdent;
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar-inner {
  overflow-y: scroll;
  height: calc(100vh - 122px);
  overscroll-behavior: contain;
}
</style>