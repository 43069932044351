var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: _setup.props.src, staticClass: "kenburns-wrap" },
    [
      _c(_setup.ImageLoader, {
        staticClass: "kenburns",
        attrs: { src: _setup.props.src },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }