import Vue from "vue"
import StaggeredDiscount from "./StaggeredDiscount.vue"
import vSelect from "vue-select"

const staggeredDiscountElement = document.getElementById("staggered-discount-module-root")

if (staggeredDiscountElement) {
    Vue.component("v-select", vSelect)
    new Vue({
        name: "StaggeredDiscountModuleRoot",
        el: staggeredDiscountElement,
        components: {
            StaggeredDiscount
        }
    });
}