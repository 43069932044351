var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "color-dots" },
    [
      _vm._l(_setup.genColors, function (color, index) {
        return _c("span", {
          key: `${color}_${index}`,
          class: { "--has-border": color.toLowerCase() === "#ffffff" },
          style: { color: color, "z-index": 20 - index },
        })
      }),
      _vm._v(" "),
      _setup.hasMoreColors
        ? _c(
            "span",
            {
              staticClass: "more-colors",
              style: {
                color: "#ffffff",
                "z-index": 20 - _setup.genColors.length,
              },
            },
            [_vm._v("\n    +\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }