/**
 *
 * @param model
 * @param id
 * @returns {*[]}
 */
function getPath(model, id) {
    let path,
        item = model;
    if (!model || typeof model !== 'object') {
        return;
    }
    if (model.id == id) {
        return [model.id];
    }
    (model.items || []).some(child => (path = getPath(child, id)));
    return path && [...path,...[item.id]]
}

/**
 *
 * @param tree
 * @param id
 * @returns {*[]}
 */
export function getParents(tree, id) {
    let parents = [];
    tree.forEach(item => {
        const result = getPath(item, id);
        if(result) {
            parents = result;
        }
    });
    return parents;
}

/**
 *
 * @param node
 * @returns {*[]}
 */
export function getAllChildren(node, wantedKey = 'id') {
    let children = [];
    if(Array.isArray(node)) {
        node.forEach(v => {
            if(v[wantedKey]) {
               children = [...children, ...[v[wantedKey]]]
            }
            if(v.items) {
                children = [...children, ...getAllChildren(v.items, wantedKey)]
            }
        })
    }
    return children;
}