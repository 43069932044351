<template>
  <div class="colorpicker">


    <!-- <div class="colorpicker__icon" @click="showColors">
       <img src="/assets/commerce/Colorpicker-Wheel.png" alt="colorpicker-icon">
       <span>Klicken zum Farbe auswählen</span>
    </div> -->

    <swatches
        ref="swatches"
        v-model="color"
        :swatches="swatches"
        v-bind="options"
    >
    </swatches>
    <input ref="inputColor" slot="trigger" :class="inputClass" :placeholder="inputPlaceholder" :title="inputValue"
           style="display: none"
           type="text" v-bind:value="color" @submit.prevent="setColor()">

    <span v-if="color == ''" class="label"> {{ translations.select_color }} </span>

    <div v-if="color !== ''"
         class="colorpicker__reset"
         @click="resetColor"
    >
      <span class="label">{{ translations.reset_color }}</span> <i class="fontello-icon-close"></i>
    </div>

    <!-- Hidden Field -->
    <input :name="inputName"
           aria-invalid="false"
           type="hidden"
           v-bind:value="color"
    />
  </div>
</template>

<script>
import Base from './Base.vue';
// import {DRIBBLE} from '../../../../../../backend/styles/constants.js'
import FIRECOLORS from '../fireDefaultColors.js';

export default {
  name: 'DribbleColorpicker',
  extends: Base,

  props: [
    'parentEventName',
  ],
  data() {
    return {
      swatches: FIRECOLORS,
      showSwatches: false
    }
  },
  created() {
    // this.colors = DRIBBLE;
    // Parse value and options from widget
    this.color = this.$props.inputValue ? this.color = this.$props.inputValue : '';
    if (this.options['show-fallback']) {
      delete this.options['show-fallback'];
    }

    if (!this.options['swatch-size']) {
      this.options['swatch-size'] = 17;
    }

    if (!this.options['swatch-style']) {
      this.options['swatch-style'] = {
        'margin': '0',
        'border-radius': '0',
        'float': 'left',
        'height': '20px',
        'width': '20px'
      }
    }

    if (!this.options['spacing-size']) {
      this.options['spacing-size'] = 0;
    }
  },
  watch: {
    inputValue(value) {
      this.color = typeof value === 'string' ? value : '';
    }
  },
  methods: {
    showColors() {
      this.showColors = true;
    },
    resetColor() {
      this.color = '';
    },
    /**
     * Switch color on input change
     */
    setColor(that) {
      if (typeof this.$refs['inputColor'].value === 'string' &&
          this.isColor(this.$refs['inputColor'].value)) {

        this.color = this.$refs['inputColor'].value;
        this.emitEvent();

        // Close swatches with click
        this.$refs['inputColor'].click();
      } else if (this.$refs['inputColor'].value === '' || this.$refs['inputColor'].value === 'undefined') {

        this.color = '';
        this.emitEvent();
        this.$refs['inputColor'].click();
      }
    },

    /**
     * Switch color on swatch change
     * @param color
     */
    setSwatchColor(color) {
      this.color = typeof color === 'string' ? color : '';
      this.emitEvent();
    },

    /**
     * Emit event if set from parent
     */
    emitEvent() {
      if (this.$props.parentEventName) {
        this.$emit(this.$props.parentEventName, {color: this.color});
      }
    },
    /**
     * Focus input on open swatches
     */
    focusInput() {
      let that = this;

      // Handle submit on input.
      let forms = window.document.querySelectorAll('form');
      for (let form of forms) {
        form.addEventListener('keypress', handleInput);
        form.addEventListener('keyup', handleInput);
      }

      /**
       * Disable form submit on enter and set color if enter hit cause focus is on input
       * @param e
       * @returns {boolean}
       */
      function handleInput(e) {
        let keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
          e.preventDefault();

          that.setColor();

          // Remove listener after color is set
          for (let form of forms) {
            form.removeEventListener('keypress', handleInput);
            form.removeEventListener('keyup', handleInput);
          }

          return false;
        }
      }

      this.$refs.inputColor.focus();
    },

    /**
     * https://stackoverflow.com/a/2819658/9015191
     * @param str
     * @returns {boolean}
     */
    isColor(str) {
      return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(str) ||
          /^rgb\s*(\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*)$/i.test(str) ||
          this.colorNames.includes(str.toLowerCase());
    }
  }
}
</script>

<style lang="scss">
.colorpicker {
  display: flex;
  align-items: center;

  .vue-swatches {
    width: 30px;
    height: 30px;
    margin-right: 1em;
  }

  .vue-swatches__trigger {
    width: 30px !important;
    height: 30px !important;
  }

  .vue-swatches__trigger__wrapper {
    width: 30px !important;
    height: 30px !important;
  }

  .vue-swatches__diagonal {
    width: 75%;
    height: 75%;
    background: linear-gradient(to top right, transparent 0, transparent calc(50% - 1px), #de080a 50%, transparent calc(50% + 1px), transparent);
  }

  .vue-swatches__trigger.vue-swatches--is-empty {
    border: 1px solid #c6cdc8;
    width: 30px !important;
    height: 30px !important;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
  }

  .vue-swatches__container {
    box-shadow: 0px 10px 20px 0px rgba(34, 44, 44, .15%) !important;
  }

  .label {
    font-size: .65em;
    margin-right: .75em;
  }

  &__reset {
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      font-size: .4em;
    }
  }
}
</style>