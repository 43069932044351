var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.config.groups, function (group) {
      return _c(
        "div",
        { key: group.key },
        [
          _c("div", { staticClass: "text-muted text-uppercase p-3" }, [
            _vm._v("\n            " + _vm._s(group.label) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              attrs: {
                list: group.elements,
                sort: false,
                group: { name: "structure", pull: "clone", put: false },
                clone: _vm.clone,
              },
            },
            _vm._l(group.elements, function (element, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "sidebar-element shadow-sm p-3 mx-1 mb-1 rounded",
                },
                [
                  _c("i", {
                    staticClass: "fa mr-2",
                    class: [`fa-${element.icon}`],
                  }),
                  _vm._v(" " + _vm._s(element.label) + "\n            "),
                ]
              )
            }),
            0
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }