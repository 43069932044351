"use strict";

document.addEventListener(('DOMContentLoaded'), () => {
    /**
     * Revert a dropdown change.
     *
     * @param evt
     */
    const revert = function(evt) {
        const element = evt.target.previousElementSibling;
        const previous = element.selectedIndex;
        element.selectedIndex = element.getAttribute('data-previous-index');
        element.setAttribute('data-previous-index', previous);
        element.setAttribute('data-reverting', 'true');
        element.dispatchEvent(new Event('change'));
        evt.target.parentElement.removeChild(evt.target);
    }

    /**
     * Add a button for reverting a change.
     *
     * @param dropdown
     */
    const addRevertButton = function(dropdown) {
        if(dropdown.getAttribute('data-reverting') === 'true') {
            dropdown.removeAttribute('data-reverting');
            return; // don't add, revert just completed
        }
        if(dropdown.nextElementSibling !== null) {
            return; // already added
        }
        const button = document.createElement('button');
        button.type = 'button';
        button.classList.add('dropdown-revert');
        button.innerText = '↶';
        dropdown.after(button);
        button.addEventListener('click', revert);
    }

    /**
     * Handle a failure to save.
     *
     * @param element
     * @param data
     * @param message
     */
    const failSave = function(element, data, message = null) {
        if(message === null) {
            message = element.getAttribute('data-error-message');
        }
        alert(message);
        element.selectedIndex = element.getAttribute('data-previous-index');
        console.error(data);
        element.classList.add('error');
    }

    /**
     * Save the previously selected input, for reverting and if the save fails.
     *
     * @param evt
     */
    const savePreviouslySelected = function(evt) {
        evt.target.setAttribute('data-previous-index', evt.target.selectedIndex);
    }

    /**
     * Create data for a fetch request for changing the selected value.
     *
     * @param element
     * @returns {{headers: {"X-CSRF-Token": *, "Content-Type": string}, method: string, body: string}}
     */
    const createRequestData = function(element) {
        return {
            method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                    'X-CSRF-Token': yii.getCsrfToken()
            },
            body: JSON.stringify({
                attribute: element.getAttribute('data-attribute'),
                model: element.getAttribute('data-model'),
                id: element.getAttribute('data-id'),
                value: element.value
            })
        };
    }

    /**
     * Change the selected option in a select list
     *
     * @param options
     * @param json
     */
    const changeSelectedOption = function(options, json) {
        for (let i = 0; i < options.length; i++) {
            if (options[i].value == json.value) {
                options[i].selected = true;
                options[i].setAttribute('selected', 'selected');
            } else {
                options[i].selected = false;
                options[i].removeAttribute('selected');
            }
        }
    }

    /**
     * Handle the dropdown change event. Submits an ajax request to change the value.
     *
     * @param evt
     */
    const dropdownChange = function(evt)  {
        evt.target.classList.add('loading');
        const url = evt.target.attributes['data-url'].value;
        const response = fetch(url, createRequestData(evt.target)).then(data => {
            data.json().then(json => {
                if (data.status === 200 && json.status === 'success') {
                    changeSelectedOption(evt.target.children, json);
                    evt.target.classList.add('changed');
                    addRevertButton(evt.target);
                } else {
                    failSave(evt.target, json, json.message);
                }
            }).catch(reason => {
                failSave(evt.target, {'data': data, 'reason': reason});
            });
        }).catch(reason => {
            failSave(evt.target, reason);

        }).finally(() => {
            evt.target.blur();
            evt.target.classList.remove('loading');
        });
    }

    document.querySelectorAll('.node-option-status').forEach(node => {
        node.addEventListener('focus', savePreviouslySelected)
        node.addEventListener('change', dropdownChange);
    });
});