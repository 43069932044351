var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "type-segment-rule" },
    [
      _c("v-select", {
        staticClass: "segment-rule-operator",
        attrs: {
          options: _vm.operators,
          reduce: (label) => label.key,
          clearable: false,
        },
        model: {
          value: _vm.operator,
          callback: function ($$v) {
            _vm.operator = $$v
          },
          expression: "operator",
        },
      }),
      _vm._v(" "),
      _vm.showContent
        ? _c("v-select", {
            staticClass: "segment-rule-content segment-rule-content-large",
            attrs: {
              options: _vm.contentOptions(),
              reduce: (label) => label.key,
              clearable: false,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function (option) {
                    return [
                      _c("span", [_vm._v(_vm._s(option.label))]),
                      _vm._v(" "),
                      option.sublabel
                        ? _c("span", { staticClass: "small text-muted ml-1" }, [
                            _vm._v(_vm._s(option.sublabel)),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "selected-option",
                  fn: function (option) {
                    return [
                      _c("span", [_vm._v(_vm._s(option.label))]),
                      _vm._v(" "),
                      option.sublabel
                        ? _c("span", { staticClass: "small text-muted ml-1" }, [
                            _vm._v(_vm._s(option.sublabel)),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              3408585421
            ),
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }