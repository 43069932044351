<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.embeddedcode'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <code class="bg-light">{{ element.items[0].data.embedHtml }}</code>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Html",
    mixins: [previewItemMixin],
};
</script>