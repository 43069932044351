import Vue from 'vue';

let iPadIcon = '<svg version="1.1" viewBox="0 0 44.8 58.8" xmlns="http://www.w3.org/2000/svg"><path d="m3.54 0a3.55 3.55 0 0 0-3.54 3.54v51.7c0 1.95 1.6 3.54 3.56 3.54h37.7a3.55 3.55 0 0 0 3.55-3.54v-51.7c0-1.96-1.6-3.54-3.56-3.54h-0.0098zm18.9 2.03c0.29 0 0.529 0.241 0.529 0.531a0.535 0.535 0 0 1-1.07 0c0-0.29 0.241-0.531 0.541-0.531zm-19.6 3.19h39.1v48.3h-39.1zm19.5 49.5a1.06 1.06 0 0 1 0.0449 0 1.06 1.06 0 0 1 0 2.13 1.06 1.06 0 0 1-0.0449-2.13z" fill="#fff" fill-rule="evenodd"/></svg>';

let iPhoneIcon = '<svg version="1.1" viewBox="0 0 33.6 61.6" xmlns="http://www.w3.org/2000/svg"><path d="m4.12 0a4.12 4.12 0 0 0-4.12 4.13v53.4a4.12 4.12 0 0 0 4.12 4.12h25.4a4.12 4.12 0 0 0 4.12-4.12v-53.4a4.12 4.12 0 0 0-4.12-4.13zm8.45 2.44a0.36 0.36 0 0 1 0.0156 0 0.36 0.36 0 0 1 0.0137 0h8.4a0.36 0.36 0 0 1 0 0.721h-8.4a0.361 0.361 0 0 1-0.0293-0.721zm-10.8 4.26h30.1v48.2h-30.1zm12.1 49.5a1.65 1.65 0 0 1 0.0137 0 1.65 1.65 0 0 1 0.0684 0h5.75a1.65 1.65 0 1 1 0 3.3h-5.75a1.65 1.65 0 0 1-0.082-3.3z" fill="#fff" fill-rule="evenodd"/></svg>';
let desktopIcon = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.334 99.333">' +
    '<path d="M95.834,5.13H3.5c-1.93,0-3.5,1.57-3.5,3.5v59c0,0.001,0.002,0.003,0.002,0.005v9.005c0,1.141,0.924,2.063,2.063,2.063' +
    'h36.564c-0.184,4.056-1.082,12.198-5.293,13.983c0,0-1.635,1.517,2.939,1.517h7.83h10.792h7.83c4.571,0,2.938-1.517,2.938-1.517' +
    'c-4.211-1.785-5.108-9.928-5.293-13.983h36.938c1.141,0,2.014-0.923,2.014-2.063v-8.912c0.002-0.033,0.01-0.063,0.01-0.098v-59' +
    'C99.334,6.702,97.764,5.13,95.834,5.13z M47.25,71.63c0-1.334,1.082-2.417,2.418-2.417c1.334,0,2.416,1.082,2.416,2.417' +
    's-1.082,2.417-2.416,2.417C48.332,74.047,47.25,72.965,47.25,71.63z M96.334,65.293H3V8.63c0-0.276,0.225-0.5,0.5-0.5h92.334' +
    'c0.275,0,0.5,0.224,0.5,0.5V65.293z"/>' +
    '</svg>';
let macBookIcon = '<svg version="1.1" viewBox="0 0 72.4 44.8" xmlns="http://www.w3.org/2000/svg"><path d="m6.97 0a3.21 3.21 0 0 0-3.2 3.23v37.3c0 0.71 0.241 1.37 0.631 1.9h-4.36s-0.2 0.199 0.32 0.719c1.46 1.47 4.74 1.6 4.74 1.6h61.5c3.42 0 4.53-1.03 5.43-1.64 0.68-0.67 0.32-0.711 0.32-0.711l0.0098-0.0098h-4.3c0.37-0.53 0.6-1.17 0.6-1.87v-37.4a3.21 3.21 0 0 0-3.2-3.21zm-0.932 2.18h60.2v37.7h-60.2zm26 40.5h8.23v0.561h-8.23z" fill="#fff" fill-rule="evenodd"/></svg>';

//https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions
let sDevices = {
    macbook: {
        title: "Macbook Pro",
        icon: macBookIcon,
        width: 1536,
        height: 864,
        type: "laptop"
    },

    ipadprob: {
        title: "iPad Pro 12.9\"",
        icon: iPadIcon,
        width: 1024,
        height: 1366,
        type: "ipad"
    },
    ipadpro: {
        title: "iPad Pro 10.5\"",
        icon: iPadIcon,
        width: 834,
        height: 1112,
        type: "ipad"
    },
    ipad: {
        title: "iPad Mini",
        icon: iPadIcon,
        width: 768,
        height:  1024,
        type: "ipad"
    },

    iphonex: {
        title: "iPhone X",
        icon: iPhoneIcon,
        width: 375,
        height: 812,
        type: "iphone"
    },
    iphone6plus: {
        title: "iPhone 6+ - 8+",
        icon: iPhoneIcon,
        width: 414,
        height: 736,
        type: "iphone"
    },
    iphone6: {
        title: "iPhone 6-8",
        icon: iPhoneIcon,
        width: 375,
        height: 667,
        type: "iphone"
    },
    iphone5: {
        title: "iPhone 5",
        icon: iPhoneIcon,
        width: 320,
        height: 568,
        type: "iphone"
    },
    iphone2: {
        title: "iPhone 2-4",
        icon: iPhoneIcon,
        width: 320,
        height: 480,
        type: "iphone"
    },
    galaxys8: {
        title: "Galaxy S8",
        icon: iPhoneIcon,
        width: 360,
        height: 740,
        type: "iphone"
    },
    galaxys5: {
        title: "Galaxy S5",
        icon: iPhoneIcon,
        width: 360,
        height: 640,
        type: "iphone"
    },
    galaxys: {
        title: "Galaxy S",
        icon: iPhoneIcon,
        width: 320,
        height: 533,
        type: "iphone"
    },
    lumia1520: {
        title: "Lumia 1520",
        icon: iPhoneIcon,
        width: 432,
        height: 768,
        type: "iphone"
    },
    desktopfhd: {
        title: "Full HD 1080p",
        icon: desktopIcon,
        width: 1920,
        height: 1080,
        type: "monitor"
    },
    desktophdplus: {
        title: "HD+ 900p",
        icon: desktopIcon,
        width: 1600,
        height: 900,
        type: "monitor"
    },
    laptowxgaplus: {
        title: "WXGA+",
        icon: desktopIcon,
        width: 1440,
        height: 900,
        type: "monitor"
    },
    desktophd: {
        title: "HD 720p",
        icon:desktopIcon,
        width: 1366,
        height: 768,
        type: "monitor"
    },
    laptopwxga: {
        title: "WXGA",
        icon:desktopIcon,
        width: 1280,
        height: 800,
        type: "monitor"
    },
};

let devices = {};
let deviceRotate = 1;
let pageContainerWidth;
let clientFrameWindow;
let activeTabPageeditor;
$(function () {

    if ($("#element-canvas-iframe").length) {
        clientFrameWindow = $("#element-canvas-iframe").get(0).contentWindow;
        let isIpad = false;
        if (/iPad/.test(navigator.userAgent)) {
            isIpad = true;
        }

        let isIphone = false;
        if (/iPhone/.test(navigator.userAgent)) {
            isIphone = true;
        }


        $.each(sDevices, function (index, element) {
            if (isIpad) {
                if (element.type !== 'ipad') {
                    devices[index] = element;
                }
            } else if (isIphone) {
                if (element.type !== 'iphone') {
                    devices[index] = element;
                }
            } else {
                devices[index] = element;
            }
        });

        let deviceRotate = 0;//amplify.store('as_iframe_rotate');
        //if (typeof deviceRotate == 'undefined' || deviceRotate == 'undefined') {
        //amplify.store('as_iframe_rotate', 0);
        //}

        $(document).on("click", "#FrontendSwitchDevices", function (e) {
            e.preventDefault();

            activeTabPageeditor = $("[data-content-tab-id*=\"-pageeditor\"]").hasClass("active");
            if (false === activeTabPageeditor) {
                $("[data-content-tab-id*=\"-pageeditor\"]").trigger("click");
            }
            activeTabPageeditor = $("[data-content-tab-id=\"pageeditor\"]").hasClass("active");
            if (false === activeTabPageeditor) {
                $("[data-content-tab-id=\"pageeditor\"]").trigger("click");
            }
            let draftToggle;
            if ($(".dmp-delete-draft").length > 0) {
                draftToggle =   "<div class='draftToggle'>" +
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 22">\n' +
                    '  <rect fill="#1a1d1e" x="1.625" y="1" width="34.875" height="20" rx="9.997" ry="9.997"/>\n' +
                    '  <circle fill="#fff" cx="11.578" cy="11" r="7.484"/>\n' +
                    '</svg>' +
                    "Draft/Live" +
                    "</div>";
            } else {
                draftToggle = '';
            }

            let frame = $(".page-container");
            $("body").append("<div class='previewOverlay normal fadeInUp'></div>");
            $(".previewOverlay").append(
                "<div class='deviceChoices'>" +
                draftToggle +
                "<div class='deviceChoiceContainer'>" +
                "<div class='deviceChoiceBg'>" +
                "<div class='deviceUp'></div>" +
                "<div class='deviceDown'></div>" +
                "</div>" +
                "<div class='deviceChoicesInner'></div>" +
                "</div>" +
                "<div :data-rotate='1' class='deviceOptionRotate'>" +
                "<span class='fontello-icon-rotate'></span>" +
                "<span>Rotieren</span>" +
                "</div>" +
                "<div class='backButtonTarget'>" +
                "<button type='button' class='btn btn-pageeditor-previewback'><span class='fontello-icon-arrowback'></span> Zuruck</button>" +
                "</div>" +
                "<div class='submitButtonTarget'></div>" +
                "</div>"
            );
            frame.append("<div class='currentZoom'></div>");
            $(".previewOverlay .submitButtonTarget").append($(".submitButtonLive").clone());
            $(".deviceChoicesInner").append(
                '<div class="devicesList">' +
                '<div v-for="(device, index) in devices"  class="deviceOption" :data-rotate="device.rotate" :data-device="index">' +
                '<div class="deviceIcon" v-html="device.icon"></div>' +
                '<span>{{ device.title }}</span>' +
                /* <span>{{ device.width }}x{{ device.height }}</span>*/
                '</div>' +
                '</div>'
            );

            new Vue({
                el: '.previewOverlay',
                data: {
                    devices: sDevices
                }
            });
            pageContainerWidth = frame.attr("style");

            frame.addClass("previewScreen").addClass("rotate");
            $(".deviceOption:eq(1)").trigger("click");

            $(clientFrameWindow.document).find('html').addClass("iframe-preview-responsive-force");
            let iframe = $("#element-canvas-iframe");
            iframe.data('original-src', iframe.attr("src"));
            let theURL = new URL(iframe.attr("src"));
            $(".draftToggle").toggleClass("active", theURL.searchParams.has("draft"));
        });

        // trigger page preview if url contains &preview
        (function() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const preview = urlParams.get('preview')
            if (null !== preview) {
                $("#FrontendSwitchDevices").trigger("click");
            }
        })();
    }
});




var $iframePreviewDIV = $('.page-container'),
    $iframe = $('#element-canvas iframe');

function scaleIframe(width, height) {
    var deviceChoiceWidth = $('.deviceChoices').width() + 45,
        containerHeight = $('.previewOverlay').height() - 60,
        containerWidth = ($('.previewOverlay').width() - 23) - deviceChoiceWidth,
        scaleFactor = 1,
        scaleFactorWidth = 1,
        scaleFactorHeight = 1;

    if (containerHeight < height) {
        scaleFactorHeight = containerHeight / height;
    }

    if (containerWidth < width) {
        scaleFactorWidth = containerWidth / width;
    }

    if (scaleFactorHeight < 1 || scaleFactorWidth < 1) {
        if (scaleFactorHeight < scaleFactorWidth) {
            scaleFactor = scaleFactorHeight;
        } else {
            scaleFactor = scaleFactorWidth;
        }
    }

    //!important
    if (!!window.chrome) {
        $(".previewScreen").css('zoom', scaleFactor);
        $(".previewScreen").css('transform', 'translateZ(1px) translateY(0%) translateX(0%)');
        $(".previewScreen").css('left', deviceChoiceWidth + 'px');
    } else {
        $(".previewScreen").css('transform', 'translateZ(1px) translateY(0%) translateX(0%) scale(' + scaleFactor + ') ');
        $(".previewScreen").css('left', deviceChoiceWidth + 'px');
    }
    //
    $iframe.css({
        width: width,
        height: height
    });

    $iframePreviewDIV.css({
        width: width,
        height: height
    }).data('width', width).data('height', height);
    $('.currentZoom').css('zoom', 1/scaleFactor).html('<span class="zoomValue">' + Math.round(100 * scaleFactor) + '%</span><span class="zoomLabel">Zoom</span>');
}

$(document).on("click", ".draftToggle", function (e) {
    let t = $(this);
    t.toggleClass("active");
    let iframe = $("#element-canvas-iframe");

    let theURL= new URL( iframe.attr("src"));
    if (t.hasClass("active")) {
        theURL.searchParams.set( "draft",  "1");
    } else {
        theURL.searchParams.delete( "draft");
    }

    document.getElementById("element-canvas-iframe").addEventListener("load", function(event) {
        $("#element-canvas-iframe").get(0).contentWindow;
        $(clientFrameWindow.document).find('html').addClass("iframe-preview-responsive-force");
    }, {once: true});
    iframe.attr("src", theURL.toString());
});

$(document).on("click", ".deviceOption, .deviceOptionRotate", function (e) {

    var deviceId = $(this).data('device'),
        rotate = $(this).data('rotate'),
        device = devices[deviceId],
        width, height;
    if (rotate) {
        deviceRotate = !deviceRotate;
        height = $iframePreviewDIV.data('width');
        width = $iframePreviewDIV.data('height');

        if (deviceRotate) {
            $iframePreviewDIV.addClass('rotate');
        } else {
            $iframePreviewDIV.removeClass('rotate');
        }
    } else {
        $('.deviceOption').removeClass('active');
        $(this).addClass('active');
        if (deviceRotate) {
            width = device.width;
            height = device.height;
        } else {
            height = device.width;
            width = device.height;
        }
    }


   // amplify.store('as_iframe_device', deviceId);
    //amplify.store('as_iframe_rotate', deviceRotate);

    scaleIframe(parseInt(width), parseInt(height));
});
$(document).on("click", ".backButtonTarget", function () {
    $(".previewOverlay, .currentZoom").remove();
    $iframePreviewDIV.css({
        width: "auto",
        height: "auto"
    }).data('width', "auto").data('height', "auto");

    $iframe.removeAttr("style");

    $(".page-container").removeClass("previewScreen")
        .attr("style", pageContainerWidth);

    $(clientFrameWindow.document).find('html').removeClass("iframe-preview-responsive-force");
    let iframe = $("#element-canvas-iframe");
    let originalSrc =iframe.data('original-src');
    let currentSrc = iframe.attr("src" );
    if (originalSrc !== currentSrc) {
        iframe.attr("src", originalSrc);
    }

    if (false === activeTabPageeditor) {
        $("[data-content-tab-id=\"structure-general\"]").trigger("click");
    }

});

$(function () {

    var offset = 0,
        deviceHeight;
    $(window).on('mousewheel DOMMouseScroll', function (event) {
        let e = event.originalEvent;
        if (e.deltaY < 0) {
            $(".deviceUp").click();
        } else if (e.deltaY > 0) {
            $(".deviceDown").click();
        }
    });
    $(document).on("click", ".deviceDown", function () {
        var containerHeight = $(".deviceChoicesInner").height(),
            deviceListHeight = $(".devicesList").height(),
            maxHeight = deviceListHeight - (containerHeight - 10);

        deviceHeight = 3 * $(".devicesList div").height();
        offset += deviceHeight;
        offset = Math.min(offset, maxHeight);

        $(".devicesList").css('transform', 'translate(0, -' + offset + 'px)');
    });
    $(document).on("click", ".deviceUp", function () {
        deviceHeight = 3 * $(".devicesList div").height();
        offset -= deviceHeight;
        offset = Math.max(offset, 0);

        $(".devicesList").css('transform', 'translate(0, -' + offset + 'px)');
    });
});
