import { render, staticRenderFns } from "./LoadingElement.vue?vue&type=template&id=41ea77a1"
import script from "./LoadingElement.vue?vue&type=script&lang=js"
export * from "./LoadingElement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41ea77a1')) {
      api.createRecord('41ea77a1', component.options)
    } else {
      api.reload('41ea77a1', component.options)
    }
    module.hot.accept("./LoadingElement.vue?vue&type=template&id=41ea77a1", function () {
      api.rerender('41ea77a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-widget-linked-items/src/assets/vue/components/LoadingElement.vue"
export default component.exports