var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _vm.hexCode !== ""
      ? _c("span", {
          staticClass: "color-dot",
          style: `background-color: ${_vm.hexCode}`,
        })
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "font-weight-bold" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }