<template>
    <div>
        <div class="mb-2">
            {{ $translations['label.answeroptions'] }}
        </div>

        <draggable
            class="drop-area p-3 rounded"
            :list="answers"
            :group="{ name: 'answers' }"
            handle=".handle"
            v-bind="dragOptions"
            @start="start"
            @end="end">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div
                    v-for="answer in answers"
                    :key="answer.id"
                    class="d-flex align-items-center mb-2">
                    <input
                        :value="answer.label"
                        class="form-control w-50"
                        placeholder="Input Label"
                        @input="input(answer.id, $event.target.value)">
                    <input
                        :value="answer.value"
                        class="form-control w-25 mx-1"
                        placeholder="Input Value"
                        @input="value(answer.id, $event.target.value)">
                    <i
                        class="fa fa-check mx-2"
                        :class="{'text-primary': answer.is_default}"
                        @click="setDefault(answer.id)" />
                    <i class="fa fa-plus-square mx-2" @click="add(answer.id)" />
                    <i class="fa fa-minus-square mx-2" @click="remove(answer.id)" />
                    <i class="fa fa-arrows-alt mx-2 handle" />
                </div>
            </transition-group>
        </draggable>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import eventBus from "../../../util/EventBus";

export default {
    name: 'AnswerToptions',
    components: {
        draggable
    },
    props: {
        answers: {
            type: Array,
            default: []
        },
        item: {
            type: Object
        }
    },
    data: function() {
        return {
            drag: false
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    mounted() {

    },
    methods: {
        input(id, value) {
            eventBus.$emit('updateAnswerOption', id, value, this.item.data.id);
        },
        add(id) {
            eventBus.$emit('addAnswerOption', id, this.item.data.id);
        },
        remove(id) {
            eventBus.$emit('removeAnswerOption', id, this.item.data.id);
        },
        setDefault(id) {
            eventBus.$emit('defaultAnswerOption', id, this.item.data.id);
        },
        start() {
            this.drag = true;
        },
        end() {
            this.drag = false;
        },
        value(id, value) {
            eventBus.$emit('updateAnswerValue', id, value, this.item.data.id);
        }
    }
};
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}

i {
    cursor: pointer;
}
</style>