var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card shadow mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$translations["element.button"])),
      _vm.element.items[0].data.mandatory ? _c("sup", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "javascript:void(0)" },
        },
        [_vm._v(_vm._s(_vm.element.items[0].data.buttontext))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }