var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "search-form" }, [
        _c(
          "div",
          { staticClass: "search-form-header" },
          [
            _c("span", { staticClass: "search-string-wrapper" }, [
              _c("input", {
                staticClass: "search-string",
                attrs: { placeholder: _vm.searchPlaceholder, type: "text" },
                on: {
                  input: function ($event) {
                    return _vm.inputSearchQuery($event)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._l(_vm.filterKeys, function (key, index) {
              return _c(
                "div",
                { class: { "mr-2": index < _vm.filterKeys.length - 1 } },
                [
                  _vm.filter[key].list === null ||
                  (_vm.filter[key].list && _vm.filter[key].list.length === 0)
                    ? [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: _vm.filter[key].label,
                            type: "text",
                          },
                          on: { input: (e) => _vm.changeFilterText(e, key) },
                        }),
                      ]
                    : [
                        _c(
                          "div",
                          [
                            _vm.filter[key].list &&
                            _vm.filter[key].list.length > 0
                              ? _c("v-select", {
                                  staticClass: "filter-select",
                                  attrs: {
                                    "get-option-label": _vm.getOptionLabel,
                                    options: _vm.filter[key].list,
                                    placeholder: _vm.filter[key].label,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputFilterSelect($event, key)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("loading-element", {
            attrs: { absolute: false, center: true, "z-index": 100 },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.json && !_vm.loading
        ? [
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("Table", {
                  attrs: {
                    fields: _vm.fields,
                    items: _vm.itemsItems,
                    "product-catalog-active": _vm.productCatalogActive,
                    "sort-by": _vm.sortBy,
                    translations: _vm.translations,
                  },
                  on: { sort: _vm.sort },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (slotProps) {
                          return [
                            !_vm.isSelected(slotProps.item)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-outline-secondary",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addItem(slotProps.item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.translations.select))]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-outline-secondary",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItem(slotProps.item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.translations.remove))]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2043139767
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center pagination-container",
              },
              [
                _c("div", { staticClass: "show-indicator" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.show) +
                      " " +
                      _vm._s(_vm.offset + 1) +
                      "-" +
                      _vm._s(_vm.offset + _vm.pagination.pageSize) +
                      " " +
                      _vm._s(_vm.translations.from) +
                      "\n        " +
                      _vm._s(_vm.pagination.totalCount) +
                      " " +
                      _vm._s(_vm.translations.items) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.pagination.totalPages > 1
                  ? _c("paginate", {
                      attrs: {
                        "click-handler": _vm.changePage,
                        "page-count": _vm.pagination.totalPages,
                        "container-class": "pagination",
                        "next-class": "page-item",
                        "next-link-class": "page-link",
                        "next-text": "<i class='fas fa-angle-right'></i>",
                        "page-class": "page-item",
                        "page-link-class": "page-link",
                        "prev-class": "page-item",
                        "prev-link-class": "page-link",
                        "prev-text": "<i class='fas fa-angle-left'></i>",
                      },
                      model: {
                        value: _vm.pagination.currentPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.pagination, "currentPage", $$v)
                        },
                        expression: "pagination.currentPage",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "page-size" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      on: {
                        input: function ($event) {
                          return _vm.selectPageSize($event)
                        },
                      },
                    },
                    _vm._l(10, function (n) {
                      return _c(
                        "option",
                        {
                          key: n,
                          domProps: {
                            selected: 10 * n === _vm.pagination.pageSize,
                            value: 10 * n,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(10 * n) + "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }