var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group label-inside" }, [
    _c(
      "select",
      {
        staticClass: "form-control",
        attrs: { id: _vm.id, type: "text" },
        domProps: { value: _vm.value },
        on: {
          change: function ($event) {
            return _vm.update($event.target.value)
          },
        },
      },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          { key: option.value, domProps: { value: option.value } },
          [_vm._v("\n            " + _vm._s(option.label) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }