var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$translations["label.answeroptions"]) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "drop-area p-3 rounded",
            attrs: {
              list: _vm.answers,
              group: { name: "answers" },
              handle: ".handle",
            },
            on: { start: _vm.start, end: _vm.end },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _c(
            "transition-group",
            {
              attrs: {
                type: "transition",
                name: !_vm.drag ? "flip-list" : null,
              },
            },
            _vm._l(_vm.answers, function (answer) {
              return _c(
                "div",
                {
                  key: answer.id,
                  staticClass: "d-flex align-items-center mb-2",
                },
                [
                  _c("input", {
                    staticClass: "form-control w-50",
                    attrs: { placeholder: "Input Label" },
                    domProps: { value: answer.label },
                    on: {
                      input: function ($event) {
                        return _vm.input(answer.id, $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control w-25 mx-1",
                    attrs: { placeholder: "Input Value" },
                    domProps: { value: answer.value },
                    on: {
                      input: function ($event) {
                        return _vm.value(answer.id, $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-check mx-2",
                    class: { "text-primary": answer.is_default },
                    on: {
                      click: function ($event) {
                        return _vm.setDefault(answer.id)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-plus-square mx-2",
                    on: {
                      click: function ($event) {
                        return _vm.add(answer.id)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-minus-square mx-2",
                    on: {
                      click: function ($event) {
                        return _vm.remove(answer.id)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-arrows-alt mx-2 handle" }),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }