<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.numericscale'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div class="form-group">
                <label>{{ element.items[0].data.question }}</label>

                <div class="scale">
                    <div
                        v-for="n in Number(element.items[0].data.scaleSteps)"
                        :key="`scale-${n}`"
                        :class="{active: n == element.items[0].data.scaledefaultselected}">
                        {{ n }}
                    </div>
                </div>

                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Numericscale",
    mixins: [previewItemMixin],
};
</script>

<style lang="scss" scoped>
.scale {
    display: flex;

    > div {
        min-width: 50px;
        height: 50px;
        border-width: 1px;
        border-right-width: 0;
        border-style: solid;
        border-color: #939393;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:last-child {
            border-right-width: 1px;
        }

        &:hover, &.active {
            background-color: #1991a0;
            color: #fff;
        }
    }
}
</style>