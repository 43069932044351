"use strict";

import Media from './Media';
import _brushes from './paintBrush';
import {_borders} from './paintCanvas';
import * as Cropping from './cropping';
import {displayFilterPreviewsFor,initFilterList,saveNewCustomFilter,updateFilterFormFields} from './filters';

window.imageedit_initialized = false;
$(function() {
    if($("#cropper-modal").length === 0 || !window.imageedit_initialized) window.MediaEditor = new MediaEditor();
});

let MediaEditor = function() {
    window.imageedit_initialized = true;
    let self = this;

    this.currentMedia = 0;   // <Media()> object which is currently being edited
    this.currentbrush = "";
    this.medias = {};    // <Media()>

    this.mediaoffset = 0;
    this.mediaoffsetleft = 0;
    this.mediaelement = $(".selectedMediaWrapper")[0];
    this.offsetleft = 0;
    this.offset = 0;
    this.menuElement = null;

    this.editorMode = null;     // function: viewMode(), cropperMode()
    // define which settings and menus are available depending on the type of the file
    let availableSettings = {
        meta: {
            container: $("#metaMenuToggle").parent()
        },
        editor: {
            container: $("#cropper-modal .headerPanel .headerWrapper")
        },
        saveAsNew: {
            container: $("#cropper-modal #asNewFile").parent()
        },
        unsupportedType: {
            container: $("#cropper-modal .headerPanel .unsupportedType")
        },
    };

    let availableSettingsForType = {
        video: ["meta", "editor"],
        image: ["meta", "editor", "saveAsNew"],
        pdf: ["meta", "editor"],
        fileicon: ["meta", "unsupportedType"]
    };

    /************************************************
     * switch between the 3 different "view"-modes
     * viewMode         (show downsized preview of the image)
     * cropMode         (show the cropper)
     * sourceImageMode  (for pdf's and other un-editable files, just show the original thumbnail)
     ***********************************************/
    // display the cropper
    let cropMode = function () {
        $("#imgPreviewContainer").removeClass("overFlow");
        $("#imgPreviewContainer").hide();
        $("#cropperjsContainer").show();
        self.currentMedia.updateFiltersForCropper();
    };

    // display only the image, no editing
    let viewMode = function () {
        $("#imgPreviewContainer").addClass("overFlow");
        $("#imgPreviewContainer").show();
        $("#cropperjsContainer").hide();
        self.currentMedia.updateFilters();
    };

    let sourceImageMode = function () {
        $("#imgPreviewContainer").addClass("overFlow");
        $("#imgPreviewContainer").show();
        $("#cropperjsContainer").hide();
    };

    $("#painttext").find(".minicolorsAlpha").each(function(){
        $(this).minicolors({
            defaultValue: $(this).val(),
            format: 'rgba',
            opacity: true
        });
    });
    $("#paintbrush").find(".minicolors").each(function(){
        $(this).minicolors({
            defaultValue: $(this).val()
        });
    });
    $(document).on("click", ".stickerselect", function(){
        self.currentMedia.paintCanvas.addImage($(this).find("img").attr("src"),$(this).hasClass("svg"));
    });
    $(document).on("click", ".borderselect", function(){
        self.currentMedia.paintCanvas.changeBorder(_borders["border1"]);
    });
    $("#paintborderWidth").on("input change", function(){
        self.currentMedia.paintCanvas.changeBorderWidth(parseInt(this.value));
    });
    $(".canvasContainer").on("click", ".canvas-container canvas", function(e){
        if($(this).hasClass("text") && !self.currentMedia.paintCanvas.fabric._activeObject){
            //http://fabricjs.com/docs/fabric.Text.html#_styleProperties
            let options = {
                top: e.offsetY,
                left: e.offsetX,
                fontFamily: $("#painttextFont").val(),
                fontSize: $("#painttextFontsize").val(),
                fontStyle: $(".painttextOptionToggle[data-option=fontStyle].active").data("value") || "",
                fontWeight: $(".painttextOptionToggle[data-option=fontWeight].active").data("value"),
                textAlign: $(".painttextOptionToggle[data-option=textAlign].active").data("value"),
                fill: $(".painttextOption[data-option=fill]").minicolors("rgbaString"),
                textBackgroundColor: $(".painttextOption[data-option=textBackgroundColor]").minicolors("rgbaString"),
                underline: $(".painttextOptionToggle[data-option=underline].active").data("value"),
                linethrough: $(".painttextOptionToggle[data-option=linethrough].active").data("value"),
            };
            self.currentMedia.paintCanvas.addTextbox("Doppelklicken zum Bearbeiten", options);
        }
    });
    $(".painttextOption").on("change input", function(){
        if(self.currentMedia.paintCanvas.fabric._activeObject){
            let val = $(this).val();
            if($(this).hasClass("minicolorsAlpha")){
                val = $(this).minicolors("rgbaString");
            }
            let obj = {};
            obj[$(this).data("option")] = val;
            self.currentMedia.paintCanvas.fabric.getActiveObject().set(obj);
            self.currentMedia.paintCanvas.fabric.renderAll();
        }
    });
    $(".painttextOptionToggle").on("click", function(){
        let obj = {};
        switch($(this).data("option")){
            case "fontStyle":
                $("#painttext").find(".painttextOptionToggle[data-option=fontStyle], .painttextOptionToggle[data-option=fontWeight]").removeClass("active");
                $(this).addClass("active");
                obj = {
                    fontWeight: "",
                    fontStyle: $(this).data("value")
                };
                break;
            case "fontWeight":
                $("#painttext").find(".painttextOptionToggle[data-option=fontStyle], .painttextOptionToggle[data-option=fontWeight]").removeClass("active");
                $(this).addClass("active");
                obj = {
                    fontWeight: $(this).data("value"),
                    fontStyle: ""
                };
                break;
            case "underline":
                $("#painttext").find(".painttextOptionToggle[data-option=linethrough]").removeClass("active");
                $(this).toggleClass("active");
                obj = {
                    underline: $(this).hasClass("active") ? "underline" : false,
                    linethrough: false
                };
                break;
            case "linethrough":
                $("#painttext").find(".painttextOptionToggle[data-option=underline]").removeClass("active");
                $(this).toggleClass("active");
                obj = {
                    underline: false,
                    linethrough: $(this).hasClass("active") ? "linethrough" : false
                };
                break;
            case "textAlign":
                $("#painttext").find(".painttextOptionToggle[data-option=textAlign]").removeClass("active");
                $(this).addClass("active");
                obj = {
                    textAlign: $(this).data("value")
                };
                break;
        }

        if(self.currentMedia.paintCanvas.fabric._activeObject) {
            self.currentMedia.paintCanvas.fabric.getActiveObject().set(obj);
            self.currentMedia.paintCanvas.fabric.renderAll();
        }
    });

    $(document).on("click",".paintStampSelect", function(){
        $(".paintStampSelect").removeClass("active");
        $(this).addClass("active");
        self.currentMedia.paintCanvas.currentElement.brush = _brushes[$(this).data("brush")];
        self.currentbrush = $(this).data("brush");
    });
    $("#expertsettings").click(function(){
        $(".settingsoverlay").show();
    });
    $(".settingsBack").click(function(){
        $(".settingsoverlay").hide();
    });

    $(".moreMedia").click(function () {
        self.mediaoffsetleft = self.mediaelement.scrollWidth - self.mediaelement.offsetWidth;
        if(self.mediaoffsetleft > self.mediaelement.offsetWidth){
            self.mediaoffset = self.mediaoffset + self.mediaelement.offsetWidth;
        }else{
            self.mediaoffset = self.mediaoffset + self.mediaoffsetleft;
        }
        if(self.mediaoffsetleft === 0){
            self.mediaoffset = 0;
        }
        $(".selectedMedias").transition({ x: "-"+self.mediaoffset+"px" });
    });

    $("#cancelEdit").click(function () {
        $(".closeIcon").trigger("click");
    });

    // tatsächlichen zoom-wert anzeigen (abhängig von original bildgrösse, nicht max grösse im editor)
    // skalierung von originalbild zum editor nur 1x berechnen, wird nur für den angezeigten wert benötigt.
    this.initZoomValue = function initZoomValue(media){
        if(media.preview.width && media.sourceImage.width){
            $("#previewZoom").attr("data-original-zoom", media.preview.width / media.sourceImage.width);
            $("#previewZoom").val(1).trigger("change");
        }
    };

    $(".minusZoom").click(function () {
        $("#previewZoom").val(Math.max( (Number($("#previewZoom").val()) - 0.1), 0.1)).trigger("change");
    });

    $(".plusZoom").click(function () {
        $("#previewZoom").val(Number($("#previewZoom").val()) + 0.1).trigger("change");
    });

    $(".closeIcon").click(function () {
        var unsavedChanges = false;
        for (var media in self.medias) {
            if (self.medias[media].unsavedChanges || self.medias[media].metaDataChanged) {
                unsavedChanges = true;
                break;
            }
        }
        if (unsavedChanges) {
            fancyboxConfirm(
                "Sind sie sicher das sie den Editor schliessen möchten ohne die Änderungen zu speichern?",
                [
                    {
                        label: "Abbrechen",
                        callback: function(){
                            //...
                        },
                        btnClass: "btn-light btn"
                    },
                    {
                        label: "Beenden",
                        callback: function(){
                            $(".submenuBtn.active").trigger("click");
                            $(".submenuEdit:visible .bckBtn").trigger("click");
                            $("#cropper-modal").modal("hide");
                        },
                        btnClass: "btn-danger btn"
                    },
                    // {
                    //     label: "Speichern & Schliessen",
                    //     callback: function(){
                    //         self.saveAllMedias();
                    //
                    //         $(".submenuBtn.active").trigger("click");
                    //         $(".submenuEdit:visible .bckBtn").trigger("click");
                    //         $("#cropper-modal").modal("hide");
                    //     },
                    //     btnClass: "btn-success btn"
                    // }
                ],
            {
                wrapCSS:'photoeditorFancybox',

                        /*'afterShow' : function() {
                            $(document).find(".fancybox-item.fancybox-close").wrap("<div class='modalHeader'></div>");
                        },*/
                    }
            );



        } else {
            $(".submenuBtn.active").trigger("click");
            $(".submenuEdit:visible .bckBtn").trigger("click");
            $("#cropper-modal").modal("hide");
        }
    });

    $("#previewZoom").on("change input", function(){

        let val = this.value;
        let $parent = $("#imgPreviewContainer");
        let $zoomWrapper = $(".previewZoomWrapper");

        $(".zoomValue").text(Math.floor(Number(val)*100*parseFloat($(this).attr("data-original-zoom"))));


        $(".imgPreview, .paintCanvas, .canvas-container").each(function(){
            this.style.transform = "scale("+val+")";
        });


        let img = $(".imgPreview")[0];
        if(img.width*val > $parent.width() || img.height*val > $parent.height()){
            $parent.addClass("overFlow");
            img.style.cursor = "move";

            var imgOffsetTop = (img.height*val)/2;
            var imgOffsetLeft = (img.width*val)/2;

            var top = $parent.offset().top + imgOffsetTop;
            var left = $parent.offset().left + imgOffsetLeft;

            var boundaries = [
                left - ((img.width*val) - $parent.width()),
                top - ((img.height*val) - $parent.height()),
                left,
                top
            ];

            // todo ganze drag/scroll funktionalität beim zoomen
            /*
            console.log(boundaries);

            $zoomWrapper.draggable({
                containment: boundaries,
                start: function(e, ui){
                     console.log(ui.offset);
                     console.log(ui.position);
                },
                drag: function(e, ui){
                    //ui.position.left += imgOffsetLeft;
                    //ui.position.top += imgOffsetTop;
                    //console.log(ui.position);
                }
            }).draggable("enable");*/
        } else {
            img.style.cursor = "default";
            $parent.removeClass("overFlow");
            if ($zoomWrapper.hasClass("ui-draggable")){
                $zoomWrapper.draggable("disable");
            }
            $zoomWrapper.css("top","50%").css("left", "50%");
        }
    });

    $(".headerBtn").click(function () {
        $(".headerBtn").removeClass("active");
        $(this).addClass("active");

        if ($(this).data("hidemenu") == true) {
            $(".headerWrapper").hide();
        }
        var action = $(this).data("action");
        if (action == "metaoverlay" || action == "settingsoverlay") {
            if ($("." + action + "").is(':visible')) {
                $("." + action).hide();
                $(this).removeClass("active");
            } else {
                $(".imgeditSelection").hide();
                $("." + action).show();
            }
        }
        else {
            $(".imgeditSelection").hide();
            $("." + action + "").show();

            self.menuElement = $("." + action + "").find(".submenuRight")[0];
            if (self.menuElement.offsetWidth < self.menuElement.scrollWidth) {
                if($("." + action + "").find(".showmoreBtn").length <= 0){
                    $("." + action + "").find(".submenuRight").css("width","80%");
                    $("." + action + "").append("<div class='submenuBtn showmoreBtn'><div class='headerBtnInner'><i class='fire-three-dots'></i><div class='btnLabel'>Weitere</div><div class='btnLabel backlbl'>Zurück</div></div></div>");
                }
                self.menuElement = $("." + action + "").find(".submenuRight")[0];
            }
        }
    });

    $(document).on("click",".showmoreBtn",function(){
        self.offsetleft = self.menuElement.scrollWidth - self.menuElement.offsetWidth;
        if(self.offsetleft > self.menuElement.offsetWidth){
            self.offset = self.offset + self.menuElement.offsetWidth;
        }else{
            self.offset = self.offset + self.offsetleft;
        }
        if(self.offsetleft <= self.menuElement.offsetWidth){
            $(this).addClass("done");
        }
        if(self.offsetleft === 0){
            self.offset = 0;
            $(this).removeClass("done");
        }
        $(this).parent().find(".submenuRightInner").transition({ x: "-"+self.offset+"px" });
    });
    $(".bckBtn").click(function () {
        self.editorMode = viewMode;
        self.editorMode();
        self.offset = 0;
        self.offsetleft = 0;
        $(".submenuRightInner").attr("style","");
        $(".showmoreBtn").removeClass("done");
        $(".submenuEdit").hide();
        $(".headerWrapper").show();
        $(".subArea").hide();
        $(".submenuBtn:not([data-ratio]):not([data-filtertoggle])").removeClass("active");
        $(".headerBtn").removeClass("active");
    });
    $(".submenuBtn").click(function () {

        if ($(this).data("ratio")) {
            $(".submenuBtn:not([data-filtertoggle])").removeClass("active");
            $(this).addClass("active");

            if ($("#ratioSelect").val() == $(this).data("ratio")) {
                $("#ratioSelect").val("-");
                $(".submenuBtn:not([data-filtertoggle])").removeClass("active");
            } else {
                $("#ratioSelect").val($(this).data("ratio"));
            }
            $("#ratioSelect").trigger("change");
        }

        if ($(this).data("filtertoggle")) {
            $(".subArea").hide();
            $(".submenuBtn:not([data-filtertoggle]):not([data-ratio])").removeClass("active");
            $(this).addClass("active");

            if ((self.currentMedia.webGLCanvas.values[$(this).data("filtertoggle")] === 1)
                || self.currentMedia.filterOptions.hasOwnProperty($(this).data("filtertoggle"))) {
                $(this).removeClass("active");
                $(".filterContainer").find("input[data-filter=" + $(this).data("filtertoggle") + "]").attr("checked",false).trigger("change"); // manually trigger click event on the checkbox
            } else {
                $(".filterContainer").find("input[data-filter=" + $(this).data("filtertoggle") + "]").attr("checked",true).trigger("change");
            }
        }
    });


    $(document).on("click", ".applyCrop", function () {
        self.currentMedia.updateCropping();
        self.editorMode = viewMode;
        self.editorMode();
        $(self.currentMedia.cropContainer).find(".applyCrop").removeClass("active");
        $(".cutsubmenu").find(".bckBtn").trigger("click");
    });

    // check webGL compatibility
    window._webglSupported = false;
    let canvas = document.createElement("canvas");
    let ctx;
    try {
        ctx = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    } catch (e) {
    }
    canvas = null;  // manual garbage collection

    window._webglSupported = !(!ctx);  // the !(!) check is to make sure its stored as true/false instead of an object, because some browser seem to interpret empty objects or undefined as true

    if(!window.Promise) window._webglSupported = false;
    if (window._webglSupported) {
        //effects that are only implemented in caman
        $(".submenuEdit.effects").find(".submenuBtn[data-key=gamma],.submenuBtn[data-key=clip]").hide();
    }

    // check typedArray compatibility, required for pica.js (image resizing) for ie9 and below
    // polyfills for typedArrays are too slow, so instead of using pica for resizing i use another (worse) function
    /*if (!window.ArrayBuffer) {
        picaResize = picaResizePolyfill;
    }*/

    this.startImageEditor = function($items){

        let $selectedItems = $items;  // selected items
        let $currentItem = $selectedItems.first();

        let selectedMediaList = $(".selectedMedias");
        let cropperjsContainer = $("#cropperjsContainer");
        let imgPreviewContainer = $("#imgPreviewContainer");

        selectedMediaList.empty();
        cropperjsContainer.empty();   // container to store all the cropperContainers for cropper.js
        imgPreviewContainer.empty();  // container to store all the image elements for previews

        //reset medias-list
        self.medias = {};

        if($selectedItems.length > 3){
            $(".moreNumber").text($selectedItems.length-3);
            $(".moreMedia").show();
        }else{
            $(".moreMedia").hide();
        }

        // create a list with all selected medias
        $selectedItems.each(function () {

            let $this = $(this);
            let id = $this.data("id");
            let imgSrc = $this.find("img").attr("src");

            //find usage of medias
            //getUsedon(id);

            // add all the selected medias to the list for the user to switch between
            selectedMediaList.append(
                "<li>" +
                "<div class='selectedMedia' data-id='" + id + "' style='background-image: url(" + imgSrc + ")'>" +
                "<div class='selectedMediaInfo'></div>" +
                "</div>" +
                "</li>"
            );

            //create a seperate source-<img> element and container for the cropper for each selected media
            cropperjsContainer.append(
                "<div id='mediaCropCont_" + id + "' class='mediaCropCont'><div class='applyCrop fa fa-check'></div></div>"
            );

            // create a seperate img element for previews for each selected media
            imgPreviewContainer.append(
                "<div class='previewZoomWrapper'><img id='imgPreview_" + id + "' class='imgPreview'></div>"
            );

            // create new media Object
            let newMedia = new Media(
                $this,
                document.getElementById('imgPreview_' + id),
                document.getElementById('mediaCropCont_' + id)
            );
            newMedia.preview.src = self.getItemImageSource($this);
            newMedia.webGL = _webglSupported;

            // add the media object to medias[]-array for later, indexed by mediaID
            self.medias[id] = newMedia;
        });

        // if more than 1 file was selected for editing, display the list of selected files, add click listeners on the medialist & enable multiple-media editing
        if ($selectedItems.length > 1) {
            //$(".selectedMedia").unbind("click");
            $(".selectedMedia").on("click", function (e) {
                e.preventDefault();

                $(".selectedMedias li").removeClass("active");
                $(this).parent("li").addClass("active");

                // switch to a different media & reinitialise the whole cropper & editor from the list of selected medias:
                self.switchToMedia($(this));
            });

            selectedMediaList.show();
            $(".applyToAll").show();
        } else {
            selectedMediaList.hide();
            $(".applyToAll").hide();
        }

        self.initUI();                           // initialise (reset) the ui to its original state

        self.switchToMedia($currentItem);

        self.initAllMedias(function () {
            self.initZoomValue(self.currentMedia);

            $('#cropper-modal').find('input[type=range]').rangeslider({
                polyfill : false
            });
        });
    };


    this.initAllMedias = function(callback) {
        startLoading("initAllMedias", "Initialisiere Medien…");   //Yii.t

        // load custom filters from db
        initFilterList('webgl', function () {

            // copy the media object list into an array
            var copy = [];
            for (var id in self.medias) {
                copy.push(self.medias[id]);
            }

            // recursive callback function to loop through all medias in the array
            var loop = function () {
                if (copy.length > 0) {
                    processNext();
                } else {
                    finishedLoading("initAllMedias");
                    if (typeof(callback) === "function") callback();
                }
            };

            // first call
            processNext();

            function processNext() {
                var media = copy.splice(0, 1);
                media[0].init(function () {
                    loop();
                });
            }
        });

    };

    // select a different media to edit
    // (either when opening the editor, or when selecting a different media inside the editor)
    // and reinitialise the cropper + ui accordingly
    //
    this.switchToMedia = function(item) {
        let id = parseInt(item.data("id"));

        // set the currently selected Item from the list
        if (self.currentMedia) self.currentMedia.isCurrentMedia = false;
        self.currentMedia = self.medias[id];
        self.currentMedia.isCurrentMedia = true;

        // hide all elements for all other medias, only show the current
        for (let key in self.medias) {
            let media = self.medias[key];
            if ($(media.item).data("id") !== id) {
                $(media.preview).parent().hide();
                //$(media.paintCanvas.canvas).hide();
                $(media.cropContainer).hide();
                //if(media.paintCanvas.fabric) $(media.paintCanvas.fabricCanvas).parent().hide();
            } else {
                $(media.preview).parent().show();
                //$(media.paintCanvas.canvas).show();
                $(media.cropContainer).show();
                //if(media.paintCanvas.fabric) $(media.paintCanvas.fabricCanvas).parent().show();
            }
        }

        let activePaintMenu = $("#playgroundOptions").find(".submenuBtn.active");
        if(activePaintMenu.data("key") === "painttext"){
            self.currentMedia.paintCanvas.startFabric();
        } else if(activePaintMenu.data("key") === "paintsticker"){
            self.currentMedia.paintCanvas.startFabric();
        } else if(activePaintMenu.data("key") === "paintbrush"){
            self.currentMedia.paintCanvas.startDrawing();
        }




        // initialise the editor
        self.initEditor(self.currentMedia);

        displayFilterPreviewsFor(self.currentMedia);

        self.updateUiInputs(self.currentMedia);
    };

    // update the ui inputvalues to the values of the passed media
    this.updateUiInputs = function(media) {
        document.getElementById("ratioSelect").selectedIndex = media.selectedRatioIndex || 0;
        document.getElementById("sizeSelect").selectedIndex = media.selectedSizeIndex || 0;

        document.getElementById("resizeWidth").value = media.targetWidth || "";
        document.getElementById("resizeHeight").value = media.targetHeight || "";

        document.getElementById("asNewFile").checked = media.newFileName;
        if (media.newFileName) {
            document.getElementById("newFileName").value = media.newFileName;
            $(".newFilePopup").show();
        } else {
            document.getElementById("newFileName").value = "";
            $(".newFilePopup").hide();
        }

        if (media.cropper.options) {
            document.getElementById("rotateValue").value = media.cropper.imageData.rotate;
        }

        // update values for custom filters
        updateFilterFormFields(media);

        /*media.readMetaData(function (data) {
            document.getElementById("fileDesc").value = media.getMetaData("description");
            document.getElementById("fileTitle").value = media.getMetaData("title");
            document.getElementById("fileSubTitle").value = media.getMetaData("subtitle");
            document.getElementById("fileLink").value = media.getMetaData("link");
            document.getElementById("fileLinkText").value = media.getMetaData("linktext");


            $("#fileLorem").prop('checked', media.getMetaData("lorem"));
            $("#fileDeveloper").prop('checked', media.getMetaData("developer"));

            $(".imgthumb").html("<img src='"+media.getMetaData("thumb")+"' />");
            $(".filetype").text(media.getMetaData("mime"));
            $(".imgdimensions").text(media.getMetaData("dimensions"));
            $(".imgsize").text(media.getMetaData("size"));
            $(".imgurl").html("<a target='_blank' href='"+media.getMetaData("full_url")+"'>"+media.getMetaData("full_url")+"</a>");
            $(".imgcreated_at").text(media.getMetaData("created_at"));
            $(".imgupdated_at").text(media.getMetaData("updated_at"));

            let select = $("#mediaTags");
            // append selected tags which dont exist yet in the <options>
            media.getMetaData("tags").forEach(function (tag) {
                if (select.find("option[value='" + tag + "']").length <= 0) {
                    select.append("<option value='" + tag + "'>" + tag + "</option>");
                }
            });
            select.val(media.getMetaData("tags")).trigger("change");
        });*/
    };

    /***********************************************
     * Globals
     **********************************************/

    this.displayAvailableSettings = function(media) {
        let type = media.item.data("type");

        for (var key in availableSettings) {
            if (availableSettingsForType[type].indexOf(key) >= 0)
                availableSettings[key]["container"].show();
            else
                availableSettings[key]["container"].hide();
        }
    };

    ///////////////////////////////////////////////////////////////////
    // Initialising
    ///////////////////////////////////////////////////////////////////
    // (re-)initialise the whole editor + canvas with the passed item
    // img: dom element to store the sourceImage
    // item: <li> dom element passed from mediamanager or when switching to another image in the edit window, containing all information about the media & file
    this.initEditor = function(media) {
        // when the selected media is a video, show the additional video-editing options
        if (media.item.data("type") === "video") {
            //loadVideoFor(media.item, $("#videoMenuToggle").parent("li").find("video"));
        } else if (media.item.data("type") === "pdf") {
            self.editorMode = sourceImageMode();
        }
        self.displayAvailableSettings(media);

        self.initSelect2();

        self.changeEditorMode(); // switch back to the previous selected editormode
    };

    // init select2 for tags
    this.initSelect2 = function() {
        // init select2 for tags
        $("#mediaTags").select2({
            tags: true,
            //dropdownCssClass: "maxZIndex",
            tokenSeparators: [',', ' ']
        })
            .on("change", function (e) {
                // mostly used event, fired to the original element when the value changes
                var selected = [];
                $(".select2-selection__choice").each(function () {
                    var name = $(this).attr("title");

                    selected.push(name);
                });

                self.currentMedia.updateTags(selected);
            });
    };

    // return the sourcepath of the original (full-size) image on a list-item
    this.getItemImageSource = function(item) {
        var type = item.data("type");
        // depending if its an image, video, pdf or some other filetype, the url for the image is saved in different data-variables
        if (type === "image") {
            return item.find("img").data('file') + "?" + (new Date).getTime();
        } else if (type === "video" || type === 'pdf') {
            return item.find("img").data('thumbfile') + "?" + (new Date).getTime();
        } else if (type === "fileicon") {
            return item.find("img").attr("src");
        } else {
            // unknown filetype? what do?
        }
        return '';
    };

    $(".fileMetaData").on("change input", function () {
        self.currentMedia.updateMetaData(this.getAttribute("data-metakey"), this.value);
    });
    $(".fileMetaDataCheckbox").on("change input", function () {
        self.currentMedia.updateMetaData(this.getAttribute("data-metakey"), this.checked);
    });



    ///////////////////////////////////////////////////////////////////
    //
    //      UI & CLICK LISTENER
    //
    ///////////////////////////////////////////////////////////////////

    // click listener for accordion-menu
    $("#metaMenuToggle, #videoMenuToggle, #filterMenuToggle, #advancedMenuToggle, .headerBtn[data-action='colorbalance']").on("click", function () {
        self.editorMode = viewMode;
        self.editorMode();
    });

    $(".headerBtn[data-action='cutsubmenu'], #cropperMenuToggle").on("click", function () {
        self.editorMode = cropMode;
        self.editorMode();
    });


    // reset ui to inital values
    this.initUI = function() {
        document.getElementById("ratioSelect").selectedIndex = 0;
        document.getElementById("sizeSelect").selectedIndex = 0;
        document.getElementById("resizeWidth").value = "";
        document.getElementById("resizeHeight").value = "";
        document.getElementById("rotateValue").value = 0;

        document.getElementById("newFileName").value = "";
        document.getElementById("asNewFile").checked = false;
        document.getElementById("saveAlert").innerHTML = "";
        document.getElementById("saveAlert").style.display = "none";

        self.editorMode = null;

        // add event listeners for all filter inputs
        if (_webglSupported) {
            //remove possibly previous events
            $("#cropper-modal").find("input[data-filter]").off("change input");
            //filter event listener for webgl filters
            $("#cropper-modal").on("change input", "input[data-filter='greyscale'], input[data-filter='invert']", function () {
                self.currentMedia.webGLCanvas.values[this.getAttribute("data-filter")] = $(this).attr("checked") ? 1 : 0;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='brightness']", function () {
                self.currentMedia.webGLCanvas.values["brightnessContrast"]["brightness"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='contrast']", function () {
                self.currentMedia.webGLCanvas.values["brightnessContrast"]["contrast"] = parseFloat(this.value) / 10;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='hue']", function () {
                self.currentMedia.webGLCanvas.values["hueSaturation"]["hue"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='saturation']", function () {
                self.currentMedia.webGLCanvas.values["hueSaturation"]["saturation"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='vibrance']", function () {
                self.currentMedia.webGLCanvas.values["vibrance"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='noise']", function () {
                self.currentMedia.webGLCanvas.values["noise"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='sepia']", function () {
                self.currentMedia.webGLCanvas.values["sepia"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='exposure']", function () {
                self.currentMedia.webGLCanvas.values["exposure"] = parseFloat(this.value) / 100;
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='red'], input[data-filter='green'], input[data-filter='blue']", function () {
                var val = parseFloat(this.value) / 100;
                if (val <= 0) {
                    self.currentMedia.webGLCanvas.values["curves"][this.getAttribute("data-filter")] = [[0, 0], [1, val+1]];
                } else if (val > 0) {
                    self.currentMedia.webGLCanvas.values["curves"][this.getAttribute("data-filter")] = [[0, val], [1, 1]];
                }
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='vignetteSize']", function () {
                self.currentMedia.webGLCanvas.values["vignette"]["size"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='vignetteAmount']", function () {
                self.currentMedia.webGLCanvas.values["vignette"]["amount"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='denoise']", function () {
                self.currentMedia.webGLCanvas.values["denoise"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='triangleBlur']", function () {
                self.currentMedia.webGLCanvas.values["triangleBlur"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='lensBlurRadius']", function () {
                self.currentMedia.webGLCanvas.values["lensBlur"]["radius"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='lensBlurBrightness']", function () {
                self.currentMedia.webGLCanvas.values["lensBlur"]["brightness"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            }).on("change input", "input[data-filter='lensBlurAngle']", function () {
                self.currentMedia.webGLCanvas.values["lensBlur"]["angle"] = parseFloat(this.value);
                self.currentMedia.applyGLFilter();
            });

        }

        $(".filterContainer input[type='range']").off("input").on("input", function () {
            $(this).parent().parent().find(".currentValue").text($(this).val());
        });

        $(".generatedSubBtn").off("click").on("click", function () {
            $(".generatedSubBtn").removeClass("active");
            $(this).addClass("active");
            if ($(this).data("key")) {
                if ($("." + $(this).data("key") + "Area:visible").length) {
                    $(".subArea").hide();
                    $(this).removeClass("active");
                } else {
                    $(".subArea").hide();
                    $("." + $(this).data("key") + "Area").show();
                }
            }
        });


        $(".headerBtn[data-action=paintmenu]").on("click", function () {
            if(self.currentMedia.paintCanvas.initialized) self.currentMedia.paintCanvas.redraw();
        });
        $(".submenuBtn[data-key=paintbrush]").on("click", function () {
            if($(this).hasClass("active")){
                if(self.currentMedia.paintCanvas.initialized) self.currentMedia.paintCanvas.startDrawing();
            } else {
                if(self.currentMedia.paintCanvas.initialized) self.currentMedia.paintCanvas.stopDrawing();
            }

            $(".canvas-container").find("canvas").removeClass("text");
        });
        $(".submenuBtn[data-key=painttext]").on("click", function () {
            if (self.currentMedia.paintCanvas.initialized) {
                if($(this).hasClass("active")){
                    self.currentMedia.paintCanvas.startFabric();
                    $(".canvas-container").find("canvas").addClass("text");
                } else {
                    self.currentMedia.paintCanvas.stopFabric();
                    $(".canvas-container").find("canvas").removeClass("text");
                }
            }
        });
        $(".submenuBtn[data-key=paintsticker]").on("click", function () {
            if (self.currentMedia.paintCanvas.initialized) {
                if($(this).hasClass("active")){
                    self.currentMedia.paintCanvas.startFabric();
                } else {
                    self.currentMedia.paintCanvas.stopFabric();
                }
            }

            $(".canvas-container").find("canvas").removeClass("text");
        });
        $(".submenuBtn[data-key=paintborder]").on("click", function () {
            if (self.currentMedia.paintCanvas.initialized) {

                if($(this).hasClass("active")){
                    self.currentMedia.paintCanvas.updateBorder(true);
                    self.currentMedia.paintCanvas.stopFabric();
                    self.currentMedia.paintCanvas.borderUpdated = false;
                    $(self.currentMedia.paintCanvas.border.c).parent().show();
                } else {
                    self.currentMedia.paintCanvas.borderUpdated = false;
                    self.currentMedia.paintCanvas.stopFabric();
                    $(self.currentMedia.paintCanvas.border.c).parent().hide();
                }
            }

            $(".canvas-container").find("canvas").removeClass("text");
        });
        $(".paintmenu .bckBtn").on("click", function(){
            self.currentMedia.paintCanvas.stopDrawing();
            self.currentMedia.paintCanvas.stopFabric();

            $(".canvas-container").find("canvas").removeClass("text");
        });

        $("input.paintbrush").off("change input").on("change input", function () {
            self.currentMedia.paintCanvas.currentElement[$(this).data("attr")] = this.value;
        });

        $("input.paintstamp").off("change input").on("change input", function(){
            if(this.type === "checkbox"){
                _brushes[self.currentbrush][$(this).data("attr")] = this.checked;
            } else {
                _brushes[self.currentbrush][$(this).data("attr")] = this.value;
            }
        });

        $(".paintbutton.clearcanvas").off("click").on("click", function () {
            self.currentMedia.paintCanvas.clearCanvas();
            self.currentMedia.paintCanvas.redraw();
        });
        $(".paintbutton.undo").off("click").on("click", function () {
            self.currentMedia.paintCanvas.drawnElements.pop();
            self.currentMedia.paintCanvas.redraw();
        });
        $(".paintbutton.brushselect").off("click").on("click", function () {
            $(".paintbutton.brushselect").removeClass("active");
            $(this).addClass("active");

            var $paintStamps = $("#paintStamps");
            var $paintStampSelection = $paintStamps.find(".paintStampSelection");

            if ($(this).data("brush")) {
                if($(this).hasClass("stampselect")){
                    // init stamp selection only once
                    if($paintStampSelection.children().length <= 0) {
                        $paintStampSelection.empty().append(window._stampSelectionContainer);
                        $paintStamps.css("left",$(this).offset().left);
                        $paintStampSelection.find("[data-brush=brush1]").addClass("active");
                    }

                    var selected = $paintStampSelection.find(".paintStampSelect.active");
                    if(selected.length > 0){
                        self.currentbrush = selected.data("brush");
                    } else {
                        self.currentbrush = "brush1";
                    }
                    $paintStamps.toggle();
                } else {
                    self.currentbrush = $(this).data("brush");
                    $paintStamps.hide();
                }

                self.currentMedia.paintCanvas.currentElement.drawStyle = "brush";
                self.currentMedia.paintCanvas.currentElement.brush = _brushes[self.currentbrush];

            } else if ($(this).data("line")) {
                self.currentMedia.paintCanvas.currentElement.drawStyle = $(this).data("line");
                self.currentMedia.paintCanvas.currentElement.brush = null;

                $paintStamps.hide();
            }
        });





        /*$("#rotateValue").on("input",function(){
            $(".currentValue").text($(this).val());
        });*/
    };

    this.changeEditorMode = function() {
        if (typeof(self.editorMode) === "function") {
            self.editorMode();
        }
        else {
            $("#loadingContainer").addClass("hidden");
            self.editorMode = sourceImageMode;
            self.editorMode();
        }
    };


    /******************************
     *  ratio select
     *****************************/

    //select ratio from the drop-down
    $("#ratioSelect").on("change", function () {
        var selected = $(this).find(":selected");
        var w = selected.data("width");
        var h = selected.data("height");
        var ratio = parseInt(w) / parseInt(h);

        document.getElementById("sizeSelect").selectedIndex = 0;
        self.currentMedia.selectedSizeIndex = 0;
        self.currentMedia.selectedRatioIndex = document.getElementById("ratioSelect").selectedIndex;
        setCropperRatio(self.currentMedia, ratio, true);
    });
    //select size & ratio from the drop-down
    $("#sizeSelect").on("change", function () {
        var selected = $(this).find(":selected");
        var w = selected.data("width");
        var h = selected.data("height");
        var ratio = parseInt(w) / parseInt(h);

        document.getElementById("ratioSelect").selectedIndex = 0;
        self.currentMedia.selectedRatioIndex = 0;
        self.currentMedia.selectedSizeIndex = document.getElementById("sizeSelect").selectedIndex;

        self.currentMedia.updateTargetSize(w, h);
        setCropperRatio(self.currentMedia, ratio, true);
        setCropperSize(self.currentMedia, w, h);
    });

    // clear fixed ratio
    $("#resetTargetRatio").on("click", function () {
        setCropperRatio(self.currentMedia, NaN, false);
        document.getElementById("ratioSelect").selectedIndex = 0;
    });


    /******************************
     *  flip/flop
     *****************************/

    // flip image horizontal
    $(".horizontal").on("click", function () {
        self.currentMedia.scaleX = self.currentMedia.scaleX * -1;
        self.scaleCropper(self.currentMedia.scaleX, self.currentMedia.scaleY);
    });
    // flip image vertical
    $(".vertical").on("click", function () {
        self.currentMedia.scaleY = self.currentMedia.scaleY * -1;
        self.scaleCropper(self.currentMedia.scaleX, self.currentMedia.scaleY);
    });

    this.scaleCropper = function(x, y) {
        Cropping.transform(self.currentMedia, "scale", {
            x: x,
            y: y
        })
    };

    /******************************
     *  rotate functions
     *****************************/

    // rotate by a fixed value on slider-update
    $("#rotateValue").on("change input", function () {
        self.rotateCropper(this.value, true);
        $(this).parent().parent().find(".currentValue").text($(this).val());
    });
    //rotate left 90 degrees
    $(".rotateleft").on("click", function () {
        $("#rotateValue").val(0);
        self.rotateCropper(-90);
    });
    //rotate right 90 degrees
    $(".rotateright").on("click", function () {
        $("#rotateValue").val(0);
        self.rotateCropper(90);
    });

    $(".rotate").on("click", function () {
        $(this).toggleClass("active");
        $(".rotationArea").toggle();
    });

    this.rotateCropper = function(a, fixedValue) {
        if (typeof(fixedValue) === "undefined") fixedValue = false;

        Cropping.transform(self.currentMedia, "rotate", {
            fixedValue: fixedValue,
            angle: a
        });
    };

    /******************************
     *  resize functions
     *****************************/

    // manual target-height input
    $("#resizeHeight").on("change input", function () {
        var widthInput = $("#resizeWidth");
        widthInput.val(Math.round($(this).val() * self.currentMedia.getCroppedRatio()));

        // update the target size with the new values
        self.currentMedia.updateTargetSize(widthInput.val(), $(this).val());
    });

    // manual target-width input
    $("#resizeWidth").on("change input", function () {
        var resizeHeight = $("#resizeHeight");
        resizeHeight.val(Math.round($(this).val() / self.currentMedia.getCroppedRatio()));

        // update the target size with the new values
        self.currentMedia.updateTargetSize($(this).val(), resizeHeight.val());
    });

    // clear target size
    $("#resetTargetSize").on("click", function () {
        self.currentMedia.resetTargetSize();
    });


    /******************************
     *  filter speichern
     *****************************/

    $("#saveFilterName").on("change input", function () {
        if (this.value !== "") {
            $("#saveFilter").show();
        } else {
            $("#saveFilter").hide();
        }
    });

    $("#saveFilter").on("click", function () {
        var options;
        var presetFilter;
        var type;
        if (self.currentMedia.webGL) {
            options = self.currentMedia.webGLCanvas.values;
            presetFilter = null;
            type = "webgl";
        }

        saveNewCustomFilter(
            document.getElementById("saveFilterName").value,
            type,
            options,
            presetFilter
        );
    });


    /******************************
     *  test-stuff
     *****************************/


    $("#cropReset").on("click", function () {
        resetCropper(self.currentMedia);
    });


    // apply selected filter & filter options to all medias
    $("#applyFilterToAll").on("click", function () {
        if (self.currentMedia.webGL) {
            var values = self.currentMedia.webGLCanvas.values;

            for (var key in self.medias) {
                var media = self.medias[key];
                media.webGLCanvas.values = values;
                media.webGLCanvas.updateFilters();
                media.webGLCanvas.draw();
                media.unsavedChanges = true;
                if (!media.isCurrentMedia)
                    media.filtersChanged = true;
            }
        } else {
            var filterOptions = self.currentMedia.filterOptions;
            var presetFilter = self.currentMedia.presetFilter;

            for (var key in self.medias) {
                var media = self.medias[key];
                media.filterOptions = filterOptions;
                media.presetFilter = presetFilter;
                media.unsavedChanges = true;
                // flag all other medias which were just updated with the new filter to be reloaded after
                if (!media.isCurrentMedia)
                    media.filtersChanged = true;
            }
        }
    });

    // apply selected tags to all medias
    // also apply developer and lorem info
    $("#applyTagsToAll").on("click", function () {
        var tags = self.currentMedia.metaData["tags"],
            developer = self.currentMedia.metaData["developer"],
            lorem = self.currentMedia.metaData["lorem"];

        for (var key in self.medias) {
            self.medias[key].updateTags(tags);
            self.medias[key].updateMetaData('developer',developer);
            self.medias[key].updateMetaData('lorem',lorem);
        }
    });

    // reset Filters
    $("#resetFilters").on("click", function () {
        updateFilterFormFields();
        self.currentMedia.resetFilters();
    });


    /******************************
     *  advanced settings
     *****************************/

    $("#picaOptions").find("input").on("change input", function () {
        var options = self.currentMedia.picaOptions;
        if (this.type === "range") {
            options[$(this).data("option")] = parseFloat(this.value);
        } else if (this.type === "checkbox") {
            options[$(this).data("option")] = this.checked;
        }

        self.currentMedia.updatePicaOptions(self.currentMedia.picaFeatures, options);
    });

    $("#picaFeatures").find("input").on("change input", function () {
        var features = ["js"];
        $("#picaFeatures").find("input").each(function () {
            if (this.checked) features.push($(this).data("feature"));
        });
        self.currentMedia.updatePicaOptions(features, self.currentMedia.picaOptions);
    });

    $("#resetPica").on("click", function () {
        self.currentMedia.resetPicaOptions();
        self.resetPicaSettingInputs();
    });

    this.resetPicaSettingInputs = function() {

        $("#picaFeatures").find("input").each(function () {
            this.checked = (self.currentMedia.picaFeaturesDefault.indexOf($(this).data("feature")) !== -1);
        });

        $("#picaOptions").find("input").each(function () {
            if (this.type === "range") {
                this.value = self.currentMedia.picaOptionsDefault[$(this).data("option")];
            } else if (this.type === "checkbox") {
                this.checked = self.currentMedia.picaOptionsDefault[$(this).data("option")];
            }
        });
    };

    ///////////////////////////////////////////////////////////////////
    // Saving
    ///////////////////////////////////////////////////////////////////


    $("#saveMedia").on("click", function () {
        self.saveMedia(self.currentMedia, false);
    });

    $("#saveAll").on("click", function () {
        self.saveAllMedias();
        $(".submenuBtn.active").trigger("click");
        $(".submenuEdit:visible .bckBtn").trigger("click");
    });

    $("#asNewFile").on("change", function () {
        if (this.checked) {
            $(".newFilePopup").show();
            $("#saveMedia").attr('disabled', true);
            $("#newFileName").val("");
            self.currentMedia.newFileName = "";
        } else {
            $(".newFilePopup").hide();
            $("#saveMedia").attr('disabled', false);
            self.currentMedia.newFileName = false;
        }
    });

    $("#newFileName").on("change input", function () {
        if (this.value.length > 0) {
            $("#saveMedia").attr('disabled', false);
            self.currentMedia.newFileName = this.value;
        }
        else {
            $("#saveMedia").attr('disabled', true);
            self.currentMedia.newFileName = false;
        }
    });


    this.saveAllMedias = function() {
        let count = Object.keys(self.medias).length;
        let saved = 0;

        for (let key in self.medias) {
            let media = self.medias[key];
            saved++;
            self.saveMedia(media, (saved >= count));
        }
    };

    this.saveMedia = function(media, exit) {
        startLoading("save", "Speichern…");   //Yii.t

        let imageSaved = false;
        let metaSaved = false;

        // only call specific save functions depending on which settings the filetype allows
        if (availableSettingsForType[media.item.data("type")].indexOf("editor") > -1) {
            media.saveImage(function (result) {
                // if there were no errors on saving, close the modal & update the listview in the mediamanager
                if (result) {
                    imageSaved = true;
                    exitSave();
                } else {
                    finishedLoading("save");
                }
            });
        } else {
            imageSaved = true;
            exitSave();
        }


        if (availableSettingsForType[media.item.data("type")].indexOf("meta") >= 0) {
            media.saveMetaData(function () {
                metaSaved = true;
                exitSave();
            });
        } else {
            metaSaved = true;
            exitSave();
        }


        function exitSave() {
            if (imageSaved && metaSaved) {
                finishedLoading("save");
                if (exit) {
                    //window.MediaManager.updateListView();
                    window.MediaManager.updateListMedia(media);
                    $("#cropper-modal").modal("hide");
                }
            }
        }
    };


    // manual garbage collection on exiting modal
    $('#cropper-modal').bind('hide', function () {
        for (var media in self.medias) {
            self.medias[media].garbageCollect();
        }
    });

    $(".newFileBtn").click(function(){
        $(".newFilePopup").hide();
    });

};