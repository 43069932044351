import {Mark, Plugin} from 'tiptap'
import {removeMark, pasteRule} from 'tiptap-commands'
import {getMarkAttrs} from 'tiptap-utils'
import updateLink from '../utils/updateLink.js'

// Todo: Transform to node to use in context menu and add other features
export default class Link extends Mark {

   get name() {
      return 'link'
   }

   get defaultOptions() {
      return {
         openOnClick: true,
      }
   }

   get schema() {
      return {
         attrs: {
            href: {
               default: null,
            },
            onclick: {
               default: null
            },
            title: {
               default: null
            },
            alt: {
               default: null
            },
            target: {
               default: null
            },
            name: {
               default: null
            },
            rel: {
               default: null
            },
            type: {
               default: null
            },
            lang: {
               default: null
            },
            class: {
               default: null
            },
            hreflang: {
               default: null
            },
            'data-button-style': {
               default: null
            },
            'data-link-type': {
               default: null
            },
            'data-anchor-name': {
               default: null
            },
            'data-lang': {
               default: null
            }
         },
         inclusive: false,
         parseDOM: [
            {
               tag: 'a[href]',
               getAttrs: dom => ({
                  href: dom.getAttribute('href'),
                  onclick: dom.getAttribute('onclick'),
                  target: dom.getAttribute('target'),
                  title: dom.getAttribute('title'),
                  alt: dom.getAttribute('alt'),
                  class: dom.getAttribute('class'),
                  lang: dom.getAttribute('lang'),
                  name: dom.getAttribute('name'),
                  rel: dom.getAttribute('rel'),
                  type: dom.getAttribute('type'),
                  hreflang: dom.getAttribute('hreflang'),
                  'data-button-style': dom.getAttribute('data-button-style'),
                  'data-link-type': dom.getAttribute('data-link-type'),
                  'data-anchor-name': dom.getAttribute('data-anchor-name')
               }),
            },
         ],
         toDOM: node => ['a', {
            ...node.attrs
         }, 0],
      }
   }

   commands({type}) {

      return attrs => {

         if (attrs.href) {
            return updateLink(type, attrs)
         }

         return removeMark(type)
      }
   }

   pasteRules({type}) {
      return [
         pasteRule(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
            type,
            url => ({href: url}),
         ),
      ]
   }

   get plugins() {
      if (!this.options.openOnClick) {
         return []
      }

      return [
         new Plugin({
            props: {
               handleClick: (view, pos, event) => {
                  const {schema} = view.state;
                  const attrs = getMarkAttrs(view.state, schema.marks.link);

                  if (attrs.href && event.target instanceof HTMLAnchorElement) {
                     event.stopPropagation();
                     //window.open(attrs.href)
                  }
               },
            },
         }),
      ]
   }

}
