<template>
    <div class="drop-element-tools">
        <div class="handle">
            <i class="fa fa-arrows-alt" />
        </div>
        <div
            class=""
            @click="edit">
            <i class="fa fa-edit" />
        </div>
        <div @click="duplicate">
            <i class="fa fa-copy" />
        </div>
        <div
            @click="remove">
            <i class="fa fa-trash" />
        </div>
    </div>
</template>

<script>
export default {
    name: "DropElementTools",
    methods: {
        edit() {
            this.$emit('edit');
        },
        duplicate() {
            this.$emit('duplicate');
        },
        remove() {
            this.$emit('remove');
        }
    }
};
</script>

<style lang="scss" scoped>
.drop-element-tools {
    position: absolute;
    top: 0px;
    right: 10px;
    display: flex;

    > div {
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>