$(function () {
    let $left = $("#showroom_intro-left");
    let $middle = $("#showroom_intro-middle");
    let $right = $("#showroom_intro-right");

    $left.find("select.select2").on("change", updateLeft);
    $middle.find("select.select2").on("change", updateMiddle);
    $right.find("select.select2").on("change", updateRight);

    function updateRight() {
        console.log("right");
        $right.find(".view_right_image").toggle($right.find("select.select2").val() === "view_right_image");
        $right.find(".view_right_video").toggle($right.find("select.select2").val() === "view_right_video");
    }

    function updateMiddle() {
        console.log("middle");
        $middle.find(".view_middle_image").toggle($middle.find("select.select2").val() === "view_middle_image");
        $middle.find(".view_middle_video").toggle($middle.find("select.select2").val() === "view_middle_video");
    }

    function updateLeft() {
        console.log("left");
        $left.find(".view_left_image").toggle($left.find("select.select2").val() === "view_left_image");
        $left.find(".view_left_video").toggle($left.find("select.select2").val() === "view_left_video");
    }

    updateLeft();
    updateRight();
    updateMiddle();
});