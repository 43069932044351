var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        "aria-hidden": "true",
        "data-backdrop": "",
        role: "dialog",
        id: _vm.modal_id,
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_vm._v("Media Manager")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.closeModal },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body mediamanager_extension_container" },
              [
                _c(
                  "div",
                  { staticClass: "settings" },
                  [
                    _c("MediaForm", {
                      ref: "mediaForm",
                      attrs: { widget: _vm.mediaFormWidget },
                      on: { inputChanged: _vm.mediaFormInputChanged },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "small-headline" }, [
                      _vm._v("Grösse"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "size" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group label-inside size_unit_select",
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preview_image.options.size_unit,
                                  expression: "preview_image.options.size_unit",
                                },
                              ],
                              ref: "sizeUnitSelect",
                              staticClass: "form-control select2",
                              attrs: {
                                id: _vm.modal_id + "_size_unit",
                                form: _vm.modal_id,
                                "data-placeholder": "Einheit",
                                "data-minimum-results-for-search": "Infinity",
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.preview_image.options,
                                    "size_unit",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "%" } }, [
                                _vm._v("Prozent"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "px" } }, [
                                _vm._v("Pixel"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: _vm.modal_id + "_size_unit" } },
                            [_vm._v("Einheit")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.width,
                              expression: "preview_image.options.width",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_width",
                            placeholder: "Breite",
                            min: "0",
                            form: _vm.modal_id,
                          },
                          domProps: { value: _vm.preview_image.options.width },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "width",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: _vm.modal_id + "_width" } },
                          [_vm._v("Breite")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.height,
                              expression: "preview_image.options.height",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_height",
                            placeholder: "Höhe",
                            min: "0",
                            form: _vm.modal_id,
                            disabled: _vm.preview_image.options.ratio_locked,
                          },
                          domProps: { value: _vm.preview_image.options.height },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "height",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: _vm.modal_id + "_height" } },
                          [_vm._v("Höhe")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group ratio_lock" }, [
                        _c(
                          "div",
                          { staticClass: "custom-control custom-checkbox" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preview_image.options.ratio_locked,
                                  expression:
                                    "preview_image.options.ratio_locked",
                                },
                              ],
                              staticClass: "custom-control-input is-valid",
                              attrs: {
                                type: "checkbox",
                                id: _vm.modal_id + "_ratio_lock",
                                value: "1",
                                disabled:
                                  _vm.preview_image.options.size_unit === "%",
                                readonly:
                                  _vm.preview_image.options.size_unit === "%",
                                form: _vm.modal_id,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.preview_image.options.ratio_locked
                                )
                                  ? _vm._i(
                                      _vm.preview_image.options.ratio_locked,
                                      "1"
                                    ) > -1
                                  : _vm.preview_image.options.ratio_locked,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.preview_image.options.ratio_locked,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.preview_image.options,
                                          "ratio_locked",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.preview_image.options,
                                          "ratio_locked",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.preview_image.options,
                                      "ratio_locked",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: _vm.modal_id + "_ratio_lock" },
                              },
                              [
                                _c("span", {
                                  class: {
                                    "fontello-icon-lock-1":
                                      _vm.preview_image.options.ratio_locked,
                                    "fontello-icon-lock-open":
                                      !_vm.preview_image.options.ratio_locked,
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ratio_lock_label" }, [
                          _vm._v("Bildverhältnis beibehalten"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "small-headline" }, [
                      _vm._v("Abstand (Pixel)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margins" }, [
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.margin_left,
                              expression: "preview_image.options.margin_left",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_left",
                            placeholder: "Links",
                            min: "0",
                            form: _vm.modal_id,
                          },
                          domProps: {
                            value: _vm.preview_image.options.margin_left,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "margin_left",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: _vm.modal_id + "_left" } },
                          [_vm._v("Links")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.margin_right,
                              expression: "preview_image.options.margin_right",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_right",
                            placeholder: "Rechts",
                            min: "0",
                            form: _vm.modal_id,
                          },
                          domProps: {
                            value: _vm.preview_image.options.margin_right,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "margin_right",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: _vm.modal_id + "_right" } },
                          [_vm._v("Rechts")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.margin_top,
                              expression: "preview_image.options.margin_top",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_top",
                            placeholder: "Oben",
                            min: "0",
                            form: _vm.modal_id,
                          },
                          domProps: {
                            value: _vm.preview_image.options.margin_top,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "margin_top",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: _vm.modal_id + "_top" } }, [
                          _vm._v("Oben"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group label-inside" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preview_image.options.margin_bottom,
                              expression: "preview_image.options.margin_bottom",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: _vm.modal_id + "_bottom",
                            placeholder: "Unten",
                            min: "0",
                            form: _vm.modal_id,
                          },
                          domProps: {
                            value: _vm.preview_image.options.margin_bottom,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.preview_image.options,
                                "margin_bottom",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: _vm.modal_id + "_bottom" } },
                          [_vm._v("Unten")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "small-headline" }, [
                      _vm._v("Ausrichtung"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "alignment" },
                      _vm._l(_vm.alignments, function (item, key) {
                        return _c(
                          "div",
                          {
                            class: {
                              selected:
                                _vm.preview_image.options.alignment === key,
                            },
                            attrs: { "data-value": key },
                            on: {
                              click: function ($event) {
                                return _vm.selectAlignment(key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "preview_container" }, [
                  _c("div", { staticClass: "preview_header" }, [
                    _vm._v("Vorschau"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _vm._v(
                        "\n                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet\n                        "
                      ),
                      _c("ImageNode", {
                        staticClass: "preview_image",
                        attrs: { attrs: _vm.preview_image.options },
                        on: { load: _vm.previewImgLoaded },
                      }),
                      _vm._v(
                        "\n                        a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,\n                    "
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Abbrechen")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { disabled: !_vm.preview_image.options.src },
                  attrs: {
                    type: "button",
                    disabled: !_vm.preview_image.options.src,
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("Speichern")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }