export default {
    props: {
        logicOptions: {
            type: Array
        }
    },
    methods: {
      enableLogic() {

          this.item.logic = {
              hide_show: "hide",
              and_or: "all",
              rules: [
                  {
                      source: this.logicOptions[0].id,
                      compare: "is",
                      value: this.logicOptions[0].answers[0].id
                  }
              ]
          };
      },
      addRule() {
          this.item.logic.rules.push({
              source: this.logicOptions[0].id,
              compare: "is",
              value: this.logicOptions[0].answers[0].id
          });
      },
      removeRule(index) {
          this.item.logic.rules.splice(index, 1);

          if (this.item.logic.rules.length === 0) {
              this.item.logic = {};
          }
      }
    }
};