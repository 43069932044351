var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "number-segment-rule" },
    [
      _c("v-select", {
        staticClass: "segment-rule-operator",
        attrs: {
          options: _vm.operators,
          reduce: (label) => label.key,
          clearable: false,
        },
        model: {
          value: _vm.operator,
          callback: function ($$v) {
            _vm.operator = $$v
          },
          expression: "operator",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content,
            expression: "content",
          },
        ],
        staticClass: "segment-rule-content",
        attrs: { type: "number", placeholder: _vm.labels.placeholder },
        domProps: { value: _vm.content },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.content = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.operator === "between"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content2,
                expression: "content2",
              },
            ],
            staticClass: "segment-rule-content2",
            attrs: { type: "text", placeholder: _vm.labels.placeholder },
            domProps: { value: _vm.content2 },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.content2 = $event.target.value
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }