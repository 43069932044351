<template>
   <div>

      <swatches
              v-model="color"
              :colors="colors"
              v-bind="options"
      ></swatches>

      <input :class="inputClass" :name="inputClass" aria-invalid="false" style="display: none;"
             v-bind:value="color"/>
   </div>
</template>

<script>
   import Base from './Base.vue';

   export default {
      name: "BasicColorpicker",
      extends: Base,

      created(){

         if(!this.options['shapes']){
            this.options['shapes'] ="squares";
         }
         if(!this.options['show-border']){
            this.options['show-border'] = 'show-border'
         }
         if(!this.options['popover-to']){
            this.options['popover-to'] = "bottom";
         }
         if(!this.options['show-fallback']){
            this.options['show-fallback'] = 'show-fallback';
         }
      }
   }
</script>