"use strict";
/**
 * Nestable Lock
 */
class NestableLock {
    constructor() {
        const structureLock = document.querySelector("[data-nested-lock]");
        if (structureLock) {
            structureLock.addEventListener("change", function (event) {
                const checked = this.checked;
                const key = checked ? "data-nested-lock-confirm" : "data-nested-unlock-confirm";
                const confirmMessage = this.getAttribute(key);
                const ok = function () {
                    const checked = structureLock.checked;
                    const identifier = structureLock.getAttribute("data-nested-lock");

                    const url = new URL(location.href.replace("/index", "/save-lock"));
                    url.searchParams.append("checked", checked);

                    const request = new XMLHttpRequest();
                    request.open("GET", url.href);
                    request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                    request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                    request.setRequestHeader("X-CSRF-Token", window.yii.getCsrfToken());
                    request.send();

                    // toggle disableParentChange in nestedSortable
                    $(`#${identifier} > ol`).nestedSortable("option", "disableParentChange", checked);

                    // if the lock has been closed, we have to reload the page.
                    // Reason is that in php we update the tree's "sort order", thus we have to reload to reflect the change
                    if (checked) {
                        request.onreadystatechange = function (e) {
                            if (4 === request.readyState) {
                                window.location.reload();
                            }
                        };
                    }
                }
                const cancel = function () {
                    structureLock.checked = !structureLock.checked;
                }
                window.yii.confirm.call(this, confirmMessage, ok, cancel);
            });
        }
    }
}

(function () {
    "use strict";
    //window.nestableLock = new NestableLock();
    new NestableLock();
})();