var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "d-flex justify-content-between" }, [
    _c("div", { staticClass: "header-label" }, [
      _vm._v("\n        " + _vm._s(_vm.translations.overview) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex tab" }, [
      _vm.active === 1 && _vm.showProductTypeFilter
        ? _c("div", { staticClass: "tab-item mr-3 p-3" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productTypeMutable,
                    expression: "productTypeMutable",
                  },
                ],
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.productTypeMutable = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.changeProductType,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v(_vm._s(_vm.translations.normal)),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v(_vm._s(_vm.translations.mother)),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [
                  _vm._v(_vm._s(_vm.translations.children)),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-item mr-3 p-3",
          class: { active: _vm.active === 0 },
          on: {
            click: function ($event) {
              return _vm.setActive(0)
            },
          },
        },
        [
          _vm._v("\n            " + _vm._s(_vm.translations.selection) + " "),
          _c("span", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-item p-3",
          class: { active: _vm.active === 1 },
          on: {
            click: function ($event) {
              return _vm.setActive(1)
            },
          },
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.translations.catalogue) + "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }