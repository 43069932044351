<template>
  <div :key="props.src" class="kenburns-wrap">
    <ImageLoader :src="props.src" class="kenburns" />
  </div>
</template>

<script setup>
import ImageLoader from './ImageLoader.vue'
import { defineProps } from 'vue'

const props = defineProps({
    src: {
        type: String
    }
})
</script>

<style scoped lang="scss">
.kenburns-wrap {
    top: -10%; // was -10vh
    bottom: -10%; // was -10vh
    left: -10%; // was -10vw
    right: -10%; // was -10vw
    position: absolute;
    overflow: hidden;
    width: auto;
    height: auto;

    img.kenburns {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: 150s ppb_kenburns linear infinite alternate;
    }
}

@keyframes ppb_kenburns {
    0% {
        transform: scale(1.3) translate(-10%, 10%);
    }
    25% {
        transform: scale(1) translate(0, 0);
    }
    50% {
        transform: scale(1.3) translate(10%, 10%);
    }
    75% {
        transform: scale(1) translate(0, 0);
    }
    100% {
        transform: scale(1.3) translate(-10%, 10%);
    }
}
</style>
