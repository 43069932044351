var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group label-inside" },
    [
      _vm.field.type === "headline"
        ? _c("h6", {
            staticClass: "heading",
            attrs: { id: _vm.getFieldId("headline") },
            domProps: { textContent: _vm._s(_vm.field.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type == "text"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass: "form-control pageeditor-ignore-updatestructure",
            attrs: {
              id: _vm.getFieldId("input"),
              readonly: _vm.readonly,
              type: "text",
            },
            domProps: { value: _vm.content },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
                _vm.handleInput,
              ],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "text"
        ? _c("label", {
            attrs: { for: _vm.getFieldId("input") },
            domProps: { textContent: _vm._s(_vm.field.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "checkbox"
        ? _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              staticClass:
                "custom-control-input pageeditor-ignore-updatestructure",
              attrs: {
                id: _vm.getFieldId("checkbox"),
                readonly: _vm.readonly,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.content)
                  ? _vm._i(_vm.content, null) > -1
                  : _vm.content,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.content,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.content = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.content = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.content = $$c
                    }
                  },
                  _vm.handleInput,
                ],
              },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "custom-control-label",
              attrs: { for: _vm.getFieldId("checkbox") },
              domProps: { textContent: _vm._s(_vm.field.label) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type == "textarea"
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass: "form-control pageeditor-ignore-updatestructure",
            attrs: {
              id: _vm.getFieldId("textarea"),
              readonly: _vm.readonly,
              type: "textarea",
            },
            domProps: { value: _vm.content },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
                _vm.handleInput,
              ],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "textarea"
        ? _c("label", {
            attrs: { for: _vm.getFieldId("textarea") },
            domProps: { textContent: _vm._s(_vm.field.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type == "select"
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              staticClass: "form-control pageeditor-ignore-updatestructure",
              attrs: {
                id: _vm.getFieldId("select"),
                readonly: _vm.readonly,
                type: "select",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.content = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.handleInput,
                ],
              },
            },
            _vm._l(_vm.field.options, function (option, index) {
              return _c("option", {
                key: index,
                domProps: {
                  value: option.value,
                  textContent: _vm._s(option.text),
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "select"
        ? _c("label", {
            attrs: { for: _vm.getFieldId("select") },
            domProps: { textContent: _vm._s(_vm.field.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "tiptap"
        ? _c("tip-tap", {
            attrs: {
              "hide-from-page-editor": true,
              "init-content": _vm.content,
              "textarea-id": _vm.getFieldId("tabeditor"),
              "textarea-name": _vm.getFieldId("tabeditor"),
              tools: [],
            },
            on: { "editor-update": (content) => _vm.tiptapUpdate(content) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }