import variables from '../../../../../backend/styles/_variables.scss';

export const FORMAT_MARK_NAMES = [
    "bold" /* bold */,
    "italic" /* italic */,
    "underline" /* underline */,
    "strike" /* strike */,
    "link" /* link */,
    "color" /* color */,
    "bgcolor" /* bgcolor */,
    "text_highlight" /* textHighlight */,
    "center" /* textHighlight */,
    "left" /* left */,
    "right" /* right */,
    "justify" /* justify */,
    "fontsize" /* fontsize */
];
export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
export const HEADINGS = [1,2,3,4];
export const COLORS = [
    variables.primary,
    variables.success,
    variables.warning,
    variables.danger,
    variables.inputColor
];
export const BGCOLORS = [
    variables.primary,
    variables.success,
    variables.warning,
    variables.danger,
    variables.inputColor
];
export const VARIABLES = variables;