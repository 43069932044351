<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.scale'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div class="form-group">
                <label>{{ element.items[0].data.question }}</label>

                <div class="scale">
                    <div @mouseleave="hover = 0">
                        <label
                            v-for="n in Number(element.items[0].data.scaleSteps)"
                            :key="n"
                            class=""
                            @mouseenter="hover = n">
                            <i :class="[`fa-${element.items[0].data.scaleIcon}`, iconStyle(n)]" />
                        </label>
                    </div>

                    <div class="scale-min-label hasi18n" />
                    <div class="scale-max-label hasi18n" />
                </div>

                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Scale",
    mixins: [previewItemMixin],
    data: function() {
        return {
            hover: 0
        };
    },
    methods: {
        iconStyle(n) {
            if (n <= this.hover) {
                return 'fa';
            }

            return n <= this.element.items[0].data.scaledefaultselected ? 'fa' : 'far';
        }
    }
};
</script>

<style lang="scss" scoped>
.scale{
    font-size: 1.6rem;

    label {
        cursor: pointer;
    }
}
</style>