var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.notifications, function (notification) {
      return _c(
        "div",
        {
          key: notification.id,
          staticClass: "alert",
          class: [`alert-${notification.type}`],
          attrs: { role: "alert" },
        },
        [_vm._v("\n        " + _vm._s(notification.message) + "\n    ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }