<template>
    <div class="form-group label-inside">
        <select
            :id="id"
            :value="value"
            type="text"
            class="form-control"
            @change="update($event.target.value)">
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <label :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        value: {
            type: [String, Number]
        },
        options: {
            type: Array
        }
    },
    methods: {
        update(value) {
            this.$emit('input', value);
        }
    },
    created() {
        if (!this.value) {
            const defaultItem = this.options.find(item => item.isDefault)
            if (defaultItem) {
                this.update(defaultItem.value)
            }
        }
    }
};
</script>