<template>
    <div class="d-flex align-items-center">
        <span class="color-dot" :style="`background-color: ${hexCode}`" v-if="hexCode !== ''"></span> <span class="font-weight-bold">{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: 'Color',
    props: {
        field: {
            type: Object
        }
    },
    computed: {
        title() {
            if (this.$attrs.item.productData && this.$attrs.item.productData.color && this.$attrs.item.productData.color.title !== '') {
                return this.$attrs.item.productData.color.title;
            }
            if (this.$attrs.item.color && this.$attrs.item.color !== '') {
                return this.$attrs.item.color.title;
            }
            return '-';
        },
        hexCode() {
            if (this.$attrs.item.productData && this.$attrs.item.productData.color && this.$attrs.item.productData.color.hexCode !== '') {
                return this.$attrs.item.productData.color.hexCode;
            }
            if (this.$attrs.item.color && this.$attrs.item.color.hexCode !== '') {
                return this.$attrs.item.color.hexCode;
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
.color-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 0.5px solid #4F5E63;
    border-radius: 50%;
    margin-right: 15px;
}
</style>