/**
 * Finds tags with no innerHtml and tags which are empty or contain only whitespace.
 *
 * Usage:
 * import "./jquery.blank";
 * let blanks = $(':blank');
 */
jQuery.extend(jQuery.expr[":"], {
    blank: function (el) {
        if (el.innerHTML) {
            return 0 === el.innerHTML.replace(/\s/ig, "").length;
        } else {
            return true;
        }
    }
});