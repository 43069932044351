var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      on: {
        click: function ($event) {
          return _setup.emit("toggle-sound-clicked", _vm.muted)
        },
      },
    },
    [
      !_vm.muted
        ? _c(
            "svg",
            {
              attrs: {
                width: "48",
                height: "48",
                viewBox: "0 0 48 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  width: "48",
                  height: "48",
                  rx: "10",
                  fill: "white",
                  "fill-opacity": "0.1",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M26.8615 18.2919C26.8951 16.5903 25.0003 15.5681 23.5969 16.5391L18.0613 20.4279H16.1436C14.9706 20.4279 14 21.3985 14 22.5714V25.4296C14 26.6025 14.9706 27.5732 16.1436 27.5732H18.0571L23.4578 31.4545L23.4682 31.4618C24.8564 32.4224 26.8237 31.4278 26.8614 29.7326L26.8615 18.2919ZM24.4117 17.7131C24.855 17.4082 25.4456 17.7263 25.4327 18.2672L25.4325 29.7074C25.4151 30.2543 24.7401 30.5989 24.2857 30.2896L18.7042 26.2784C18.5827 26.1911 18.4368 26.1441 18.2872 26.1441H16.1436C15.7599 26.1441 15.4291 25.8133 15.4291 25.4296V22.5714C15.4291 22.1877 15.7599 21.8569 16.1436 21.8569H18.2872C18.4342 21.8569 18.5776 21.8116 18.6979 21.7271L24.4117 17.7131ZM31.4862 18.3875C31.8237 18.1832 32.2631 18.2911 32.4675 18.6287C33.4477 20.2476 33.9766 22.0994 33.9998 23.9917L34 24.0092C33.9769 25.9016 33.4477 27.7533 32.4675 29.3723C32.2631 29.7098 31.8237 29.8178 31.4862 29.6134C31.1486 29.409 31.0406 28.9697 31.245 28.6321C32.0916 27.2339 32.5492 25.6349 32.5707 24.0005C32.5492 22.3661 32.0916 20.7671 31.245 19.3689C31.0406 19.0313 31.1486 18.5919 31.4862 18.3875ZM28.5497 21.3063C28.8538 21.0548 29.3042 21.0974 29.5557 21.4015C30.1587 22.1305 30.4714 23.0559 30.4348 24.0005C30.4714 24.945 30.1587 25.8705 29.5557 26.5995C29.3042 26.9036 28.8538 26.9462 28.5497 26.6947C28.2456 26.4432 28.203 25.9928 28.4545 25.6887C28.8379 25.2252 29.0345 24.6353 29.0059 24.0344C29.0049 24.0118 29.0049 23.9892 29.0059 23.9666C29.0345 23.3657 28.8379 22.7758 28.4545 22.3123C28.203 22.0082 28.2456 21.5578 28.5497 21.3063Z",
                  fill: "white",
                },
              }),
            ]
          )
        : _c(
            "svg",
            {
              attrs: {
                width: "48",
                height: "48",
                viewBox: "0 0 48 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  width: "48",
                  height: "48",
                  rx: "10",
                  fill: "white",
                  "fill-opacity": "0.1",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M26.8571 18.2941C26.8907 16.5931 24.9965 15.5712 23.5936 16.5419L18.0599 20.4293H16.1429C14.9703 20.4293 14 21.3996 14 22.5722V25.4293C14 26.6019 14.9703 27.5722 16.1429 27.5722H18.0557L23.4546 31.4522L23.465 31.4595C24.8527 32.4197 26.8193 31.4255 26.857 29.7309L26.8571 18.2941ZM24.4081 17.7155C24.8513 17.4107 25.4417 17.7287 25.4288 18.2694L25.4286 29.7057C25.4112 30.2524 24.7364 30.5969 24.2822 30.2878L18.7026 26.2779C18.5811 26.1906 18.4353 26.1436 18.2857 26.1436H16.1429C15.7593 26.1436 15.4286 25.8129 15.4286 25.4293V22.5722C15.4286 22.1886 15.7592 21.8579 16.1429 21.8579H18.2857C18.4327 21.8579 18.5761 21.8126 18.6963 21.7281L24.4081 17.7155ZM28.4949 21.3528C28.7739 21.0739 29.2261 21.0739 29.5051 21.3528L31.1429 22.9906L32.7806 21.3528C33.0596 21.0739 33.5118 21.0739 33.7908 21.3528C34.0697 21.6317 34.0697 22.084 33.7908 22.3629L32.153 24.0007L33.7908 25.6385C34.0697 25.9175 34.0697 26.3697 33.7908 26.6487C33.5118 26.9276 33.0596 26.9276 32.7806 26.6487L31.1429 25.0109L29.5051 26.6487C29.2261 26.9276 28.7739 26.9276 28.4949 26.6487C28.216 26.3697 28.216 25.9175 28.4949 25.6385L30.1327 24.0007L28.4949 22.3629C28.216 22.084 28.216 21.6317 28.4949 21.3528Z",
                  fill: "white",
                },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }