<template>
    <div class="filter-settings__sidebar">
        <draggable :group="{ name: 'filter', pull: 'clone', put: false }" :sort="false" :clone="clone" :list="filtersArray" v-if="filters" :animation="150" :component-data="getComponentData()">
            <div v-for="(value, key) in filtersArray" class="filter" :key="key">
                <div class="handle"></div>
                {{ value.label }}
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: 'Sidebar',
    components: {
        draggable
    },
    props: {
        filters: {
            type: Object,
            default: {}
        },
        active: {
            type: Array,
            default: []
        }
    },
    computed: {
        filtersArray() {
            const list = [];

            Object.keys(this.filters).forEach(key => {
                let found = false;

                // check if filter is already selected
                this.active.forEach(active => {
                    if (active.filterId === this.filters[key].attribute) {
                        found = true;
                    }
                });

                if (!found && this.filters[key].type != 'boolean') {
                    list.push(this.filters[key]);
                }
            });

            return list;
        }
    },
    methods: {
        clone(filter) {
            return {
                filterId: filter.attribute,
                filterData: {
                    label: filter.label
                }
            };
        },
        getComponentData() {
            return {
                on: {
                    start: this.handleStart,
                    end: this.handleEnd
                }
            };
        },
        handleStart() {
            this.$emit('drag-start');
        },
        handleEnd() {
            this.$emit('drag-end');
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-settings__sidebar{
    background-color: #FAFBFB;
    padding: 10px;
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.filter {
    display: flex;
    align-items: center;
    height: 35px;
    background-color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    color: #4C5E64;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 1px 2px #4C5E641A;
    padding: 0 15px;

    div.handle {
        margin-right: 16px;

        &:after {
            content: '\e807';
            font-family: "fontello";
            font-size: 10px;
            cursor: move;
        }
    }
}
</style>