<template>
  <table>
    <thead>
    <tr>
      <th
          v-for="(field, index) in fields"
          :key="index"
          :class="[field.cssClass !== undefined ? field.cssClass : '']">
        <ColumnHeader
            :field="field"
            :sort-by="sortBy"
            @sort="sort"/>
      </th>
      <th class="fit-column">
        {{ translations.status }}
      </th>
      <th class="fit-column">
        <a
            v-if="productCatalogActive"
            class="btn btn-sm btn-outline-secondary"
            href="javascript:void(0)"
            @click="selectAll">
          {{ translations.select_all }}
        </a>
      </th>
    </tr>
    </thead>
    <template v-if="sortable">
      <draggable :animation="200" :list="items" draggable=".is-draggable" handle=".handle" tag="tbody">
        <tr v-for="(item, i) in items" :key="item.id" class="is-draggable">
          <td
              v-for="(field, index) in fields"
              :key="`item-${index}`"
              :class="[field.cssClass !== undefined ? field.cssClass : '']">
            <component
                :is="field.component"
                :field="field"
                :item="item"/>
          </td>
          <td class="fit-column">
            <span v-if="(item.productData ? item.productData.status : 0) || (item.status == 1)"
                  class="badge badge-alternate-success">{{ translations.active }}
            </span>
            <span v-else class="badge badge-alternate-warning">{{ translations.inactive }}</span>
          </td>
          <td class="fit-column">
            <slot :item="item" :order="(i + 1) * 10"/>
          </td>
        </tr>
      </draggable>
    </template>
    <template v-else>
      <tbody>
      <tr v-for="(item, i) in items" :key="item.id">
        <td
            v-for="(field, index) in fields"
            :key="`item-${index}`"
            :class="[field.cssClass !== undefined ? field.cssClass : '']">
          <component
              :is="field.component"
              :field="field"
              :item="item"/>
        </td>
        <td class="fit-column">
          <span v-if="item.status" class="badge badge-alternate-success">{{ translations.active }}</span>
          <span v-else class="badge badge-alternate-warning">{{ translations.inactive }}</span>
        </td>
        <td class="fit-column">
          <slot :item="item" :order="(i + 1) * 10"/>
        </td>
      </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
import draggable from "vuedraggable";

import Color from "./columns/Color.vue";
import EventBus from "./util/EventBus";
import Media from "./columns/Media.vue";
import TextBold from "./columns/TextBold.vue";
import TextNormal from "./columns/TextNormal.vue";
import Favorite from "./columns/Favorite.vue";
import SortHandle from "./columns/SortHandle.vue";
import ColumnHeader from "./ColumnHeader.vue";

export default {
  name: 'Table',
  components: {
    Color,
    Media,
    TextBold,
    TextNormal,
    Favorite,
    SortHandle,
    ColumnHeader,
    draggable
  },
  props: {
    fields: {
      type: Object
    },
    items: {
      type: Array
    },
    sortBy: {
      type: String
    },
    resultsKey: {
      type: String
    },
    sortable: {
      type: Boolean,
      default: false
    },
    translations: {
      type: Object
    },
    productCatalogActive: {
      type: Boolean
    }
  },
  methods: {
    sort(sortBy) {
      this.$emit('sort', sortBy);
    },
    selectAll() {
      EventBus.$emit('selectAll');
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  table-layout: auto;

  th, td {
    border-bottom: 1px solid #D6D8D8;
    padding: 0 0 0 20px;

    &:first-child {
      padding: 0;
    }

    &.fit-column {
      width: 1px;
      white-space: nowrap;
    }
  }

  th {
    height: 40px;
    color: #4C5E64;
    font-size: 0.5em;
    font-weight: normal;
  }

  td {
    font-size: 0.625em;
    color: #30464C;
    height: 65px;

    &:last-child {
      text-align: right;
    }
  }

  .column-image {
    width: 40px;
  }
}
</style>