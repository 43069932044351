var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.field.accessor(_vm.item) !== ""
      ? _c("img", { attrs: { src: _vm.field.accessor(_vm.item) } })
      : _c("i", { staticClass: "fas fa-image" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }