var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "editorContainer",
      class: [{ editable: _vm.editable === true }, "editor"],
      attrs: { xmlns: "http://www.w3.org/1999/html" },
    },
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ commands, isActive, getMarkAttrs }) {
              return [
                _c("div", { staticClass: "menubar" }, [
                  _c("div", { staticClass: "menubar-row" }, [
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("heading") !== -1
                        ? _c("div", { staticClass: "select-wrapper" }, [
                            _c(
                              "select",
                              {
                                ref: "selectHeading",
                                staticClass: "inject-frontend headings",
                                on: {
                                  change: function ($event) {
                                    return _vm.insertTag($event)
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { selected: "", disabled: "" } },
                                  [_vm._v("Stil")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "optgroup",
                                  { attrs: { label: "Blockstile" } },
                                  [
                                    _vm._l(_vm.HEADINGS, function (heading) {
                                      return _c(
                                        "option",
                                        {
                                          domProps: {
                                            selected: isActive.heading({
                                              level: heading,
                                            }),
                                            value: "h" + heading,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "H" +
                                              _vm._s(heading) +
                                              "\r\n                                "
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "p" } }, [
                                      _vm._v("p"),
                                    ]),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("fontsize") !== -1
                        ? _c("div", { staticClass: "select-wrapper" }, [
                            _c(
                              "select",
                              {
                                ref: "selectFontsize",
                                staticClass: "inject-frontend fontsize",
                                on: {
                                  change: function ($event) {
                                    return _vm.fontSize($event)
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "" },
                                    domProps: {
                                      selected: !getMarkAttrs("fontsize").size,
                                    },
                                  },
                                  [_vm._v("Grösse")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "optgroup",
                                  { attrs: { label: "Schriftgrösse" } },
                                  _vm._l(_vm.FONT_SIZES, function (fontSize) {
                                    return _c(
                                      "option",
                                      {
                                        style: `font-size: ${fontSize}px;`,
                                        attrs: { "data-fontsize": fontSize },
                                        domProps: {
                                          selected:
                                            getMarkAttrs("fontsize").size ===
                                            fontSize,
                                          value: fontSize,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(fontSize) +
                                            "\r\n                                "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 || _vm.tools.indexOf("cut") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button", title: "Ausschneiden" },
                              on: {
                                click: function ($event) {
                                  return _vm.cut()
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "cut" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 || _vm.tools.indexOf("copy") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button", title: "Kopieren" },
                              on: {
                                click: function ($event) {
                                  return _vm.copy($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "copy" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("paste") !== -1
                        ? _c(
                            "button",
                            {
                              ref: "editorPaste",
                              staticClass: "menubar__button",
                              attrs: { type: "button", title: "Einfügen" },
                              on: {
                                click: function ($event) {
                                  return _vm.paste()
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "paste" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("clear") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: {
                                type: "button",
                                title: "Format entfernen",
                              },
                              on: { click: commands.clear },
                            },
                            [_c("icon", { attrs: { name: "clear" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("pasteraw") !== -1
                        ? _c(
                            "button",
                            {
                              ref: "editorPasteRaw",
                              staticClass: "menubar__button",
                              attrs: {
                                type: "button",
                                title: "Einfügen ohne Formatierung",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.pasteRaw()
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "paste_raw" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 || _vm.tools.indexOf("undo") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button" },
                              on: { click: commands.undo },
                            },
                            [_c("icon", { attrs: { name: "undo" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 || _vm.tools.indexOf("redo") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button" },
                              on: { click: commands.redo },
                            },
                            [_c("icon", { attrs: { name: "redo" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("createlink") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.link() },
                              attrs: {
                                type: "button",
                                title: isActive.link()
                                  ? "Link bearbeiten"
                                  : "Link hinzufügen",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.createLinkModal(getMarkAttrs("link"))
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "link" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("removelink") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.link() },
                              attrs: {
                                type: "button",
                                title: "Link entfernen",
                              },
                              on: {
                                click: function ($event) {
                                  return commands.link({ href: null })
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "remove-link" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          class: { "is-active": isActive.link() },
                          attrs: {
                            type: "button",
                            title: isActive.link()
                              ? "Button bearbeiten"
                              : "Button hinzufügen",
                          },
                          on: {
                            click: function ($event) {
                              _vm.createLinkModal(
                                getMarkAttrs("link"),
                                "button"
                              )
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "button" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          class: { "is-active": isActive.link() },
                          attrs: { type: "button", title: "Button entfernen" },
                          on: {
                            click: function ($event) {
                              return commands.link({ href: null })
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "remove-button" } })],
                        1
                      ),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("table") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: {
                                type: "button",
                                title: "Tabelle hinzufügen",
                              },
                              on: {
                                click: function ($event) {
                                  return commands.createTable({
                                    rowsCount: 3,
                                    colsCount: 3,
                                    withHeaderRow: false,
                                  })
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "table" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.extensionIframeEnabled() &&
                      (_vm.tools.length === 0 ||
                        _vm.tools.indexOf("youtube") !== -1)
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button", title: "Youtube" },
                              on: {
                                click: function ($event) {
                                  return _vm.insertYoutube(commands.iframe)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "youtube" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.extensionMediamanagerEnabled() &&
                      (_vm.tools.length === 0 ||
                        _vm.tools.indexOf("mediamanager") !== -1)
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: { type: "button", title: "Mediamanager" },
                              on: { click: _vm.createMediamanagerModal },
                            },
                            [_c("icon", { attrs: { name: "mediamanager" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "menubar-row" }, [
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 || _vm.tools.indexOf("bold") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.bold() },
                              attrs: { type: "button", title: "Fett" },
                              on: { click: commands.bold },
                            },
                            [_c("icon", { attrs: { name: "bold" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("italic") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.italic() },
                              attrs: { type: "button", title: "Kursiv" },
                              on: { click: commands.italic },
                            },
                            [_c("icon", { attrs: { name: "italic" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("underline") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.underline() },
                              attrs: { type: "button", title: "Unterstrichen" },
                              on: { click: commands.underline },
                            },
                            [_c("icon", { attrs: { name: "underline" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("strike") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.strike() },
                              attrs: {
                                type: "button",
                                title: "Durchgestrichen",
                              },
                              on: { click: commands.strike },
                            },
                            [_c("icon", { attrs: { name: "strike" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("subscript") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.subscript() },
                              attrs: { type: "button", title: "Tiefgestellt" },
                              on: { click: commands.subscript },
                            },
                            [_c("icon", { attrs: { name: "subscript" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("superscript") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.superscript() },
                              attrs: { type: "button", title: "Hochgestellt" },
                              on: { click: commands.superscript },
                            },
                            [_c("icon", { attrs: { name: "superscript" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("fontcolor") !== -1
                        ? _c(
                            "div",
                            { staticClass: "color__picker--container" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: { "is-active": _vm.textColorIsActive },
                                  attrs: { type: "button", title: "Textfarbe" },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.textColorIsActive =
                                        !_vm.textColorIsActive),
                                        _vm.closeOtherColorDialog("text")
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "color-type" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "scroll-y-transition",
                                    mode: "out-in",
                                  },
                                },
                                [
                                  _vm.textColorIsActive
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "color__picker--content",
                                        },
                                        [
                                          _c("FiregroupColorpicker", {
                                            key: "color-",
                                            attrs: {
                                              title: "Text Farbe",
                                              modelId: _vm.modelId,
                                              inputValue:
                                                getMarkAttrs("color").color,
                                              inputOptions: {},
                                              parentEventName: "setTextColor",
                                              inputClass: "form-control",
                                              inputPlaceholder:
                                                "Hex code eingeben",
                                            },
                                            on: {
                                              setTextColor: function ($event) {
                                                _vm.editor.commands.color({
                                                  color: $event.color,
                                                }),
                                                  (_vm.textColorIsActive = false)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("backgroundcolor") !== -1
                        ? _c(
                            "div",
                            { staticClass: "color__picker--container" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active": _vm.backgroundColorIsActive,
                                  },
                                  attrs: {
                                    type: "button",
                                    title: "Hintergrundfarbe",
                                  },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.backgroundColorIsActive =
                                        !_vm.backgroundColorIsActive),
                                        _vm.closeOtherColorDialog("bg")
                                    },
                                  },
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "color-background" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "scroll-y-transition",
                                    mode: "out-in",
                                  },
                                },
                                [
                                  _vm.backgroundColorIsActive
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "color__picker--content",
                                        },
                                        [
                                          _c("FiregroupColorpicker", {
                                            key: "bcolor-",
                                            attrs: {
                                              title: "Hintergrund Farbe",
                                              modelId: _vm.modelId,
                                              inputValue:
                                                getMarkAttrs("bgcolor").bgcolor,
                                              inputOptions: {},
                                              parentEventName: "setBgColor",
                                              inputClass: "form-control",
                                              inputPlaceholder:
                                                "Hex code eingeben",
                                            },
                                            on: {
                                              setBgColor: function ($event) {
                                                _vm.editor.commands.bgcolor({
                                                  bgcolor: $event.color,
                                                }),
                                                  (_vm.backgroundColorIsActive = false)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("bulletlist") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.bullet_list() },
                              attrs: { type: "button", title: "Liste" },
                              on: { click: commands.bullet_list },
                            },
                            [_c("icon", { attrs: { name: "list_unordered" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("orderedlist") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.ordered_list() },
                              attrs: {
                                type: "button",
                                title: "geordnete Liste",
                              },
                              on: { click: commands.ordered_list },
                            },
                            [_c("icon", { attrs: { name: "list_ordered" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 || _vm.tools.indexOf("line") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              attrs: {
                                type: "button",
                                title: "Horizontale Linie",
                              },
                              on: { click: commands.horizontal_rule },
                            },
                            [_c("icon", { attrs: { name: "line" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("alignleft") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.left() },
                              attrs: { type: "button", title: "Linksbündig" },
                              on: { click: commands.left },
                            },
                            [_c("icon", { attrs: { name: "left" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("aligncenter") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.center() },
                              attrs: { type: "button", title: "Zentriert" },
                              on: { click: commands.center },
                            },
                            [_c("icon", { attrs: { name: "center" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("alignright") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.right() },
                              attrs: { type: "button", title: "Rechtsbündig" },
                              on: { click: commands.right },
                            },
                            [_c("icon", { attrs: { name: "right" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tools.length === 0 ||
                      _vm.tools.indexOf("blocktext") !== -1
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.justify() },
                              attrs: { type: "button", title: "Blocktext" },
                              on: { click: commands.justify },
                            },
                            [_c("icon", { attrs: { name: "justify" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menubar-panel" }, [
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          class: { "is-active": _vm.isSourceActive },
                          attrs: { type: "button" },
                          on: { click: _vm.showSource },
                        },
                        [_c("icon", { attrs: { name: "source" } })],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "menubar-row" },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "scroll-y-transition",
                            mode: "out-in",
                          },
                        },
                        [
                          isActive.table() && !_vm.isSourceActive
                            ? _c("div", { staticClass: "menubar-panel" }, [
                                _c("fieldset", [
                                  _c("legend", [
                                    _vm._v("Tabelleneigenschaften:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Tabelle löschen",
                                      },
                                      on: { click: commands.deleteTable },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "delete_table" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Spalte vor",
                                      },
                                      on: { click: commands.addColumnBefore },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "add_col_before" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Spalte nach",
                                      },
                                      on: { click: commands.addColumnAfter },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "add_col_after" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Reihe löschen",
                                      },
                                      on: { click: commands.deleteColumn },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "delete_col" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Reihe hinzufügen vor",
                                      },
                                      on: { click: commands.addRowBefore },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "add_row_before" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Reihe hinzufügen nach",
                                      },
                                      on: { click: commands.addRowAfter },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "add_row_after" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Reihe löschen",
                                      },
                                      on: { click: commands.deleteRow },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "delete_row" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Zellen kombinieren",
                                      },
                                      on: { click: commands.toggleCellMerge },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "combine_cells" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        type: "button",
                                        title: "Header-Zelle",
                                      },
                                      on: { click: commands.toggleHeaderCell },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "toggle_table_header" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("editor-content", {
        ref: "editorContent",
        staticClass: "editor__content",
        attrs: { editor: _vm.editor },
      }),
      _vm._v(" "),
      _c("textarea", {
        ref: "editorTextarea",
        staticClass: "form-control",
        class: { "pageeditor-ignore-updatestructure": _vm.hideFromPageEditor },
        staticStyle: { display: "none" },
        attrs: {
          id: _vm.textareaId,
          name: _vm.textareaName,
          rows: "6",
          "aria-invalid": "false",
          "data-inherit": "input",
        },
        on: { change: _vm.textContentChanged },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "mm_extension_modal_container",
          staticClass: "mm_extension_modal_container",
        },
        _vm._l(_vm.mediamanagerExtensionModals, function (mm_modal, id) {
          return _c("MediamanagerModal", {
            key: id,
            attrs: {
              modelName: _vm.modelName,
              original_attr: _vm.attributeName,
              attributeName: mm_modal.attributeName
                ? mm_modal.attributeName
                : null,
              modelmedias: mm_modal.modelmedias,
              imageOptions: mm_modal.imageOptions,
              saveCallback: _vm.editor.commands.mediamanager,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "linkModalContainer", staticClass: "link_modal_container" },
        _vm._l(_vm.linkModals, function (linkModal) {
          return _c("LinkModal", {
            key: linkModal.id,
            attrs: {
              modalId: linkModal.id,
              editor: _vm.editor,
              attrs: linkModal.attrs,
              isButton: _vm.isButton,
              saveCallback: _vm.saveLinkCallback,
              closeCallback: _vm.destroyLinkModal,
              openMediamanagerCallback: _vm.createMediamanagerModal,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "invalid-feedback" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }