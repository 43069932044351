import {Mark} from 'tiptap'
import {updateMark} from 'tiptap-commands'

import {DRIBBLE} from '../../../../../../backend/styles/constants.js';

export default class Color extends Mark {

    get name() {
        return 'color'
    }

    get defaultOptions() {
        return {
            colors: DRIBBLE,
        }
    }

    get schema() {
        return {
            attrs: {
                color: {
                    default: '#000',
                },
            },
            inline: true,
            content: 'inline*',
            group: 'block',

            parseDOM: [{
                style: 'color',
                getAttrs(value) { return { color : value} },
            }],

            toDOM: node => [`span`, {'style': `color: ${node.attrs.color};`}, 0],
        }
    }

    commands({type}) {
        return attrs => updateMark(type, attrs)
    }
}
