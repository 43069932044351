<template>
    <div class="treeview">
        <div v-if="debug" class="alert alert-warning">
            <span class="d-block">selected: {{ selected }}</span>
            <span class="d-block">inputValue: {{ inputValue }}</span>
        </div>
        <input :name="inputName" :value="inputValue" type="hidden">
        <ul :class="containerClasses" role="group">
            <TreeviewNode v-for="(child, index) in data"
                          :key="child.id"
                          v-model="selected"
                          :data="child"
                          :depth="0"
                          :disable-inheritance="disableInheritance"
                          :item-disabled="itemDisabled"
                          :item-key="itemKey"
                          :item-text="itemText"
                          :leaf-icon-close="leafIconClose"
                          :leaf-icon-open="leafIconOpen"
            >
            </TreeviewNode>
        </ul>
    </div>
</template>

<script>
import TreeviewNode from './TreeviewNode.vue'
import { eventBus } from './helper/EventBus';
import { getAllChildren, getParents } from './../assets/js/helpers.js'

export default {
    name: 'Treeview',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        activeClass: {
            type: String,
            default: 'treeview-node--active',
        },
        inputName: {
            type: String
        },
        itemDisabled: {
            type: String,
            default: 'disabled',
        },
        itemKey: {
            type: String,
            default: 'id',
        },
        itemText: {
            type: String,
            default: 'title',
        },
        leafIconOpen: {
            type: String
        },
        leafIconClose: {
            type: String
        },
        selections: {
            type: Array
        },
        disableInheritance: {
            type: Boolean,
            default: false
        },
        debug: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TreeviewNode
    },
    data: () => ({
        selected: []
    }),
    computed: {
        containerClasses() {
            return [
                {'treeview-container': true}
            ]
        },
        inputValue() {
            return JSON.stringify(this.selected)
        }
    },
    beforeDestroy() {
        eventBus.$off('toggleItem');
    },
    created() {
        this.selected = this.selections.length ? this.selections.map(item => item + '') : []

        eventBus.$on('toggleItem', (data) => {
            const {id, state, node} = data
            if (!this.disableInheritance) {
                let children = node ? getAllChildren(node, this.itemKey) : []
                let parents = getParents(this.data, id)
                const allParentsAndChildren = [...parents, ...children]
                let selected
                if (state) {
                    selected = [...new Set([...this.selected, ...allParentsAndChildren])]
                } else {
                    children = [...children, ...[id + '']]
                    selected = this.selected.filter((el) => !children.includes(el));
                }
                this.selected = selected
            } else {
                if (state) {
                    this.selected = [...new Set([...this.selected, id + ''])]
                } else {
                    this.selected = this.selected.filter((el) => el != id);
                }
            }
        })
    }
}
</script>

<style scoped>
</style>