<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.input'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label>{{ element.items[0].data.question }}</label>
                <input
                    class="form-control"
                    :type="element.items[0].data.inputtype"
                    :placeholder="element.items[0].data.placeholder">
                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Input",
    mixins: [previewItemMixin],
};
</script>