var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade-transition" } }, [
    _vm.isLoading
      ? _c(
          "div",
          {
            key: "loading",
            staticClass: "loading--container",
            class: _vm.cssClass,
            style: _vm.styles,
          },
          [
            _c(
              "div",
              {
                staticClass: "spinner-border",
                class: _vm.color,
                style: _vm.spinnerStyles,
                attrs: { role: "status" },
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }