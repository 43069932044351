<template>
    <div class="number-segment-rule">
        <v-select v-model="operator" :options="operators" :reduce="label => label.key" class="segment-rule-operator" :clearable="false"></v-select>
        <input type="number" v-model="content" :placeholder="labels.placeholder" class="segment-rule-content"/>
        <input v-if="operator === 'between'" type="text" v-model="content2" :placeholder="labels.placeholder" class="segment-rule-content2"/>
    </div>
</template>

<script>
import SegmentRuleBase from "./SegmentRuleBase.vue"
export default {
    extends: SegmentRuleBase,
    data() {
        return {
            operators: [
                {
                    label: this.labels.equal,
                    key: "equal"
                },
                {
                    label: this.labels.not_equal,
                    key: "not_equal"
                },
                {
                    label: this.labels.lesser,
                    key: "lesser"
                },
                {
                    label: this.labels.greater,
                    key: "greater"
                },
                {
                    label: this.labels.between,
                    key: "between"
                }
            ]
        }
    }
};
</script>