var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", {
        ref: "header",
        attrs: {
          count: _vm.selection.length,
          "show-product-type-filter": _vm.showProductTypeFilter,
          translations: _vm.translations,
          "product-type": _vm.productType,
        },
        on: {
          active: _vm.setActiveView,
          "update-product-type": _vm.updateProductType,
        },
      }),
      _vm._v(" "),
      _c("Selection", {
        attrs: {
          api: _vm.api,
          hidden: _vm.active !== 0,
          selection: _vm.selection,
          fields: _vm.fieldsConfigSelected,
          "search-placeholder": _vm.searchPlaceholder,
          "input-name-prefix": _vm.inputNamePrefix,
          "item-id-name": _vm.itemIdName,
          "item-id-value": _vm.itemIdValue,
          "sort-order-name": _vm.sortOrderName,
          "is-favorite-name": _vm.isFavoriteName,
          "is-favorite-value": _vm.isFavoriteValue,
          "empty-selection-text": _vm.emptySelectionText,
          translations: _vm.translations,
        },
        on: {
          "remove-item": _vm.removeItem,
          "go-to-product-catalog": function ($event) {
            return _vm.setActiveView(1)
          },
        },
      }),
      _vm._v(" "),
      _vm.active === 1
        ? _c("Items", {
            attrs: {
              api: _vm.api,
              selection: _vm.selection,
              query: _vm.queryItems,
              variables: _vm.variablesItems,
              fields: _vm.fieldsConfigItems,
              "search-placeholder": _vm.searchPlaceholder,
              "items-accessor": _vm.itemsAccessor,
              "total-count-accessor": _vm.totalCountAccessor,
              "selection-item-key": _vm.selectionItemKey,
              "product-type": _vm.productType,
              "show-product-type-filter": _vm.showProductTypeFilter,
              translations: _vm.translations,
              filter: _vm.filter,
              "product-catalog-active": _vm.active === 1,
            },
            on: { "add-item": _vm.addItem, "remove-item": _vm.removeItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }