import Vue from "vue";
import FilterSettings from "./views/FilterSettings.vue";

document.addEventListener("DOMContentLoaded", () => {
    if(document.querySelector('.filter-settings')) {

        new Vue({
            el: '.filter-settings',
            components: {
                FilterSettings
            }
        });
    }
});