var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "treeview" }, [
    _vm.debug
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("span", { staticClass: "d-block" }, [
            _vm._v("selected: " + _vm._s(_vm.selected)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "d-block" }, [
            _vm._v("inputValue: " + _vm._s(_vm.inputValue)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      attrs: { name: _vm.inputName, type: "hidden" },
      domProps: { value: _vm.inputValue },
    }),
    _vm._v(" "),
    _c(
      "ul",
      { class: _vm.containerClasses, attrs: { role: "group" } },
      _vm._l(_vm.data, function (child, index) {
        return _c("TreeviewNode", {
          key: child.id,
          attrs: {
            data: child,
            depth: 0,
            "disable-inheritance": _vm.disableInheritance,
            "item-disabled": _vm.itemDisabled,
            "item-key": _vm.itemKey,
            "item-text": _vm.itemText,
            "leaf-icon-close": _vm.leafIconClose,
            "leaf-icon-open": _vm.leafIconOpen,
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }