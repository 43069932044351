//Todo
import { Extension } from 'tiptap';
import {clearMarks} from "../utils/clearMarks";
//Todo: Collect nodes like in clear
export default class Copy extends Extension {
    get name() {
        return 'copy';
    }

    commands() {
        /*return () => (state, dispatch) => {
            const tr = clearMarks(state.tr.setSelection(state.selection), state.schema);
            if (dispatch && tr.docChanged) {
                dispatch(tr);
                return true;
            }
            return false;
        };*/
    }
}
