var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-tags-input", {
        attrs: {
          tags: _vm.tags,
          "autocomplete-items": _vm.filteredItems,
          placeholder: _vm.placeholder,
        },
        on: {
          "tags-changed": (newTags) => (_vm.tags = newTags),
          "before-adding-tag": _vm.addTag,
          "before-deleting-tag": _vm.deleteTag,
        },
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }