var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade-slide-up" } }, [
    (_setup.props.caption || _setup.props.title || _setup.props.subtitle) &&
    _setup.isVisible
      ? _c("div", { staticClass: "detail-box-blurred" }, [
          _setup.props.caption
            ? _c("div", { staticClass: "caption" }, [
                _vm._v(_vm._s(_setup.props.caption)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.props.title
            ? _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_setup.props.title)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.props.subtitle
            ? _c("div", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_setup.props.subtitle)),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }