var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.classes, attrs: { role: "treeitem" } }, [
    _c("div", { staticClass: "treeview-node__item", class: _vm.itemClasses }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          {
            staticClass: "custom-control custom-checkbox",
            class: { "is-intermediate": _vm.isIntermediate },
          },
          [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { id: `checkbox_${_vm.data.id}`, type: "checkbox" },
              domProps: {
                checked: _vm.isChecked,
                indeterminate: _vm.isIntermediate,
                value: _vm.data.id,
              },
              on: { change: _vm.handleItemToggle },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: `checkbox_${_vm.data.id}` },
              },
              [_vm._v(" ")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item-content",
          style: _vm.spanStyle,
          on: { click: _vm.handleItemClick },
        },
        [
          _c("div", { staticClass: "leaf-handler" }, [
            _vm.getLeafIcon ? _c("i", { class: _vm.getLeafIcon }) : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.getLabel))]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.hasChildren && _vm.isOpen
      ? _c(
          "ul",
          {
            ref: "group",
            staticClass: "tree-children",
            attrs: { role: "group" },
          },
          _vm._l(_vm.data.items, function (item) {
            return _c("TreeviewNode", {
              key: item.id,
              attrs: {
                data: item,
                depth: +(_vm.depth + 1),
                "disable-inheritance": _vm.disableInheritance,
                "item-disabled": _vm.itemDisabled,
                "item-key": _vm.itemKey,
                "item-text": _vm.itemText,
                "leaf-icon-close": _vm.leafIconClose,
                "leaf-icon-open": _vm.leafIconOpen,
              },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }