/**
 * Search for nodes of a type in the whole editor
 * Returns an array of the found nodes
 * @param state
 * @param type
 * @returns {{}|[]}
 */
export default function getNodes(state, type) {
    const to = state.doc.content.size;
    let nodes = [];

    state.doc.nodesBetween(0, to, node => {
        if (node.type.name === type) {
            nodes.push(node);
        }
    });

    if (nodes) {
        return nodes;
    }

    return []
}
