import { render, staticRenderFns } from "./ProductDetails.vue?vue&type=template&id=4b18f9ed&scoped=true"
import script from "./ProductDetails.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductDetails.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ProductDetails.vue?vue&type=style&index=0&id=4b18f9ed&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b18f9ed",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b18f9ed')) {
      api.createRecord('4b18f9ed', component.options)
    } else {
      api.reload('4b18f9ed', component.options)
    }
    module.hot.accept("./ProductDetails.vue?vue&type=template&id=4b18f9ed&scoped=true", function () {
      api.rerender('4b18f9ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-module-commerce2/src/backend/assets/components/ProductsMoodGrid/ProductDetails.vue"
export default component.exports