import $ from "jquery";

import '../../dist/skin-bootstrap/ui.fancytree.less';  // CSS or LESS

import {createTree} from 'jquery.fancytree';

import 'jquery.fancytree/dist/modules/jquery.fancytree.table';

// The init event from fancytree gets called before all columns are rendered,
// It gets called as soon as the visible columns are rendered, but to update intermediate state we require all columns.
// So we add our own init, which we call manually after creating the tree
$.ui.fancytree._FancytreeClass.prototype.afterInit = function () {
    console.log("afterInit");
    const self = this.$container[0];

    const columns = [];
    self.querySelectorAll(`thead tr th`).forEach(th => {
        const column = th.getAttribute('data-column');
        if (column) {
            columns.push(column);
        }
    });

    for (const column of columns) {
        const nodes = self.querySelectorAll(`[data-column="${column}"].col-select:not(.select-all)`);
        const nodesReversed = [];
        let i = nodes.length;
        while (i--)
            nodesReversed.push(nodes[i]);

        for (const parent of nodesReversed) {
            const parentLft = parseInt(parent.getAttribute('data-lft'), 10);
            const parentRgt = parseInt(parent.getAttribute('data-rgt'), 10);
            const parentLvl = parseInt(parent.getAttribute('data-lvl'), 10);

            let count = 0;
            let countChecked = 0;
            for (const box of nodes) {
                const boxLft = parseInt(box.getAttribute('data-lft'), 10);
                const boxRgt = parseInt(box.getAttribute('data-rgt'), 10);
                const boxLvl = parseInt(box.getAttribute('data-lvl'), 10);

                // only parents match this
                // only direct childs of current node match the conditions
                if (boxLft > parentLft && boxRgt < parentRgt && boxLvl === (parentLvl + 1)) {

                    let childCheckbox = box.querySelector('input[type="checkbox"]');
                    if (childCheckbox.checked) {
                        countChecked++
                    }
                    count++;
                }
            }

            const parentCheckbox = parent.querySelector('input[type="checkbox"]');
            if (countChecked > 0 && count !== countChecked) {
                parentCheckbox.indeterminate = true;
                parentCheckbox.checked = true;
                //parent.setAttribute('style','background:green;');
            }

        }
    }
};

window.addEventListener('DOMContentLoaded', (event) => {
    let tables = document.querySelectorAll('[data-treetable="true"][data-source]');

    for (let table of tables) {
        let source = table.getAttribute('data-source');
        let treeSelector = `#${table.id}[data-treetable="true"]`;

        if (source) {
            // Fade out the table to fade in later on init
            $(table).fadeOut(0);

            const tree = createTree(treeSelector, {

                source: JSON.parse(source),

                debugLevel: 0,
                minExpandLevel: 0,
                selectMode: 3,
                clickFolderMode: 2,
                disabled: false,
                icon: false,
                tabbable: false, // Whole tree behaves as one single control
                titlesTabbable: false,
                autoActivate: false,
                activeVisible: false,

                extensions: ['table'],
                checkbox: true,

                table: {
                    indentation: 20,      // indent 20px per node level
                    nodeColumnIdx: 0,     // render the node title into the 1st column
                    checkboxColumnIdx: 100, // not really working, set to 100 to insert checkboxes in all other tds
                },

                /**
                 * Renders the table columns, we add some listeners here to set the checked state from saved data
                 * @param event
                 * @param data
                 */
                renderColumns: function (event, data) {
                    const node = data.node;
                    const $tdList = $(node.tr).find(">td");

                    const $checkboxes = $tdList.find('input[type="checkbox"]');


                    /**
                     * Change name attribute in checkbox input to save as form relation
                     */
                    $checkboxes.each(function (key, checkbox) {
                        const property = checkbox.value;
                        const name = `${data.tree.$container[0].id}[${data.node.data.id}][${property}]`;
                        checkbox.setAttribute('name', name);
                        checkbox.setAttribute('id', name);
                        checkbox.nextElementSibling.setAttribute('for', name);
                        checkbox.value = 1;

                        const nodeData = data.node.data;
                        const relatedModel = nodeData[lcfirst(data.tree.$container[0].id) + 's'];

                        /**
                         * Set checkbox state if related model data exists
                         */
                        if (relatedModel && relatedModel[property]) {
                            checkbox.checked = relatedModel[property] === 1;
                        }

                        // Disable not permitted checkboxes
                        if (nodeData.permissions && ((false === nodeData.permissions[property]) ||
                            (nodeData.permissions[property] && !nodeData.permissions[property]))) {

                            checkbox.disabled = 'disabled';
                        } else {
                            // Add listener to cells to check containing checkboxes hierarchically.
                            checkbox.parentNode.parentNode.addEventListener('click', function () {
                                checkbox.checked = !checkbox.checked;
                                if (false === checkbox.checked) {
                                    checkbox.indeterminate = false;
                                }
                                /**
                                 *  Set checked state of the child
                                 */
                                const column = this.getAttribute('data-column');
                                const lft = parseInt(this.getAttribute('data-lft'), 10);
                                const rgt = parseInt(this.getAttribute('data-rgt'), 10);

                                const otherBoxes = data.tree.$container[0].querySelectorAll(`[data-column="${column}"].col-select:not(.select-all)`);


                                let parents = [];

                                // Toggle state of childs, this only goes through childs
                                for (const box of otherBoxes) {
                                    const boxLft = parseInt(box.getAttribute('data-lft'), 10);
                                    const boxRgt = parseInt(box.getAttribute('data-rgt'), 10);


                                    if (boxLft > lft && boxRgt < rgt) {
                                        // only childs of current object match this, toggle there checkboxes
                                        const childCheckbox = box.querySelector('input[type="checkbox"]');
                                        childCheckbox.checked = !!checkbox.checked;
                                    } else if (boxLft < lft && boxRgt > rgt) {
                                        //get all parents of current node
                                        parents.push(box);
                                    }
                                }

                                // reversing parents, so we can start from bottom
                                // Means we only have to evaluate direct childs, if we start from top, we have to evaluate a nested tree multiple times, for each nesting again.
                                // This way we can check first the deepest nested, and set there state, then go to its parent and check its direct childs.
                                parents = parents.reverse();
                                console.log(parents);
                                for (const parent of parents) {
                                    const parentLft = parseInt(parent.getAttribute('data-lft'), 10);
                                    const parentRgt = parseInt(parent.getAttribute('data-rgt'), 10);
                                    const parentLvl = parseInt(parent.getAttribute('data-lvl'), 10);

                                    let count = 0;
                                    let countChecked = 0;
                                    for (const box of otherBoxes) {
                                        const boxLft = parseInt(box.getAttribute('data-lft'), 10);
                                        const boxRgt = parseInt(box.getAttribute('data-rgt'), 10);
                                        const boxLvl = parseInt(box.getAttribute('data-lvl'), 10);

                                        // only parents match this
                                        // only direct childs of current node match the conditions
                                        if (boxLft > parentLft && boxRgt < parentRgt && boxLvl === (parentLvl + 1)) {

                                            let childCheckbox = box.querySelector('input[type="checkbox"]');
                                            if (childCheckbox.checked && !childCheckbox.indeterminate) {
                                                countChecked++
                                            }
                                            count++;
                                        }
                                    }

                                    const parentCheckbox = parent.querySelector('input[type="checkbox"]');
                                    if (countChecked > 0 && count !== countChecked) {
                                        parentCheckbox.indeterminate = true;
                                        parentCheckbox.checked = true;
                                        //parent.setAttribute('style','background:green;');
                                    } else if (countChecked === 0) {
                                        parentCheckbox.indeterminate = false;
                                        //parent.setAttribute('style','background:red;');
                                    } else if (count === countChecked) {
                                        parentCheckbox.indeterminate = false;
                                        //parent.setAttribute('style','background:yellow;');
                                    }

                                }

                            });
                        }

                        /**
                         * Add nested set info to checkboxes
                         */
                        checkbox.parentNode.parentNode.setAttribute('data-lft', data.node.data.lft);
                        checkbox.parentNode.parentNode.setAttribute('data-rgt', data.node.data.rgt);
                        checkbox.parentNode.parentNode.setAttribute('data-lvl', data.node.data.lvl);
                    });
                },

                init: function () {
                    // Fade in the table
                    $(this).fadeIn(300);
                },

                /**
                 * After process, hide table, set all checked states and de-expand the list
                 * @param event
                 * @param data
                 */
                postProcess: function (event, data) {
                    /**
                     * Select all columns from tfoot select all checkboxes
                     * @type {NodeListOf<HTMLElementTagNameMap[string]> | NodeListOf<Element> | NodeListOf<SVGElementTagNameMap[string]>}
                     */
                    let selectAllBoxes = this.querySelectorAll('.select-all');
                    let that = this;

                    for (let box of selectAllBoxes) {
                        box.addEventListener('click', function () {

                            let selectAllBox = this.querySelector('input[type="checkbox"]');
                            let checkboxes = that.querySelectorAll(`[data-column="${selectAllBox.value}"].col-select:not(.select-all) > div > input[type="checkbox"]`);

                            selectAllBox.checked = !selectAllBox.checked;
                            checkboxes.forEach(cb => cb.checked = selectAllBox.checked)
                        });
                    }

                },

            });
            // Force Render tree
            tree.render(true, true);

            tree.afterInit();
        }
    }

    /**
     * Make a string's first character lowercase
     * @param s
     * @returns {string|*}
     */
    function lcfirst(s) {
        if (/^[A-Z]{2}/.test(s)) {
            return s;
        }
        return s.substr(0, 1).toLowerCase() + s.substring(1);
    }
});