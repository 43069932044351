<template>
    <div class="sidebar-form-settings">
        <div class="mx-3">
            <div class="form-group label-inside">
                <input
                    id="formName"
                    :value="name"
                    :placeholder="$translations['label.formname']"
                    class="form-control"
                    type="text"
                    @input="updateFormName">
                <label for="formName">
                    {{ $translations['label.formname'] }}
                </label>
            </div>
        </div>

        <div class="mx-3">
            <div class="form-group label-inside">
                <input
                    id="formIdent"
                    :value="ident"
                    :placeholder="$translations['label.formident']"
                    class="form-control"
                    type="text"
                    @input="updateFormIdent">
                <label for="formIdent">
                    {{ $translations['label.formident'] }}
                </label>
            </div>
        </div>

        <div class="mx-3">
            <a class="btn btn-block btn-secondary" :href="emailUrl">{{ $translations['button.edit-template'] }}</a>
            <a
                class="btn btn-block btn-secondary"
                href="javascript:void(0)"
                @click="generateTemplate">{{ $translations['button.generate-template'] }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidebarFormSettings',
    props: {
        formId: {
            type: String
        },
        csrfToken: {
            type: String
        },
        emailUrl: {
            type: String
        },
        name: {
            type: String
        },
        ident: {
            type: String
        }
    },
    methods: {
        generateTemplate() {
            if (window.confirm("Dadurch wird die aktuelle E-Mail Vorlage überschrieben. Fortfahren?")) {
                fetch(`generateemailtemplate?id=${this.formId}`, {
                    method: 'get',
                    headers: {
                        'X-CSRF-Token': this.csrfToken
                    }
                })
                .then(res => res.text())
                .then(body => {
                    // todo besseres & schöneres Feedback?
                    if(body === "") {
                        alert("Vorlage erstellt");
                    }
                    else {
                        alert(body);
                    }

                });
            }
        },
        updateFormName(event) {
            this.$emit('update-formname', event.target.value);
        },
        updateFormIdent(event) {
            this.$emit('update-formident', event.target.value);
        }
    }
};
</script>
