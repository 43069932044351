import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class AlignJustify extends Mark {

    get name() {
        return 'justify'
    }

    get schema() {
        return {
            parseDOM: [

                {
                    style: 'text-align',
                    getAttrs: value => value === 'justify',
                },
            ],
            toDOM: () => ['p', { 'style': "text-align:justify;" }, 0],
        }
    }

    commands({ type }) {
        return () => toggleMark(type)
    }

}
