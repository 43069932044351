import Vue from 'vue';
import TipTap from '../../tiptap/tiptap.vue';

(function () {

   'use strict';

   /**
    * Init TipTap on drag in page
    */
   window.addEventListener("Pageeditor:insert", (function(tevent) {
         initTipTap();
      }
   ));

   /**
    * Init TipTap on dom content loaded
    */
   window.addEventListener('DOMContentLoaded', (function(event) {
         initTipTap();
      }
   ));

   /**
    * Init all TipTap in document
    * //Todo: init just new editors
    */
   function initTipTap() {
      let textareas = document.querySelectorAll('textarea[class*="tiptap"]');

      for (let textarea of textareas) {

         new Vue({
             name: "TipTapRoot",
            el: textarea,
            render: createElement => {
               const context = {
                  props: {
                     textareaId: textarea.id,
                     textareaName: textarea.name,
                     modelName: textarea.dataset.modelname,
                     modelId: textarea.dataset.modelid,
                     modelClass: textarea.dataset.modelclass,
                     attributeName: textarea.dataset.attributename,
                     enableIframe: textarea.dataset.extensionsiframe,
                     enableMediamanager: textarea.dataset.extensionsmediamanager,
                     tools: eval(textarea.dataset.tools),
                     hideFromPageEditor: false,
                     editable: textarea.dataset.editable || true,
                  },
               };
               return createElement(TipTap, context);
            }
         });
      }
   }
})();