import { render, staticRenderFns } from "./editor.template?vue&type=template&id=82a18204&external"
import script from "./tiptap.vue?vue&type=script&lang=js"
export * from "./tiptap.vue?vue&type=script&lang=js"
import style0 from "./tiptap.vue?vue&type=style&index=0&id=82a18204&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('82a18204')) {
      api.createRecord('82a18204', component.options)
    } else {
      api.reload('82a18204', component.options)
    }
    module.hot.accept("./editor.template?vue&type=template&id=82a18204&external", function () {
      api.rerender('82a18204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-widget-tiptap/src/tiptap/tiptap.vue"
export default component.exports