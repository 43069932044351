import variables from './_variables.scss';
import material from './colors/material.scss';
import dribble from './colors/dribble.scss';

let colors = [];
for(let i in variables){
   if(variables[i] && !colors.includes(variables[i]) && isColor(variables[i])){
      colors.push(variables[i]);
   }
}

let dribColors = [];
let dribbleColors = dribble.colors.split(' ');
for(let i in dribbleColors){
   if(dribbleColors[i] && !dribColors.includes(dribbleColors[i]) && isColor(dribbleColors[i])){
      dribColors.push(dribbleColors[i]);
   }
}

let matColors = [];
let materialColors = material.colors.split(' ');
for(let i in materialColors){
   if(materialColors[i] && !matColors.includes(materialColors[i]) && isColor(materialColors[i])){
      matColors.push(materialColors[i]);
   }
}

export const FORMAT_MARK_NAMES = [
   "bold" /* bold */,
   "italic" /* italic */,
   "underline" /* underline */,
   "strike" /* strike */,
   "link" /* link */,
   "text_color" /* textColor */,
   "text_highlight" /* textHighlight */,
   "center" /* textHighlight */,
   "left" /* left */,
   "right" /* right */,
   "justify" /* justify */,
   "fontsize" /* fontsize */
];
export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
export const HEADINGS = [1,2,3];

export  const COLORS = colors;
export const MATERIAL = matColors;
export const DRIBBLE = dribColors;

export const BGCOLORS = [
   variables.primary,
   variables.success,
   variables.warning,
   variables.danger,
   variables.inputColor
];
export const VARIABLES = variables;

// https://stackoverflow.com/a/2819658/9015191
function isColor(str) {
   return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(str) || /^rgb\s*(\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*)$/i.test(str);
}