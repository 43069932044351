var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs-container" }, [
    _c(
      "ul",
      { staticClass: "nav nav-tabs" },
      _vm._l(_vm.tabList, function (tab, index) {
        return _c(
          "li",
          _vm._b(
            {
              key: index,
              staticClass: "nav-item",
              staticStyle: { cursor: "pointer" },
            },
            "li",
            tab.dataAttrs,
            false
          ),
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.isActive(index), disabled: tab.disabled },
                attrs: { "data-toggle": "tab" },
                on: {
                  click: function ($event) {
                    return _vm.select(index)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(tab.title) + "\n            "
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }