var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "toggle-check w-100 my-3" }, [
    _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
    _c("input", {
      staticClass: "mandatory toggle-check-input",
      attrs: { type: "checkbox" },
      domProps: { checked: _vm.value },
      on: {
        change: function ($event) {
          return _vm.update($event.target.checked)
        },
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "toggle-check-text" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }