var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _c("div", { staticClass: "h5 text-muted mb-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$translations["element.button"]) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-content",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.content"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-content" } },
          [
            _c("form-input", {
              attrs: {
                id: "buttontext",
                label: _vm.$translations["label.buttontext"],
              },
              model: {
                value: _vm.item.data.buttontext,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "buttontext", $$v)
                },
                expression: "item.data.buttontext",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-dependencies",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.dependencies"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("LogicSection", {
          attrs: { item: _vm.item, "logic-options": _vm.logicOptions },
          on: {
            "add-rule": _vm.addRule,
            "remove-rule": _vm.removeRule,
            "enable-logic": _vm.enableLogic,
          },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-advanced",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.advanced"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-advanced" } },
          [
            _c("form-input", {
              attrs: {
                id: "htmloptions",
                label: _vm.$translations["label.css-class"],
              },
              model: {
                value: _vm.item.data.htmloptions,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmloptions", $$v)
                },
                expression: "item.data.htmloptions",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [_c("FooterActions", { on: { save: _vm.save } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }