<template>
    <div class="form-group label-inside">
        <textarea
            :id="id"
            :value="value"
            type="text"
            class="form-control"
            :placeholder="label"
            @input="update($event.target.value)" />
        <label :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'FormTextarea',
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        value: {
            type: String
        }
    },
    methods: {
        update(value) {
            this.$emit('input', value);
        }
    }
};
</script>