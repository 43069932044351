var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-form-settings" }, [
    _c("div", { staticClass: "mx-3" }, [
      _c("div", { staticClass: "form-group label-inside" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: {
            id: "formName",
            placeholder: _vm.$translations["label.formname"],
            type: "text",
          },
          domProps: { value: _vm.name },
          on: { input: _vm.updateFormName },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "formName" } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$translations["label.formname"]) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mx-3" }, [
      _c("div", { staticClass: "form-group label-inside" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: {
            id: "formIdent",
            placeholder: _vm.$translations["label.formident"],
            type: "text",
          },
          domProps: { value: _vm.ident },
          on: { input: _vm.updateFormIdent },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "formIdent" } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$translations["label.formident"]) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mx-3" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-block btn-secondary",
          attrs: { href: _vm.emailUrl },
        },
        [_vm._v(_vm._s(_vm.$translations["button.edit-template"]))]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-block btn-secondary",
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.generateTemplate },
        },
        [_vm._v(_vm._s(_vm.$translations["button.generate-template"]))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }