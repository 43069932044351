<template>
    <ScreensContainer :light-is-on="lightIsOn" :bg-glow-color="bgGlowColor">
        <template v-slot:left_screen>
            <ImageViewer :src="getAssetPathOfTargetAttr(getLeftScreenImageAttribute, content)" :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
            <DetailBoxBlurred v-if="props.activeTab === 'Products'" caption="Topic" :title="content.title" :subtitle="''"/>
        </template>

        <template v-slot:middle_screen>
            <div class="topic-middle-screen-wrapper">
                <TopicsDetail v-if="props.activeTab === 'Detail'" :content="content"/>
                <ProductsMoodGrid v-if="props.activeTab === 'Products'" :content="props.content"/>
                <VideoPlayer v-if="props.activeTab === 'Video'" :content="props.content" :loop="true" :muted="muteState" :fill-screen="true"/>
            </div>
        </template>

        <template v-slot:right_screen>
            <ImageViewer :src="getAssetPathOfTargetAttr(getRightScreenImageAttribute, content)" :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
        </template>
    </ScreensContainer>
</template>

<script setup>
import {defineProps, computed} from 'vue'
import TopicsDetail from "../../components/TopicsDetail.vue";
import {checkIfTargetAttrIsAvailable, getAssetPathOfTargetAttr} from '../../js/helpers'
import ImageViewer from "../../components/ImageViewer.vue";
import VideoPlayer from "../../components/VideoPlayer.vue";
import ScreensContainer from "../../components/ScreensContainer.vue";
import ProductsMoodGrid from "../../components/ProductsMoodGrid/ProductsMoodGrid.vue";
import DetailBoxBlurred from "../../components/DetailBoxBlurred.vue";
import {defaultProps} from "../../js/mixins"

const props = defineProps(defaultProps)

const muteState = computed(() => {
    return props.muted
})

const getLeftScreenImageAttribute = computed(() => {
     if (props.activeTab === 'Products' && checkIfTargetAttrIsAvailable('left_mood', props.content)) {
         return 'left_mood'
     } else {
         return 'left_screen'
     }
})

const getRightScreenImageAttribute = computed(() => {
    if(props.activeTab === 'Products' && checkIfTargetAttrIsAvailable('right_mood', props.content)) {
        return 'right_mood'
    } else {
        return 'right_screen'
    }
})

</script>

<style scoped lang="scss">
.topic-middle-screen-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
</style>