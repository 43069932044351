<template>
    <div>
        <button
            type="button"
            class="btn btn-block btn-primary"
            @click="save">
            {{ $translations['button.save'] }}
        </button>
        <button
            type="button"
            class="btn btn-block btn-secondary"
            @click="cancel">
            {{ $translations['button.cancel'] }}
        </button>
    </div>
</template>

<script>
import eventBus from "../../../util/EventBus";

export default {
    name: 'FooterActions',
    props: {
        element: {
            type: Object
        }
    },
    methods: {
        save() {
            this.$emit("save");
        },
        cancel() {
            eventBus.$emit('cancelElement');
        }
    }
};
</script>