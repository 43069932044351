<template>
  <div>
    <div class="search-form">
      <div class="search-form-header">
                <span class="search-string-wrapper">
                    <input
                        :placeholder="searchPlaceholder"
                        class="search-string"
                        type="text"
                        @input="inputSearchQuery($event)">
                </span>

        <div v-for="(key, index) in filterKeys" :class="{'mr-2': index < filterKeys.length - 1}">
          <template v-if="filter[key].list === null || (filter[key].list && filter[key].list.length === 0)">
            <!-- add filter text input -->
            <input :placeholder="filter[key].label" class="form-control" type="text"
                   @input="e => changeFilterText(e, key)"/>
          </template>

          <template v-else>
            <div>
              <v-select
                  v-if="filter[key].list && filter[key].list.length > 0"
                  :get-option-label="getOptionLabel"
                  :options="filter[key].list"
                  :placeholder="filter[key].label"
                  class="filter-select"
                  @input="inputFilterSelect($event, key)"/>
            </div>
          </template>
        </div>

      </div>
    </div>

    <loading-element
        v-if="loading"
        :absolute="false"
        :center="true"
        :z-index="100"/>

    <template v-if="json && !loading">
      <div class="table-responsive">
        <Table
            :fields="fields"
            :items="itemsItems"
            :product-catalog-active="productCatalogActive"
            :sort-by="sortBy"
            :translations="translations"
            @sort="sort">
          <template v-slot:default="slotProps">
            <a v-if="!isSelected(slotProps.item)" class="btn btn-outline-secondary" href="javascript:void(0)"
               @click="addItem(slotProps.item)">{{ translations.select }}</a>
            <a v-else class="btn btn-outline-secondary" href="javascript:void(0)"
               @click="removeItem(slotProps.item)">{{ translations.remove }}</a>
          </template>
        </Table>
      </div>

      <div class="d-flex justify-content-between align-items-center pagination-container">
        <div class="show-indicator">
          {{ translations.show }} {{ offset + 1 }}-{{ offset + pagination.pageSize }} {{ translations.from }}
          {{ pagination.totalCount }} {{ translations.items }}
        </div>
        <paginate
            v-if="pagination.totalPages > 1"
            v-model="pagination.currentPage"
            :click-handler="changePage"
            :page-count="pagination.totalPages"
            container-class="pagination"
            next-class="page-item"
            next-link-class="page-link"
            next-text="<i class='fas fa-angle-right'></i>"
            page-class="page-item"
            page-link-class="page-link"
            prev-class="page-item"
            prev-link-class="page-link"
            prev-text="<i class='fas fa-angle-left'></i>"
        />
        <div class="page-size">
          <select class="form-control" @input="selectPageSize($event)">
            <option
                v-for="n in 10"
                :key="n"
                :selected="10 * n === pagination.pageSize"
                :value="10 * n">
              {{ 10 * n }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EventBus from "./util/EventBus";
import Paginate from 'vuejs-paginate';
import LoadingElement from "./LoadingElement.vue";
import Table from "./Table.vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: "Items",
  components: {
    LoadingElement,
    Paginate,
    Table,
    vSelect
  },
  props: {
    api: {
      type: String
    },
    query: {
      type: String
    },
    variables: {
      type: Object
    },
    selection: {
      type: Array
    },
    fields: {
      type: Object
    },
    itemsAccessor: {
      type: Function
    },
    totalCountAccessor: {
      type: Function
    },
    searchPlaceholder: {
      type: String
    },
    selectionItemKey: {
      type: String
    },
    productType: {
      type: [Number, String]
    },
    showProductTypeFilter: {
      type: Boolean,
      default: false
    },
    translations: {
      type: Object
    },
    filter: {
      type: Object
    },
    productCatalogActive: {
      type: Boolean
    }
  },
  data: function () {
    return {
      json: null,
      loading: true,
      offset: 0,
      searchQuery: "",
      items: [],
      pagination: {
        totalCount: undefined,
        currentPage: 1,
        totalPages: undefined,
        pageSize: 10
      },
      sortBy: undefined,
      timer: null,
      currentPage: 1,
      filterSettings: {}
    };
  },
  computed: {
    itemsItems() {
      return this.itemsAccessor(this.json);
    },
    filterKeys() {
      return typeof this.filter === 'object' ? Object.keys(this.filter) : [];
    }
  },
  mounted() {
    for (const [k, v] of Object.entries(this.filter)) {
      if (v.list) {
        let result = [];

        for (const [key, value] of Object.entries(v.list)) {
          result.push({value: key, label: typeof value === 'string' ? value : value.label});
        }

        v.list = result;
      }
    }

    this.loadData();

    EventBus.$on('selectAll', () => {
      for (const [key, value] of Object.entries(this.itemsItems)) {
        if (!this.isSelected(value)) {
          this.addItem(value)
        }
      }
    });
  },
  methods: {
    loadData(resetPaging = false) {
      this.loading = true;

      const body = {
        query: this.query,
        variables: {
          limit: this.pagination.pageSize,
          offset: this.offset,
          searchQuery: this.searchQuery,
          ...this.variables
        }
      };

      if (this.sortBy !== undefined) {
        body.variables.sortBy = this.sortBy;
      }

      if (this.showProductTypeFilter && this.productType !== 3) {
        body.variables.productType = this.productType;
      }

      if (Object.keys(this.filterSettings).length > 0) {
        let searchQuery = {
          search: this.searchQuery
        }

        Object.keys(this.filterSettings).forEach((key) => {
          searchQuery[key] = this.filterSettings[key].value;
        })

        body.variables.searchQuery = JSON.stringify(searchQuery);
      }


      // for ( let selectedFilterKey of selectedFilterKeys) {
      //     //debugger
      //     body.variables.selectedFilters.push(this.filterSettings.get(selectedFilterKey));
      // }

      const headers = {
        'Content-Type': 'application/json'
      };
      headers['X-CSRF-Token'] = yii.getCsrfToken();

      fetch(this.api, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
          .then(res => res.json())
          .then(res => {
            this.json = res;

            if (resetPaging) {
              this.changePage(1);
            }

            this.totalPages();
            this.totalCount();
            this.loading = false;
          });
    },
    totalPages() {
      this.pagination.totalPages = Math.ceil(this.totalCountAccessor(this.json) / this.pagination.pageSize);
    },
    totalCount() {
      this.pagination.totalCount = this.totalCountAccessor(this.json);
    },
    getOffset() {
      this.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    },
    changePage(page) {
      this.pagination.currentPage = page;
      this.getOffset();
      this.loadData();
    },
    selectPageSize(event) {
      this.pagination.pageSize = parseInt(event.target.value);
      this.loadData();
    },
    inputSearchQuery(event) {
      if (event.target.value.length > 2 || event.target.value.length === 0) {
        this.searchQuery = event.target.value;

        this.debounce(this.loadData, 200)(true);
      }
    },
    sort(sortBy) {
      this.sortBy = sortBy;
      this.loadData();
    },
    addItem(item) {
      this.$emit('add-item', item);
    },
    removeItem(item) {
      this.$emit('remove-item', item);
    },
    isSelected(item) {
      if (this.selectionItemKey !== undefined) {
        return this.selection.some(selectedItem => selectedItem[this.selectionItemKey].id === item.id);
      } else {
        return this.selection.some(selectedItem => selectedItem.id === item.id);
      }
    },
    debounce(func, timeout = 1000) {
      //let timer;
      return (...args) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      }
    },
    selectFilterSelect(option, key) {
      // if (e.target.value === '') {
      //     if (this.filterSettings.hasOwnProperty(option.key)) {
      //         delete this.filterSettings[option.key];
      //     }
      // } else {
      //     this.filterSettings[option.key] = option.key;
      // }

      if (option === null) delete this.filterSettings[key];

      this.filterSettings[option.key] = option.key;

      this.loadData();
    },
    inputFilterSelect(e, key) {
      if (e === null) delete this.filterSettings[key];
      else this.filterSettings[key] = e;
      this.loadData();
    },
    changeFilterText(e, key) {

      if (e.currentTarget.value === '') {
        if (this.filterSettings.hasOwnProperty(key)) {
          delete this.filterSettings[key];
        }
      } else {
        this.filterSettings[key] = e.currentTarget.value;
      }

      if (e.currentTarget.value.length > 2) {
        this.loadData();
      }
    },
    getOptionLabel(option) {
      return option.value === 'firecms_unset_value' ? 'firecms_unset_value' : option.label;
    },
    getOptionValue(option) {
      return option.value;
    }
  },
  watch: {
    productType() {
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "frontend/styles/framework";
@import "frontend/styles/global/loader";

.show-indicator {
  font-size: 0.563em;
  color: #4C5E64
}

.filter-bar {
  margin-top: 20px;

  .query {
    max-width: 320px;
  }
}

.filter-select {
  width: 200px;
  font-size: .8rem;

  ::v-deep .vs__clear {
    display: flex;
    align-items: center;
  }
}

</style>

<style lang="scss" scoped>
@import "backend/styles/global/pagination";

.search-form-header {
  display: flex;
  flex-wrap: wrap;
  overflow: visible !important;
}

.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.fade-transition-enter-active, .fade-transition-leave-active {
  transition: opacity .5s
}

.fade-transition-enter, .fade-transition-leave-to {
  opacity: 0;
}

::v-deep .vs__dropdown-toggle {
  min-height: 45px;
  box-shadow: 0px 2px 6px 0px rgba(34, 44, 44, 0.05);
  border: none;
}

::v-deep .vs__dropdown-menu {
  border-radius: 10px !important;
  border: none;
  margin-top: 1rem;
  top: calc(100% + 5px);
}

::v-deep .vs__dropdown-option--highlight {
  background: transparent;
  color: #22a4ae;
}

.pagination-container {
  margin-top: 1.5rem;

  .pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      box-shadow: 0px 2px 6px 0px rgba(34, 44, 44, 0.05);

      .page-link {
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem 0.9rem;
      }
    }
  }
}

</style>