var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: _setup.transitionName } }, [
    _c("div", {
      staticClass: "bg--image",
      class: [_vm.imageClass, _setup.imageState],
      style: _setup.computedStyle,
      attrs: {
        "data-width": _setup.imageWidth,
        "data-height": _setup.imageHeight,
        "data-state": _setup.imageState,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }