<template>
    <div class="text-segment-rule">
        <v-select v-model="operator" :options="operators" :reduce="label => label.key" class="segment-rule-operator" :clearable="false"></v-select>
        <input v-if="showContent" type="text" v-model="content" :placeholder="labels.placeholder" class="segment-rule-content segment-rule-content-large"/>
    </div>
</template>

<script>
import SegmentRuleBase from "./SegmentRuleBase.vue"
export default {
    extends: SegmentRuleBase,
    data() {
        return {
            operators: [
                {
                    label: this.labels.equal,
                    key: "equal"
                },
                {
                    label: this.labels.not_equal,
                    key: "not_equal"
                },
                {
                    label: this.labels.contains,
                    key: "contains"
                },
                {
                    label: this.labels.not_contains,
                    key: "not_contains"
                },
                {
                    label: this.labels.begins_with,
                    key: "begins_with"
                },
                {
                    label: this.labels.not_begins_with,
                    key: "not_begins_with"
                },
                {
                    label: this.labels.ends_with,
                    key: "ends_with"
                },
                {
                    label: this.labels.not_ends_with,
                    key: "not_ends_with"
                }
            ]
        }
    }
};
</script>