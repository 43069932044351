import Vue from 'vue';
import Treeview from "../../components/Treeview.vue";

document.addEventListener("DOMContentLoaded", () => {
    initNestedCheckbox();
});

window.addEventListener("Pageeditor:insert", function () {
    initNestedCheckbox();
});

window.addEventListener("Pageeditor:change", function () {
    initNestedCheckbox();
});

const initialized = [];

function initNestedCheckbox() {
    document.querySelectorAll('.fire__nested-checkbox').forEach(element => {
        const id = element.id;

        if(!initialized.includes(id)) {
            new Vue({
                name: 'NestedCheckbox',
                el: element,
                components: {
                    Treeview
                }
            });
        }

        initialized.push(id);
    });
}