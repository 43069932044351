<template src="./editor.template"></template>

<script>
   import bootbox from "bootbox";
   import prettier from "prettier/standalone.js";
   import parserHtml from "prettier/parser-html"

   import {Iframe, Anchor} from './nodes'

   import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
   import {
      Bold,
      BulletList,
      HardBreak,
      Heading,
      History,
      HorizontalRule,
      Italic,
      ListItem,
      OrderedList,
      Strike,
      Table,
      TableCell,
      TableHeader,
      TableRow,
      Underline
   } from 'tiptap-extensions'
   import {MediamanagerNode} from "../extensions/mediamanager/MediamanagerNode.js";
   import MediamanagerModal from "../extensions/mediamanager/MediamanagerModal.vue";
   import {ImageObject} from "../extensions/mediamanager/ImageObject.js";
   import {Clear, Fullscreen} from "./extensions";

   import {Icon, LinkModal} from "./components";

   // Marks
   import {
      AlignCenter,
      AlignLeft,
      AlignRight,
      AlignJustify,
      Superscript,
      Subscript,
      FontSize,
      Color,
      BgColor,
      Link
   } from "./marks";

   import {FONT_SIZES, HEADINGS, COLORS, BGCOLORS, VARIABLES} from "./constants.js";

   import getNodeAttrs from "./utils/getNodeAttrs.js";

   import {FiregroupColorpicker} from "../../../fire-widget-colorpicker/src/js/components/index.js";
   import {DRIBBLE} from '../../../../../backend/styles/constants.js';

   let content = ''; // Save content global before Vue is created
   let parentInheritanceEnabled = false;
   export default {
      components: {
         'EditorContent': EditorContent,
         'EditorMenuBar': EditorMenuBar,
         'Icon': Icon,
         'MediamanagerModal': MediamanagerModal,
         'LinkModal': LinkModal,
         'FiregroupColorpicker': FiregroupColorpicker
      },
      props: [
         'textareaId',
         'textareaName',
         'modelName',
         'modelId',
         'modelClass',
         'attributeName',
         'enableIframe',
         'enableMediamanager',
         'tools',
         'initContent', // if set, initial content is content of this variable, otherwise content from textarea is used
         'hideFromPageEditor',
         'editable'
      ],

      data() {
         return {
            lastPos: 0, // Last position in editor
            linkUrl: null,
            dialog: null, // Active bootbox dialog
            anchorName: null,
            linkMenuIsActive: false,
            anchorMenuIsActive: false,
            textColorIsActive: false,
            backgroundColorIsActive: false,
            json: 'Update content to see changes', // Serialized editor content
            html: 'Update content to see changes', // Html editor content
            isSourceActive: false,
            clipboard: '',
            selectedText: '',
            selectedHtml: '',
            mediamanagerExtensionModals: [],
            linkModals: [],
            isButton: false,
            editor: new Editor({
               editable: true,
               onUpdate: ({getJSON, getHTML}) => { // Update content on every change
                  this.json = getJSON();
                  this.html = getHTML();

                  this.setTextareaContent(this.html);
               },
               extensions: [
                  new MediamanagerNode(),
                  new BulletList(),
                  new HardBreak(),
                  new Heading({levels: HEADINGS}),
                  new HorizontalRule(),
                  new ListItem(),
                  new OrderedList(),
                  new AlignCenter(),
                  new AlignLeft(),
                  new AlignRight(),
                  new AlignJustify(),
                  new Link(),
                  new Bold(),
                  new Italic(),
                  new Strike(),
                  new Underline(),
                  new History(),
                  new Clear(),
                  new FontSize(),
                  new Color(),
                  new BgColor(),
                  new Anchor(),
                  //new Fullscreen(),
                  new Table({
                     resizable: true,
                  }),
                  new TableHeader(),
                  new TableCell(),
                  new TableRow(),
                  new Iframe(),
                  new Subscript(),
                  new Superscript(),
               ],
            }),
         }
      },

      methods: {

        /**
         * Checks if the Iframe (Youtube) button shall be shown, used in template to hide buttons
         */
        extensionIframeEnabled() {
          return this.enableIframe === '1';
        },

        /**
         * Checks if the Media Manager button shall be shown, used in template to hide buttons
         */
        extensionMediamanagerEnabled() {
          return this.enableMediamanager === '1';
        },

         /**
          * Insert youtube link as iframe
          **/
         insertYoutube() {
            let that = this;
            this.openPrompt('Youtube embed Link', '', 'text', this.transformDialog, function(url) {
               if (url) {
                  if (url.indexOf('/embed/') === -1) {
                     url = that.transformToYoutubeEmbedLink(url);
                  }
                  that.editor.commands.iframe({src: url});
               }
            });
         },

         transformToYoutubeEmbedLink(url) {
            let params = (new URL(url)).searchParams;
            let v = params.get('v');

            return v ? `https://www.youtube.com/embed/${v}` : url;
         },

         insertIframe() {
            let that = this;
            this.openPrompt('Link', '', 'text', this.transformDialog, function(url) {
               if (url) {
                  that.editor.commands.iframe({src: url});
               }
            });
         },


         /**
          * Open simple bootbox prompt and return value to callback
          **/
         openPrompt(title, value, inputType, transformDialog, callback) {
            this.dialog = bootbox.prompt({
               backdrop: true,
               onEscape: true,
               title: title,
               centerVertical: true,
               centerHorizontal: true,
               inputType: inputType,
               value: value,
               size: 'normal',
               callback: callback,
               buttons: {
                  confirm: {
                     label: 'Speichern',
                     className: 'btn-success'
                  },
                  cancel: {
                     label: 'Abbrechen',
                     className: 'btn-secondary'
                  }
               },
            });

            this.dialog.init(transformDialog);
         },

         transformDialog() {
            // Todo: locale
            //this.dialog.find('.btn-primary').removeClass('btn-primary').addClass('btn-success');
            //this.dialog.find('.btn-secondary').text('Abbrechen');
         },

         /**
          * Insert youtube link as iframe
          **/
         updateAnchor(attrs) {
            let that = this;
            let value = attrs.id ? attrs.id : '';

            this.openPrompt('Anker name', value, 'text', this.transformDialog, function(name, test) {

               if (name) {
                  that.editor.commands.anchor({id: name});
               }
            });
         },

         /**
          * Show link menu bubble
          **/
         showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
               this.$refs.linkInput.focus()
            })
         },

         createMediamanagerModal() {
            this.mediamanagerExtensionModals.push({
               attributeName: null,
               modelmedias: [],
               imageOptions: {},
            });
         },

         createLinkModal(attrs, type) {
            this.linkModals.push(
               {id: this.createUID('link'), attrs: attrs}
            );

            if (type == 'button') {
               this.isButton = true;
            }
            else {
               this.isButton = false;
            }
         },

         destroyLinkModal(modalId) {
            this.linkModals = this.linkModals.filter(function(value, index, arr) {
               return value !== modalId;
            });
         },

         createUID(name) {
            function s4() {
               return Math.floor((1 + Math.random()) * 0x10000)
                  .toString(16)
                  .substring(1);
            }

            return `tiptap_${name}-${s4() + s4() + s4()}`;
         },

         /**
          * Wrap marked content with tag
          **/
         insertTag(event) {
            if (this.isHeaderTag(event.target.value)) {
               this.editor.commands.heading({level: parseInt(event.target.value.substring(1, 2))});
            } else if (event.target.value === 'p') {
               this.editor.commands.paragraph();
            }
         },

         fontSize(event) {
            this.editor.commands.fontsize({size: parseInt(event.target.value)});
         },

         /**
          * Returns true if string is a h1-h6 tag
          **/
         isHeaderTag(string) {
            return string === 'h1' || string === 'h2' || string === 'h3' || string === 'h4' || string === 'h5' || string === 'h6';
         },

         /**
          * Copy current selection
          **/
         copy() {
            this.addToClipboard();
         },

         /**
          * Cut current selection
          **/
         cut() {
            this.addToClipboard().deleteFromDocument();
         },

         /**
          * Paste complete selection
          **/
         paste() {
            if (this.clipboard.type !== 'None') {
               document.execCommand('insertHtml', false, this.selectedHtml)
            }
         },

         /**
          * Paste raw selection
          **/
         pasteRaw() {
            if (this.clipboard.type !== 'None') {
               document.execCommand('insertText', false, this.removeFormat(this.selectedText));
            }
         },

         /**
          * Remove new lines
          **/
         removeFormat(str) {
            return str.replace(/^\s+|\n|\s+$/g, '');
         },

         /**
          * Simple fullscreen function via styles
          * Todo: Use native functions
          **/
         fullscreen() {
            if (this.$refs.editorContainer.classList.contains('fullscreen')) {
               this.closeFullscreen()
            } else {
               this.openFullscreen();
            }
         },

         closeFullscreen() {
            window.removeEventListener('keyup', this.eventCloseFullscreen);
            this.$refs.editorContainer.classList.remove('fullscreen');
            this.$refs.fullscreenBtn.classList.remove('is-active');
            document.body.style.overflow = 'auto';
         },

         openFullscreen() {
            window.addEventListener('keyup', this.eventCloseFullscreen);
            this.$refs.editorContainer.classList += ' fullscreen';
            this.$refs.fullscreenBtn.classList += ' is-active';
            document.body.style.overflow = 'hidden';
         },

         /**
          * Close fullscreen on ESC
          **/
         eventCloseFullscreen(e) {
            if (!e) e = window.event;
            let keyCode = e.keyCode || e.which;
            if (keyCode == '27') {
               this.closeFullscreen();
            }
         },

         /**
          * Helper to get log from template
          **/
         log(...msg) {
            console.log(...msg);
         },

         saveLinkCallback(type, data) {
            if (type === 'url') {
               this.editor.commands.link(data);
            } else if (type === 'anchor') {
               this.editor.commands.link(data);
            } else if (type === 'email') {
               this.editor.commands.link(data);
            } else if (type === 'iframe') {
               this.editor.commands.iframe(data);
            }
         },


         getNodeAttrs: getNodeAttrs,

         /**
          * https://stackoverflow.com/a/3997896/9015191
          **/
         replaceSelectedText(replacementText) {
            let sel, range;
            if (window.getSelection) {
               sel = window.getSelection();
               if (sel.rangeCount) {
                  range = sel.getRangeAt(0);
                  range.deleteContents();
                  range.insertNode(document.createTextNode(replacementText));
               }
            } else if (document.selection && document.selection.createRange) {
               range = document.selection.createRange();
               range.text = replacementText;
            }
         },

         /**
          * Adds active selection to clipboard and local data
          **/
         addToClipboard() {
            this.clipboard = window.getSelection();
            document.execCommand('copy');
            this.selectedText = this.getSelectedText();
            this.selectedHtml = this.getSelectedHtml();

            this.$refs.editorPaste.classList += ' is-active';
            this.$refs.editorPasteRaw.classList += ' is-active';

            return this.clipboard;
         },

         /**
          * Get selection html
          * https://stackoverflow.com/a/4177234/9015191
          **/
         getSelectedHtml() {
            let html = "";
            if (typeof window.getSelection != "undefined") {
               let sel = window.getSelection();

               if (sel.rangeCount) {
                  let container = document.createElement("div");
                  for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                     container.appendChild(sel.getRangeAt(i).cloneContents());
                  }
                  html = container.innerHTML;
               }
            } else if (typeof document.selection != "undefined") {
               if (document.selection.type == "Text") {
                  html = document.selection.createRange().htmlText;
               }
            }

            return html;
         },

         /**
          * Get text from selection
          * https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection
          **/
         getSelectedText() {
            let {from, to} = this.editor.selection;
            return this.editor.state.doc.textBetween(from, to, ' ');
         },

         /**
          * Update content with given value
          */
         setContent(content) {
            this.editor.setContent(content);
            this.html = content;
         },

         /**
          * Set textarea content to keep html in form
          **/
         setTextareaContent(content) {
            if (this.$refs.editorTextarea.value !== content) {
               this.$refs.editorTextarea.value = content;
               this.textContentChanged();

               let event = new Event('change', {bubbles: true});
               this.$refs.editorTextarea.dispatchEvent(event);
            }
         },

         /**
          * Show/hide textarea and update content
          */
         showSource() {
            if (this.isSourceActive) {
               this.isSourceActive = false;
               this.setContent(this.$refs.editorTextarea.value);
               this.$refs.editorTextarea.style.display = 'none';
               this.$refs.editorContent.$el.style.display = 'block';
            } else {
               this.isSourceActive = true;
               let formatedContent = '';

               try {
                  formatedContent = prettier.format(this.html, {
                     semi: false,
                     parser: 'html',
                     plugins: [parserHtml],
                  });
               } catch(e) {
                  formatedContent = this.html;
                  console.error(e.message);
               }
               this.setTextareaContent(formatedContent);
               this.$refs.editorTextarea.style.display = 'block';
               this.$refs.editorContent.$el.style.display = 'none';
            }
         },
         textContentChanged() {
            // check entire content on each update for mediamanager-nodes (tedious but necessary due to possible direct src-editing)

            let regexp = this.$refs.editorTextarea.value.matchAll(/data-modal_id=["'](.*?)["']/g);
            let matches = Array.from(regexp, m => m[1]);

            let modals = this.$refs.mm_extension_modal_container.children;
            for (let i = 0; i < modals.length; i++) {
               let inputs = modals[i].querySelectorAll('.modelmedia_edit input');

               // toggle the "form" attribute on the medialists input fields
               // depending on wether the <img> node for this list is present in the textarea content or not
               // to make sure always only the right medialists are sent with the form
               if (matches.includes(modals[i].id)) {
                  for (let j = 0; j < inputs.length; j++) {
                     inputs[j].removeAttribute('form');
                  }
               } else {
                  for (let j = 0; j < inputs.length; j++) {
                     inputs[j].setAttribute('form', modals[i].id);
                  }
               }
            }

            // if tiptap is included inside another vue app, content can be retrieved via v-model
            this.$emit("editor-update", this.$refs.editorTextarea.value);

            // check entire content on each update for btn with no span tag wrap
            const btnRegex = new RegExp('(<a[^>]+class=\"btn[^\"]+\"[^>]+>)([^<]+)(<\\/a>)', 'g');
            const replacer = [];
            let match;
            while ((match = btnRegex.exec(this.$refs.editorTextarea.value)) !== null) {
               const [tag, tagOpen, tagContent, tagClose] = match;
               const startWithSpanWrap = tagContent.indexOf('<span>') === 0;
               if (!startWithSpanWrap) {
                  replacer.push({
                     from: tag,
                     to: `${tagOpen}<span>${tagContent}</span>${tagClose}`
                  });
               }
            }
            for (const replace of replacer) {
               this.$refs.editorTextarea.value = this.$refs.editorTextarea.value.replace(replace.from, replace.to);
            }
         },
          closeOtherColorDialog(type) {
             if (type == 'text') {
                 this.backgroundColorIsActive = false
             }
             if (type == 'bg') {
                 this.textColorIsActive = false;
             }
          },
          toggleI18nInheritance() {
              let inheritContainer = $(this.$refs.editorContainer).closest("[data-inherit='container']");
              if (inheritContainer.length > 0) {
                  this.editable = inheritContainer.attr("data-inherit-enabled") === "0";
                  this.editor.setOptions({
                      editable: this.editable && !parentInheritanceEnabled,
                  });
              }
          },
          toggleParentInheritance() {
             // when parent inheritance is on, there is no toggle existing for i18n
              let inheritContainer = $(this.$refs.editorContainer).parents(".form-group").first();
              if (inheritContainer.length > 0) {
                  parentInheritanceEnabled = inheritContainer[0].classList.contains('parent-inheritance-locked');
                  this.editor.setOptions({
                      editable: this.editable && !parentInheritanceEnabled,
                  });
              }
          },
      },

      mounted() {
         let vm = this;
         vm.setContent(content);
         vm.setTextareaContent(content);

         // if not default language (is inside an inherit-container),
         // initially setting editable, based on inherit-enabled
         vm.toggleI18nInheritance();
         vm.toggleParentInheritance();

         let ImageObjectClass = ImageObject;
         // load existing media Objects from db, and initialise mediamanager modals for each
         $(function() {
            $.post(window.MediaManager.controllerUrl + 'tiptapmedias', {
               'model': vm.modelClass,
               'id': vm.modelId,
               'attribute': vm.attributeName,
            }, (data) => {
               data = JSON.parse(data);
               let images = vm.$refs.editorContent.$el.querySelectorAll('img[data-modal_id]');
               for (let i = 0; i < images.length; i++) {
                  let attr = images[i].dataset.modal_id.split('-').pop();
                  if (data[attr]) {
                     vm.mediamanagerExtensionModals.push({
                        attributeName: data[attr][0].target_attr,
                        modelmedias: data[attr],
                        imageOptions: ImageObjectClass.getAttrsFromEl(images[i]),
                     });
                  }
               }
            });
         });

          // event listener for changing textarea content from outside through i18n inheritance toggle
          vm.$refs.editorTextarea.addEventListener('tiptap_inheritance_toggled', function (e) {
              vm.setContent(e.target.value);

              vm.toggleI18nInheritance();

              // if source-mode and switching to default language, disable source-mode
              if (vm.isSourceActive && $(e.target).closest("[data-inherit='container']").attr("data-inherit-enabled") === "1") {
                  vm.isSourceActive = false;
                  vm.$refs.editorTextarea.style.display = 'none';
                  vm.$refs.editorContent.$el.style.display = 'block';
              }

              // todo mediamanager modals... ?
          }, false);

      },
      created() {
         this.FONT_SIZES = FONT_SIZES;
         this.HEADINGS = HEADINGS;
         this.COLORS = DRIBBLE;
         this.BGCOLORS = BGCOLORS;
         this.VARIABLES = VARIABLES;
      },

      beforeDestroy() {
         this.editor.destroy()
      },

      beforeCreate() {
         if (this.$options.propsData['initContent'] !== undefined) {
            content = this.$options.propsData['initContent'];
         } else {
            content = document.querySelector(`[name="${this.$options.propsData['textareaName']}"]`).value; // Get content from textarea
         }
      }
   }
</script>

<style lang="scss">

    .editor:not(.editable), .parent-inheritance-locked .editor {
        .menubar{
            opacity: 0.5;
            pointer-events: none;
        }
        .editor__content img{
            pointer-events: none;
        }
    }

   /* Reset all styles in editor content area */
   .editor__content {
      all: initial;


      *:not([contenteditable="true"]) {
         //all: unset;
      }
       /*> .ProseMirror:not([contenteditable="true"]) {
           background-color: #f1f1f1;
       }*/

   }

   /* Re-declare bootstrap style to editor content area */
   .editor__content.editor__content.editor__content {
      @import "sass/default";

      /* Re-declare font-family */
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
      background-color: #fff;
   }

   @import "sass/menu";
   @import 'sass/content';
   @import "sass/page";
   @import "sass/editor";
   @import "sass/tabs";

   @import "sass/fullscreen";

   @import "sass/context";

   @debug "import: frontend/styles/frontend.scss";

   .editor__content.editor__content.editor__content,
   .inject-frontend {
      @import "../../../../../frontend/styles/frontend.scss";
   }

   @debug "import done: frontend/styles/frontend.scss";

   .scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
       transition: .3s cubic-bezier(.25, .8, .5, 1) !important
   }

   .scroll-y-transition-move {
       transition: transform .6s
   }

   .scroll-y-transition-enter, .scroll-y-transition-leave-to {
       opacity: 0
   }

   .scroll-y-transition-enter {
       transform: translateY(-15px)
   }

   .scroll-y-transition-leave-to {
       transform: translateY(15px)
   }

</style>