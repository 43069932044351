var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mediaformwidget onecolumn" }, [
    _c(
      "div",
      {
        staticClass: "mm-dropzone",
        class: { disabled: _vm.widgetOptions.uploadDisabled },
      },
      [_c("div", { staticClass: "dropzone-overlay" }), _vm._v(" "), _vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "medialist_wrapper",
        class: { empty: _vm.widgetOptions.medias.length <= 0 },
      },
      [
        _c(
          "ul",
          {
            staticClass: "medialist",
            attrs: {
              "data-maxfiles": _vm.widgetOptions.maxFiles,
              "data-model": _vm.widgetOptions.modelName,
              "data-attribute": _vm.widgetOptions.attributeName,
            },
          },
          [
            _c("MediaFormItem", {
              staticClass: "template",
              attrs: { widget: _vm.widgetOptions, media: {}, index: -1 },
            }),
            _vm._v(" "),
            _vm._l(_vm.widgetOptions.medias, function (media, index) {
              return _c("MediaFormItem", {
                key: index,
                attrs: {
                  widget: _vm.widgetOptions,
                  media: media,
                  index: index,
                },
              })
            }),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mediaform_wrapper" }, [
      _c("div", { staticClass: "mediaform_dropzone" }, [
        _c("input", {
          staticClass: "mediaform_upload_input uploadFile",
          class: {
            disabled:
              _vm.widgetOptions.uploadDisabled || _vm.widgetOptions.readonly,
          },
          attrs: {
            form: _vm.widgetOptions.dummyFormName,
            type: "file",
            multiple: "",
            name: "Media",
            disabled: _vm.widgetOptions.uploadDisabled,
            id: _vm.uploadInputId,
            "data-attribute": _vm.uploadInputId,
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.uploadInputId } }, [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "loadMediaManager btn btn-primary",
          class: { disabled: _vm.widgetOptions.readonly },
        },
        [_vm._v("Mediamanager")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "badge badge-auto badge-alternate-primary" },
      [_c("span", [_vm._v("Dateien hier ablegen")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload_media_icon" }, [
      _c("i", { staticClass: "far fa-plus" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload_media_title" }, [
      _vm._v("\n          Dateien hinzufügen"),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("Oder einen Ordner auswählen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }