var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group label-inside" }, [
    _c("textarea", {
      staticClass: "form-control",
      attrs: { id: _vm.id, type: "text", placeholder: _vm.label },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.update($event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }