var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card shadow mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$translations["element.country"])),
      _vm.element.items[0].data.mandatory ? _c("sup", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _vm.element.items[0].data.answeroptionsclass === "" &&
      _vm.element.items[0].data.answeroptionsfunction === ""
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v(_vm._s(_vm.element.items[0].data.question))]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "select",
                { attrs: { id: "", name: "" } },
                _vm._l(
                  _vm.element.items[0].data.answeroptions,
                  function (answer) {
                    return _c("option", { key: answer.id }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(answer.label) +
                          "\n                    "
                      ),
                    ])
                  }
                ),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "form-text text-muted mt-3",
                attrs: { id: "emailHelp" },
              },
              [_vm._v(_vm._s(_vm.element.items[0].data.description))]
            ),
          ])
        : _c("div", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.element.items[0].data.question))]),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(_vm._s(_vm.$translations["label.generatedby"]) + " "),
              _c("code", [
                _vm._v(
                  _vm._s(
                    `${_vm.element.items[0].data.answeroptionsclass}::${_vm.element.items[0].data.answeroptionsfunction}`
                  )
                ),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }