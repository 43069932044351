import {Mark} from 'tiptap'
import {updateMark} from 'tiptap-commands'

import {FONT_SIZES} from '../constants';

export default class FontSize extends Mark {

    get name() {
        return 'fontsize'
    }

    get defaultOptions() {
        return {
            sizes: FONT_SIZES,
        }
    }

    get schema() {
        return {
            attrs: {
                size: {
                    default: 0,
                },
            },
            inline: true,
            content: 'inline*',
            group: 'block',

            parseDOM: [{
                style: 'font-size',
                getAttrs(value) { return { size : parseInt(value) } },
            }],

            toDOM: node => [`span`, {'style': `font-size: ${node.attrs.size}px;`}, 0],
        }
    }

    commands({type}) {
        return attrs => updateMark(type, attrs)
    }
}
