var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fg__colorpicker" }, [
    _c(
      "div",
      { staticClass: "input" },
      [
        _vm.color === ""
          ? _c(
              "div",
              { staticClass: "colorpicker-icon", attrs: { title: _vm.color } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "20",
                      height: "21",
                      viewBox: "0 0 20 21",
                      fill: "none",
                      role: "img",
                    },
                  },
                  [
                    _c(
                      "mask",
                      {
                        attrs: {
                          id: "mask0",
                          "mask-type": "alpha",
                          maskUnits: "userSpaceOnUse",
                          x: "0",
                          y: "0",
                          width: "20",
                          height: "21",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "10",
                            cy: "10.5",
                            r: "10",
                            fill: "#C4C4C4",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("g", { attrs: { mask: "url(#mask0)" } }, [
                      _c("rect", {
                        attrs: {
                          x: "10",
                          y: "0.5",
                          width: "10",
                          height: "20",
                          fill: "#3A8BBB",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M20 4.5L10 -2.5V10.5L20 4.5Z",
                          fill: "#00B6E3",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M20 16.5L10 24.5V10.5L20 16.5Z",
                          fill: "#C142A0",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M0 0.5H10V10.5L0 16.5V0.5Z",
                          fill: "#FCC870",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M-7 0.5H10V10.5L-7 0.5Z",
                          fill: "#EF875B",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M0 16.5L10 24.5V10.5L0 16.5Z",
                          fill: "#FF5555",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("circle", {
                      attrs: { cx: "10", cy: "10.5", r: "3", fill: "#F5F6F7" },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.color !== ""
          ? _c(
              "div",
              { staticClass: "colorpicker-icon", attrs: { title: _vm.color } },
              [
                _c("div", {
                  style: `background-color: ${_vm.color}; width: 20px; height: 20px; border-radius: 50%;`,
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          ref: "inputColor",
          class: _vm.inputClass,
          attrs: {
            type: "text",
            title: _vm.inputValue,
            placeholder: _vm.inputPlaceholder,
          },
          domProps: { value: _vm.color },
          on: {
            change: function ($event) {
              return _vm.setColor()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "swatches",
          _vm._b(
            {
              ref: "swatches",
              attrs: { colors: _vm.colors, swatches: _vm.colors, inline: "" },
              on: {
                input: function ($event) {
                  return _vm.setSwatchColor(_vm.color)
                },
              },
              model: {
                value: _vm.color,
                callback: function ($$v) {
                  _vm.color = $$v
                },
                expression: "color",
              },
            },
            "swatches",
            _vm.options,
            false
          )
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      staticStyle: { display: "none" },
      attrs: { name: _vm.inputName, "aria-invalid": "false" },
      domProps: { value: _vm.color },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }