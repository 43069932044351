var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("input", {
          staticStyle: { width: "1200px" },
          attrs: { type: "hidden", name: "Segment[query]" },
          domProps: { value: _vm.jsonString },
        }),
        _vm._v(" "),
        _c("input", {
          staticStyle: { width: "1200px" },
          attrs: { type: "hidden", name: "Segment[op]" },
          domProps: { value: _vm.queryOp },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary",
            attrs: { type: "button" },
            on: { click: _vm.addGroup },
          },
          [_vm._v(_vm._s(_vm.labels.addGroup))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary",
            attrs: { type: "button" },
            on: { click: _vm.addRule },
          },
          [_vm._v(_vm._s(_vm.labels.addRule))]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("checkbox-switch", {
          attrs: { classes: "is-warning" },
          on: { input: _vm.updatePreview },
          scopedSlots: _vm._u([
            {
              key: "before",
              fn: function () {
                return [_vm._v("OR")]
              },
              proxy: true,
            },
            {
              key: "after",
              fn: function () {
                return [_vm._v("AND")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.queryOp,
            callback: function ($$v) {
              _vm.queryOp = $$v
            },
            expression: "queryOp",
          },
        }),
        _vm._v(" "),
        _c("segment-nested-draggable", {
          class: _vm.queryOp ? "dragArea op-not" : "dragArea op-and",
          attrs: { query: _vm.query, labels: _vm.labels, rules: _vm.rules },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }