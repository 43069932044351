<template>
    <span @click="sort" :class="{pointer: field.hasOwnProperty('sort'), active: isActive}">
        {{ field.label }}
        <i v-if="Object.prototype.hasOwnProperty.call(field, 'sort') && sortBy === field.sort.desc" class="fas fa-angle-up" />
        <i v-else-if="Object.prototype.hasOwnProperty.call(field, 'sort')" class="fas fa-angle-down" />
    </span>
</template>

<script>
export default {
    name: 'ColumnHeader',
    props: {
        field: {
            type: Object
        },
        sortBy: {
            type: String
        }
    },
    computed: {
        isActive() {
            if (!Object.prototype.hasOwnProperty.call(this.field, 'sort')) {
                return false;
            }

            return this.sortBy === this.field.sort.asc || this.sortBy === this.field.sort.desc;
        }
    },
    methods: {
        sort() {
            // column is not sortable
            if (!Object.prototype.hasOwnProperty.call(this.field, 'sort')) {
                return;
            }

            // set sortBy
            if(this.sortBy === undefined) {
                this.$emit('sort', this.field.sort.asc);
            } else if(this.sortBy === this.field.sort.asc) {
                this.$emit('sort', this.field.sort.desc);
            } else if(this.sortBy === this.field.sort.desc) {
                this.$emit('sort', this.field.sort.asc);
            } else {
                this.$emit('sort', this.field.sort.asc);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "frontend/styles/framework";

.pointer {
    cursor: pointer;
}
.active {
    color: $color-primary;
    font-weight: bold;
}
</style>