 let fireColors = [
    [
        "#f9ddd1",
        "#fad3d1",
        "#fad2e7",
        "#e5d2fa",
        "#d3d1fa",
        "#d1e2fa",
        "#d2f3fa",
        "#d1faef",
        "#d1fbd8",
        "#ebfbd1",
        "#fafad1",
        "#faefd1",
        "#fae6d0",
        "#f2e3d9",
        "#ffffff",
    ],
    [   
        "#f4b79c",
        "#f4a09b",
        "#f59cc8",
        "#c69cf4",
        "#a49bf4",
        "#9cc2f4",
        "#9de5f5",
        "#9cf5e0",
        "#9cf4a7",
        "#d4f49d",
        "#f4f19c",
        "#f4dc9c",
        "#f4c89d",
        "#e3c0ac",
        "#e4e4e4",
        "#ee8e66",
    ],
    [
        "#ee6d66",
        "#ee66ab",
        "#a866ee",
        "#7165ee",
        "#67a1ee",
        "#68d6ee",
        "#67eece",
        "#66ed77",
        "#bcef66",
        "#ede966",
        "#eeca66",
        "#eeaa66",
        "#d59f80",
        "#b4b4b4",
        "#e86831",
    ],
    [
        "#e83b30",
        "#e9308c",
        "#8a30e7",
        "#402fe8",
        "#307fe8",
        "#2fc8e8",
        "#32e7bc",
        "#30e848",
        "#a4e82f",
        "#e8e230",
        "#e8b830",
        "#e88b30",
        "#c77e53",
        "#848484",
        "#c74a16",
    ],
    [
        "#c72015",
        "#c7146f",
        "#6d16c7",
        "#2616c7",
        "#1463c6",
        "#12aac7",
        "#14c89e",
        "#13c72e",
        "#86c717",
        "#c7c117",
        "#c79816",
        "#c66e16",
        "#a56037",
        "#545454",
        "#913810",
    ],
    [
        "#911711",
        "#911051",
        "#4f1091",
        "#1b1091",
        "#104791",
        "#117c91",
        "#109274",
        "#109121",
        "#619110",
        "#908c0f",
        "#916e11",
        "#90500f",
        "#794628",
        "#242424",
        "#5c2309",
    ],
    [
        "#5c0f0a",
        "#5c0b33",
        "#330a5c",
        "#120a5c",
        "#0c2e5c",
        "#0b4e5c",
        "#085d49",
        "#095c15",
        "#3d5b0b",
        "#5b5809",
        "#5c460a",
        "#5c340a",
        "#4c2d18",
        "#000000",
    ]
];

export default fireColors;