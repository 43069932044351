var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-wrapper" },
    [
      _c("div", { staticClass: "form-group label-inside" }, [
        _c(
          "div",
          {
            attrs: {
              "data-inherit-enabled": _vm.inheritance,
              "data-inherit-source": _vm.itemsJSON,
              "data-inherit": "container",
            },
          },
          [
            _c(
              "div",
              { staticClass: "i18n-inheritance" },
              [
                _vm._l(_vm.mediaForms, function (mediaForm, index) {
                  return _c("input", {
                    key: `hidden-${index}`,
                    attrs: {
                      name: `Page[page][${_vm.modelName}Inheritance][${_vm.identifier}][${mediaForm.key}][inherit]`,
                      form: "pageeditor-dummy-form",
                      type: "hidden",
                      value: "0",
                    },
                  })
                }),
                _vm._v(" "),
                _vm._l(_vm.mediaForms, function (mediaForm, index) {
                  return !_vm.isDefaultLocale
                    ? _c("input", {
                        key: `checkbox-${index}`,
                        staticClass: "d-none",
                        attrs: {
                          name: `Page[page][${_vm.modelName}Inheritance][${_vm.identifier}][${mediaForm.key}][inherit]`,
                          form: "pageeditor-dummy-form",
                          type: "checkbox",
                          value: "1",
                        },
                        domProps: { checked: _vm.inheritanceEnabled },
                      })
                    : _vm._e()
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: {
            "component-data": _vm.getComponentData(),
            draggable: ".draggable",
            handle: ".handle",
            tag: "div",
          },
          model: {
            value: _vm.items,
            callback: function ($$v) {
              _vm.items = $$v
            },
            expression: "items",
          },
        },
        _vm._l(_vm.items, function (item, index) {
          return _vm.mounted
            ? _c("accordion-item", {
                key: `slider-item-${item.id}`,
                attrs: {
                  id: item.id,
                  "disable-editable-fields": _vm.disableEditableFields,
                  "disable-remove": _vm.items.length <= _vm.min,
                  "element-title": _vm.elementTitle,
                  fields: _vm.fields,
                  groups: _vm.groups,
                  identifier: _vm.identifier,
                  index: index,
                  item: item,
                  "item-label-key": _vm.itemLabelKey,
                  "items-length": _vm.items.length,
                  "media-forms": _vm.mediaForms,
                  medias: _vm.getMedias(item.id),
                  min: _vm.min,
                  "model-name": _vm.modelName,
                  offsets: _vm.getOffsets(item.id),
                  readonly: _vm.inheritanceEnabled,
                },
                on: {
                  "remove-item": _vm.removeItem,
                  "value-update": _vm.valueUpdate,
                  "media-add": _vm.mediaAdd,
                  "media-update": _vm.mediaUpdate,
                  "media-remove": _vm.mediaRemove,
                  "media-sort": _vm.mediaSort,
                },
              })
            : _vm._e()
        }),
        1
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "button",
          {
            staticClass: "btn btn-primary w-100 my-4",
            attrs: {
              disabled: _vm.items.length >= _vm.max,
              title: _vm.addTitle(),
              type: "button",
            },
            on: { click: _vm.addItem },
          },
          [_vm._v("Hinzufügen")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }