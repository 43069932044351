<template>
    <i v-if="field.accessor(item) === 0" class="far fa-star" @click="toggleIsFavorite"></i>
    <i v-else-if="field.accessor(item) === 1" class="fas fa-star" @click="toggleIsFavorite"></i>
</template>

<script>
import EventBus from "../util/EventBus";

export default {
    name: "Favorite",
    props: {
        field: {
            type: Object
        },
        item: {
            type: Object
        }
    },
    methods: {
        toggleIsFavorite() {
            EventBus.$emit('toggleIsFavorite', this.item, this.field);
        }
    }
};
</script>

<style lang="scss" scoped>
i {
    font-size: 1.2rem;
    cursor: pointer;
}
</style>