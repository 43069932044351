var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.tabs
    ? _c(
        "div",
        { staticClass: "tabs" },
        [
          _vm._l(_setup.props.tabs, function (tab, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "tab",
                class: {
                  active: _setup.activeTab === i,
                  disabled: !_setup.tabContainsContent(i),
                },
                on: {
                  click: function ($event) {
                    _setup.tabContainsContent(i)
                      ? (_setup.setActiveTab(i),
                        _setup.emit("tab-changed", tab))
                      : null
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(tab) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "active-bg",
            style: {
              width: _setup.tabWidth,
              transform: `translateX(${_setup.activeTab * 100}%)`,
            },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }