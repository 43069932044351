<template>
    <header class="d-flex justify-content-between">
        <div class="header-label">
            {{ translations.overview }}
        </div>
        <div class="d-flex tab">
            <div class="tab-item mr-3 p-3" v-if="active === 1 && showProductTypeFilter">
                <select v-model="productTypeMutable" @change="changeProductType">
                    <option value="0">{{ translations.normal }}</option>
                    <option value="1">{{ translations.mother }}</option>
                    <option value="2">{{ translations.children }}</option>
                </select>
            </div>
            <div
                class="tab-item mr-3 p-3"
                :class="{active: active === 0}"
                @click="setActive(0)">
                {{ translations.selection }} <span class="count">{{ count }}</span>
            </div>
            <div
                class="tab-item p-3"
                :class="{active: active === 1}"
                @click="setActive(1)">
                {{ translations.catalogue }}
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    props: {
        count: {
            type: Number,
            default: 0
        },
        productType: {
            type: [Number, String],
            default: 1
        },
        showProductTypeFilter: {
            type: Boolean,
            default: false
        },
        translations: {
            type: Object
        }
    },
    data() {
        return {
            active: 0,
            productTypeMutable: 1
        };
    },
    methods: {
        setActive(value) {
            this.active = value;
            this.$emit('active', value);
        },
        changeProductType(e) {
            this.$emit('update-product-type', parseInt(e.target.value))
        }
    },
    mounted() {
        this.productTypeMutable = this.productType;
    }
};
</script>

<style lang="scss" scoped>
@import "frontend/styles/framework";

header {
    margin-bottom: 20px;
}

.header-label {
    font-size: 0.8rem;
    font-weight: 600;
}

.tab {
    .tab-item {
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        background-color: #F6F8F8;
        color: rgba(76, 94, 100, .5);
        border-radius: 5px;

        &.active {
            color: $color-primary;
            background-color: rgba(68, 144, 158, 0.1);
        }

        .count {
            background-color: rgba(68, 144, 158, 0.15);
            border-radius: 20px;
            padding: 0 12px;
            margin-left: 1rem;
        }
    }
}

select {
    border: none;
    background-color: transparent;
    height: 100%;
    width: 100%;
}
</style>