<template>
    <draggable
        handle=".handle"
        tag="ul"
        :list="query"
        :group="{name: 'fields', pull: true, put: true}"
        :scroll-sensitivity="190"
        :force-fallback="true"

        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
    >

            <li v-for="(el,index) in query" :key="el.id" :class="el.type">
                <!-- Group -->
                <div v-if="TYPE_GROUP === el.type">
                    <i class="fa fa-fw fa-grip-vertical handle segment-rule-handler"></i>
                    <checkbox-switch classes="is-warning" v-model="el.op" checked>
                        <template #before>OR</template>
                        <template #after>AND</template>
                    </checkbox-switch>
                    <!--<input type="checkbox" value="1" :checked="el.op" @input="el.op = Number($event.target.checked)">-->
                    <segment-nested-draggable :query="el.query" :labels="labels" :rules="rules" :class="el.op ? 'dragArea op-not' : 'dragArea op-and'"/>
                    <button type="button" @click="removeAt(index)" class="segment-rule-remove"><i class="fa fa-times" ></i></button>
                </div>
                <!-- Rule -->
                <segment-rule
                    v-if="TYPE_RULE === el.type"
                    :labels="labels"
                    :rules="rules"
                    :index="index"
                    v-model="query[index]"
                    @removeAt="removeAt"
                ></segment-rule>
            </li>

    </draggable>
</template>

<script>
import CheckboxSwitch from "./components/CheckboxSwitch.vue";
import SegmentRule from "./components/SegmentRule.vue";
import draggable from "vuedraggable";

export default {
    name: "segment-nested-draggable",
    data() {
        return {
            TYPE_GROUP: "group",
            TYPE_RULE: "rule"
        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false
            };
        }
    },
    props: {
        rules: {
            required: true,
            type: Object
        },
        labels: {
            required: true,
            type: Object
        },
        query: {
            required: true,
            type: Array
        }
    },
    components: {
        draggable,
        SegmentRule,
        CheckboxSwitch
    },
    methods: {
        removeAt(index) {
            this.query.splice(index, 1);
        }
    }
};
</script>
<style scoped lang="scss">

$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;
$ident: 20px;
$left: -($ident);
$borderRadius: 0.2rem;
$onColor: #80cdc1;
$offColor: #dfc27d;
ul {
    margin-left: $ident;
    padding: 0;
    min-height: 34px;
}
#segment-module-root {
    ul.dragArea {
        display: block;
        border: 1px solid $border;
        padding: 10px;
        margin: 10px 0 10px 0;
        border-radius: $borderRadius;
        transition: box-shadow 200ms ease-out;
        &.op-not {
            box-shadow: inset 0 0 20px 3px $onColor;
            > li {
                border: 1px solid scale-color($onColor, $alpha: -50%);
            }
        }
        &.op-and {
            box-shadow: inset 0 0 20px 3px $offColor;
           > li {
               border: 1px solid scale-color($offColor, $alpha: -50%);
            }
        }
    }
    .sortable-drag {
        opacity: 0.5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        background:white;
        &:before, &:after  {
            display:none;
        }
        height:auto !important;
    }

    li {

        border-radius: $borderRadius;
        &.sortable-ghost {
            z-index: 100;
            box-shadow: 0 0 8px #1991a0;
        }
        background-color:white;
        &.group {
            margin: 0;
        }
        &.rule {
            margin:0;
        }
        list-style-type: none;
        position: relative;
        display:block;
        border: 1px solid $border;
        padding:10px;
        color:$text;
        text-decoration:none;

    }
}
input[type="checkbox"] {
    display: none;
    &:checked {
        + span {
            &:before {
                background-color: rgba(#007FEB, 0.5);
            }
            &:after {
                background-color: #007FEB;
                transform: translate(80%, -50%);
            }
        }
    }
    + span {
        position: relative;
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        text-align: left;
        margin: 0;
        padding: 0 44px;
        &:before,
        &:after {
            content: "";
            cursor: pointer;
            position: absolute;
            margin: 0;
            outline: 0;
            top: 50%;
            transform: translate(0, -50%);
            transition: all 200ms ease-out;
        }
        &:before {
            left: 1px;
            width: 34px;
            height: 14px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }
        &:after {
            left: 0;
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
        }
    }
    &:checked + span &:after {
        transform: translate(80%, -50%);
    }
}
</style>
<style lang="scss">

.handle {
    cursor: grab;
    opacity: 0.4;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    user-select:none;
    /*vertical-align: top;*/
    &:hover {
        opacity: 1;
    }
}

i.segment-rule-handler {
    width: 30px;
}

:root {
    --vs-dropdown-option--active-bg: rgba(34, 164, 174, 0.06) !important;
    --vs-dropdown-option--active-color: #22a4ae !important;
    --vs-dropdown-box-shadow: 0px 10px 15px 0px rgb(34 44 44 / 10%) !important;
}
</style>