<template>
    <div class="filter-settings">

        <top-bar :filters="filters" v-if="showTopBar" :input-name-prefix="inputNamePrefix" :active="initActive" />

        <div class="d-flex w-100">
            <div class="d-flex w-100">
                <div class="filter-settings__content">
                    <EmptyState :empty-headline="$attrs['empty-headline']" :empty-subline="$attrs['empty-subline']" :empty-hint="$attrs['empty-hint']" v-if="activeFiltered.length === 0" :active="activeFiltered" :is-dragging="isDragging" />
                    <table class="w-100" v-else>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{{ $attrs['label-type'] }}</th>
                                <th>{{ $attrs['label-position'] }}</th>
                                <th>{{ $attrs['label-favorite'] }}</th>
                                <th v-if="showShowroomToggle">{{ $attrs['label-showroom'] }}</th>
                                <th>{{ $attrs['label-view'] }}</th>
                                <th>{{  $attrs['label-extension'] }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <draggable :group="{ name: 'filter' }" :list="active" tag="tbody" :animation="150" :swap-threshold="100">
                            <Item
                                v-for="(filter, index) in active"
                                :filter="filter"
                                :position="index + 1"
                                :display-option-values="displayOptionValues"
                                :additional-option-values="additionalOptionValues"
                                :shop-category-id="shopCategoryId"
                                :label-remove-button="$attrs['button-delete']"
                                :input-name-prefix="inputNamePrefix"
                                :show-showroom-toggle="showShowroomToggle"
                                :key="`filter-${filter.filterId}`"
                                @remove="remove"/>
                        </draggable>
                    </table>
                </div>
                <div class="filter-settings__sidebar">
                    <sidebar :filters="filters" :active="active" @drag-start="dragStart" @drag-end="dragEnd" />
                </div>
                <input type="hidden" :name="`${inputNamePrefix}[filters][]`" v-if="active.length === 0" />
            </div>
        </div>
    </div>
</template>


<script>
import EmptyState from '../components/EmptyState.vue';
import Sidebar from '../components/Sidebar.vue';
import TopBar from '../components/TopBar.vue';
import Item from '../components/Item.vue';
import draggable from 'vuedraggable';

export default {
    name: 'FilterSettings',
    components: {
        EmptyState,
        Sidebar,
        TopBar,
        Item,
        draggable
    },
    data: function() {
        return {
            filters: null,
            active: [],
            displayOptionValues: null,
            additionalOptionValues: null,
            shopCategoryId: undefined,
            isDragging: false,
            showTopBar: false,
            queryFilterUrl: null,
            gqlQuerySelection: null,
            entityId: null,
            entityAttributeName: null,
            resultAccessor: null,
            inputNamePrefix: null,
            api: null,
            showShowroomToggle: true,
            initActive: []
        };
    },
    computed: {
        activeFiltered() {
            return this.active.filter(active => active.displayOnTop !== 1);
        }
    },
    mounted() {
        this.shopCategoryId = this.$attrs['category-id'];
        this.showTopBar = this.$attrs['show-top-bar'];
        this.queryFilterUrl = this.$attrs['query-filter-url'];
        this.gqlQuerySelection = this.$attrs['gql-query-selection'];
        this.entityId = this.$attrs['entity-id'];
        this.entityAttributeName = this.$attrs['entity-attribute-name'];
        this.showTopBar = this.$attrs['show-top-bar'];
        this.resultAccessor = this.$attrs['result-accessor'];
        this.inputNamePrefix = this.$attrs['input-name-prefix'];
        this.api = this.$attrs['api'];
        this.showShowroomToggle = this.$attrs['show-showroom-toggle'];

        this.fetchCategories();
        this.fetchActiveFilters();
    },
    methods: {
        remove(filter) {
            this.active = this.active.filter(active => active.filterId !== filter.filterId);
        },
        dragStart() {
            this.isDragging = true;
        },
        dragEnd() {
            this.isDragging = false;
        },
        fetchCategories() {
            fetch(this.queryFilterUrl)
                .then(res => res.json())
                .then(json => {
                   this.filters = json.data.filters.results;
                   this.displayOptionValues = json.data.filters.displayOptionValues;
                   this.additionalOptionValues = json.data.filters.additionalOptionValues;
                });
        },
        fetchActiveFilters() {
            const variables = {};
            variables[this.entityAttributeName] = this.entityId;

            const body = {
                query: this.gqlQuerySelection,
                variables
            };

            const headers = {
                'Content-Type': 'application/json'
            };
            headers['X-CSRF-Token'] = yii.getCsrfToken();

            fetch(this.api, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    const activeFilters = this.resultAccessor(json).filters;
                    this.initActive = [...activeFilters]
                    this.active = activeFilters.filter(active => active.displayOnTop !== 1);
                });
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    thead {
        tr {
            border-bottom: 1px solid #D6D8D8;
        }

        th {
            font-size: 8px;
            font-weight: normal;
            color: #4C5E64;
            padding: 15px 5px;
        }
    }
}
.sortable-ghost {
    background: rgba(189, 184, 184, 0.1);
}

.filter-settings {

    &__content {
        flex-grow: 1;
        min-height: 420px;
        margin-right: 2rem;
    }

    &__sidebar {
        width: 235px;
        min-height: 420px;
        background-color: #FAFBFB;
    }
}
</style>