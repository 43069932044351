<template>
    <section id="section-dependencies" class="collapse">
        <div class="p-3">
            <div v-if="Object.keys(logic).length === 0">
                <LogicHint :logic-options="logicOptions" />
                <button
                    class="btn btn-block btn-primary"
                    :disabled="logicOptions.length === 0"
                    @click="enableLogic">
                    {{ $translations['button.uselogic'] }}
                </button>
            </div>
            <div v-else>
                <LogicEditor
                    :options="logicOptions"
                    :element="item"
                    :logic="logic"
                    @add-rule="addRule"
                    @remove-rule="removeRule" />
            </div>
        </div>
    </section>
</template>

<script>
import LogicHint from "./LogicHint.vue";
import LogicEditor from "./LogicEditor.vue";

export default {
    name: 'LogicSection',
    components: {
        LogicHint,
        LogicEditor
    },
    props: {
        item: {
            type: Object
        },
        logicOptions: {
            type: Array
        }
    },
    computed: {
        logic() {
            return this.item.type === 'formsection' ? this.item.data.logic : this.item.logic;
        }
    },
    methods: {
        enableLogic() {
            this.$emit('enable-logic');
        },
        addRule() {
            this.$emit('add-rule');
        },
        removeRule(index) {
            this.$emit('remove-rule', index);
        }
    }
};
</script>