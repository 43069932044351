import eventBus from "../util/EventBus";

export default {
    props: {
        item: {
            type: Object
        }
    },
    data: function() {
        return {
            element: this.item
        };
    },
    watch: {
        item(newVal) {
            this.element = newVal;
        }
    },
    mounted() {
        eventBus.$on('previewupdate', item => {
            if(item.data.id === this.element.items[0].data.id) {
                // update item
                this.$set(this.element.items[0], 'data', item.data);
            }
        });

        eventBus.$on('previewreset', () => {
            this.element = this.item;
        });
    }
};