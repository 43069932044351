<template>
    <ul
        :id="elementId"
        class="context-menu"
        v-click-outside="onClickOutside"
    >
        <li
            v-for="(option, index) in options"
            :key="index"
            @click="optionClicked(option)"
            class="context-menu__item"
            :class="option.class"
        ><icon v-if="option.icon"
               :name="option.icon"/><span class="title">{{option.name}}</span><span v-if="option.hint"
                                                                                    :class="option.hint.class ? option.hint.class : 'hint'"
                                                                                    :title="option.hint.title">{{option.hint.text}}</span></li>
    </ul>
</template>

<script>

    import Vue from 'vue'
    import vClickOutside from 'v-click-outside'
    import Icon from "./Icon.vue"

    Vue.use(vClickOutside);

    export default {
        name: 'ContextMenu',
        components: {
            Icon
        },
        props: {
            elementId: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                item: null,
                menuWidth: null,
                menuHeight: null
            }
        },
        methods: {
            showMenu(event, item) {
                this.item = item;

                let menu = document.getElementById(this.elementId);
                if (!menu) {
                    return
                }

                if (!this.menuWidth || !this.menuHeight) {
                    menu.style.visibility = "hidden";
                    menu.style.display = "block";
                    this.menuWidth = menu.offsetWidth;
                    this.menuHeight = menu.offsetHeight;
                    menu.removeAttribute("style")
                }
                let pos = this.getRelativeCoordinates(event, menu);

                if (pos) {
                    menu.style.left = pos.x / 4 + 'px';
                }

                menu.classList.add('context-menu--active')
            },
            hideContextMenu() {
                let element = document.getElementById(this.elementId);
                if (element) {
                    element.classList.remove('context-menu--active');
                }
            },
            onClickOutside() {
                this.hideContextMenu()
            },
            optionClicked(option) {
                this.hideContextMenu();
                this.$emit('option-clicked', {
                    item: this.item,
                    option: option
                })
            },
            onEscKeyRelease(event) {
                if (event.keyCode === 27) {
                    this.hideContextMenu();
                }
            },

            getRelativeCoordinates(event, referenceElement) {
                const position = {
                    x: event.pageX,
                    y: event.pageY
                };

                const offset = {
                    left: referenceElement.offsetLeft,
                    top: referenceElement.offsetTop
                };

                let reference = referenceElement.offsetParent;

                while(reference) {
                    offset.left += reference.offsetLeft;
                    offset.top += reference.offsetTop;
                    reference = reference.offsetParent;
                }

                return {
                    x: position.x - offset.left,
                    y: position.y - offset.top,
                };

            }
        },
        mounted() {
            document.body.addEventListener('keyup', this.onEscKeyRelease);
        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.onEscKeyRelease);
        }
    }
</script>

