var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "swatches",
        _vm._b(
          {
            attrs: { colors: _vm.colors },
            model: {
              value: _vm.color,
              callback: function ($$v) {
                _vm.color = $$v
              },
              expression: "color",
            },
          },
          "swatches",
          _vm.options,
          false
        )
      ),
      _vm._v(" "),
      _c("input", {
        class: _vm.inputClass,
        staticStyle: { display: "none" },
        attrs: { name: _vm.inputClass, "aria-invalid": "false" },
        domProps: { value: _vm.color },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }