var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card shadow mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$translations["element.terms"])),
      _vm.element.items[0].data.mandatory ? _c("sup", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v(_vm._s(_vm.element.items[0].data.question))]),
        _vm._v(" "),
        _c("div", { staticClass: "card bg-light mb-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("p", {
              staticClass: "card-text",
              domProps: {
                innerHTML: _vm._s(_vm.element.items[0].data.textBox),
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-check" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: { id: "exampleCheck1", type: "checkbox" },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "exampleCheck1" },
            },
            [_vm._v(_vm._s(_vm.element.items[0].data.confirmLabel))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "small",
          {
            staticClass: "form-text text-muted mt-3",
            attrs: { id: "emailHelp" },
          },
          [_vm._v(_vm._s(_vm.element.items[0].data.description))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }