var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-block btn-primary",
        attrs: { type: "button" },
        on: { click: _vm.save },
      },
      [
        _vm._v(
          "\n        " + _vm._s(_vm.$translations["button.save"]) + "\n    "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-block btn-secondary",
        attrs: { type: "button" },
        on: { click: _vm.cancel },
      },
      [
        _vm._v(
          "\n        " + _vm._s(_vm.$translations["button.cancel"]) + "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }