<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.terms'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div class="form-group">
                <label>{{ element.items[0].data.question }}</label>

                <div class="card bg-light mb-3">
                    <div class="card-body">
                        <p class="card-text" v-html="element.items[0].data.textBox" />
                    </div>
                </div>

                <div class="form-check">
                    <input
                        id="exampleCheck1"
                        type="checkbox"
                        class="form-check-input">
                    <label class="form-check-label" for="exampleCheck1">{{ element.items[0].data.confirmLabel }}</label>
                </div>

                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Terms",
    mixins: [previewItemMixin],
};
</script>