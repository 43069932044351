/**
 * Backend Menu functionality & toggles
 */

class BackendMenu {

    constructor() {
        "use strict";
        this.backendMenu = document.querySelector('.backendmenu');
        this.backendContent = document.querySelector('.backendcontent');
        this.mainNav = this.backendMenu.querySelector('ul.nav');
        this.mainItems = this.mainNav.querySelectorAll('li.nav-item');
        this.adminMenuCookieName = 'admin-menu-state';
    }

    init() {
        let self = this;
        if (this.readCookie(this.adminMenuCookieName)) {
            let parentActiveElement = document.querySelector('.parent-active');
            if (parentActiveElement) {
                this.mainNav.classList.add('menu_open');
                this.backendContent.classList.add('menu_open', 'transition-disabled');
                parentActiveElement.querySelector('.menu__subnav').classList.add('transition-disabled', 'open');
            }
        }
        for (let i = 0; i < this.mainItems.length; i++) {
            if (this.mainItems[i].dataset['subnav']) {
                // toggle hide/show subnav for this item
                this.mainItems[i].querySelector('.nav-link').addEventListener('click', (e) => {
                    self.toggleSubNav(this.mainItems[i]);
                });

                this.mainItems[i].querySelector('.menu__subnav__close').addEventListener('click', (e) => {
                    self.toggleSubNav(this.mainItems[i]);
                });
            }
        }
    }

    toggleSubNav(item) {
        let subnav_id = item.dataset['subnav'];
        let subNav = item.querySelector(subnav_id);

        this.backendContent.classList.remove('transition-disabled');

        // toggle 'open' state on this item
        subNav.classList.toggle('open');
        // disable transitions on this item, if it is being opened an another one is already open
        subNav.classList.toggle('transition-disabled', this.mainNav.classList.contains('menu_open') && subNav.classList.contains('open'));

        // remove 'open' state on all other subnavs
        let subNavs = this.mainNav.querySelectorAll('.menu__subnav:not(' + subnav_id + ')');
        for (let i = 0; i < subNavs.length; i++) {
            subNavs[i].classList.remove('open');
            //disable transitions on all other subnavs, if the current selected is being opened
            subNavs[i].classList.toggle('transition-disabled', subNav.classList.contains('open'));
        }

        // toggle 'open' state on all main-items
        for (let i = 0; i < this.mainItems.length; i++) {
            this.mainItems[i].classList.remove('open');
        }
        item.classList.toggle('open', subNav.classList.contains('open'));

        // update 'menu_open' state on containers
        this.backendContent.classList.toggle('menu_open', subNav.classList.contains('open'));
        this.mainNav.classList.toggle('menu_open', subNav.classList.contains('open'));
        if (this.mainNav.classList.contains('menu_open')) {
            this.createCookie(this.adminMenuCookieName, 1, 10);
        } else {
            this.deleteCookie(this.adminMenuCookieName);
        }
    }

    initContentTabs() {
        let tabItems = this.backendContent.querySelectorAll('.content__tabs .tabs__item, .header__tabs .tabs__item');
        for (let i = 0; i < tabItems.length; i++) {
            tabItems[i].addEventListener('click', (e) => {
                if (e.currentTarget.classList.contains('active')) {
                    return; // do nothing on already active clicked links
                }
                if (e.currentTarget.classList.contains('disabled')) {
                    return; // do nothing on disabled links
                }

                //send info to server
                let tabId = e.currentTarget.dataset['contentTabId'];
                let tabGroup = e.currentTarget.dataset['tabGroup'];
                if (tabGroup === undefined) {
                    tabGroup = e.currentTarget.dataset['tabGroupControl'];
                }
                const id = document.querySelector(".main__content form").getAttribute('id');
                let xhr = new XMLHttpRequest();
                xhr.open('GET', 'activate-menu?id=' + id + "&group=" + tabGroup + "&tab=" + tabId);
                xhr.send();

                // toggle visible tab content
                let content_tabs = this.backendContent.querySelectorAll('.main__content .content__tab');
                for (let j = 0; j < content_tabs.length; j++) {
                    content_tabs[j].classList.toggle('open', content_tabs[j].getAttribute('id') === e.currentTarget.dataset['contentTabId']);
                }

                // update current active tab-links
                for (let i = 0; i < tabItems.length; i++) {

                    // if the clicked link is part of a group, update the group-control for it
                    if (e.currentTarget.dataset['tabGroup'] && tabItems[i].dataset['tabGroupControl'] === e.currentTarget.dataset['tabGroup']) {
                        // overwrite the content-tab-id for the group-control so the next time switching into this group again will reopen to the same view
                        tabItems[i].dataset['contentTabId'] = e.currentTarget.dataset['contentTabId'];
                    }

                    // if the clicked link is a group-control, toggle visibility of group items
                    if (tabItems[i].dataset['tabGroup'] && e.currentTarget.dataset['tabGroupControl']) {
                        tabItems[i].classList.toggle('hidden', tabItems[i].dataset['tabGroup'] !== e.currentTarget.dataset['tabGroupControl']);
                    }

                    // update current active links
                    tabItems[i].classList.toggle('active', tabItems[i].dataset['contentTabId'] === e.currentTarget.dataset['contentTabId']);
                }

                // update nav header when switching to another group
                /*if(e.currentTarget.dataset['tabGroupControl']){
                    this.backendContent.querySelector('.content__tabs .content__tabs__header').innerText = e.currentTarget.innerText;
                }*/

                // toggle visibility of the tab-nav
                this.backendContent.classList.toggle('tabs_open', !(e.currentTarget.classList.contains('hide_tab_nav') && e.currentTarget.classList.contains('active')));
            });
        }


        // toggle pageeditor link in header when page->is_redirect is toggled
        let pageeditor_headerbutton = this.backendContent.querySelector('.header .header__tabs .tabs__item[data-content-tab-id*="-pageeditor"]');
        if (pageeditor_headerbutton) {
            let pageRedirectCheckbox = this.backendContent.querySelector('input[type=checkbox]#page-is_redirect');
            if (pageRedirectCheckbox) {
                pageRedirectCheckbox.addEventListener('change', (e) => {
                    pageeditor_headerbutton.classList.toggle('disabled', e.target.checked);
                });
            }
        }
    }

    initHeaderBreadcrumbs() {
        let self = this;
        let breadcrumbs = document.querySelectorAll('.breadcrumbs ol.breadcrumb li.breadcrumb-item');
        for (let i = 0; i < breadcrumbs.length; i++) {
            let modelid = breadcrumbs[i].dataset['modelid'];
            if (modelid) {
                let navitem = this.mainNav.querySelector('.nav-item[data-model-id="' + modelid + '"]');
                if (navitem) {
                    if (navitem.classList.contains('main-item')) {
                        breadcrumbs[i].addEventListener('click', (e) => {
                            self.toggleSubNav(navitem);
                        });
                    } else {
                        breadcrumbs[i].addEventListener('click', (e) => {
                            // open subnav accordion menu
                            let collapsecontainer = navitem.querySelector('ul.accordion-menu');
                            $(collapsecontainer).collapse('show');        // need jquery here... :(

                            // open containing main menu
                            let parentmainmenu = $(navitem).parents('.main-item')[0];
                            self.toggleSubNav(parentmainmenu);
                        });
                    }

                }
            }
        }
    }

    createCookie(name, value, days) {
        let expires;
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    readCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    deleteCookie(name) {
        this.createCookie(name, "", -1);
    }
}

(function () {
    "use strict";
    if (document.querySelector('.backendmenu')) {
        window.backendMenu = new BackendMenu();
        window.backendMenu.init();
        window.backendMenu.initContentTabs();
        window.backendMenu.initHeaderBreadcrumbs();
    }
})();