<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.section'] }} <span v-if="getIdent" class="text-small">(ident:{{ getIdent }})</span>
        </div>
        <div class="card-body">
            <draggable
                class="section-drop-area p-3 rounded"
                :group="{ name: 'structure', pull: true, put: putSectionArea }"
                :list="item.items"
                handle=".handle"
                v-bind="dragOptions"
                filter=".formsection"
                @start="start"
                @end="end">
                <drop-element
                    v-for="element in item.items"
                    :key="getKey(element)"
                    :parent-id="item.data.id"
                    :element="element"
                    :nested="true" />
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "Section",
    components: {
        draggable,
    },
    props: {
        item: {
            type: Object
        },
        putSectionArea: {
            type: Boolean
        }
    },
    computed: {
        getIdent () {
            return this.item?.data?.ident
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        clone(item) {
            return {
                type: 'formitem',
                items: [ item ]
            };
        },
        getKey(item) {
            if(item.type === 'formsection') {
                return item.data.id;
            } else {
                // formitem
                return item.items[0].data.id;
            }
        },
        start() {
            this.drag = true;
        },
        end() {
            this.drag = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.section-drop-area {
    min-height: 200px;
    border: 2px dashed #747474;
    background-color: #ededed;
}
.text-small {
    font-size: .785rem;
    letter-spacing: 0;
}
</style>