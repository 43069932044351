<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.shipping-method'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div v-if="element.items[0].data.answeroptionsclass === '' && element.items[0].data.answeroptionsfunction === ''" class="form-group">
                <label>{{ element.items[0].data.question }}</label>

                <div>
                    <div
                        v-for="(answer, index) in element.items[0].data.answeroptions"
                        :key="answer.id"
                        class="form-check">
                        <input
                            :id="`radio-${index}`"
                            class="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            value="option1">
                        <label class="form-check-label" :for="`radio-${index}`">
                            {{ answer.label }}
                        </label>
                    </div>
                </div>

                <input
                    v-if="element.items[0].data.customanswer"
                    type="text"
                    class="form-control mt-3"
                    :placeholder="element.items[0].data.customanswerplaceholder">

                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
            <div v-else>
                <div class="form-group">
                    <label>{{ element.items[0].data.question }}</label>
                </div>
                <div>{{ $translations['label.generatedby'] }} <code>{{ `${element.items[0].data.answeroptionsclass}::${element.items[0].data.answeroptionsfunction}` }}</code></div>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Shippingmethod",
    mixins: [previewItemMixin],
};
</script>