<template>
    <div>
        <div className="mb-5">
            <div className="h5 text-muted mb-3">
                {{ $translations['element.country'] }}
            </div>
            <header
                className="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-target="#section-content"
                data-toggle="collapse">
                {{ $translations['section.content'] }}
            </header>
            <section id="section-content" className="collapse">
                <form-checkbox
                    id="mandatory-checkbox"
                    v-model="item.data.mandatory"
                    :label="$translations['label.mandatory']"/>

                <form-input
                    v-if="item.data.mandatory"
                    id="warning-notification"
                    v-model="item.data.mandatory_warning"
                    :label="$translations['label.mandatory-warning']"/>

                <form-input
                    id="question"
                    v-model="item.data.question"
                    :label="$translations['label.label']"/>

                <form-textarea
                    id="description"
                    v-model="item.data.description"
                    :label="$translations['label.description']"/>

                <AnswerOptions v-if="item.data.answeroptionsclass === '' && item.data.answeroptionsfunction === ''" :answers="item.data.answeroptions" :item="item"/>
                <div v-else className="mt-4 mb-3">
                    Dynamisch generiert <code>{{ `${element.items[0].data.answeroptionsclass}::${element.items[0].data.answeroptionsfunction}` }}</code>
                </div>

                <form-checkbox
                    id="customanswer"
                    v-model="item.data.alphabeticalorder"
                    :label="$translations['label.alphabetical']"/>

                <form-checkbox
                    id="randomorder"
                    v-model="item.data.multiselect"
                    :label="$translations['label.multiselect']"/>
            </section>

            <header
                className="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-target="#section-dependencies"
                data-toggle="collapse">
                {{ $translations['section.dependencies'] }}
            </header>

            <LogicSection
                :item="item"
                :logic-options="logicOptions"
                @add-rule="addRule"
                @remove-rule="removeRule"
                @enable-logic="enableLogic"/>

            <header
                className="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-target="#section-advanced"
                data-toggle="collapse">
                {{ $translations['section.advanced'] }}
            </header>
            <section id="section-advanced" className="collapse">
                <form-input
                    id="unique-label"
                    v-model="item.data.uniqlabel"
                    :label="$translations['label.uniqlabel']"/>
                <form-input
                    id="htmloptions"
                    v-model="item.data.htmloptions"
                    :label="$translations['label.css-class']"/>
                <form-checkbox
                    id="html-purify"
                    v-model="item.data.htmlpurify"
                    :label="$translations['label.html-purify']"/>
                <form-input
                    id="customvalidation"
                    v-model="item.data.customvalidation"
                    :label="$translations['label.regex']"/>
                <form-input
                    id="customerrormessage"
                    v-model="item.data.customerrormessage"
                    :label="$translations['label.regex-warning']"/>
                <form-input
                    id="dynamic-value-class"
                    v-model="item.data.answeroptionsclass"
                    :label="$translations['label.class-for-default']"/>
                <form-input
                    id="dynamic-value-function"
                    v-model="item.data.answeroptionsfunction"
                    :label="$translations['label.function-for-default']"/>
            </section>
        </div>

        <div>
            <FooterActions @save="save"/>
        </div>
    </div>
</template>

<script>
import eventBus from "../../util/EventBus";
import FooterActions from "./parts/FooterActions.vue";

import formInput from "./formparts/textinput.vue";
import formCheckbox from "./formparts/checkbox.vue";
import formTextarea from "./formparts/textarea.vue";

import AnswerOptions from "./formparts/AnswerOptions.vue";

import LogicSection from "./formparts/LogicSection.vue";

import logicMixin from '../../mixins/logic';
import previewMixin from '../../mixins/preview';
import answersOptionsMixin from "../../mixins/answeroptions";

export default {
    name: "Country",
    components: {
        FooterActions,
        formInput,
        formTextarea,
        formCheckbox,
        AnswerOptions,
        LogicSection
    },
    mixins: [logicMixin, previewMixin, answersOptionsMixin],
    props: {
        element: {
            type: Object
        }
    },
    data: function () {
        return {
            item: {
                data: {
                    view: '',
                    id: '',
                    mandatory: false,
                    mandatory_warning: '',
                    question: '',
                    description: '',
                    answeroptions: [],
                    customanswer: false,
                    customanswerlabel: '',
                    customanswerplaceholder: '',
                    alphabeticalorder: false,
                    randomorder: false,

                    // extended form
                    uniqlabel: '',
                    htmloptions: '',
                    htmlpurify: false,
                    defaultvalueclass: '',
                    defaultvaluefunction: ''
                },
                logic: {}
            },
            stepOptions: []
        };
    },
    mounted() {
        // set step options
        for (let i = 3; i <= 10; i++) {
            this.stepOptions.push({value: i, label: `${i}`});
        }

        for (const key in this.element.items[0].data) {
            this.$set(this.item.data, key, this.element.items[0].data[key]);
        }

        this.$set(this.item, 'logic', this.element.items[0].logic);
    },
    methods: {
        save() {
            eventBus.$emit("saveElement", this.item);
        }
    }
};
</script>