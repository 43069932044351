var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card shadow mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$translations["element.section"]) + " "),
      _vm.getIdent
        ? _c("span", { staticClass: "text-small" }, [
            _vm._v("(ident:" + _vm._s(_vm.getIdent) + ")"),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c(
          "draggable",
          _vm._b(
            {
              staticClass: "section-drop-area p-3 rounded",
              attrs: {
                group: {
                  name: "structure",
                  pull: true,
                  put: _vm.putSectionArea,
                },
                list: _vm.item.items,
                handle: ".handle",
                filter: ".formsection",
              },
              on: { start: _vm.start, end: _vm.end },
            },
            "draggable",
            _vm.dragOptions,
            false
          ),
          _vm._l(_vm.item.items, function (element) {
            return _c("drop-element", {
              key: _vm.getKey(element),
              attrs: {
                "parent-id": _vm.item.data.id,
                element: element,
                nested: true,
              },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }