<template>
    <div>
        <div v-if="item">
            <div class="d-flex align-items-center">
                <select v-model="item.hide_show" class="form-control w-50 mr-2 mb-2">
                    <option value="hide">
                        {{ $translations['logic.hide'] }}
                    </option>
                    <option value="show">
                        {{ $translations['logic.show'] }}
                    </option>
                </select>
                <div>{{ $translations['logic.if-this.field'] }}</div>
            </div>
            <div class="d-flex align-items-center">
                <select v-model="item.and_or" class="form-control w-50 mr-2">
                    <option value="all">
                        {{ $translations['logic.all'] }}
                    </option>
                    <option value="any">
                        {{ $translations['logic.any'] }}
                    </option>
                </select>
                <div>{{ $translations['logic.conditions-met'] }}</div>
            </div>

            <hr>

            <div
                v-for="(rule,index) in item.rules"
                :key="`${index}-${rule.value}`"
                class="d-flex align-items-center mb-3">
                <div class="flex-grow-1 d-flex">
                    <select v-model="item.rules[index].source" class="form-control w-50">
                        <option
                            v-for="option in options"
                            :key="`${index}-${option.id}`"
                            :value="option.id">
                            {{ option.view }}
                        </option>
                    </select>
                    <select v-model="item.rules[index].compare" class="form-control w-25">
                        >
                        <option value="is">
                            {{ $translations['logic.is'] }}
                        </option>
                        <option value="isnot">
                            {{ $translations['logic.isnot'] }}
                        </option>
                    </select>
                    <select v-model="item.rules[index].value" class="form-control w-25">
                        >
                        <option
                            v-for="answer in getAnswers(rule.source)"
                            :key="`${index}-${answer.id}`"
                            :value="answer.id">
                            {{ answer.label }}
                        </option>
                    </select>
                </div>
                <div class="px-3 text-right">
                    <i class="fa fa-trash" @click="remove(index)" />
                </div>
            </div>

            <button
                type="button"
                class="btn btn-primary btn-block btn-sm"
                @click="add">
                {{ $translations['logic.addcondition'] }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogicEditor',
    props: {
        element: {
            type: Object
        },
        options: {
            type: Array
        },
        logic: {
            type: Object
        }
    },
    data: function() {
        return {
            item: null
        };
    },
    watch: {
        logic(newVal) {
            this.item = newVal;
        }
    },
    mounted() {
        this.item = this.logic;
    },
    methods: {
        getAnswers(id) {
            const index = this.options.findIndex(option => option.id === id);

            return index !== -1 ? this.options[index].answers : [];
        },
        add() {
            this.$emit('add-rule');
        },
        remove(index) {
            this.$emit('remove-rule', index);
        }
    }
};
</script>