var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    [
      _c("thead", [
        _c(
          "tr",
          [
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                "th",
                {
                  key: index,
                  class: [field.cssClass !== undefined ? field.cssClass : ""],
                },
                [
                  _c("ColumnHeader", {
                    attrs: { field: field, "sort-by": _vm.sortBy },
                    on: { sort: _vm.sort },
                  }),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("th", { staticClass: "fit-column" }, [
              _vm._v("\n      " + _vm._s(_vm.translations.status) + "\n    "),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "fit-column" }, [
              _vm.productCatalogActive
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-outline-secondary",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.selectAll },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.select_all) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.sortable
        ? [
            _c(
              "draggable",
              {
                attrs: {
                  animation: 200,
                  list: _vm.items,
                  draggable: ".is-draggable",
                  handle: ".handle",
                  tag: "tbody",
                },
              },
              _vm._l(_vm.items, function (item, i) {
                return _c(
                  "tr",
                  { key: item.id, staticClass: "is-draggable" },
                  [
                    _vm._l(_vm.fields, function (field, index) {
                      return _c(
                        "td",
                        {
                          key: `item-${index}`,
                          class: [
                            field.cssClass !== undefined ? field.cssClass : "",
                          ],
                        },
                        [
                          _c(field.component, {
                            tag: "component",
                            attrs: { field: field, item: item },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "fit-column" }, [
                      (item.productData ? item.productData.status : 0) ||
                      item.status == 1
                        ? _c(
                            "span",
                            { staticClass: "badge badge-alternate-success" },
                            [
                              _vm._v(
                                _vm._s(_vm.translations.active) + "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "badge badge-alternate-warning" },
                            [_vm._v(_vm._s(_vm.translations.inactive))]
                          ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "fit-column" },
                      [
                        _vm._t("default", null, {
                          item: item,
                          order: (i + 1) * 10,
                        }),
                      ],
                      2
                    ),
                  ],
                  2
                )
              }),
              0
            ),
          ]
        : [
            _c(
              "tbody",
              _vm._l(_vm.items, function (item, i) {
                return _c(
                  "tr",
                  { key: item.id },
                  [
                    _vm._l(_vm.fields, function (field, index) {
                      return _c(
                        "td",
                        {
                          key: `item-${index}`,
                          class: [
                            field.cssClass !== undefined ? field.cssClass : "",
                          ],
                        },
                        [
                          _c(field.component, {
                            tag: "component",
                            attrs: { field: field, item: item },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "fit-column" }, [
                      item.status
                        ? _c(
                            "span",
                            { staticClass: "badge badge-alternate-success" },
                            [_vm._v(_vm._s(_vm.translations.active))]
                          )
                        : _c(
                            "span",
                            { staticClass: "badge badge-alternate-warning" },
                            [_vm._v(_vm._s(_vm.translations.inactive))]
                          ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "fit-column" },
                      [
                        _vm._t("default", null, {
                          item: item,
                          order: (i + 1) * 10,
                        }),
                      ],
                      2
                    ),
                  ],
                  2
                )
              }),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }