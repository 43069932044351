<template>
    <div class="font-weight-bold">{{ field.accessor(item) }}</div>
</template>

<script>
export default {
    name: 'TextBold',
    props: {
        field: {
            type: Object
        },
        item: {
            type: Object
        }
    }
};
</script>