var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "product-details item-info",
      class: { "--margin-top": _vm.marginTop },
    },
    [
      _c("div", { staticClass: "item-name", class: _vm.fontSize }, [
        _vm._v(
          "\n        " + _vm._s(_setup.props.item.productData.title) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _setup.props.item.productData.customSku
        ? _c("div", { staticClass: "item-sku", class: _vm.fontSize }, [
            _vm._v(
              "\n        " +
                _vm._s(_setup.props.item.productData.customSku) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "price-placeholder" }, [
        _vm._v("$ X.XX | $ X.XX RRP "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-center w-100" }, [
        _c(
          "div",
          { staticClass: "color-container d-flex align-items-center" },
          [
            _c(_setup.ColorDots, { attrs: { colors: _setup.colors } }),
            _vm._v(" "),
            _c("div", { staticClass: "color-title" }, [
              _vm._v(_vm._s(_setup.props.item.productData.color.title)),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }