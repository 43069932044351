<template>
    <div class="top-bar d-flex justify-content-between w-100">
        <div>Einstellungen</div>
        <div class="d-flex">
            <div class="toggle">
                <div class="custom-control custom-switch">
                    <input type="checkbox" id="ShopCategory_search" class="custom-control-input" :name="`${inputNamePrefix}[filters][search][displayOnTop]`" value="1" :checked="isChecked('search')">
                    <label class="custom-control-label" for="ShopCategory_search">Suche</label>
                </div>
            </div>

            <div v-for="(filter, index) in filtersArray" class="toggle">
                <div class="custom-control custom-switch">
                    <input type="checkbox" :id="`ShopCategory_${filter.attribute}`" class="custom-control-input" :name="`${inputNamePrefix}[filters][${filter.attribute}][displayOnTop]`" value="1" :checked="isChecked(filter.attribute)">
                    <label class="custom-control-label" :for="`ShopCategory_${filter.attribute}`">{{  filter.label  }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    props: {
        filters: {
            type: Object,
            default: null
        },
        active: {
            type: Array
        },
        shopCategoryId: {
            type: Number
        },
        inputNamePrefix: {
            type: String
        }
    },
    computed: {
        filtersArray() {
            if(this.filters === null) {
                return [];
            }

            const list = [];

            Object.keys(this.filters).forEach(key => {
                if(this.filters[key].type === 'boolean') {
                    list.push(this.filters[key]);
                }
            });

            return list;
        }
    },
    methods: {
        isChecked(attribute) {
            let selected = false;

            this.active.forEach(active => {
                if(active.filterId === attribute && active.displayOnTop === 1) {
                    selected = true
                }
            });

            return selected;
        }
    }
}
</script>

<style lang="scss" scoped>
.top-bar {
    margin-bottom: 30px;
}
.toggle {
    margin-right: 40px;
}
</style>