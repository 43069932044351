import Vue from 'vue';
import Unsplash from '../../views/mediamanager/modals/unsplash.vue';

window.addEventListener('DOMContentLoaded', (event) => {
    $(function () {

        let $modal = $("#unsplash_modal");

        if ($modal.length) {
            new Vue({
                el: '#unsplash_modal',
                components: {Unsplash},
            });
        }

    });
});