var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-settings__sidebar" },
    [
      _vm.filters
        ? _c(
            "draggable",
            {
              attrs: {
                group: { name: "filter", pull: "clone", put: false },
                sort: false,
                clone: _vm.clone,
                list: _vm.filtersArray,
                animation: 150,
                "component-data": _vm.getComponentData(),
              },
            },
            _vm._l(_vm.filtersArray, function (value, key) {
              return _c("div", { key: key, staticClass: "filter" }, [
                _c("div", { staticClass: "handle" }),
                _vm._v("\n            " + _vm._s(value.label) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }