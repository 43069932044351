<template>
    <transition name="fade-slide-up">
        <div class="detail-box-blurred" v-if="(props.caption || props.title || props.subtitle) && isVisible">
            <div v-if="props.caption" class="caption">{{props.caption}}</div>
            <div v-if="props.title" class="title">{{props.title}}</div>
            <div v-if="props.subtitle" class="subtitle">{{props.subtitle}}</div>
        </div>
    </transition>
</template>

<script setup>
import { defineProps, ref, onMounted } from 'vue'

const isVisible = ref(false)

const props = defineProps({
    caption: {
        type: String
    },
    title: {
        type:  String
    },
    subtitle: {
        type: String
    }
})

const toggleFadeTransition = () => {
    isVisible.value = !isVisible.value;
}

onMounted(()=>{
    toggleFadeTransition()
})
</script>

<style scoped lang="scss">
.fade-slide-up-enter-active, .fade-slide-up-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}
.fade-slide-up-enter, .fade-slide-up-leave-to /* .fade-slide-up-leave-active in <2.1.8 */ {
    opacity: 0;
    transform: translateY(20px); /* Adjust the value as needed */
}
.fade-slide-up-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.detail-box-blurred {
    position: absolute;
    background: rgba(0, 0, 0, 0.10);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    color: white;
    padding: 7%;
    width: 80%;
    left: 5%;
    bottom: 5%;
    line-height: 1;
    display: flex;
    flex-direction: column;
    gap: 0.55vw;


    .caption {
        color: #fff;
        opacity: 0.5;
        font-size: 0.725vw;
        font-weight: 400;
    }

    .title {
        color: #FFF;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.08;
        font-family: Inter-Regular;
    }

    .subtitle {
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #fff;
        line-height: 1;
        color: #fff;
        opacity: 0.5;
    }
}
</style>