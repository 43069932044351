var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "video-player fullscreen" }, [
    _c(
      "video",
      {
        key: _setup.props.content.id,
        ref: "player",
        staticClass: "fullscreen",
        style: _vm.fillScreen
          ? "width: 100% !important; height: auto !important"
          : "",
        attrs: {
          crossorigin: "use-credentials",
          autoplay: "",
          loop: _setup.props.loop,
        },
        domProps: { muted: _setup.mute },
      },
      [
        _c("source", {
          key: _setup.props.content.id,
          attrs: {
            src: _setup.getAssetPathOfTargetAttr(
              _setup.props.targetAttr || "video",
              _setup.props.content
            ),
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }