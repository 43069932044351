<template>
  <transition :name="transitionName">
    <div
      class="bg--image"
      :class="[imageClass, imageState]"
      :style="computedStyle"
      :data-width="imageWidth"
      :data-height="imageHeight"
      :data-state="imageState" />
  </transition>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits, onMounted, nextTick } from 'vue'

const props = defineProps({
    src: {
        type: String,
        default: ''
    },
    transition: {
        type: String,
        default: 'fade'
    },
    styles: {
        type: Object
    },
    imageClass: {
        type: String,
        required: false,
        default: ''
    },
    backgroundSize: {
        type: String,
        required: false,
        default: 'cover'
    }
})

const emit = defineEmits([ 'image-loaded' ])

const imageWidth = ref(0)

const imageHeight = ref(0)

const imageState = ref('loading')

const asyncImage = ref(new Image())

const transitionName = computed(() => {
    return props.transition
})

const computedStyle = computed(() => {
    if (imageState.value === 'loaded') {
        return 'background-image: url(' + asyncImage.value.src + '); background-size: ' + props.backgroundSize
    }
    return ''
})

function fetchImage(url) {
    asyncImage.value.onload = imageOnLoad
    asyncImage.value.onerror = imageOnError
    imageState.value = 'loading'
    asyncImage.value.src = props.src
}

function imageOnLoad(success) {
    imageState.value = 'loaded'
    imageWidth.value = asyncImage.value.naturalWidth
    imageHeight.value = asyncImage.value.naturalHeight
    emit('image-loaded')
}
function imageOnError(error) {
    console.log('src', props.src)
    console.log(error)
    imageState.value = 'error'
}

onMounted(() => {
    nextTick(() => {
        fetchImage()
    })
})
</script>

<style scoped lang="scss">
.bg--image {
    position: absolute;
    inset: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
    transition: all 0.5s ease-in-out;
}
.fade-up-enter-from,
.fade-up-leave-to {
    opacity: 0;
    transform: rotate(20deg) translateY(200px);
}
</style>
