"use strict";
/*
some util & math functions
copied from http://perfectionkills.com/exploring-canvas-drawing-techniques/
and https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
*/

import {fabric} from 'fabric';

function distanceBetween(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}
function angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
}
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export let _borders = {
    border1: {
        corners: "./assets/mediaeditor/sticker/border/border1.svg",
        sides: "./assets/mediaeditor/sticker/border/border1.svg"
    }
};

let PaintCanvasBorder = function(canvas, insertAfter){
    this.c = document.createElement("canvas");
    this.c.className = "borderCanvas";

    this.c.width = canvas.width;
    this.c.height = canvas.height;

    $(this.c).insertAfter(insertAfter);

    this.canvas = new fabric.Canvas(this.c);
    this.canvas.selection = false;

    this.border = null;
    this.borderWidth = 50;

    this.updateSize = function(w,h){
        this.c.width = w;
        this.c.height = h;
        this.canvas.setDimensions({width:w, height:h});

        this.recalculateBorder();
        this.redraw();
    };

    this.changeBorderWidth = function(w){
        this.borderWidth = w;

        this.recalculateBorder();
        this.redraw();
    };

    this.changeBorder = function(border){
        this.border = border;

        var that = this;
        this.loadBorderImages(function(){
            that.recalculateBorder();
            that.redraw();
        });
    };

    this.loadBorderImages = function(callback){
        var toLoad = 8;
        var loaded = 0;

        this.corners.tl.loadImg(this.border.corners, loadCallback);
        this.corners.tr.loadImg(this.border.corners, loadCallback);
        this.corners.bl.loadImg(this.border.corners, loadCallback);
        this.corners.br.loadImg(this.border.corners, loadCallback);

        this.sides.t.loadImg(this.border.sides, loadCallback);
        this.sides.r.loadImg(this.border.sides, loadCallback);
        this.sides.b.loadImg(this.border.sides, loadCallback);
        this.sides.l.loadImg(this.border.sides, loadCallback);

        function loadCallback(){
            loaded++;
            if(loaded >= toLoad){
                if(typeof(callback) === "function") callback();
            }
        }
    };

    // ???



    this.redraw = function(){
        this.canvas.clear();

        this.corners.bl.draw(this.canvas);
        this.corners.br.draw(this.canvas);
        this.corners.tl.draw(this.canvas);
        this.corners.tr.draw(this.canvas);

        this.sides.b.draw(this.canvas);
        this.sides.t.draw(this.canvas);
        this.sides.l.draw(this.canvas);
        this.sides.r.draw(this.canvas);
    };





    this.corners = {
        tl: new BorderElement(0,0,null,0,this.borderWidth),
        tr: new BorderElement(0,0,null,0,this.borderWidth),
        bl: new BorderElement(0,0,null,0,this.borderWidth),
        br: new BorderElement(0,0,null,0,this.borderWidth)
    };

    this.sides = {
        t: new BorderElement(0,0,null,0,this.borderWidth),
        r: new BorderElement(0,0,null,0,this.borderWidth),
        b: new BorderElement(0,0,null,0,this.borderWidth),
        l: new BorderElement(0,0,null,0,this.borderWidth)
    };

    this.recalculateBorder = function(){
        //top-left corner
        this.corners.tl.width = this.corners.tl.height = this.borderWidth;
        //top-right corner
        this.corners.tr.x = this.c.width - this.borderWidth;
        this.corners.tr.width = this.corners.tr.height = this.borderWidth;
        // bottom-left corner
        this.corners.bl.y = this.c.height - this.borderWidth;
        this.corners.bl.width = this.corners.bl.height = this.borderWidth;
        // bottom-right corner
        this.corners.br.x = this.c.width - this.borderWidth;
        this.corners.br.y = this.c.height - this.borderWidth;
        this.corners.br.width = this.corners.br.height = this.borderWidth;

        // top border
        this.sides.t.x = this.borderWidth;
        this.sides.t.height = this.borderWidth;
        this.sides.t.width = this.c.width - (this.borderWidth*2);
        // right border
        this.sides.r.x = this.c.width - this.borderWidth;
        this.sides.r.y = this.borderWidth;
        this.sides.r.width = this.borderWidth;
        this.sides.r.height = this.c.height - (this.borderWidth*2);
        // bottom border
        this.sides.b.x = this.borderWidth;
        this.sides.b.y = this.c.height - this.borderWidth;
        this.sides.b.height = this.borderWidth;
        this.sides.b.width = this.c.width - (this.borderWidth*2);
        // left border
        this.sides.l.y = this.borderWidth;
        this.sides.l.width = this.borderWidth;
        this.sides.l.height = this.c.height - (this.borderWidth*2);
    };
    this.recalculateBorder();

    function BorderElement(x,y,img,rotation,borderWidth){
        this.x = x || 0;
        this.y = y || 0;
        this.img = img || null;
        this.rotation = rotation || 0;

        this.width = borderWidth || 0;
        this.height = borderWidth || 0;

        this.fabricObj = null;

        this.loadImg = function(src,callback){
            this.img = src;
            var that = this;
            fabric.loadSVGFromURL(src, function(objects, options) {
                that.fabricObj = fabric.util.groupSVGElements(objects, options);
                if(typeof(callback) === "function") callback();
            });
        };

        this.draw = function(canvas){
            var top = this.y;
            var left = this.x;
            var w = this.width;
            var h = this.height;
            var angle = this.rotation;
            var obj = this.fabricObj;

            if(obj){
                obj.set("scaleX", w / obj.width);
                obj.set("scaleY", h / obj.height);

                obj.top = top;
                obj.left = left;
                obj.angle = angle;
                obj.selectable = false;
                obj.hoverCursor = "default";
                canvas.add(obj).renderAll();
            }
        };
    }

};


export function PaintCanvas(media){
    this.media = media;
    this.canvas = document.createElement("canvas");
    this.fabricCanvas = document.createElement("canvas");
    this.ctx = this.canvas.getContext("2d");
    this.initialized = false;

    this.fabric = false;
    this.fabricImage = false;

    this.border = null;
    this.borderUpdated = false;

    this.fabricHandleStyle = {
        padding: 10,
        cornerStyle: "circle",
        cornerSize: 15,
        cornerColor: 'rgba(255,255,255,0.5)',
        cornerStrokeColor: "white",
        transparentCorners: false,
        borderColor: "white",
        borderDashArray: [3, 3]
    };

    this.initFabric = function(){
        if(!this.fabric) {
            $(this.fabricCanvas).show();
            this.fabric = new fabric.Canvas(this.fabricCanvas);

            $(this.fabricCanvas).parent().hide();
        }
    };

    this.startFabric = function(){
        this.stopDrawing();
        this.redraw(true);  // redraw the canvas without the fabric overlay

        // initialising the fabric canvas once
        this.initFabric();

        $(this.fabricCanvas).parent().show();
        $(this.border.c).parent().hide();

        var that = this;
        this.updateBorder();

        // delete-button event to remove fabric objects
        $(window).keydown(function(e) {
            if(that.fabric.getActiveObject() && $(that.fabricCanvas).parent().is(":visible")){
                switch (e.keyCode) {
                    case 46: // delete
                        if(!that.fabric._activeObject || !that.fabric._activeObject.isEditing){
                            var obj = that.fabric.getActiveObject();
                            that.fabric.remove(obj);
                            that.fabric.renderAll();
                            return false;
                        }
                }
            }
            return;
        });

    };

    this.stopFabric = function(callback){
        var that = this;
        if(this.fabric){
            this.updateBorder(false,function(){
                $(that.fabricCanvas).parent().hide();

                that.fabricImage = document.createElement("img");
                that.fabricImage.src = that.fabric.toDataURL();
                $(that.fabricImage).on("load", function(){
                    that.redraw();
                    if(typeof(callback) === "function") callback(this);
                });
            });
        }

        //$(window).keydown(function(e) {return;});

    };

    this.addImage = function(src,svg,params){
        this.media.unsavedChanges = true;
        var that = this;
        if(this.fabric){
            if(svg){
                fabric.loadSVGFromURL(src, function(objects, options) {
                    var obj = fabric.util.groupSVGElements(objects, options);
                    addImageCallback(obj,{});
                });
            } else {
                fabric.Image.fromURL(src, function(obj) {
                    addImageCallback(obj,{});
                });
            }
        }

        function addImageCallback(obj, options){
            obj.scale((Math.max(that.canvas.width, that.canvas.height) / obj.width) * 0.33);
            obj.top = that.canvas.height / 3;
            obj.left = that.canvas.width / 3;
            obj.controlsAboveOverlay = true;
            obj.lockUniScaling = true;

            obj.set(that.fabricHandleStyle);

            that.fabric.add(obj).setActiveObject(obj).renderAll();
        }
    };

    this.changeBorder = function(border){
        this.media.unsavedChanges = true;
        this.borderUpdated = false;
        this.border.changeBorder(border);
    };

    this.changeBorderWidth = function(w){
        this.media.unsavedChanges = true;
        this.borderUpdated = false;
        this.border.changeBorderWidth(w);
    };

    this.updateBorder = function(hide,callback){
        if(hide){
            //this.borderUpdated = false;
            this.fabric.setOverlayImage(null).renderAll();
            if(typeof(callback) === "function") callback();
        } else if(this.border.canvas.getObjects().length > 0 && !this.borderUpdated){
            var that = this;
            fabric.Image.fromURL(this.border.canvas.toDataURL(), function(img){
                that.fabric.setOverlayImage(img, function(){
                    that.borderUpdated = true;
                    that.fabric.renderAll();
                    $(that.border.c).parent().hide();
                    if(typeof(callback) === "function") callback();
                });
            });
        } else {
            if(typeof(callback) === "function") callback();
        }
    };

    this.addTextbox = function(text,options){
        this.media.unsavedChanges = true;
        var that = this;
        if(this.fabric){
            var textbox = new fabric.Textbox(text,options);
            textbox.controlsAboveOverlay = true;
            textbox.set(this.fabricHandleStyle);
            textbox.setControlsVisibility({
                mt: false,
                mb: false
            });
            this.fabric.add(textbox).setActiveObject(textbox);
        }
    };


    this.init = function(canvas){
        if(!this.initialized && canvas){

            //console.log("source-canvas(gl) "+canvas.width+"x"+canvas.height);

            this.canvas.width = this.fabricCanvas.width = canvas.width;
            this.canvas.height = this.fabricCanvas.height = canvas.height;
            this.initContext();
            this.canvas.classList.add("paintCanvas");
            this.fabricCanvas.classList.add("fabricCanvas");
            this.canvas.style = this.fabricCanvas.style = canvas.style;
            this.fabricCanvas.style.display = "none";
            $(this.canvas).insertAfter(canvas);
            $(this.fabricCanvas).insertAfter(this.canvas);
            this.border = new PaintCanvasBorder(this.canvas,this.fabricCanvas);
            //$(this.border.c).insertAfter(this.fabricCanvas);
            this.initialized = true;
        }
    };

    this.updateSize = function(w,h){
        this.canvas.width = w;
        this.canvas.height = h;
        this.border.updateSize(w,h);
        this.initContext();
        this.redraw();
        if(this.fabric) this.fabric.setDimensions({width: w, height: h});
    };

    // sadly the context needs to be reinitialized every time something about the canvas changes (updateSize)
    this.initContext = function(){
        this.ctx.lineJoin = this.ctx.lineCap = "round";
        this.ctx.strokeStyle = this.currentElement.strokeStyle;
        this.ctx.lineWidth = this.currentElement.lineWidth;
        this.ctx.globalAlpha = this.currentElement.globalAlpha;
    };

    this.paint = false;             // currently painting (mousedown) or not

    this.drawnElements = [];        // all elements (lines) that have already been drawn before
    this.currentElement = {         // the element that is currently being drawn
        drawStyle: "default",       // "default", "smooth", "brush" ...
        brush: null,                // Brush Object, if drawStyle = "brush"
        points: [],
        strokeStyle: "#111c9d",     // color
        lineWidth: 20,              // brush size
        globalAlpha: 1              // opacity
    };



    this.addPoint = function(point){
        this.currentElement.points.push(point);
    };



    this.drawElement = function(element){
        switch(element.drawStyle){
            case "default":
                this.drawLine(element);
                break;
            case "smooth":
                this.drawSmoothLine(element);
                break;
            case "brush":
                this.brushLine(element);
                break;
        }
    };


    // drawing method for a simple line (drawStyle = "default")
    // using native path, line and stroke() methods
    //
    // this method first defines a complete path with all points and only draws the completed line once,
    // allowing better use of opacity
    //
    // from: http://perfectionkills.com/exploring-canvas-drawing-techniques/
    this.drawLine = function(element){
        if(element.points.length>0){

            this.ctx.globalAlpha = element.globalAlpha;
            this.ctx.strokeStyle = element.strokeStyle;
            this.ctx.lineWidth = element.lineWidth;

            this.ctx.beginPath();
            this.ctx.moveTo(element.points[0].x, element.points[0].y);
            for(var i=0; i < element.points.length; i++) {
                this.ctx.lineTo(element.points[i].x, element.points[i].y);
            }
            this.ctx.stroke();
            this.ctx.closePath();
        }
    };

    // drawing method for a smoothed line (drawStyle = "smooth")
    // drawing rounded rectangles at each point with gradients, instead of Paths/Lines
    //
    // this method generates much smoother, more natural looking edges but doesnt work well with opacity,
    // since it just keeps drawing multiple elements on top of each other
    //
    // from: http://perfectionkills.com/exploring-canvas-drawing-techniques/
    this.drawSmoothLine = function(element){
        if(element.points.length>0){
            this.ctx.globalAlpha = element.globalAlpha;

            this.ctx.moveTo(element.points[0].x, element.points[0].y);
            for(var i=0; i < element.points.length; i++) {
                var dist = distanceBetween(element.points[i], element.points[i+1] || element.points[i]);
                var angle = angleBetween(element.points[i], element.points[i+1] || element.points[i]);
                for (var j = 0; j < dist; j++) {
                    var x = element.points[i].x + (Math.sin(angle) * j);
                    var y = element.points[i].y + (Math.cos(angle) * j);
                    var radgrad = this.ctx.createRadialGradient(x,y,element.lineWidth/4,x,y,element.lineWidth/2);
                    radgrad.addColorStop(0, element.strokeStyle);
                    radgrad.addColorStop(0.5, hexToRGB(element.strokeStyle,"0.5"));
                    radgrad.addColorStop(1, hexToRGB(element.strokeStyle,"0"));
                    this.ctx.fillStyle = radgrad;
                    this.ctx.fillRect(x-(element.lineWidth/2), y-(element.lineWidth/2), element.lineWidth, element.lineWidth);
                }
            }
        }
    };


    // used to draw brush elements (images) in the correct selected color, instead of the original colors of the image
    // when drawing with a brush, this canvas will be used to draw with instead of the original brush-image
    this.tintedBrush = document.createElement("canvas");

    // initialise the tinted Brush only once per drawn element with the correct color and correct brush-width
    this.initTintedBrush = function(img,color,w,h){
        if(typeof(h) === "undefined") h = w;

        this.tintedBrush.width = w;
        this.tintedBrush.height = h;
        var ctx = this.tintedBrush.getContext("2d");

        // fill with tint color
        ctx.fillStyle = color;
        ctx.fillRect(0,0,w,h);
        // apply alpha channel of the image to the canvas
        ctx.globalCompositeOperation = "destination-atop";
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w, h);
    };

    // drawing method for using brushes (drawStyle = "brush")
    // using a predefined image (brush "mask" or "stamp") to draw on every point.
    //
    // also see methods above initTintedBrush
    //
    // from: http://perfectionkills.com/exploring-canvas-drawing-techniques/
    this.brushLine = function(element){
        var points = element.points;
        if(points.length>0){
            this.initTintedBrush(element.brush.img,element.strokeStyle,element.lineWidth);
            this.ctx.globalAlpha = element.globalAlpha;

            var interval = element.brush.interval;
            for(var i=0; i < points.length; i++) {
                var dist = 0;
                var angle = 0;
                if(element.brush.fillPoints){
                    dist = distanceBetween(points[i], points[i+1] || points[i]);
                    angle = angleBetween(points[i], points[i+1] || points[i]);
                }

                for (var j = 0; j <= dist; j++) {
                    interval++;
                    if(interval >= element.brush.interval){
                        var x = points[i].x + (Math.sin(angle) * j) - (element.brush.cursorOffsetX * element.lineWidth);
                        var y = points[i].y + (Math.cos(angle) * j) - (element.brush.cursorOffsetY * element.lineWidth);

                        this.ctx.save();

                        if(points[i].opacity){
                            this.ctx.globalAlpha = points[i].opacity;
                        }
                        if(points[i].rotation){
                            this.ctx.translate(x, y);
                            this.ctx.rotate(points[i].rotation);
                            x = 0;
                            y = 0;
                        }

                        var w = this.tintedBrush.width;
                        var h = this.tintedBrush.height;
                        var w2 = (points[i].radius) ? w * points[i].radius : w;
                        var h2 = (points[i].radius) ? h * points[i].radius : h;
                        this.ctx.drawImage(this.tintedBrush,0,0,w,h,x,y,w2,h2);

                        this.ctx.restore();
                        interval = 0;
                    }
                }
            }
        }
    };


    this.redraw = function(noFabric){
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height); // Clears the canvas

        //this.initContext();

        // draw all previously drawn lines
        for(var i = 0; i < this.drawnElements.length; i++){
            this.drawElement(this.drawnElements[i]);
        }

        // draw the currently drawn line
        this.drawElement(this.currentElement);

        // draw the fabric-canvas on top
        if(!noFabric && this.fabricImage){
            this.ctx.drawImage(this.fabricImage,0,0);
        }

    };


    // enable mouse listeners on the canvas to draw
    this.startDrawing = function(){

        this.stopFabric();

        $(this.border.c).parent().hide();

        // disabled zoom-drag
        if($('.previewZoomWrapper').data('ui-draggable'))
            $(".previewZoomWrapper").draggable("disable");


        var that = this;
        this.redraw();

        $(this.canvas).off("mousedown").on("mousedown", function(e){
            that.paint = true;
            that.addPoint(getRelativeCoords(e));
            that.redraw();
        });

        $(this.canvas).off("mouseup mouseleave").on("mouseup mouseleave", function(e){
            //in case mouseleave triggers while not drawing
            if(that.paint){
                that.drawnElements.push(Object.assign({},that.currentElement));    // save the complete drawn element (copy, NOT reference)
                that.currentElement.points = [];    // clear the points
                that.media.unsavedChanges = true;
            }
            that.paint = false;
        });

        $(this.canvas).off("mousemove").on("mousemove", function(e){
            if(that.paint){
                that.addPoint(getRelativeCoords(e));
                that.redraw();
            }
        });


        function getRelativeCoords(event) {
            var point = {x: event.offsetX || event.layerX, y: event.offsetY || event.layerY};

            // adjust x,y and add possible other attributes for this point, if they should be random for a brush
            var el = that.currentElement;
            if (el.drawStyle === "brush"){
                if (el.brush.varSpread !== 0) {
                    point.x += getRandomInt(-el.brush.varSpread, el.brush.varSpread);
                    point.y += getRandomInt(-el.brush.varSpread, el.brush.varSpread);
                }
                if(el.brush.varRotation){
                    point.rotation = Math.PI * 180 / getRandomInt(0, 180);
                }
                if(el.brush.varOpacity){
                    point.opacity = Math.random();
                }
                if(el.brush.varRadius){
                    point.radius = Math.random()+0.5;
                }
            }

            return point;
        }
    };

    // disable all mouse listeners on the canvas
    this.stopDrawing = function(){
        $(this.canvas).off("mousedown mouseup mouseleave mousemove");
        this.redraw();

        // re-enable zoom-drag
        if($('.previewZoomWrapper').data('ui-draggable'))
            $(".previewZoomWrapper").draggable("enable");
    };

    this.clearCanvas = function(){
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height); // Clears the canvas
        this.drawnElements = [];
        this.currentElement.points = [];
    };

}