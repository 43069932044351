var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "colorpicker" },
    [
      _c(
        "swatches",
        _vm._b(
          {
            ref: "swatches",
            attrs: { swatches: _vm.swatches },
            model: {
              value: _vm.color,
              callback: function ($$v) {
                _vm.color = $$v
              },
              expression: "color",
            },
          },
          "swatches",
          _vm.options,
          false
        )
      ),
      _vm._v(" "),
      _c("input", {
        ref: "inputColor",
        class: _vm.inputClass,
        staticStyle: { display: "none" },
        attrs: {
          slot: "trigger",
          placeholder: _vm.inputPlaceholder,
          title: _vm.inputValue,
          type: "text",
        },
        domProps: { value: _vm.color },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.setColor()
          },
        },
        slot: "trigger",
      }),
      _vm._v(" "),
      _vm.color == ""
        ? _c("span", { staticClass: "label" }, [
            _vm._v(" " + _vm._s(_vm.translations.select_color) + " "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.color !== ""
        ? _c(
            "div",
            {
              staticClass: "colorpicker__reset",
              on: { click: _vm.resetColor },
            },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.translations.reset_color)),
              ]),
              _vm._v(" "),
              _c("i", { staticClass: "fontello-icon-close" }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { name: _vm.inputName, "aria-invalid": "false", type: "hidden" },
        domProps: { value: _vm.color },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }