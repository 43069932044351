var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _c("div", { staticClass: "h5 text-muted mb-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$translations["element.numericscale"]) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-content",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.content"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-content" } },
          [
            _c("form-checkbox", {
              attrs: {
                id: "mandatory-checkbox",
                label: _vm.$translations["label.mandatory"],
              },
              model: {
                value: _vm.item.data.mandatory,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "mandatory", $$v)
                },
                expression: "item.data.mandatory",
              },
            }),
            _vm._v(" "),
            _vm.item.data.mandatory
              ? _c("form-input", {
                  attrs: {
                    id: "warning-notification",
                    label: _vm.$translations["label.mandatory-warning"],
                  },
                  model: {
                    value: _vm.item.data.mandatory_warning,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "mandatory_warning", $$v)
                    },
                    expression: "item.data.mandatory_warning",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "question",
                label: _vm.$translations["label.label"],
              },
              model: {
                value: _vm.item.data.question,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "question", $$v)
                },
                expression: "item.data.question",
              },
            }),
            _vm._v(" "),
            _c("form-textarea", {
              attrs: {
                id: "description",
                label: _vm.$translations["label.description"],
              },
              model: {
                value: _vm.item.data.description,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "description", $$v)
                },
                expression: "item.data.description",
              },
            }),
            _vm._v(" "),
            _c("form-select", {
              attrs: {
                id: "steps",
                label: _vm.$translations["label.steps"],
                options: _vm.stepOptions,
              },
              model: {
                value: _vm.item.data.scaleSteps,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "scaleSteps", $$v)
                },
                expression: "item.data.scaleSteps",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "scaledefaultselected",
                label: _vm.$translations["label.default-value"],
                type: "number",
              },
              model: {
                value: _vm.item.data.scaledefaultselected,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "scaledefaultselected", $$v)
                },
                expression: "item.data.scaledefaultselected",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "scaleMinLabel",
                label: _vm.$translations["label.min"],
              },
              model: {
                value: _vm.item.data.scaleMinLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "scaleMinLabel", $$v)
                },
                expression: "item.data.scaleMinLabel",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "scaleMaxLabel",
                label: _vm.$translations["label.max"],
              },
              model: {
                value: _vm.item.data.scaleMaxLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "scaleMaxLabel", $$v)
                },
                expression: "item.data.scaleMaxLabel",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-dependencies",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.dependencies"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("LogicSection", {
          attrs: { item: _vm.item, "logic-options": _vm.logicOptions },
          on: {
            "add-rule": _vm.addRule,
            "remove-rule": _vm.removeRule,
            "enable-logic": _vm.enableLogic,
          },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-advanced",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.advanced"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-advanced" } },
          [
            _c("form-input", {
              attrs: {
                id: "unique-label",
                label: _vm.$translations["label.uniqlabel"],
              },
              model: {
                value: _vm.item.data.uniqlabel,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "uniqlabel", $$v)
                },
                expression: "item.data.uniqlabel",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "htmloptions",
                label: _vm.$translations["label.css-class"],
              },
              model: {
                value: _vm.item.data.htmloptions,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmloptions", $$v)
                },
                expression: "item.data.htmloptions",
              },
            }),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: {
                id: "html-purify",
                label: _vm.$translations["label.html-purify"],
              },
              model: {
                value: _vm.item.data.htmlpurify,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmlpurify", $$v)
                },
                expression: "item.data.htmlpurify",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-class",
                label: _vm.$translations["label.class-for-default"],
              },
              model: {
                value: _vm.item.data.defaultvalueclass,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "defaultvalueclass", $$v)
                },
                expression: "item.data.defaultvalueclass",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-function",
                label: _vm.$translations["label.function-for-default"],
              },
              model: {
                value: _vm.item.data.defaultvaluefunction,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "defaultvaluefunction", $$v)
                },
                expression: "item.data.defaultvaluefunction",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [_c("FooterActions", { on: { save: _vm.save } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }