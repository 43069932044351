import { getMarkRange } from 'tiptap-utils'

/**
 * If selection is empty, a new text node is created to add mark
 * @param type
 * @param attrs
 * @returns {function(*, *): *}
 */
export default function updateLink(type, attrs) {
    return (state, dispatch) => {
        const { tr, selection, doc, schema} = state;
        let { from, to } = selection;
        const { $from, empty } = selection;

        if (empty) {
            const range = getMarkRange($from, type);

            from = range.from;
            to = range.to
        }

        const hasMark = doc.rangeHasMark(from, to, type);

        if (hasMark) {
            tr.removeMark(from, to, type)
        }

        if (empty) {
            if (!attrs.value) {
                attrs.value = attrs.href;
            }

            const node = schema.text(attrs.value, false);
            tr.insert(selection.from, node);
            tr.addMark(selection.from, parseInt(selection.from )+parseInt(attrs.value.length), type.create(attrs));
        } else {
            tr.addMark(from, to, type.create(attrs));
        }

        return dispatch(tr)
    }
}