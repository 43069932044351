var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    _vm._b(
      {
        attrs: {
          handle: ".handle",
          tag: "ul",
          list: _vm.query,
          group: { name: "fields", pull: true, put: true },
          "scroll-sensitivity": 190,
          "force-fallback": true,
        },
        on: {
          start: function ($event) {
            _vm.drag = true
          },
          end: function ($event) {
            _vm.drag = false
          },
        },
      },
      "draggable",
      _vm.dragOptions,
      false
    ),
    _vm._l(_vm.query, function (el, index) {
      return _c(
        "li",
        { key: el.id, class: el.type },
        [
          _vm.TYPE_GROUP === el.type
            ? _c(
                "div",
                [
                  _c("i", {
                    staticClass:
                      "fa fa-fw fa-grip-vertical handle segment-rule-handler",
                  }),
                  _vm._v(" "),
                  _c("checkbox-switch", {
                    attrs: { classes: "is-warning", checked: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "before",
                          fn: function () {
                            return [_vm._v("OR")]
                          },
                          proxy: true,
                        },
                        {
                          key: "after",
                          fn: function () {
                            return [_vm._v("AND")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: el.op,
                      callback: function ($$v) {
                        _vm.$set(el, "op", $$v)
                      },
                      expression: "el.op",
                    },
                  }),
                  _vm._v(" "),
                  _c("segment-nested-draggable", {
                    class: el.op ? "dragArea op-not" : "dragArea op-and",
                    attrs: {
                      query: el.query,
                      labels: _vm.labels,
                      rules: _vm.rules,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "segment-rule-remove",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.removeAt(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.TYPE_RULE === el.type
            ? _c("segment-rule", {
                attrs: { labels: _vm.labels, rules: _vm.rules, index: index },
                on: { removeAt: _vm.removeAt },
                model: {
                  value: _vm.query[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.query, index, $$v)
                  },
                  expression: "query[index]",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }