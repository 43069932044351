import Vue from 'vue';
import Accordion from "../../accordion/Accordion.vue";

const initialized = [];

function initSlider(){
    document.querySelectorAll('.accordion-editor').forEach(element => {
        const id = element.id;

        if(!initialized.includes(id)) {
            new Vue({
                name: 'Accordion',
                el: element,

                components: {
                    'accordion': Accordion
                },
            });
        }

        initialized.push(id);
    });
}

document.addEventListener("DOMContentLoaded", initSlider);
window.addEventListener("Pageeditor:insert", initSlider);
window.addEventListener("Pageeditor:change", initSlider);

