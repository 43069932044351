import { render, staticRenderFns } from "./ColorDots.vue?vue&type=template&id=5dd012a3&scoped=true"
import script from "./ColorDots.vue?vue&type=script&setup=true&lang=js"
export * from "./ColorDots.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ColorDots.vue?vue&type=style&index=0&id=5dd012a3&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd012a3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5dd012a3')) {
      api.createRecord('5dd012a3', component.options)
    } else {
      api.reload('5dd012a3', component.options)
    }
    module.hot.accept("./ColorDots.vue?vue&type=template&id=5dd012a3&scoped=true", function () {
      api.rerender('5dd012a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-module-commerce2/src/backend/assets/components/ProductsMoodGrid/ColorDots.vue"
export default component.exports