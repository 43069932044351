<template>
  <div class="color-dots">
    <span
      v-for="(color, index) in genColors"
      :key="`${color}_${index}`"
      :style="{ color: color, 'z-index': 20 - index }"
      :class="{ '--has-border': color.toLowerCase() === '#ffffff' }" />
    <span
      v-if="hasMoreColors"
      class="more-colors"
      :style="{ color: '#ffffff', 'z-index': 20 - genColors.length }">
      +
    </span>
  </div>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
    colors: {
        type: Array,
        default: () => []
    },
    max: {
        type: Number,
        default: 5
    }
})

const hasMoreColors = computed(() => {
    return props.colors.length > props.max
})

const genColors = computed(() => {
    return props.colors.slice(0, props.max)
})
</script>
<style lang="scss" scoped>
.color-dots {
    display: flex;
    margin-left: auto;
    z-index: 0;
    span {
        border: 2px solid var(--color-border-color, #fafafa);
        width: 0.8vw;
        height: 0.8vw;
        display: flex;
        place-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: currentColor;
        position: relative;
        margin-right: calc(-.4 * var(--font-size, 2vh));

        &.--has-border {
            box-shadow: inset 0 0 0 1px #E6E6E6;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
