import {Mark} from 'tiptap'
import { updateMark } from 'tiptap-commands'


export default class BgColor extends Mark {

    get name() {
        return 'bgcolor'
    }

    get schema() {
        return {
            attrs: {
                bgcolor: {
                    default: '#fff',
                },
            },
            inline: true,
            content: 'inline*',
            group: 'block',

            parseDOM: [{
                style: 'bgcolor',
                getAttrs(value) { return { bgcolor : value } },
            }],

            toDOM: node => [`span`, {'style': `background-color: ${node.attrs.bgcolor};`}, 0],
        }
    }

    commands({type}) {
        return attrs => updateMark(type, attrs)
    }
}
