<template>
    <div>
        <div v-if="logicOptions.length === 0" class="alert alert-primary">
            {{ $translations['logic.hint'] }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogicHint',
    props: {
        logicOptions: {
            type: Array
        }
    }
};
</script>