import "select2";
import "./jquery.contains_ci";

$(function () {
    /**
     * Add class loaded to elementCards (prevent early drag&drop)
     */
    $(".elementCards").addClass("loaded");

    /**
     * Search for element list
     */
    $("#element-search").keyup(function (/* event */) {
        let token = $(this).val(),
            $matches = $("#element-library .el-card:contains_ci('" + token + "')");//.not("[data-layout='1']");

        $matches.fadeIn(300);
        //hide not matching
        $("#element-library .el-card").not($matches).fadeOut(0);
    });

    /**
     * Initialize select2 for the filter select
     */
    $("#element-filter-select").select2();

});