import Vue from 'vue';
import Tags from '../vue/tags.vue';

(function() {

   'use strict';

   /**
    * Init Tags on drag in page
    */
   window.addEventListener("Pageeditor:insert", (function(tevent) {
         initTags();
      }
   ));

   /**
    * Init Tags on dom content loaded
    */
   window.addEventListener('DOMContentLoaded', (function(event) {
         initTags();
      }
   ));

   //Todo: Init just uninitialised
   function initTags(){
      let tagInputs = document.querySelectorAll('.tag-input');

      for(let tagInput of tagInputs) {
         new Vue({
            el: tagInput,
            render: createElement => {
               const context = {
                  props: {elementId: tagInput.id, initAutocompleteItems: [], checkedItems: []},
               };
               return createElement(Tags, context);
            }
         });
      }
   }
})();