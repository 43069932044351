<template>
    <div>
        <Header
            @active="setActiveView"
            @update-product-type="updateProductType"
            :count="selection.length"
            :show-product-type-filter="showProductTypeFilter"
            ref="header"
            :translations="translations"
            :product-type="productType" />
        <Selection
            :api="api"
            :hidden="active !== 0"
            :selection="selection"
            :fields="fieldsConfigSelected"
            :search-placeholder="searchPlaceholder"
            :input-name-prefix="inputNamePrefix"
            :item-id-name="itemIdName"
            :item-id-value="itemIdValue"
            :sort-order-name="sortOrderName"
            :is-favorite-name="isFavoriteName"
            :is-favorite-value="isFavoriteValue"
            :empty-selection-text="emptySelectionText"
            :translations="translations"
            @remove-item="removeItem"
            @go-to-product-catalog="setActiveView(1)" />
        <Items
            v-if="active === 1"
            :api="api"
            :selection="selection"
            :query="queryItems"
            :variables="variablesItems"
            :fields="fieldsConfigItems"
            :search-placeholder="searchPlaceholder"
            :items-accessor="itemsAccessor"
            :total-count-accessor="totalCountAccessor"
            :selection-item-key="selectionItemKey"
            :product-type="productType"
            :show-product-type-filter="showProductTypeFilter"
            :translations="translations"
            :filter="filter"
            :product-catalog-active="active === 1"
            @add-item="addItem"
            @remove-item="removeItem" />
    </div>
</template>

<script>
import Header from "./Header.vue";
import Selection from "./Selection.vue";
import Items from "./Items.vue";
import EventBus from "./util/EventBus";

export default {
    name: 'LinkedItems',
    components: {
        Header,
        Selection,
        Items
    },
    props: {
        themeworldId: {
            type: Number
        },
        api: {
            type: String
        },
        queryItems: {
            type: String
        },
        variablesItems: {
            type: Object
        },
        querySelected: {
            type: String
        },
        variablesSelected: {
            type: Object
        },
        fieldsItems: {
            type: Object
        },
        fieldsSelected: {
            type: Object
        },
        searchPlaceholder: {
            type: String
        },
        itemsAccessor: {
            type: Function
        },
        totalCountAccessor: {
            type: Function
        },
        selectionAccessor: {
            type: Function
        },
        inputNamePrefix: {
            type: String
        },
        itemIdName: {
            type: Function
        },
        itemIdValue: {
            type: Function
        },
        sortOrderName: {
            type: Function
        },
        isFavoriteName: {
            type: Function
        },
        isFavoriteValue: {
            type: Function
        },
        selectionItemKey: {
            type: String
        },
        multipleFavorites: {
            type: Boolean
        },
        emptySelectionText: {
            type: String
        },
        showProductTypeFilter: {
            type: Boolean,
            default: false
        },
        translations: {
            type: Object
        },
        filter: {
            type: Object
        }
    },
    data: function() {
        return {
            selection: [],
            items: [],
            active: 0,
            fieldsConfigItems: {},
            fieldsConfigSelected: {},
            productType: 1
        };
    },
    mounted() {
        Object.keys(this.fieldsItems).forEach(key => {
            this.$set(this.fieldsConfigItems, key, { ...this.fieldsItems[key], accessor: eval(this.fieldsItems[key].accessor) });
        });

        Object.keys(this.fieldsSelected).forEach(key => {
            this.$set(this.fieldsConfigSelected, key, { ...this.fieldsSelected[key], accessor: eval(this.fieldsSelected[key].accessor) });
        });
        this.loadData();

        EventBus.$on('toggleIsFavorite', (item, field) => {
            // if not multipleFavorites is configured => reset all favorites
            if (!this.multipleFavorites) {
                for(let i in this.selection) {
                    if(this.itemIdValue(this.selection[i]) !== this.itemIdValue(item)) {
                        this.selection[i].isFavorite = 0;
                    }
                }
            }

            this.selection.forEach((itemToFind, index) => {
                if (this.itemIdValue(itemToFind) === this.itemIdValue(item)) {
                    this.selection[index].isFavorite = this.selection[index].isFavorite === 1 ? 0 : 1;
                }
            });
        });
    },
    methods: {
        setActiveView(value) {
            this.active = value;
            this.$refs.header.active = value;
        },
        // request selected items
        loadData() {
            const body = {
                query: this.querySelected,
                variables: this.variablesSelected
            };

            const headers = {
                'Content-Type': 'application/json'
            };
            headers['X-CSRF-Token'] = yii.getCsrfToken();

            fetch(this.api, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })
                .then(res => res.json())
                .then(json => {
                    ;                    this.selection = this.selectionAccessor(json);
                });
        },
        // add item to selection
        addItem(item) {
            let itemToPush = {
                isFavorite: 0,
                sortOrder: (this.selection.length + 1) + 10,
            };

            if(this.selectionItemKey !== undefined) {
                itemToPush[this.selectionItemKey] = {
                    ...item
                };
            } else {
                itemToPush = {
                    ...itemToPush,
                    ...item
                };
            }


            this.selection.push(itemToPush);
        },
        // remove item from selection
        removeItem(itemToRemove) {
            if (this.selectionItemKey !== undefined) {
                if (Object.prototype.hasOwnProperty.call(itemToRemove, this.selectionItemKey)) {
                    this.selection = this.selection.filter(item => item[this.selectionItemKey].id !== itemToRemove[this.selectionItemKey].id);
                } else {
                    this.selection = this.selection.filter(item => item[this.selectionItemKey].id !== itemToRemove.id);
                }
            } else {
                this.selection = this.selection.filter(item => item.id !== itemToRemove.id);
            }

        },
        updateProductType(value) {
            this.productType = value;
        }
    }
};
</script>