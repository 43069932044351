import Vue from "vue";
import LinkedItems from "./components/LinkedItems.vue";

document.addEventListener("DOMContentLoaded", () => {
    if(document.querySelector('.linked-items')) {

        new Vue({
            name: 'LinkedItemsRoot',
            el: '.linked-items',
            components: {
                LinkedItems
            }
        });
    }
});