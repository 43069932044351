var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "empty-state" },
    [
      _c("h2", { staticClass: "headline" }, [
        _vm._v(_vm._s(_vm.emptyHeadline)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subline" }, [_vm._v(_vm._s(_vm.emptySubline))]),
      _vm._v(" "),
      _vm.active.length === 0
        ? _c(
            "draggable",
            {
              staticClass: "hint",
              class: { isDragging: _vm.isDragging },
              attrs: { group: { name: "filter" }, list: _vm.active },
            },
            [
              !_vm.isDragging
                ? _c("div", { staticClass: "hint-text" }, [
                    _vm._v(_vm._s(_vm.emptyHint)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }