<template>
    <div
        class="product-details item-info"
        :class="{ '--margin-top': marginTop }">
        <div class="item-name" :class="fontSize">
            {{ props.item.productData.title }}
        </div>
        <div
            v-if="props.item.productData.customSku"
            class="item-sku"
            :class="fontSize">
            {{ props.item.productData.customSku }}
        </div>
        <div class="price-placeholder">$ X.XX | $ X.XX RRP </div>
        <div class="d-flex align-center w-100">

            <div class="color-container d-flex align-items-center">
                <ColorDots :colors="colors"/>
                <div class="color-title">{{props.item.productData.color.title}}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, defineProps} from 'vue'
import ColorDots from "./ColorDots.vue";

const props = defineProps({
    hasInfo: {
        type: Boolean,
        default: false
    },
    zoom: {
        type: String,
        default: 'small'
    },
    fontSize: {
        type: String,
        default: 'normal',
        validator(value) {
            return [ 'normal', 'large' ].includes(value)
        }
    },
    item: {
        type: Object,
        default: () => {}
    },
    imageWidth: {
        type: Number,
        default: 400
    },
    perspective: {
        type: String,
        default: 'front'
    },
    showImage: {
        type: Boolean,
        default: true
    },
    marginTop: {
        type: Boolean,
        default: true
    },
    hasImageTransition: {
        type: Boolean,
        default: false
    }
})

const colors = computed(() => {
    let colorsArray = []
     colorsArray.push(props.item.productData.color.hexCode)
    return colorsArray
})

</script>

<style lang="scss" scoped>

[data-grid="medium"] {
    --item-content-padding: 0 0.8vw 0.8vw 0.8vw;
    --item-content-font-size: 0.7vw;
    --item-headline-font-size: 1vw;
    --item-headline-margin: 0 0 0 0;
    --item-footer-margin-top: 0;
}

[data-grid="large"] {
    --item-content-padding: 0 0.8vw 0.8vw 0.8vw;
    --item-content-font-size: 0.7vw;
    --item-headline-font-size: 1vw;
    --item-headline-margin: 0 0 0 0;
    --item-footer-margin-top: 0;
}

.item-info {
    font-weight: 200;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;
    width: 100%;
    max-width: 100%;
    color: #000;
    padding: var(--item-content-padding);
    font-size: var(--item-content-font-size);
    &.--margin-top {
        margin-top: -5%;
    }

    &:deep(.price ) {
        span {
            font-size: var(--item-content-font-size);
        }
    }

    .price-placeholder {
        color: #000;
        font-family: Inter-Regular;
        margin-top: 0.8vw;
    }
}
.item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    font-size: var(--item-headline-font-size);
    font-family: Inter-Regular;
    line-height: 1.2;
}
.item-sku {
    color: #6E6E6E;
    font-size: 0.75vw;
    font-family: 'Inter-Medium';
    line-height: 1;
    margin-top: 0.6vw;
}

.color-container {
    margin-top: 0.6vw;
    margin-bottom: 0.3vw;
}

.color-title {
    margin-left: 0.5vw;
    font-family: Inter-Regular;
    color: #000;
    line-height: 1;
}

.product--image {
    width: 100%;
    object-fit: contain;
    transition: all 0.5s;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
}
</style>