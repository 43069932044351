<template>
    <div class="modal fade link-modal" aria-hidden="true" data-backdrop="" role="dialog" :id="modalId">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="!isButton" class="modal-title">Link hinzufügen</h5>
                    <h5 v-else class="modal-title">Button hinzufügen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <tabs @changed="tabChanged">
                        <tab title="Linkinfo" active="true">

                            <div class="form-group label-inside">
                                <select v-model="linkType" class="form-control">
                                    <option value="url">URL</option>
                                    <option v-show="!isButton" value="anchor">Anker</option>
                                    <option value="email">E-Mail</option>
                                </select>
                                <label class="">Link-Typ</label>
                            </div>

                            <div v-if="linkType === 'url'">
                                <div class="form-group label-inside">
                                    <input id="link-modal-url" v-model="url" type="text" class="form-control">
                                    <label for="link-modal-url">URL</label>
                                </div>
                            </div>

                            <div v-if="linkType === 'anchor'">
                                <div class="form-group label-inside">
                                    <input id="link-modal-anchor" v-model="anchorName" type="text" class="form-control">
                                    <label for="link-modal-anchor">Anker</label>
                                </div>
                            </div>

                            <div v-if="linkType === 'email'">
                                <div class="form-group label-inside">
                                    <input id="link-modal-email" type="email" v-model="emailAddress" class="form-control">
                                    <label for="link-modal-email">E-Mail</label>
                                </div>
                            </div>

                        </tab>

                        <tab title="Zielseiten">
                            <div class="form-group label-inside">
                                <select id="link-modal-target" v-model="linkTarget" aria-labelledby="target" class="form-control">
                                    <option value="_blank">Neues Fenster (_blank)</option>
                                    <option value="_top"> Oberstes Fenster (_top)</option>
                                    <option value="_self"> Gleiches Fenster (_self)</option>
                                    <option value="_parent">Oberes Fenster (_parent)</option>
                                </select>
                                <label for="link-modal-target">Ziel wählen</label>
                            </div>
                        </tab>

                        <tab title="Erweitert">
                            <div class="form-group label-inside">
                                <input class="form-control" id="link-modal-popup-lang" type="text" v-model="lang" aria-labelledby="link-modal-popup-lang">
                                <label for="link-modal-popup-lang">Sprache</label>
                            </div>
                            <div class="form-group label-inside">
                                <input class="form-control" id="link-modal-popup-title" type="text" v-model="title" aria-labelledby="link-modal-popup-title">
                                <label for="link-modal-popup-title">Titel Tag</label>
                            </div>
                        </tab>

                        <tab v-if="isButton" title="Button Stil">
                           <div class="form-group label-inside">
                            <select id="button-modal-buttonstyle" v-model="btnStyle" aria-labelledby="btnstyle" class="form-control">
                                <option value="primary">Primary</option>
                                <option value="primary-outline">Primary Outline</option>
                                <option value="primary-underline">Primary Underline</option>
                                <option value="secondary">Secondary</option>
                                <option value="secondary-outline">Secondary Outline</option>
                                <option value="secondary-underline">Secondary Underline</option>
                            </select>
                            <label for="button-modal-buttonstyle">Button Stil</label>
                           </div>
                        </tab>
                    </tabs>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
                    <button type="button" class="btn btn-success"
                            @click="save"
                            :disabled="!this.url && !this.emailAddress && !this.anchorName"
                            :class="{'disabled': !this.url && !this.emailAddress && !this.anchorName }"
                    >Speichern
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Tab} from "vue-simple-tabs";
    import Tabs from "./Tabs.vue";
    import getNodes from "../utils/getNodes.js";

    import 'select2/dist/js/select2.full.js'
    import 'select2/dist/css/select2.min.css'

    export default {
        name: 'LinkModal',
        components: {
            Tabs,
            Tab,
        },
        props: {
            'modalId': {type: String},
            'editor': {type: Object},
            'attrs': {type: Object},
            'isButton': {type: Boolean},
            'buttonStyle': {type: String},
            'saveCallback': {type: Function},
            'closeCallback': {type: Function},
            'openMediamanagerCallback': {type: Function},
        },
        data() {

            let emailSubject = '';
            let emailBody = '';
            let emailAddress = '';
            let linkType = '';
            let url = '';
            let target = '';
            let hreflang = '';
            let btnStyle = '';
            let anchorName = '';
            let lang = '';
            let title= '';


            // Attributes
            let attrs = $(this.$props['attrs']);

            if (this.$props['attrs'].href) {
                try {
                    let urlObj = (new URL(this.$props['attrs'].href));
                    if (urlObj.protocol === 'mailto:') {
                        emailAddress = urlObj.pathname;
                        let params = new URLSearchParams(urlObj.search);

                        emailSubject = params.get('subject');
                        emailBody = params.get('body');
                    }

                } catch(e) {
                    console.error(e);
                }

                if (this.$props['attrs'].value) {
                    emailAddress = this.$props['attrs'].value; //Todo: parse email from href
                }
                // linkType = this.$props['attrs'].href.indexOf('mailto:') > -1 ? 'email' : 'url';

                if (attrs.attr('data-link-type') == 'url') {
                    url = this.$props['attrs'].href;
                }

                if (attrs.attr('data-link-type') == 'anchor') {
                    url = this.$props['attrs'].href;
                }
            }

            if (this.$props['attrs'].target) {
                target = this.$props['attrs'].target;
            }

            if (this.$props['attrs'].hreflang) {
                hreflang = this.$props['attrs'].hreflang;
            }


            if ($(this.$props['attrs']).attr('data-button-style')) {
                btnStyle = $(this.$props['attrs']).attr('data-button-style');
            }

            if ($(this.$props['attrs']).attr('data-link-type')) {
                linkType = $(this.$props['attrs']).attr('data-link-type');
            }

            if ($(this.$props['attrs']).attr('data-anchor-name')) {
                anchorName = $(this.$props['attrs']).attr('data-anchor-name');
            }

            if ($(this.$props['attrs']).attr('title')) {
                title = $(this.$props['attrs']).attr('title');
            }

            if ($(this.$props['attrs']).attr('lang')) {
                lang = $(this.$props['attrs']).attr('lang');
            }

            return {
                data: false,
                $modalNode: false,
                modalSave: false,
                anchorNodes: [],
                linkNodes: [],
                linkType: linkType,
                linkTarget: target,
                url: url,
                hreflang: hreflang,
                btnStyle: btnStyle,
                anchorName: anchorName,
                lang: lang,
                title: title,
                protocol: '',
                emailAddress: emailAddress,
                emailSubject: emailSubject,
                emailBody: emailBody,
                popup: [],
                extra: []
            }
        },
        computed: {},
        methods: {
            openMediamanager() {
                this.$modalNode.modal('hide');
                this.openMediamanagerCallback();
                this.closeCallback(this.modalId);
            },
            save() {
                this.modalSave = true;

                let target = null;
                let btnClass = '';
                let btnStyle = '';

                const btnVariants = {
                    'primary': {
                        btnClass: 'btn btn-primary',
                        btnStyle: 'primary'
                    },
                    'primary-outline': {
                        btnClass: 'btn btn-outline-primary',
                        btnStyle: 'primary-outline'
                    },
                    'primary-underline': {
                        btnClass: 'btn btn-underline-primary',
                        btnStyle: 'primary-underline'
                    },
                    'secondary': {
                        btnClass: 'btn btn-secondary',
                        btnStyle: 'secondary'
                    },
                    'secondary-outline': {
                        btnClass: 'btn btn-outline-secondary',
                        btnStyle: 'secondary-outline'
                    },
                    'secondary-underline': {
                        btnClass: 'btn btn-underline-secondary',
                        btnStyle: 'secondary-underline'
                    }
                };

                if (btnVariants[this.btnStyle]) {
                    btnClass = btnVariants[this.btnStyle].btnClass;
                    btnStyle = btnVariants[this.btnStyle].btnStyle;
                }

                if (this.linkTarget.indexOf('_') === 0) {
                    target = this.linkTarget;
                }

                if (this.linkType === 'email') {
                    this.saveCallback(this.linkType, {
                        value: this.emailAddress,
                        href: this.createMailto(),
                        target: target,
                        class: btnClass,
                        'data-link-type': this.linkType
                    });
                }

                else if (this.linkType === 'url') {
                    let data = {
                        href: this.url,
                        target: this.linkTarget,
                        title: this.title,
                        class: btnClass,
                        hreflang: this.lang,
                        'data-button-style': this.btnStyle,
                        'data-link-type': this.linkType
                    };
                    data = Object.assign(data, this.extra);
                    this.saveCallback(this.linkType, data);
                }

                else if (this.linkType === 'anchor') {
                    let data = {
                        id: this.anchorName,
                        href: `${this.anchorName}`,
                        'data-link-type': this.linkType,
                        'data-anchor-name': this.anchorName
                    };
                    data = Object.assign(data, this.extra);
                    this.saveCallback(this.linkType, data);
                }

                this.$modalNode.modal('hide');
                this.closeCallback(this.modalId);
            },

            createMailto() {
                let params = '';
                if (this.emailBody || this.emailSubject) {
                    this.emailBody = this.emailSubject ? '&body=' + this.emailBody : this.emailBody;
                    params = `?subject=${this.emailSubject}` + this.emailBody;
                }
                return encodeURI(`mailto:${this.emailAddress}${params}`);
            },

            createPopup() {
                let popup = '';

                popup = `onclick="window.open(this.href, '${this.popup['name']}',`;
                popup += 'resizable=';
                popup += this.popup['resizeable'] === false ? 'no' : 'yes';
                popup += ',status=';
                popup += this.popup['status'] === false ? 'false' : 'yes';
                popup += ',location=';
                popup += this.popup['location'] === false ? 'false' : 'yes';
                popup += ',toolbar=';
                popup += this.popup['toolbar'] === false ? 'false' : 'yes';
                popup += ',menubar=';
                popup += this.popup['menubar'] === false ? 'false' : 'yes';
                popup += ',fullscreen=';
                popup += this.popup['fullscreen'] === false ? 'false' : 'yes';
                popup += ',scrollbars=';
                popup += this.popup['scrollbars'] === false ? 'false' : 'yes';
                popup += ',dependent=';
                popup += this.popup['dependent'] === false ? 'false' : 'yes';
                popup += this.popup['height'] ? ',height=' + this.popup['height'] : '';
                popup += this.popup['width'] ? ',width=' + this.popup['width'] : '';
                popup += this.popup['left'] ? ',left=' + this.popup['left'] : '';
                popup += this.popup['top'] ? ',top=' + this.popup['top'] : '';
                popup += "); return false;";

                return popup;
            },
            tabChanged() {
                console.log('tab changed test 2');
                // this.$nextTick( () => {
                //     $('.select2').select2({
                //         language: {
                //             noResults: function (params) {
                //                 return "Keine Resultate gefunden";
                //             }
                //         }
                //     });
                // })
            }
        },
        mounted() {
            this.$modalNode = $('#' + this.modalId);
            this.$modalNode.modal('show');

            if (this.$props['editor']) {
                this.anchorNodes = getNodes(this.$props['editor'].view.state, 'anchor');
                this.linkNodes = getNodes(this.$props['editor'].view.state, 'link');
            }

            let that = this;
            $(this.$el).on('hidden.bs.modal', function() {
                //  Close modal if not in saveMode
                if (!that.modalSave) {
                    that.closeCallback(that.modalId);
                }
            });
        },
        watch: {},
    }
</script>
<style scoped lang="scss">
.modal.link-modal {
    .modal-content {
        margin-top: 106px; //header is 90px
        margin-bottom: 16px;
        box-shadow: #04051b85 0 0 0px 2000px;
    }
}
</style>