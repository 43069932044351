var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "collapse", attrs: { id: "section-dependencies" } },
    [
      _c("div", { staticClass: "p-3" }, [
        Object.keys(_vm.logic).length === 0
          ? _c(
              "div",
              [
                _c("LogicHint", {
                  attrs: { "logic-options": _vm.logicOptions },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-primary",
                    attrs: { disabled: _vm.logicOptions.length === 0 },
                    on: { click: _vm.enableLogic },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$translations["button.uselogic"]) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("LogicEditor", {
                  attrs: {
                    options: _vm.logicOptions,
                    element: _vm.item,
                    logic: _vm.logic,
                  },
                  on: {
                    "add-rule": _vm.addRule,
                    "remove-rule": _vm.removeRule,
                  },
                }),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }