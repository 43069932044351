var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _c("div", { staticClass: "h5 text-muted mb-3" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$translations["element.address"]) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-content",
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$translations["section.content"]) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-content" } },
          [
            _c("form-checkbox", {
              attrs: {
                id: "mandatory-checkbox",
                label: _vm.$translations["label.mandatory"],
              },
              model: {
                value: _vm.item.data.mandatory,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "mandatory", $$v)
                },
                expression: "item.data.mandatory",
              },
            }),
            _vm._v(" "),
            _vm.item.data.mandatory
              ? _c("form-input", {
                  attrs: {
                    id: "warning-notification",
                    label: _vm.$translations["label.mandatory-warning"],
                  },
                  model: {
                    value: _vm.item.data.mandatory_warning,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "mandatory_warning", $$v)
                    },
                    expression: "item.data.mandatory_warning",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "question",
                label: _vm.$translations["label.label"],
              },
              model: {
                value: _vm.item.data.question,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "question", $$v)
                },
                expression: "item.data.question",
              },
            }),
            _vm._v(" "),
            _c("form-textarea", {
              attrs: {
                id: "description",
                label: _vm.$translations["label.description"],
              },
              model: {
                value: _vm.item.data.description,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "description", $$v)
                },
                expression: "item.data.description",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 mb-3" }, [
              _vm._v("\n                    Dynamisch generiert "),
              _c("code", [
                _vm._v(
                  _vm._s(
                    `${_vm.element.items[0].data.answeroptionsclass}::${_vm.element.items[0].data.answeroptionsfunction}`
                  )
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-dependencies",
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$translations["section.dependencies"]) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c("LogicSection", {
          attrs: { item: _vm.item, "logic-options": _vm.logicOptions },
          on: {
            "add-rule": _vm.addRule,
            "remove-rule": _vm.removeRule,
            "enable-logic": _vm.enableLogic,
          },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-advanced",
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$translations["section.advanced"]) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-advanced" } },
          [
            _c("form-input", {
              attrs: {
                id: "unique-label",
                label: _vm.$translations["label.uniqlabel"],
              },
              model: {
                value: _vm.item.data.uniqlabel,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "uniqlabel", $$v)
                },
                expression: "item.data.uniqlabel",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "htmloptions",
                label: _vm.$translations["label.css-class"],
              },
              model: {
                value: _vm.item.data.htmloptions,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmloptions", $$v)
                },
                expression: "item.data.htmloptions",
              },
            }),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: {
                id: "html-purify",
                label: _vm.$translations["label.html-purify"],
              },
              model: {
                value: _vm.item.data.htmlpurify,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmlpurify", $$v)
                },
                expression: "item.data.htmlpurify",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "customvalidation",
                label: _vm.$translations["label.regex"],
              },
              model: {
                value: _vm.item.data.customvalidation,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "customvalidation", $$v)
                },
                expression: "item.data.customvalidation",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "customerrormessage",
                label: _vm.$translations["label.regex-warning"],
              },
              model: {
                value: _vm.item.data.customerrormessage,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "customerrormessage", $$v)
                },
                expression: "item.data.customerrormessage",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-class",
                label: _vm.$translations["label.class-for-default"],
              },
              model: {
                value: _vm.item.data.answeroptionsclass,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "answeroptionsclass", $$v)
                },
                expression: "item.data.answeroptionsclass",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-function",
                label: _vm.$translations["label.function-for-default"],
              },
              model: {
                value: _vm.item.data.answeroptionsfunction,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "answeroptionsfunction", $$v)
                },
                expression: "item.data.answeroptionsfunction",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [_c("FooterActions", { on: { save: _vm.save } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }