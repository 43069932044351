import { v4 as uuidv4 } from 'uuid';
import eventBus from '../util/EventBus';

export default {
    mounted() {
        eventBus.$on('addAnswerOption', (id, itemId) => {
            if (this.item.data.id !== itemId) {
                return;
            }

            const newOption = {
                id: uuidv4(),
                label: '',
                is_default: false
            };

            if (this.item.data.answeroptions.length > 0) {
                const index = this.item.data.answeroptions.findIndex(answer => answer.id === id);

                this.item.data.answeroptions.splice(index + 1, 0, newOption);
            } else {
                this.item.data.answeroptions.push(newOption);
            }
        });
        eventBus.$on('removeAnswerOption', (id, itemId) => {
            if (this.item.data.id !== itemId) {
                return;
            }

            const index = this.item.data.answeroptions.findIndex(answer => answer.id === id);

            this.item.data.answeroptions.splice(index, 1);
        });
        eventBus.$on('updateAnswerOption', (id, value, itemId) => {
            if (this.item.data.id !== itemId) {
                return;
            }

            const index = this.item.data.answeroptions.findIndex(answer => answer.id === id);

            this.item.data.answeroptions[index].label = value;
        });
        eventBus.$on('updateAnswerValue', (id, value, itemId) => {
            if (this.item.data.id !== itemId) {
                return;
            }

            const index = this.item.data.answeroptions.findIndex(answer => answer.id === id);

            this.item.data.answeroptions[index].value = value;
        });
        eventBus.$on('defaultAnswerOption', (id, itemId) => {
            if (this.item.data.id !== itemId) {
                return;
            }

            this.item.data.answeroptions.forEach((answer, index) => {
                if (id === answer.id) {
                    this.item.data.answeroptions[index].is_default = true;
                } else {
                    this.item.data.answeroptions[index].is_default = false;
                }
            });
        });
    }
};