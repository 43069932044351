/** Holds variable from the fire cms */
/* global fire*/

import "bootstrap/js/src/collapse";
import "bootstrap/js/src/tab";
import "./element-library";
import "./jquery.blank";

import {loopAtFps, debounce, getScrollTop, isFunction, disableSelection, triggerEvent} from "./helpers";


const pageeditor = {};

let updateToolbarCenteringTimeout;
pageeditor.updateToolbarCentering = function() {
    clearTimeout(updateToolbarCenteringTimeout);
    updateToolbarCenteringTimeout = setTimeout(function () {
        //console.log('update');
        const doc = clientFrameWindow.$(clientFrameWindow.document),
            frameHeight = parseInt($("#element-canvas-iframe").css("height"), 10);
        const els = doc.find(".el-card");
        els.each(function (i, el) {
            if ($(el).height() > frameHeight) {
                $(el).addClass("sticky-toolbar");
            } else {
                $(el).removeClass("sticky-toolbar");
            }
        });
    }, 100);
};

let clientFrameWindow,
    toleranceTop,
    toleranceBottom,
    myAutoscroll,
    dragndropAjaxRequestDataForm;


/**
 * Draggable is implemented as Jquery Plugin, which allows to use "this" to access current element.
 * It also allows at any point in time, to trigger $('#element').draggableElement
 */
$.fn.draggableElement = function () {


    let isLibrary = this.hasClass("elementCards"),
        isLayout = this.hasClass("el-layout-container") ? 1 : 0,
        isEditable= this.hasClass("el-layout-container") ? 1 : 0,
        dropMarkerHeight = 25;

    $(this).find(".el-handle:not(.el-toolbar-button-disabled)").mousedown(function (event) {
        // if mouse left click, else bail out
        if (event.which !== 1) {
            return;
        }
        let elCard, code, drag, mouseUp, resetDragDrop, eventCounter;
        dragndropAjaxRequestDataForm = false;

        myAutoscroll.loopy.start();

        if (isLibrary) {
            elCard = $(this);
            code = elCard.data("code");
            let title = elCard.data("label");
            isLayout = elCard.data("layout");
            drag = elCard.clone();
            drag.css({
                height: elCard.css("height"),
                width: elCard.css("width")
            });

            eventCounter = 0;
            //$card.addClass('el-loader');
            $.post(
                fire.actionForm,
                {
                    target_id: fire.modelId,
                    target_class: fire.modelClass,
                    code: code,
                    isLayout:isLayout,
                    isEditable: isEditable,
                    title: title
                },
                function (data) {
                    eventCounter++;
                    dragndropAjaxRequestDataForm = data;
                    if (eventCounter > 1) {
                        pageeditor.loadPreview(data.identifier, data.content, true, null, isLayout, isEditable);
                    }
                },
                "json"
            ).fail(function (xhr, textStatus, errorThrown) {
                if ("abort" !== errorThrown) {
                    console.error([xhr, textStatus, errorThrown]);
                }
                //$card.removeClass('el-loader');
            });

        } else {
            eventCounter = 2;
            if (isLayout) {
                elCard = $(this).parents(".el-layout-container");
            } else {
                elCard = $(this).parents(".el-card");
            }
            elCard.addClass("backend-we-are-moving");
            code = elCard.data("code");
            drag = $("#element-library .el-card[data-code=\"" + code + "\"]").clone();
        }

        // disable draggable attribute from our clone, this sets various through css,
        // most important being "pointer-events: none", so that the iframe can react to the cursor with hover states,
        // and js events.

        drag.addClass("dragging");
        requestAnimationFrame(function(){
            drag.addClass("dragging-lifted");
        });

        //add our clone
        $(document).find("body").append(drag);

        // user tolerance, so we activate drop area as soon as element is over it, instead of pointer
        // for this we inject a custom css into the iframe, which holds the proper padding's/margins
        let elementHeight = drag.height() * 0.5,
            elementWidth = drag.width() * 0.5;
        toleranceTop = elementHeight + dropMarkerHeight;
        toleranceBottom = toleranceTop;

        // Add class to body of window and iframe, so we can alter the css globally (e.g. set cursor to grabbing)
        if (isLayout) {
            clientFrameWindow.$(clientFrameWindow.document.documentElement).addClass("backend-drag-drop-layout");
        } else {
            clientFrameWindow.$(clientFrameWindow.document.documentElement).addClass("backend-drag-drop");
        }
        $("body").addClass("backend-drag-drop");

        let canvasIframe = window.document.querySelector("#element-canvas-iframe");

        // Inject our drop markers
        pageeditor.addMarker(elCard, isLayout);

        // initialize position of our dragging element - below the cursor
        // margin is set to reflect initial click - not prefect - our initial element is the button not the actual element we drag
        let drage = drag[0];
            //debugger;

        //Movement outside of the iframe
        let mouseMoveWindow = function (event) {
            let scrOfY = getScrollTop(window);
            drage.style.top = event.pageY - scrOfY + "px";
            drage.style.left = event.pageX + "px";
        };

        /*
        let y = event.offsetY + "px";
        let x = event.offsetX + "px";
        drage.style.clipPath = "circle(60px at " + x + " " + y +")";
        */


        //Movement inside the iframe
        let mouseMoveIFrame = function (event) {
            let scrOfY = getScrollTop(clientFrameWindow);
            let viewportOffset = canvasIframe.getBoundingClientRect(),
            // these are relative to the viewport, i.e. the window, as such includes the window scroll
            offsetTop = viewportOffset.top,
            offsetLeft = viewportOffset.left;

            drage.style.top = event.pageY - (scrOfY - offsetTop) + "px";
            drage.style.left = event.pageX  + offsetLeft + "px";

            myAutoscroll.mousemove(event);
        };

        // This check works in firefox and chrome as of 12.nov.2019
        if (event.originalEvent.view.window === window.top) {
            mouseMoveWindow(event);
            // we put the drag element exactly where it was clicked
            drage.style.marginTop = "-" + event.offsetY + "px";
            drage.style.marginLeft = "-" + event.offsetX + "px";
        } else {
            mouseMoveIFrame(event);
            // we put the element in center of the cursor, as there is just an arrow to click on
            drage.style.marginTop = "-" + elementHeight + "px";
            drage.style.marginLeft = "-" + elementWidth + "px";
        }

        //reset when drag operation has ended
        resetDragDrop = function () {
            myAutoscroll.loopy.stop();
            $(clientFrameWindow.document).off("mousemove", mouseMoveIFrame).off("mouseup", mouseUp).off("mouseleave", myAutoscroll.mouseleave);
            $(clientFrameWindow.document).off(($.support.selectstart ? "selectstart" : "mousedown") + ".ui-disableSelection", disableSelection);

            $(window.document).off("mousemove", mouseMoveWindow).off("mouseup", mouseUp);
            $(window.document).off(($.support.selectstart ? "selectstart" : "mousedown") + ".ui-disableSelection", disableSelection);
            $(window).off("blur", resetDragDrop);

            //remove our drop markers
            $(clientFrameWindow.document).find(".drop-marker:not(:hover), .drop-spacer").remove();
            clientFrameWindow.$(clientFrameWindow.document.documentElement).removeClass("backend-drag-drop");
            clientFrameWindow.$(clientFrameWindow.document.documentElement).removeClass("backend-drag-drop-layout");
            window.$("body").removeClass("backend-drag-drop");
            elCard.removeClass("backend-we-are-moving");
            drag.remove();
        };

        //element got dropped
        mouseUp = function () {
            myAutoscroll.loopy.stop();

            if (isLibrary) {
                eventCounter++;
            }

            let target = clientFrameWindow.$(clientFrameWindow.document).find(".drop-marker:hover");
            if (target.length > 0) {
                target.addClass("el-loader");
            }

            if (eventCounter > 1) {
                let identifier, $elementForm;
                if (isLibrary) {
                    eventCounter++;
                    identifier = dragndropAjaxRequestDataForm.identifier;
                    $elementForm = dragndropAjaxRequestDataForm.content;
                } else {
                    identifier = elCard.data("identifier");
                    $elementForm = $("#element-editor .el-card-form[data-identifier=\"" + identifier + "\"]");
                }
                if (target.length > 0) {
                    if (!isLibrary && isLayout) {
                        target.replaceWith(elCard);
                        pageeditor.updateStructure();
                    } else {
                        let isEnabled = elCard.attr("data-enabled");
                        let isEditable = elCard.attr("data-editable");

                        if (typeof isEnabled === "undefined") {
                            isEnabled = 1;
                        } //add more params

                        if (typeof isEditable === "undefined") {
                            isEditable = 1;
                        } //add more params
                        pageeditor.loadPreview(identifier, $elementForm, isLibrary, target, isLayout, isEnabled, isEditable);
                    }
                }
            }
            resetDragDrop();
            /*
            setTimeout(function () {
                $(clientFrameWindow.document).find(".el-card").each(function (index) {
                    $(this).find("*[data-position]").attr("data-position", index);
                });
            }, 500);
            */
        };

        function resetDragDropFully() {
            // disable drag drop
            resetDragDrop();
            // remove the possible left over which was hovered.
            $(clientFrameWindow.document).find(".drop-marker").remove();

        }//setup listeners
        $(document).on("keyup",function(e) {
            if (e.key === "Escape") { // escape key maps to keycode `27`
                resetDragDropFully();
            }
        });

        clientFrameWindow.$(clientFrameWindow.document).on("keyup", function(e) {
            if (e.key === "Escape") { // escape key maps to keycode `27`
                resetDragDropFully();
            }
        });
        $(clientFrameWindow.document).on("mousemove", mouseMoveIFrame).on("mouseup", mouseUp).on("contextmenu", resetDragDrop).on("mouseleave", myAutoscroll.mouseleave);

        $(clientFrameWindow.document).on(($.support.selectstart ? "selectstart" : "mousedown") + ".ui-disableSelection", disableSelection);

        $(window.document).on("mousemove", mouseMoveWindow).on("mouseup", mouseUp).on("contextmenu", resetDragDrop);
        $(window.document).on(($.support.selectstart ? "selectstart" : "mousedown") + ".ui-disableSelection", disableSelection);
        $(window).on("blur", resetDragDrop);
    });
};

// Make autoscroll function
(function(){
    let contentHeight, elementHeight, heightUp, heightDown, loopy = false, loopyInterval,
        goldenRatio = 1.61803398875, scroll, container, iFrame, scrollModifier = 0,
        scrollScale = 20,
        debugScrollingVisually = false,
        toolbarWidthDefault = 328;

    // Makes the iframe autoscroll, depending on drag&drop being active
    let autoScroll = function (iiFrame) {
        iFrame = iiFrame;
        scroll = iFrame.document.querySelector(".scroll");
        container = iFrame.document.querySelector("body");
        let frameWindow = window.document.querySelector("#element-canvas-iframe");

        elementHeight = frameWindow.offsetHeight;
        contentHeight =  Math.round(clientFrameWindow.document.documentElement.getBoundingClientRect().height);

        heightDown = elementHeight / goldenRatio;
        heightUp = (elementHeight - heightDown) / 2;
        heightDown = heightDown + heightUp;

        //visualizes the area which triggers scroll
        if (debugScrollingVisually) {
            clientFrameWindow.$("body").append(clientFrameWindow.$("<div id=\"heightDownVisualizer\" style=\"z-index:2000; pointer-events:none;background:rgba(50,50,0,0.3);width:100%; top:" + heightDown + "px; position:fixed; bottom:0;\"></div>"));
            clientFrameWindow.$("body").append(clientFrameWindow.$("<div id=\"heightUpVisualizer\" style=\"z-index:2000;pointer-events:none;background:rgba(50,0,50,0.3);width:100%; height:" + heightUp + "px; position:fixed; top:0;\"></div>"));
        }
        loopy = loopAtFps(function (delta) {
            let scrOfY = getScrollTop(clientFrameWindow);
            //console.log( {'scrOfY': scrOfY, 'delta': delta, 'loopyInterval': loopyInterval, 'scrollModifier': scrollModifier});
            clientFrameWindow.scrollTo(0, scrOfY + ((delta - loopyInterval) / delta + 1) * scrollModifier);
        });
        loopyInterval = loopy.getInterval();
        return {
            mouseleave: mouseleave,
            mousemove: mousemove,
            loopy: loopy
        };
    };

    /**
     * Called from scrollbar resizer, to update autoscroll variables
     */
    pageeditor.updateDragDrop = function () {
        scroll = clientFrameWindow.document.querySelector(".scroll");
        container = clientFrameWindow.document.querySelector("body");
        const frameWindow = window.document.querySelector("#element-canvas-iframe");

        elementHeight = frameWindow.offsetHeight;
        contentHeight =  Math.round(clientFrameWindow.document.documentElement.getBoundingClientRect().height);
        heightDown = elementHeight / goldenRatio;
        heightUp = (elementHeight - heightDown) / 2;
        heightDown = heightDown + heightUp;
        if (debugScrollingVisually) {
            clientFrameWindow.$("#heightDownVisualizer").css("top", heightDown + "px");
            clientFrameWindow.$("#heightUpVisualizer").css("height", heightUp + "px");
        }
    };

    function mouseleave(/* event */) {
        scrollModifier = 0;
        loopy.stop();
    }

    function mousemove(e) {
        let mousePos = e.originalEvent.clientY,
            scrOfY = getScrollTop(clientFrameWindow);


        if (mousePos <= heightUp) {
            if (scrOfY > 0) {
                scrollModifier = -Math.exp(-( scrollScale * ((mousePos - heightUp) / elementHeight) ));
                loopy.start();
            } else {
                loopy.stop();
            }
        } else if (mousePos >= heightDown) {
            if (scrOfY < contentHeight) {
                scrollModifier = Math.exp(scrollScale * ((mousePos - heightDown) / elementHeight));
                loopy.start();
            } else {
                loopy.stop();
            }
        } else {
            scrollModifier = 0;
            loopy.stop();
        }
    }

    window.autoScroll = autoScroll;

    /**
     * Toolbar resize stuff
     */
    let $elementToolbar = $("#page-main .element-toolbar"),
        $page = $("#page-main  .page-container"),
        $elementToolbarResizer = false;

    $(document).on("mousedown", "#toolbar-handle", function (/* event */) {
        let documentWidth = $(document).width() + 8,
            toolbarWidth,
            maxWidth = $(document).width() / 2,
            minWidth = toolbarWidthDefault;

        if ($elementToolbarResizer !== false) {
            $(document).off("mousemove", $elementToolbarResizer);
        }

        $("body").addClass("resizing");
        $elementToolbarResizer = function (e) {
            toolbarWidth = documentWidth - e.clientX;
            if (toolbarWidth < minWidth) {
                toolbarWidth = minWidth;
            } else if (toolbarWidth > maxWidth) {
                toolbarWidth = maxWidth;
            }

            localStorage.setItem("pageeditor_toolbarwidth", toolbarWidth);

            resizeToolbar(toolbarWidth);
        };
        $(document).on("mousemove", $elementToolbarResizer);
    });

    function resizeToolbar(width) {
        width = width || toolbarWidthDefault;
        let toolbarWidthNegative = width + "px";
        $elementToolbar.width(width);

        $page.width("calc(100% - " + toolbarWidthNegative + " + 14px)");
    }
    $(document).on("mouseup", function (/* event */) {
        if ($elementToolbarResizer !== false) {
            $(document).off("mousemove", $elementToolbarResizer);
            $elementToolbarResizer = false;
            $("body").removeClass("resizing");
        }
    });
    resizeToolbar(localStorage.getItem("pageeditor_toolbarwidth"));

})();

function initializeIframe() {

    $("#element-filter-select").on("select2:select", function (e) {
        let data = e.params.data;
        if (data.id === "layout") {
            toggleLayout();
        } else {
            toggleElements();
            if (data.id === "all") {
                $("#element-library div[class^='el-card']").not("[data-layout='1']").removeClass("hidden-tag").fadeIn(300);
            } else {
                let $matches = $("#element-library div[data-tags*='" + $(this).val() + ",']");
                //show matching
                $matches.removeClass("hidden-tag").fadeIn(300);
                //hide not matching
                $("#element-library .el-card").not($matches).addClass("hidden-tag").fadeOut(0);
            }
        }
    });
    function toggleElements() {
        $(".elementCards .el-card[data-layout=\"1\"]").addClass("hidden-tag");
        //$("#element-filter").css("opacity", "1");
        $(".elementCards .el-card[data-layout=\"0\"]").removeClass("hidden-tag").fadeIn(300);
        $(".elementCards").removeClass("layout");
        clientFrameWindow.$(clientFrameWindow.document.documentElement).removeClass("backend-drag-drop-edit-layout");

        $(clientFrameWindow.document).find(".el-layout-container").find(".el-handle:not(.el-toolbar-button-disabled)").off("mousedown");
        $(clientFrameWindow.document).find(".el-card").draggableElement();
        //$(clientFrameWindow.document).find(".el-layout-container").draggableElement();
    }
    function toggleLayout() {
        $(".elementCards .el-card[data-layout=\"1\"]").removeClass("hidden-tag").fadeIn(300);
        //$("#element-filter").css("opacity", "0");
        $(".elementCards .el-card[data-layout=\"0\"]").addClass("hidden-tag");
        $(".elementCards").addClass("layout");
        clientFrameWindow.$(clientFrameWindow.document.documentElement).addClass("backend-drag-drop-edit-layout");

        $(clientFrameWindow.document).find(".el-card").find(".el-handle:not(.el-toolbar-button-disabled)").off("mousedown");
        $(clientFrameWindow.document).find(".el-layout-container").draggableElement();
        //$(clientFrameWindow.document).find(".el-card").draggableElement();
    }

    try {
        clientFrameWindow = $("#element-canvas-iframe").get(0).contentWindow;
    } catch (error) {
        console.log("iframe problem");
    }

    // Takes care of resize events inside of the iframe.
    // This is buggy. When html/body has 100% height, then this does not work correctly.
    // The body has to have the full page height.
    const resizeObserver = new ResizeObserver( () => {
        //updateScrollbarHandle();
        pageeditor.updateDragDrop();
        pageeditor.updateToolbarCentering();
    });
    resizeObserver.observe(clientFrameWindow.document.documentElement);

    // Resize trigger for the main window containing the iframe (content size does not change, when the main window height gets changed)
    //window.addEventListener("resize", updateScrollbarHandle, {passive: true});
    window.addEventListener("resize", pageeditor.updateDragDrop, {passive: true});
    window.addEventListener("resize", pageeditor.updateToolbarCentering, {passive: true});

    //$("#element-canvas-iframe").on("load", function () {
        // Initialize drag&drop autoscroll
        myAutoscroll = window.autoScroll(clientFrameWindow);

        //make our elements draggable
        $(clientFrameWindow.document).find(".el-card").draggableElement();
        //$(clientFrameWindow.document).find(".el-layout-container").draggableElement();

        //make our elements in the library draggable
        $("#element-library .elementCards").draggableElement();

        //remove button for elements
        $(clientFrameWindow.document).on("click", ".el-card-remove:not(.el-toolbar-button-disabled)", function () {
            if (true === confirm("Dieses Element löschen?")) {
                let $card = $(this.closest(".el-card"));
                triggerEvent("Pageeditor:remove-element", $card);
                $card.remove();

                $("#element-editor, #element-editor .form-container .el-card-form").hide();
                $("#element-library").show();

                pageeditor.updateStructure();
            }
        });

        //remove button for layout
        $(clientFrameWindow.document).on("click", ".el-row-remove:not(.el-toolbar-button-disabled)", function () {
            if (true === confirm("Diese Zeile löschen, es werden auch alle Elemente entfernt?")) {
                let $row = $(this.closest(".el-layout-container"));
                triggerEvent("Pageeditor:remove-layout", $row);
                $row.remove();
                pageeditor.updateStructure();
            }
        });

        //disable button for elements
        $(clientFrameWindow.document).on("click", ".el-card-enabled:not(.el-toolbar-button-disabled)", function () {
            let $lock = $(this).find("i"),
                $card = $(this).closest(".el-card"),
                enabled = +(!$card.data("enabled")),
                enabledEventText = enabled ? "enabled" : "disabled";

            $lock.toggleClass("pageeditor-toggle-on pageeditor-toggle-off");
            $card.attr("data-enabled", enabled).data("enabled", enabled);
            triggerEvent("Pageeditor:" + enabledEventText, $card);
            triggerEvent("Pageeditor:toggle", $card, {toggle: enabled});
            pageeditor.updateStructure();
        });

        //finish editing button
        $(document).on("click", "#pagedynamic-edit-close, .backToModules", function () {
            $("#element-editor .form-container .el-card-form").hide();
            let $currentButton = $(clientFrameWindow.document).find(".el-card-edit.active");
            $currentButton.removeClass("active");
            $(clientFrameWindow.document).find(".el-card.form-active").removeClass("form-active");
            $("#element-library").show();
            $("#element-editor").hide();
        });

        //start editing button
        $(clientFrameWindow.document).on("click", ".el-card-edit:not(.el-toolbar-button-disabled)", function () {
            let $card = $(this).closest(".el-card"),
                $form = $("#element-editor .form-container .el-card-form[data-identifier=\"" + $card.data("identifier") + "\"]"),
                $this = $(this),
                open = true;

            $("#element-editor .form-container .el-card-form").hide();
            if ($this.hasClass("active")) {
                //Form is open, user most likely wants to finish editing
                $this.removeClass("active");
                open = false;
            } else {
                $(clientFrameWindow.document).find(".el-card-edit.active").removeClass("active");
                $this.addClass("active");
            }

            //add title to backend sidebar
            $("[data-element-title-target]").text($form.attr("data-element-title"));

            //$(clientFrameWindow.document).find(".el-card .el-toolbar").show();
            $(clientFrameWindow.document).find(".el-card.form-active").removeClass("form-active");
            if (open) {

                $form.show();

                $("#element-library").hide();
                $("#element-editor").show();
                //$card.find(".el-toolbar").hide();

                $card.addClass('form-active');
                //open first collapsed element if not already opened
                $form.find(".el-form section").first().collapse({toggle: true});
                $form.find(".el-form header").first().removeClass("collapsed");
            } else {
                $form.hide();

                $("#element-library").show();
                $("#element-editor").hide();
            }
        });

        pageeditor.updateToolbarCentering();
    //});

    /**
     * Adds dropzones into the iframe
     *
     * @param targets The possible layouts the element is allowed to go in
     * @param source The current element (so it does not add dropzones onto itself)
     * @param isLayout
     */
    pageeditor.addMarker = function (source, isLayout) {

        let $dropMarker = $("<div class=\"drop-marker\"><div class=\"dp-wave-drop-container\"></div></div>");
        let $source = $(source);
        let $clientBody = $(clientFrameWindow.document.body);

        if (isLayout) {
            let containers = $clientBody.find(".el-layout-container");
            if (containers.length > 0) {
                $dropMarker.clone().insertAfter(containers.not($source));
                $dropMarker.clone().insertBefore(containers.first().not($source));
            } else {
                $clientBody.prepend($dropMarker.clone());
            }
        } else {
            let targets;
            // Only the "cards" in the element library contain the information about targets.
            // The one rendered in frontend, don't have ( it would require an additional DB lookup, so we just get info from Element Library)
            if (source.hasClass("el-handle")) {
                targets = source.data("targets");
            } else {
                targets = $("#element-library .elementCards  .el-card[data-code=\"" + source.data("code") + "\"]").data("targets");
            }
            if (targets === '') {
                targets = 'main';
            }

            targets = targets.split(",");
            $.each(targets, function (targetKey , target) {
                //$.each(positions, function (pos, value) {
                //if (pos === 'top') {

                //}
                //if (pos === 'all') {
                $dropMarker.clone().insertAfter($clientBody.find(".dp-layout-element." + target + " .el-card:not(:last-child)").not($source));
                //}
                //an empty row, this counts as top
                $dropMarker.clone().appendTo($clientBody.find(".dp-layout-element." + target + ":blank"));

                //if (pos === 'bottom') {
                //}
                $dropMarker.clone().insertAfter($clientBody.find(".dp-layout-element." + target + " .el-card:last-child").not($source));

                $dropMarker.clone().insertBefore($clientBody.find(".dp-layout-element." + target + " .el-card:first-child").not($source));
                //});
            });
        }
        // $(clientFrameWindow.document).find('#l-wrapper').prepend($('<div class="drop-spacer drop-spacer-top"></div>')).append($('<div class="drop-spacer drop-spacer-top-fixed"></div><div class="drop-spacer drop-spacer-bot"></div>'));
    };


    /**
     * Helper function, to listen for changes in the elements form
     */
    pageeditor.listenForChange = function($form) {
        //create a debounced function for updating the preview
        let myDebouncer = debounce(function ( /* event */) {
            pageeditor.loadPreview($form.data("identifier"), $form, false);
        }, 200, false);

        window.initFileUpload($form.find(".uploadFile"));
        window.fireInheritance.bind($form);
        //input, textarea fields (covers ckeditor in source mode)
        $form.on("keyup change", "input, textarea, select, iframe", myDebouncer);

        //listen for changes to mediamanager
        $form.find('.mediaformwidget').on("mediamanager:change",myDebouncer);

        //listen to ckeditor changes
        //$form.find('.textarea-ckeditor').each(function (i, el) {

        //});
    };

    /**
     * Ckeditor .. wait for ckeditor itself reporting an instance as ready, we then initialize our listener.
     */
    // CKEDITOR.on("instanceReady", function (evt) {
    //     var $formExtra = $(evt.editor.element.$).closest(".el-card-form");
    //     if ($formExtra.length) {
    //         var myDebouncerExtra = debounce(function (/* event */) {
    //             loadPreview($formExtra.data("identifier"), $formExtra, false);
    //         }, 200, false);
    //         evt.editor.on("change", myDebouncerExtra);
    //     }
    // });

    $("#element-editor .el-card-form").each(function () {
        pageeditor.listenForChange($(this));
    });

    /**
     * Load the elements frontend view, with the data provided in its form.
     *
     * @param identifier int
     * @param form
     * @param isLibrary
     * @param isLayout
     * @param $previewCard
     */
    let loadPreviewInstance;
    pageeditor.loadPreview = function(identifier, form, isLibrary, $previewCard, isLayout, isEnabled, isEditable) {
        if (loadPreviewInstance) {
            loadPreviewInstance.abort();
        }
        let $form = $(form),
            serialize, $mediaList, $this, $field, $fields, modelClass, modelId, dataId, fieldName, modelAttribute;

        $previewCard = $previewCard || clientFrameWindow.$(".el-card[data-identifier=\"" + identifier + "\"]");
        isLayout = isLayout || 0;

        // console.log(identifier, form, isLibrary, $previewCard);
        //Update CKEditor Textareas
        // for (var instanceName in CKEDITOR.instances) {
        //     CKEDITOR.instances[instanceName].updateElement();
        // }

        //Build hidden media fields, as serialize is gona process them automatically
        $mediaList = $form.find(".modelMedias.enabled .medialist li:not(.template)");
        let modelAttributeKey = 0,
            mediaIndex;
        $mediaList.each(function (/* index */) {
            $this = $(this);
            modelAttribute = $this.parents("div.modelMedias.enabled").attr("data-attr");
            if (modelAttributeKey !== modelAttribute) {
                mediaIndex = 0;
                modelAttributeKey = modelAttribute;
            } else {
                mediaIndex++;
            }

            modelClass = $this.attr("data-modelclass");
            modelId = $this.attr("data-modelid");
            dataId = $this.attr("data-id");
            if (isFunction($form.addHidden)) {
                $form.addHidden("MMedia[" + modelAttribute + "][" + mediaIndex + "][target_class]", modelClass, "mediacollectform");
                $form.addHidden("MMedia[" + modelAttribute + "][" + mediaIndex + "][target_id]", modelId, "mediacollectform");
                $form.addHidden("MMedia[" + modelAttribute + "][" + mediaIndex + "][file_id]", dataId, "mediacollectform");
                //$form.addHidden('MMedia[' + modelAttribute + '][' + index + '][media_id]', mediaId, 'mediacollectform');
                $fields = $this.find("[data-name]");
                $fields.each(function ( /* fieldindex */) {
                    $field = $(this);
                    fieldName = $field.attr("data-name");
                    $form.addHidden("MMedia[" + modelAttribute + "][" + mediaIndex + "][" + fieldName + "]", $field.val(), "mediacollectform");
                });
            }
        });

        //build post data from inputs
        serialize = $form.find("input, select, textarea").serialize();

        //remove again the hidden media fields, after serializing them
        $form.find("[mediacollectform=\"remove\"]").remove();

        // Probably because of 2 jquery running, data does not contain the info.
        // (windows has jquery, and the iframe has also a jquery)

        let enabled = isEnabled || $previewCard.attr("data-enabled") ;
        if (typeof enabled === "undefined") {
            enabled = 1;
        }

        let editable = isEditable || $previewCard.attr("data-enabled") ;
        if (typeof editable === "undefined") {
            editable = 1;
        }
        //add more params
        let params = {
            code: $form.data("code"),
            identifier: identifier,
            target_id: fire.modelId,
            target_class: fire.modelClass,
            enabled: enabled,
            isLayout: isLayout,
            isEditable: editable

        };
        serialize += "&" + $.param(params);

        //load the element
        loadPreviewInstance = $.post(
            fire.actionElement,
            serialize,
            function (data) {

                //hide existing errors - if any
                $form.find("div.alert").html("").hide();
                if (data.error) {
                    //show errors - if any

                    $.each(data.error, function (index, value) {
                        let $yiiField = jQuery("#" + index);
                        if ($yiiField.length > 0) {
                            $yiiField.siblings('.invalid-feedback').html(value).show();
                        }
                    });
                    /*
                    let yiiForm = $form.find("form");
                    if (0 === yiiForm.length) {
                        yiiForm = $form.closest("form");
                    }
                    console.log(form);
                    // see https://github.com/samdark/yii2-cookbook/blob/master/book/forms-activeform-js.md
                    yiiForm.yiiActiveForm("updateMessages", data.error, true);
                     */
                } else {
                    if (isLibrary) {
                        //inserting element from library
                        let dataElement = clientFrameWindow.$(data.html),
                            insertionPoint = clientFrameWindow.$(clientFrameWindow.document).find(".drop-marker:hover");
                        if (insertionPoint.length === 0) {
                            insertionPoint = clientFrameWindow.$(clientFrameWindow.document).find(".drop-marker.el-loader");
                        }
                        if (insertionPoint.length > 0) {
                            if (isLayout) {
                                dataElement = dataElement.find(".el-layout-container").unwrap();
                            }
                            insertionPoint.after(clientFrameWindow.$(dataElement));
                            let $dataForm = $(dragndropAjaxRequestDataForm.content);
                            $("#element-editor .form-container").append($dataForm);
                            //TODO initFileUpload();
                            pageeditor.listenForChange($dataForm);
                            $(dataElement).draggableElement();
                            pageeditor.updateStructure();
                            $(dataElement).find(".el-card-edit:not(.el-toolbar-button-disabled)").click();
                            triggerEvent("Pageeditor:insert", $(dataElement));
                            triggerEvent("Pageeditor:insert", $dataForm);

                        }
                        $(clientFrameWindow.document).find(".drop-marker, .drop-spacer").remove();
                    } else {
                        let triggerAction;
                        //either update element, or move element
                        if (!$previewCard.hasClass("el-card")) {
                            //we are moving an element - lets remove the "old" element; previewCard is the marker in this case
                            clientFrameWindow.$(clientFrameWindow.document).find(".el-card[data-identifier=\"" + identifier + "\"]").remove();
                            triggerAction = 'move';
                        } else {
                            triggerAction = 'change';
                        }
                        let $new = clientFrameWindow.$(data.html);
                        $previewCard.replaceWith($new);
                        pageeditor.updateStructure();

                        $($new).draggableElement();
                        triggerEvent("Pageeditor:" + triggerAction, $new);
                        //$($new).find('.el-card-edit').click();

                    }
                    pageeditor.updateToolbarCentering();
                }
            },
            "json"
        ).fail(function (xhr, textStatus, errorThrown) {
            if ("abort" !== errorThrown) {
                console.error([xhr, textStatus, errorThrown]);
            }
        });
    };

    /**
     * Creates a JSON containing the structure of the page,
     * E.e. Which layout (and if its disabled), and the elements contained in the layout.
     */
    pageeditor.updateStructure = function() {
        const hiddenField = $("#pageeditor-json"),
            $rows = clientFrameWindow.$(".el-layout-container");
        let structure = {};

        // EachlLayout
        let layoutIndex = 0;
        $rows.each(function() {
            const $cols = clientFrameWindow.$(this).find(".dp-layout-element"),
                layoutCode = clientFrameWindow.$(this).data("code");
            layoutIndex++;
            structure[layoutIndex] = {
                code: layoutCode,
                cols: {}
            };

            // Each column in layout
            $cols.each(function() {
                const type = clientFrameWindow.$(this).data("type"),
                    $elements = clientFrameWindow.$(this).find(".el-card");
                structure[layoutIndex].cols[type] = {};

                // Each element in column
                $elements.each(function(){
                    // Probably because of 2 jquery running, data does not contain the info.
                    // (windows has jquery, and the iframe has also a jquery)
                    const enabled =  parseInt(clientFrameWindow.$(this).attr("data-enabled"), 10),
                        elementCode = clientFrameWindow.$(this).data("code"),
                        identifier = clientFrameWindow.$(this).data("identifier"),
                        $form = $("#element-editor .el-card-form[data-code='"+elementCode+"'][data-identifier='"+identifier+"']");

                    let fields = {},
                        inheritances = {},
                        medias = {},
                        NamedGroups = {
                            magic: 1,
                            modelAttribute: 2,
                            id: 3,
                            mediaAttributename: 4
                        };
                    $form.find("input, textarea, select").each(function(){

                        //ignore the upload button & sort-selector-dropdown from mediamanager
                        if ( "file" !== $(this).attr("type") && !$(this).hasClass("mmSortSelector") && !$(this).hasClass("pageeditor-ignore-updatestructure")) {
                            let attrNameValue = $(this).attr("name");
                            if (attrNameValue) {
                                // check if is the media stuff
                                let data = /^Page\[.*]\[.*]\[.*]\[(.*)]\[(.*)]\[(.*)]\[(.*)]/.exec(attrNameValue);


                                if(null === data) {
                                    let name = /^Page\[.*]\[.*]\[.*]\[(.*)]/g.exec(attrNameValue)[1];

                                    if("inherit" === name) {
                                        // if is the inheritance checkbox
                                        let name = /^Page\[.*]\[.*]\[.*]\[(.*)]\[inherit]$/g.exec($(this).attr("name"))[1];
                                        inheritances[name] = $(this).is(":checked") ? 1 : 0;
                                    } else if ("" === name) {
                                        // this is either a radio or a checkbox list
                                        let name = /^Page\[.*]\[.*]\[(.*)]\[.*]/g.exec(attrNameValue)[1];
                                        if (!Array.isArray(fields[name]) ) {
                                            if ("" !== fields[name]) {
                                                console.log("element with input name " + name + " has unexpected value " + fields[name]);
                                            }
                                            fields[name] = [];
                                        }
                                        if ($(this).is(':checked')) {
                                            fields[name].push($(this).val());
                                        }
                                    } else if ($(this).is(':checkbox, :radio')) {
                                        // this is a checkbox
                                        if ($(this).is(':checked')) {
                                            fields[name] = $(this).val();
                                        }
                                    } else {
                                        fields[name] = $(this).val();
                                    }

                                } else if('medias' === data[NamedGroups.magic] && data[NamedGroups.id] > -1 && $(this).closest(".medialist_i18n_source").length === 0) {
                                    // index with -1 is the template
                                    // ignore items in medialist_i18n_source

                                    let mediaModelAttribute = data[NamedGroups.modelAttribute],
                                       mediaId = data[NamedGroups.id],
                                       mediaAttribute = data[NamedGroups.mediaAttributename],
                                       mediaValue = $(this).val();

                                    if(!(mediaModelAttribute in medias)) {
                                        medias[mediaModelAttribute] = {};
                                    }
                                    if(!(mediaId in medias[mediaModelAttribute])) {
                                        medias[mediaModelAttribute][mediaId] = {};
                                    }
                                    medias[mediaModelAttribute][mediaId][mediaAttribute] = mediaValue;

                                }
                                // This prevents the existing fields to be submitted with the form.
                                // As we generate a complete JSON containing all data, it is not needed.
                                $(this).attr("form", "pageeditor-dummy-form");
                            }
                        }
                    });
                    structure[layoutIndex].cols[type][identifier] = {
                        code: elementCode,
                        enabled: enabled,
                        fields: fields,
                        medias: medias,
                        inheritances: inheritances
                    };
                });
            });
        });
        hiddenField.val(JSON.stringify(structure));
    };

    pageeditor.updateStructure();


    // Check if layout exists, else toggle layout on automatically.
    (function() {
        let $clientBody = $(clientFrameWindow.document.body);
        let containers = $clientBody.find(".el-layout-container");

        if (containers.length === 0) {
            $("#element-filter-select").val("layout")
            .trigger("change")
            .trigger({
                type: 'select2:select',
                params: {
                    data: {
                        id: "layout"
                    }
                }
            });
        }
    }());

    //disable editable button if its disabled - we save through this some time when rendering - as we do not have to make db lookup
    (function() {
        let $clientBody = $(clientFrameWindow.document.body);
        let containers = $clientBody.find(".el-card");
        containers.each(function(_, el) {
           let code = $(el).attr('data-code');
           let editButton = $(el).find(".el-card-edit");
           let editable = $(".el-card[data-code='"+code+"']").data('editable');
           if (0 === parseInt(editable, 10)) {
               editButton.addClass("el-toolbar-button-disabled");
           }
        });

    }());
};


/**
 * The window and the iframe load at different times, sometimes iframe is first, sometimes the window.
 * Therefor we use a counter to keep track of loading.
 */
// iframeLoaderInit exposed, so we can call it from the iframe
window.iframeLoaderInit = function() {
    if (window.iframeLoaderCounter >= 2) {
        initializeIframe();
    }
}

document.addEventListener('DOMContentLoaded', function() {
    window.iframeLoaderCounter ? window.iframeLoaderCounter++ : window.iframeLoaderCounter = 1;
    iframeLoaderInit();
}, {once: true, capture: true});