<template>
    <div class="row">
        <div class="col-12">
            <input type="hidden" name="Segment[query]" :value="jsonString" style="width:1200px">
            <input type="hidden" name="Segment[op]" :value="queryOp" style="width:1200px">
            <button class="btn btn-sm btn-secondary" type="button" @click="addGroup">{{labels.addGroup}}</button>
            <button class="btn btn-sm btn-secondary" type="button" @click="addRule">{{labels.addRule}}</button>
            <br>
            <br>
            <checkbox-switch classes="is-warning" v-model="queryOp" @input="updatePreview">
                <template #before>OR</template>
                <template #after>AND</template>
            </checkbox-switch>
            <segment-nested-draggable :query="query" :labels="labels" :rules="rules"  :class="queryOp ? 'dragArea op-not' : 'dragArea op-and' "/>
        </div>
    </div>
</template>

<script>
import segmentNestedDraggable from "./SegmentNestedDraggable.vue";
import CheckboxSwitch from "./components/CheckboxSwitch.vue";

export default {
    components: {
        segmentNestedDraggable,
        CheckboxSwitch
    },
    props: {
        /** The translated labels */
        labels: {
            required: true,
            type: Object
        },
        /** The available filter rules */
        rules: {
            required: true,
            type: Object
        },
        /** The query */
        passquery: {
            required: true,
            type: Array
        },
        passop: {
            required: true,
            type: Boolean
        }
    },
    data() {
        if (!(this.passquery instanceof Array)) {
            this.passquery = []
        }
        return {
            query: this.passquery,
            queryOp: this.passop
        }
    },
    computed: {
        /** Creates the json string we save into db */
        jsonString() {
            if (window.updatePreview) {
                window.updatePreview()
            }
            return JSON.stringify( this.query, null, null)
        },

    },
    methods: {
        uniqueId() {
            return "_" + performance.now().toString().replace(".", "");
        },
        addGroup() {
            this.query.splice(0,0, {
                id: this.uniqueId(),
                type: "group",
                query: [],
                op: true
            });
        },
        addRule() {
            this.query.splice(0,0, {
                id:  this.uniqueId(),
                type: "rule",
                value: "",
                value2: "",
                operator: "equal",
                attribute: ""
            });
        },
        updatePreview() {
            if (window.updatePreview) {
                window.updatePreview()
            }
        }
    }
};
</script>