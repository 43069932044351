var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "topic--container" }, [
    _c("div", { staticClass: "topic--details" }, [
      _c("div", { staticClass: "details--image" }, [
        _c(
          "div",
          { key: _setup.item.id, staticClass: "topic--detail--image" },
          [
            _c(_setup.ImageLoader, {
              attrs: {
                src: _setup.getAssetPathOfTargetAttr(
                  "product_image",
                  _setup.item
                ),
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail__section-image-background" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "details--content" }, [
        _c(
          "div",
          { key: _setup.item.id, staticClass: "topic--detail--content" },
          [
            _c(
              "div",
              { staticClass: "content__image content__image--first" },
              [
                _c("div", { staticClass: "v-responsive__sizer" }),
                _vm._v(" "),
                _c("div", { staticClass: "content__image--reveal" }),
                _vm._v(" "),
                _c(_setup.ImageLoader, {
                  staticClass: "topic--detail_image",
                  attrs: {
                    src: _setup.getAssetPathOfTargetAttr(
                      "main_image",
                      _setup.item
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content__image content__image--second" },
              [
                _c("div", { staticClass: "v-responsive__sizer" }),
                _vm._v(" "),
                _c(_setup.ImageLoader, {
                  staticClass: "topic--detail_image",
                  attrs: {
                    src: _setup.getAssetPathOfTargetAttr(
                      "left_image",
                      _setup.item
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content__image content__image--third" },
              [
                _c("div", { staticClass: "v-responsive__sizer" }),
                _vm._v(" "),
                _c(_setup.ImageLoader, {
                  staticClass: "topic--detail_image",
                  attrs: {
                    src: _setup.getAssetPathOfTargetAttr(
                      "right_image",
                      _setup.item
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "textbox-wrapper" }, [
              _c("div", { staticClass: "content--title" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_setup.item.title) +
                    "\n                        "
                ),
                _c("div", { staticClass: "content--year" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_setup.item.subtitle) +
                      "\n                        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content--description" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_setup.item.description) +
                    "\n                    "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail__section-content-background" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }