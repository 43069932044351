<template>
    <div>
        <div class="mb-5">
            <div class="h5 text-muted mb-3">
                {{ $translations['element.scale'] }}
            </div>
            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-content">
                {{ $translations['section.content'] }}
            </header>
            <section id="section-content" class="collapse">
                <form-checkbox
                    id="mandatory-checkbox"
                    v-model="item.data.mandatory"
                    :label="$translations['label.mandatory']" />

                <form-input
                    v-if="item.data.mandatory"
                    id="warning-notification"
                    v-model="item.data.mandatory_warning"
                    :label="$translations['label.mandatory-warning']" />

                <form-input
                    id="question"
                    v-model="item.data.question"
                    :label="$translations['label.label']" />

                <form-textarea
                    id="description"
                    v-model="item.data.description"
                    :label="$translations['label.description']" />

                <form-select
                    id="steps"
                    v-model="item.data.scaleSteps"
                    :label="$translations['label.steps']"
                    :options="stepOptions" />

                <form-input
                    id="scaledefaultselected"
                    v-model="item.data.scaledefaultselected"
                    :label="$translations['label.default-value']"
                    type="number" />

                <form-select
                    id="steps"
                    v-model="item.data.scaleIcon"
                    :label="$translations['label.icon']"
                    :options="stepIcons" />

                <form-input
                    id="scaleMinLabel"
                    v-model="item.data.scaleMinLabel"
                    :label="$translations['label.min']" />

                <form-input
                    id="scaleMaxLabel"
                    v-model="item.data.scaleMaxLabel"
                    :label="$translations['label.max']" />
            </section>

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-dependencies">
                {{ $translations['section.dependencies'] }}
            </header>

            <LogicSection
                :item="item"
                :logic-options="logicOptions"
                @add-rule="addRule"
                @remove-rule="removeRule"
                @enable-logic="enableLogic" />

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-advanced">
                {{ $translations['section.advanced'] }}
            </header>
            <section id="section-advanced" class="collapse">
                <form-input
                    id="unique-label"
                    v-model="item.data.uniqlabel"
                    :label="$translations['label.uniqlabel']" />
                <form-input
                    id="htmloptions"
                    v-model="item.data.htmloptions"
                    :label="$translations['label.css-class']" />
                <form-checkbox
                    id="html-purify"
                    v-model="item.data.htmlpurify"
                    :label="$translations['label.html-purify']" />
                <form-input
                    id="customvalidation"
                    v-model="item.data.customvalidation"
                    :label="$translations['label.regex']" />
                <form-input
                    id="customerrormessage"
                    v-model="item.data.customerrormessage"
                    :label="$translations['label.regex-warning']" />
            </section>
        </div>

        <div>
            <FooterActions @save="save" />
        </div>
    </div>
</template>

<script>
import eventBus from "../../util/EventBus";
import FooterActions from "./parts/FooterActions.vue";

import formInput from "./formparts/textinput.vue";
import formCheckbox from "./formparts/checkbox.vue";
import formTextarea from "./formparts/textarea.vue";
import formSelect from "./formparts/select.vue";

import LogicSection from "./formparts/LogicSection.vue";

import logicMixin from '../../mixins/logic';
import previewMixin from '../../mixins/preview';

export default {
    name: "Scale",
    components: {
        FooterActions,
        formInput,
        formTextarea,
        formCheckbox,
        formSelect,
        LogicSection
    },
    mixins: [logicMixin, previewMixin],
    props: {
        element: {
            type: Object
        }
    },
    data: function() {
        return {
            item: {
                data: {
                    view: '',
                    id: '',
                    mandatory: false,
                    mandatory_warning: '',
                    question: '',
                    description: '',
                    scaleSteps: 3,
                    scaleMinLabel: '',
                    scaleMaxLabel: '',
                    scaledefaultselected: 0,
                    // extended form
                    uniqlabel: '',
                    htmloptions: '',
                    htmlpurify: false,
                    defaultvalueclass: '',
                    defaultvaluefunction: ''
                },
                logic: {}
            },
            stepOptions: [],
            stepIcons: [
                {
                    label: 'Star',
                    value: 'star'
                },
                {
                    label: 'Heart',
                    value: 'heart'
                },
                {
                    label: 'Circle',
                    value: 'circle'
                }
            ]
        };
    },
    mounted() {
        // set step options
        for (let i = 3; i <= 10; i++) {
            this.stepOptions.push( { value: i, label: `${i}` } );
        }

        for (const key in this.element.items[0].data) {
            this.$set(this.item.data, key, this.element.items[0].data[key]);
        }

        this.$set(this.item, 'logic', this.element.items[0].logic);
    },
    methods: {
        save() {
            eventBus.$emit("saveElement", this.item);
        }
    }
};
</script>