<template>
    <transition name="fade-transition">
        <div v-if="isLoading" class="loading--container" key="loading" :style="styles" :class="cssClass">
            <div class="spinner-border" :style="spinnerStyles" :class="color" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'LoadingElement',
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        absolute: {
            type: Boolean,
            default: false
        },
        center: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'text-dark'
        },
        bgColor: {
            type: String,
            default: '#fff'
        },
        zIndex: {
            type: Number,
            default: 5
        },
        width: {
            type: [Number, String],
            default: -1
        },
        height: {
            type: [Number, String],
            default: -1
        },
        border: {
            type: [Number, String],
            default: -1
        }
    },
    computed: {
        genWidth () {
            if (this.width === -1) {
                return ''
            }
            return typeof this.width === 'number' ? `${this.width}px` : this.width
        },
        genHeight () {
            if (this.height === -1) {
                return ''
            }
            return typeof this.height === 'number' ? `${this.height}px` : this.height
        },
        genBorder () {
            if (this.border === -1) {
                return ''
            }
            return typeof this.border === 'number' ? `${this.border}px` : this.border
        },
        cssClass() {
            return {
                'loader--absolute': this.absolute,
                'loader--center': this.center
            }
        },
        styles() {
            return {
                'background-color': this.bgColor,
                'z-index': this.zIndex,
            }
        },
        spinnerStyles() {
            return {
                'width': this.genWidth,
                'height': this.genHeight,
                'border-width': this.genBorder
            }
        }
    }
}
</script>