export const getAssetPathOfTargetAttr = ( targetAttr , content) => {
    const selectedScreenMedias = content.medias.filter((item) => {
        return item.targetAttr === targetAttr
    })

    if (selectedScreenMedias && selectedScreenMedias.length > 0) {
        if (selectedScreenMedias[0].thumbnail.includes('webm' ) || selectedScreenMedias[0].thumbnail.includes('mp4')) {
            //if video, take "path"
            return selectedScreenMedias[0].path
        } else if(selectedScreenMedias[0].thumbnail) {
            //if video, take "path
            return selectedScreenMedias[0].thumbnail
        } else if(selectedScreenMedias[0].path) {
            return selectedScreenMedias[0].path
        }
    } else {
        return null
    }
}

export const checkIfTargetAttrIsAvailable = ( targetAttr, content ) => {
    const selectedScreenMedias = content.medias.filter((item) => {
        return item.targetAttr === targetAttr
    })

    return selectedScreenMedias && selectedScreenMedias.length > 0
}