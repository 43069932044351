var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-bar d-flex justify-content-between w-100" },
    [
      _c("div", [_vm._v("Einstellungen")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", { staticClass: "toggle" }, [
            _c("div", { staticClass: "custom-control custom-switch" }, [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: {
                  type: "checkbox",
                  id: "ShopCategory_search",
                  name: `${_vm.inputNamePrefix}[filters][search][displayOnTop]`,
                  value: "1",
                },
                domProps: { checked: _vm.isChecked("search") },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "ShopCategory_search" },
                },
                [_vm._v("Suche")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filtersArray, function (filter, index) {
            return _c("div", { staticClass: "toggle" }, [
              _c("div", { staticClass: "custom-control custom-switch" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "checkbox",
                    id: `ShopCategory_${filter.attribute}`,
                    name: `${_vm.inputNamePrefix}[filters][${filter.attribute}][displayOnTop]`,
                    value: "1",
                  },
                  domProps: { checked: _vm.isChecked(filter.attribute) },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: `ShopCategory_${filter.attribute}` },
                  },
                  [_vm._v(_vm._s(filter.label))]
                ),
              ]),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }