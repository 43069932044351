<template>
    <div>
        <div
            v-for="notification in notifications"
            :key="notification.id"
            class="alert"
            :class="[`alert-${notification.type}`]"
            role="alert">
            {{ notification.message }}
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import eventBus from "../util/EventBus";

export default {
    name: 'Notification',
    data: function() {
        return {
            notifications: []
        };
    },
    mounted() {
        eventBus.$on('add-notification', (notification) => {

            const id = uuidv4();

            notification.id = id;
            this.notifications.push(notification);

            setTimeout(() => {
                this.notifications = this.notifications.filter(notification => notification.id !== id);
            }, 5000);
        });
    }
};
</script>