<template>
  <transition :name="transitionName">
    <img
      :src="src"
      :width="imageWidth"
      :height="imageHeight"
      alt="#"
      :style="cssStyles"
      @load="loaded">
  </transition>
</template>

<script setup>
import { computed, ref, defineEmits } from 'vue'
const props = defineProps({
  src: { type: String },
  transition: [ String, Boolean ],
  styles: Object,
  imageWidth: {
    type: Number
  },
  imageHeight: {
    type: Number
  }
})
const isLoaded = ref(false)
const cssStyles = computed(() => {
  return Object.assign(props.styles || {}, {
    opacity: isLoaded.value ? 1 : 0
  })
})
const transitionName = computed(() => props.transition || 'fade')

const emit = defineEmits([ 'image-loaded' ])
const loaded = () => {
  isLoaded.value = true
  emit('image-loaded')
}
</script>

<style scoped lang="scss">
img {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: rotate(20deg) translateY(200px);
}
</style>
