<template>
    <div>
        <div v-for="group in config.groups" :key="group.key">
            <div class="text-muted text-uppercase p-3">
                {{ group.label }}
            </div>

            <draggable
                :list="group.elements"
                :sort="false"
                :group="{ name: 'structure', pull: 'clone', put: false }"
                :clone="clone">
                <div
                    v-for="(element, index) in group.elements"
                    :key="index"
                    class="sidebar-element shadow-sm p-3 mx-1 mb-1 rounded">
                    <i class="fa mr-2" :class="[`fa-${element.icon}`]" /> {{ element.label }}
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import draggable from "vuedraggable";
import config from "../config/config";

export default {
    name: "SidebarElements",
    components: {
        draggable
    },
    props: {
        structure: {
            type: Array
        }
    },
    data: function() {
        return {
            config: config
        };
    },
    methods: {
        clone(control) {
            let element;

            if (control.type === 'formitem') {
                element = {
                    type: control.type,
                    items: [
                        {
                            data: {
                                id: uuidv4(),
                                view: control.view,
                                uniqlabel: this.getUniqueLabel(control),
                                inputtype: control.inputtype,
                                ...control.initData
                            },
                            logic: {}
                        }
                    ]
                };

                // add ids to answer options
                if (['radio', 'checkbox', 'select'].includes(control.view)) {
                    element.items[0].data.answeroptions = element.items[0].data.answeroptions.map(option => ({
                        id: uuidv4(),
                        ...option
                    }));
                }
            }

            if (control.type === 'formsection') {
                element = {
                    type: control.type,
                    items: [],
                    data: {
                        id: uuidv4(),
                        logic: {},
                        ...control.initData
                    }
                };
            }

            return element;
        },
        getUniqueLabel(control) {
            let uniqlabel = control.view + (control.inputtype ? '_' + control.inputtype : "");

            let count = 0;

            this.structure.forEach(structureItem => {
                structureItem.items.forEach(item => {
                    if (item.data && item.data.uniqlabel.startsWith(uniqlabel)) {
                        count += 1;
                    }
                });
            });

            if (count > 0) {
                uniqlabel = uniqlabel + "_" + count;
            }

            return uniqlabel;
        }
    }
};
</script>

<style lang="scss">
.sidebar-element {
    cursor: move;
    background-color: #fff;

    &:hover {
        background-color: #f3f6f5;
    }
}
</style>