// Base imports
import 'expose-loader?exposes=jquery-ui!jquery-ui';
// Change JQueryUI plugin names to fix name collision with Bootstrap.
$.widget.bridge('uitooltip', $.ui.tooltip);
$.widget.bridge('uibutton', $.ui.button);
import 'bootstrap/dist/js/bootstrap.js';

import 'expose-loader?exposes=sortable!../../node_modules/jquery-ui/ui/widgets/sortable.js';
import 'expose-loader?exposes=pjax!../../node_modules/yii2-pjax/jquery.pjax.js';
import 'expose-loader?exposes=gridView!../../vendor/yiisoft/yii2/assets/yii.gridView.js';
import 'expose-loader?exposes=nestedSortable!../../vendor/firegroup/fire-core-nested-sets/src/assets/js/jquery.mjs.nestedSortable.js';

// Fix missing functions in document javascript
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
window.confirmDatePlugin = ConfirmDatePlugin;

// Import generated assets
import './assets/generatedAssets';

// Import styles
import '../styles/styles.js';

// Custom scripts
import './select2/initSelect2.js';