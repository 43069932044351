<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.checkbox'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div v-if="element.items[0].data.answeroptionsclass === '' && element.items[0].data.answeroptionsfunction === ''" class="form-group">
                <label>{{ element.items[0].data.question }}</label>
                <div v-for="(answer, index) in element.items[0].data.answeroptions" class="form-check">
                    <input
                        :id="`checkbox-${index}`"
                        class="form-check-input"
                        type="checkbox"
                        name="exampleRadios"
                        value="">
                    <label class="form-check-label" :for="`checkbox-${index}`">
                        {{ answer.label }}
                    </label>
                </div>

                <div v-if="element.items[0].data.customanswer" class="form-check">
                    <input
                        id="checkbox-custom"
                        class="form-check-input"
                        type="checkbox"
                        name="exampleRadios"
                        value="">
                    <label class="form-check-label" for="checkbox-custom">
                        {{ element.items[0].data.customanswerlabel }}
                    </label>
                </div>

                <input
                    v-if="element.items[0].data.customanswer"
                    class="form-control mt-3"
                    :placeholder="element.items[0].data.customanswerplaceholder">

                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
            <div v-else>
                <div class="form-group">
                    <label>{{ element.items[0].data.question }}</label>
                </div>
                <div>{{ $translations['label.generatedby'] }} <code>{{ `${element.items[0].data.answeroptionsclass}::${element.items[0].data.answeroptionsfunction}` }}</code></div>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Checkbox",
    mixins: [previewItemMixin],
};
</script>