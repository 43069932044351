import {getPDFThumbnailFromServer} from './pdf_thumbnail';

if (!window.MediaManager) {
    window.MediaManager = new function () {

        this.selectedFiles = [];
        this.selectedFilesObject = [];
        this.controllerUrl = '';

        this.standalone = false;
        this.ckeditor = {};
        this.listCriteria = {
            name: null,
            category: null,
            filedir: null,
            date: {
                from: null,
                to: null,
            },
            tags: [],
            mimes: [],
            sort: {},
        };
        this.dialog = null;

        this.InfScroll = false;

        this.recalculateScroll = function () {
            if (!this.InfScroll) this.InfScroll = new InfScroll();
            this.InfScroll.calcResize();
            this.InfScroll.recalculate();
        };

        this.toggleBodyScroll = function (val) {
            $('body').css({
                overflowY: val ? 'scroll' : 'hidden'
            });
        };

        this.setDefaultCriteria = function () {
            this.listCriteria = {
                name: null,
                category: null,
                filedir: null,
                date: {
                    from: null,
                    to: null,
                },
                tags: [],
                mimes: [],
                sort: {"updated_at": "desc"},
            };

            $("#mmSearchInput").val("");

            $("#mediamanager input:not(:checkbox).mm_applysettings").val("");
            $("#mediamanager input:checkbox.mm_applysettings").prop("checked", false);

            $("#mediacategoryFilter").find("li").removeClass("active");
            $(".mm-tag-selector").removeClass("active");
            $(".mm-filedir-treestructure .item.active").removeClass("active");
            //$(".mm-dir-selector").removeClass("active");
            //$("#mm-dir-list").find("span.dir-name.active").removeClass("active");

            $(".mediaSorter .mm-sort").removeClass("asc desc").attr("data-order", "");
            $(".mediaSorter .mm-sort[data-sort=updated_at]").addClass("desc").attr("data-order", "desc");

            //this.updateListView();
        };

        // update media list
        // mode:
        // 'init' -> reload & reset full list
        // 'append' -> (paging) append new elements at the bottom
        // 'prepend' -> (paging) prepend new elements at the top
        this.updateListView = function (args, paging, mode, callback) {
            args = args || null;
            paging = paging || null;
            mode = mode || "init";

            var self = this;
            this.updateButtonsAndSelectedFiles(mode !== "init");

            args = args || self.listCriteria;

            if (!self.InfScroll) self.InfScroll = new InfScroll();

            if (mode === "init") {
                self.InfScroll.calcResize(mode);   // initial calculation for paging
                args.total_count = "true"; // retrieve the total count for this query as well (without pagination)
            } else {
                args.total_count = "false";
            }

            args.paging = paging || self.InfScroll.initialPaging;

            console.log("fetching elements, offset:" + args.paging.offset + " limit:" + args.paging.limit + " mode: " + mode);

            self.showLoadingAnimation();

            $.post(window.MediaManager.controllerUrl + 'listfiles', args, function (data) {
                data = JSON.parse(data);

                if (mode === "init") {
                    $("ul#mm_items .mm-file-container:not(.template)").remove();
                    $(".mm-content-header .directoryPath").text(self.getDirectoryPath());

                    $('.mm-content-header .results .total').text(data["info"]["base_total_count"]);
                    $('.mm-content-header .results .current').text(data["info"]["total_count"]);

                    self.InfScroll.recalculate(data["info"]["total_count"]);   // exact recalculation from elements, update container height & scrolling
                }

                if (mode === "prepend") {
                    // reverse order (elements are prepended one-by-one)
                    data["data"].reverse();
                }

                for (var i = 0; i < data["data"].length; i++) {
                    self.addItem(data["data"][i], mode, args.paging.offset + i);
                }

                self.hideLoadingAnimation();

                if (typeof (callback) === "function") {
                    callback(data);
                }
                //self.preSelectFiles();
            });
        };

        // only update a single media in the list without reloading the list
        this.updateListMedia = function (media) {
            if (media.updatedImgSrc) {
                media.item.find(".mm-file-thumb img").attr("src", media.updatedImgSrc);
            }
        }

        this.updateFileDirectories = function () {
            let self = this;
            $.post(window.MediaManager.controllerUrl + 'listfiledirs', {}, function (data) {
                $(".mm-nav .mm-filedir-treestructure").empty().append(data);

                $(".mm-nav .mm-filedir-treestructure").find('li > .item').each(function () {
                    if (self.drake_dirs) {
                        self.drake_dirs.containers.push(this);
                    } else {
                        self.drake_dirs = self.initDragula_dirs([this]);
                    }
                });
            });
        };

        this.updateButtonsAndSelectedFiles = function (keepSelection) {
            if (!keepSelection) {
                $('.mm-file, .mm-file-container').removeClass("selected");
                window.MediaManager.selectedFiles = [];
                window.MediaManager.selectedFilesObject = [];
            }

            var c = window.MediaManager.selectedFiles.length;

            //$(".btn-mediamanager.edit, .btn-mediamanager.delete").toggleClass("hidden",c<=0);
            $(".btn-mediamanager.delete").toggleClass("hidden", c <= 0);
            //$(".btn-mediamanager.upload").toggleClass("hidden",c>0);
            $(".btn-mediamanager.mm-add").toggleClass("disabled", c <= 0).prop("disabled", c <= 0);

            this.recalculateNavScrollBar();
        };

        this.recalculateNavScrollBar = function () {
            // re-initialize jquery.scrollbar in nav, because it doesnt always
            // automatically update max-height when parent-container elements have changed
            window.requestAnimationFrame(function () {
                $(".mm-nav .scroll-content.mm-nav-scrollable.scrollbar-macosx").scrollbar({
                    disableBodyScroll: true
                });
            });
        };

        this.close = function () {
            var self = this;
            window.requestAnimationFrame(function () {
                self.toggleBodyScroll(!self.standalone);

                $('.loadMediaManager').removeClass('active');
                $('.medialist').removeClass('active');
                $('.changeMediaButton').removeClass('active');
                $('#mediamanager').removeClass("open");
                $('body').removeClass("mediamanager-modal-open");
            });
        };

        this.open = function (args = {}) {
            var self = this;
            self.setDefaultCriteria();

            if (args.dialog) {
                self.dialog = args.dialog;
                self.dialog.hide();
                delete args.dialog;     // would throw errors on jquery buildParams()
            }
            if (args.category) {
                self.listCriteria.category = args.category;
            }
            self.targetMaxFiles = args.targetMaxFiles || false;
            self.targetExistingFiles = args.targetExistingFiles || false;
            self.preSelectedFiles = args.preSelectedFiles || false;

            self.toggleBodyScroll(self.standalone);
            //if(!self.standalone){
            $('#mediamanager').addClass("open");
            //}
            $('body').addClass("mediamanager-modal-open");
            //self.updateFileDirectories();
            self.updateListView(self.listCriteria);
        };

        this.preSelectFiles = function () {
            if (this.preSelectedFiles) {
                for (var i in this.preSelectedFiles) {
                    var el = $("div.mm-file[data-id='" + this.preSelectedFiles[i] + "']");
                    el.addClass("selected");
                    el.parent(".mm-file-container").addClass("selected");
                    el.find(".img-container").attr("data-count", (parseInt(i) + 1));
                }
            }
        };

        this.addItem = function (item, mode, index) {
            if (typeof (index) === "undefined") index = false;
            if (typeof (mode) === "undefined") mode = "append";
            var c = $(".mm-file-container.template").clone();
            c.removeClass("template");

            c.find(".mm-file")
                .attr("data-id", item.id)
                .attr("data-updated_at", item.updated_at)
                .attr("data-file_ending", item.file_ending)
                .attr("data-type", item.type);
            c.find(".mm-file-thumb img")
                //.attr("alt",item.name)
                .attr("src", item.src)
                //.attr("importance","low")
                .attr("data-file", item.imgurloriginal)
                .attr("data-thumbid", item.thumbid)
                .attr("data-thumbfile", item.thumbfile);
            c.find(".mm-file-col.name").text(item.name);
            c.find(".mm-file-col.date").text(item.created_at);
            c.find(".mm-file-col.size").text(formatBytes(item.size));
            c.find(".mm-file-col.dim").text(item.dim);
            c.find(".mm-file-col.label").text(item.label);
            c.find(".mm-file-col.sublabel").text(item.sublabel);
            c.find(".mm-file-col.description").text(item.description);
            c.find(".mm-file-col.link").text(item.link);
            c.find(".mm-file-col.linktitle").text(item.linktitle);
            c.find(".mm-file-col.type").html("<i class='type-icon " + item.category + "'></i>" + item.file_ending);

            c.find(".mm-file-col.hasPermissions").toggleClass('active', item.permissions > 0);

            c.find(".mm-file-menu .download a, .mm-file-menu .open-in-new a").attr("href", item.imgurloriginal);

            for (var tag in item.tags) {
                if (item.tags[tag].color && item.tags[tag].color !== "")
                    c.find(".mm-file-col.tags ul").append("<li data-tag-id='" + item.tags[tag].id + "' data-tag-name='" + item.tags[tag].name + "' style='background-color: " + item.tags[tag].color + "'></li>");
            }

            if (mode === "prepend") {
                //c.prependTo("ul#mm_items");
                c.insertAfter("ul#mm_items .mm_listview_header");
            } else {
                c.appendTo("ul#mm_items");
            }

            // save a reference to this dom element for the InfScroller
            if (index !== false && this.InfScroll.element_list[index]) {
                this.InfScroll.element_list[index].html_element = c.get(0);
            }

            if (this.drake_tags) {
                // add this element to dragula containers
                this.drake_tags.containers.push(c.get(0));
            } else {
                this.initDragula_tags(c.get(0));
            }

            if (this.drake_dirs) {
                this.drake_dirs.containers.push(c.get(0));
            } else {
                this.initDragula_dirs([c.get(0)]);
            }

            var indexExists = window.MediaManager.selectedFiles.indexOf(item.id);
            if (indexExists > -1) {
                c.toggleClass('selected');
                c.find(".mm-file")
                    .toggleClass("selected")
                    .attr("data-count", indexExists + 1);

            }
        };


        /////////////////////////////////////////
        // updating db search & sort criterias
        /////////////////////////////////////////

        this.updateSearchNameCriteria = function (name) {
            this.listCriteria.name = name || null;

            this.criteriaChanged();
        };
        this.updateCategoryCriteria = function (category) {
            this.listCriteria.category = category || null;

            this.criteriaChanged();
        };
        this.updateDateCriteria = function (from, to) {
            this.listCriteria.date.from = from || null;
            this.listCriteria.date.to = to || null;

            this.criteriaChanged();
        };
        this.updateTagsCriteria = function (tag, toggle, override) {
            if (override) {     // reset all previous tags and just apply this new one
                this.listCriteria.tags = [];
                $(".filterTags input.mm_applysettings[data-setting=tag]").each(function () {
                    $(this).prop("checked", $(this).val() === tag);
                });
            } else {

            }

            var i = this.listCriteria.tags.indexOf(tag);
            if (toggle && i === -1) {
                this.listCriteria.tags.push(tag);
            } else {
                this.listCriteria.tags.splice(i, 1);
            }

            this.criteriaChanged();
        };
        this.updateFiledirsCriteria = function (dir, toggle) {
            this.listCriteria.filedir = toggle ? dir : null;

            this.criteriaChanged();
        };
        this.updateMimesCriteria = function (mime, toggle) {
            var i = this.listCriteria.mimes.indexOf(mime);
            if (toggle && i === -1) {
                this.listCriteria.mimes.push(mime);
            } else {
                this.listCriteria.mimes.splice(i, 1);
            }

            this.criteriaChanged();
        };
        this.updateSortCriteria = function (attr, sortOrder) {
            if (sortOrder === "") {
                delete this.listCriteria.sort[attr];
            } else {
                this.listCriteria.sort[attr] = sortOrder;
            }

            this.criteriaChanged();
        };


        this.criteriaChanged = function () {
            this.updateListView(this.listCriteria);
        };


        this.showLoadingAnimation = function () {
            $(".mm-content .loading-animation").addClass("active");
        };

        this.hideLoadingAnimation = function () {
            $(".mm-content .loading-animation").removeClass("active");
        };


        this.initDragula_tags = function (c) {
            this.drake_tags = initTagDragNDrop(c);
        };

        this.initDragula_dirs = function (c = null) {
            this.drake_dirs = initImageDragNDrop(c);
        };


        // get full directory "path" for current selected directory
        this.getDirectoryPath = function () {
            var dir = $("#mm-dir-list .item.active");
            if (dir.length <= 0) return "";

            var current = dir.find(".item-badges span.tree-item-title").text();
            var path = current ? current + "/" : "";
            var parentDir = dir.parent('li').parent().closest("li[class^=mjs-]");
            while (parentDir.length > 0) {
                path = parentDir.find('> .item').find("span.tree-item-title").text() + "/" + path;
                parentDir = parentDir.parent().closest("li[class^=mjs-]");
            }
            return "../root/" + path;
        }
    };
}


/**
 * mediamanager image-scale slider
 * using jquery-ui/slider
 */
$(function () {
    var sliderDefaultValue = $("#mm_items").attr("data-zoom");        // default selected
    var sliderMin = 0;
    var sliderMax = 12;
    var minimizeTreshold = 4;           // maximum slider value to display in minimized style

    var $slider = $('#mm-slider');

    $slider.slider({
        range: 'min',
        min: sliderMin,
        max: sliderMax,
        value: sliderDefaultValue,
        slide: function (event, ui) {
            mmAdjustImage(ui.value, false)
        },
        change: function (event, ui) {
            mmAdjustImage(ui.value)
        },
    });

    //scale images around
    $(document).on("click", ".mm-slider-slideup", function () {
        $slider.slider("value", $slider.slider("value") + 1);
    });

    $(document).on("click", ".mm-slider-slidedown", function () {
        $slider.slider("value", $slider.slider("value") - 1);
    });

    function mmAdjustImage(val, saveSettings = true) {
        $("#mm_items").attr("data-zoom", val).toggleClass("minimized", parseInt(val) <= minimizeTreshold);
        window.MediaManager.recalculateScroll();

        if (saveSettings) {
            $.post(
                window.MediaManager.controllerUrl + 'usersettings',
                {
                    zoom: val
                },
                function (data) {
                }
            );
        }
    }
});


/**
 * MediaManager infinite scroll (pure JS, no jquery)
 *
 *
 * simplified example:
 * maximum number of elements visible in the container (calculated from container & element size) : 10
 * maximum number of elements to preload & buffer: 10 * 3 = 30
 * on scroll-down, remove the top 10 elements and load & append the next 10 elements below
 * on scroll-up, remove the bottom 10 elements and load & append the previous 10 elements above
 *
 * first, the container & element size have to be retrieved to calculate
 * - amount of elements per row
 * - amount of maximum visible rows
 * - amount of elements to preload with extra buffer above & below
 *
 * this has to be recalculated on every window-resize or zoom-value change
 *
 */
function InfScroll() {
    this.__list = document.querySelector("#mediamanager #mm_items");
    this.__viewContainer = document.querySelector("#mediamanager .mm-content");
    this.__scrollBar = document.querySelector("#mediamanager .mm-scrollbar");
    this.__scroller = document.querySelector("#mediamanager .mm-scrollbar .scroller");

    this.list_padding_x = 30;
    this.list_padding_y = 30;
    //this.header_height = 40 + this.list_padding_y;
    this.header_height = 90;
    this.listview_header_height = 45;
    this.listview_spacing = 4;

    // initial calculation on load and after zoom-change or window-resize
    this.calcResize = function (mode) {
        // need to re-initialise this for overlay-mediamanager for some reason :(
        this.__list = document.querySelector("#mediamanager #mm_items");
        this.__viewContainer = document.querySelector("#mediamanager .mm-content");
        this.__scrollBar = document.querySelector("#mediamanager .mm-scrollbar");
        this.__scroller = document.querySelector("#mediamanager .mm-scrollbar .scroller");

        var updateScrollPos = false;
        var firstEl = null;
        var lastEl = null;
        var previousScrollTop = this.__viewContainer.scrollTop || 0;
        var previousTotalListHeight = this.totalListHeight || 1;

        // reset (when filters changed, or page refresh)
        if (mode === "init") {
            this.element_list = null;
            this.total_elements = 0;
        } else {
            // find current loaded elements (in previous scale), to calculate where the new scroll-position should be after a window resize or zoom-slider change
            if (this.currentTopRow && this.currentBottomRow && this.elements_per_row && this.element_list) {
                // find current first loaded element
                for (var i = (this.currentTopRow - 1) * this.elements_per_row; i < this.currentTopRow * this.elements_per_row; i++) {
                    if (this.element_list[i] && this.element_list[i].loaded && firstEl === null) firstEl = i + 1;
                }
                // find current last loaded element
                for (var j = (this.currentBottomRow - 1) * this.elements_per_row; j < this.currentBottomRow * this.elements_per_row && j < this.total_elements; j++) {
                    if (this.element_list[j] && this.element_list[j].loaded) lastEl = j + 1;
                }
                updateScrollPos = true;
            }
        }

        this.elSize = this.getElementSize();
        this.rowHeight = this.elSize.height;                                             // row-height (px)

        this.elements_per_row = this.calcMaxElementsPerRow(this.elSize);                 // elements per row
        this.maxVisibleRows = this.calcMaxVisibleRows(this.elSize);                      // max visible rows in the viewport

        if (updateScrollPos) {
            this.currentTopRow = Math.ceil(firstEl / this.elements_per_row);
            this.currentBottomRow = Math.ceil(lastEl / this.elements_per_row);
        }


        this.total_rows = Math.ceil((this.total_elements || 0) / this.elements_per_row);    // total row-count (without paging)
        this.totalListHeight = this.rowHeight * (this.total_rows || 0);                  // total container-height (px) (without paging)

        this.initialPaging = {offset: 0, limit: this.maxVisibleRows * this.elements_per_row};

        this.initCustomScrollBar(this.totalListHeight); // calculate scrollbar-height
        this.initScrollEvent(this.__viewContainer, Math.floor((previousScrollTop / previousTotalListHeight) * this.totalListHeight));
    };


    /**
     * Recalculate container-height and other required parameters
     * (initially and f.e. after window-resize or zoom-slider update)
     *
     * @param elements      number of total elements in the list
     */
    this.recalculate = function (elements) {
        if (elements) {
            this.total_elements = parseInt(elements);                                       // total element-count  (without paging)
            this.total_rows = Math.ceil(this.total_elements / this.elements_per_row);    // total row-count (without paging)
            this.totalListHeight = this.rowHeight * this.total_rows;                        // total container-height (px) (without paging)
            this.initCustomScrollBar(this.totalListHeight); // calculate scrollbar-height

            this.currentTopRow = 1;
            this.currentBottomRow = Math.ceil(Math.min(this.initialPaging.limit, this.total_elements) / this.elements_per_row);

            this.element_list = [];
            for (var i = 0; i < this.total_elements; i++) {
                this.element_list[i] = new GridItem(i + 1);
                if (i < this.initialPaging.limit) {
                    this.element_list[i].visible = true;    // initial values for the first loaded elements
                    this.element_list[i].loaded = true;
                }
            }
        }

        this.updateListStyle();
    };

    this.updateListStyle = function () {
        this.currentListHeight = (this.currentBottomRow - this.currentTopRow + 1) * this.rowHeight;    // current container-height (loaded rows in the dom, without padding)

        this.listPaddingTop = ((this.currentTopRow - 1) * this.rowHeight) + (this.isListView() ? (this.listview_header_height + this.listview_spacing) : 0);
        this.listPaddingBottom = this.totalListHeight - this.currentListHeight - this.listPaddingTop;          // calculate padding-bottom to fill the exact scroll height needed

        this.__list.style.paddingBottom = this.listPaddingBottom + "px";
        this.__list.style.paddingTop = this.listPaddingTop + "px";
    };

    this.isGridView = function () {
        return this.__list.classList.contains("gridview");
    };
    this.isListView = function () {
        return this.__list.classList.contains("listview");
    };

    // calculate how many elements can fit in 1 row, depending on container-size, selected view and current zoom-level
    this.calcMaxElementsPerRow = function (el_rect) {
        if (this.isListView()) return 1;  // in listview theres always 1 element per row... *duh*
        var rect = this.__list.getBoundingClientRect();    // dimensions of the container
        if (rect.width === 0) {       // fallback in case the element isnt visible yet
            // dynamically calculate how large the list "should" be, based on window-size
            rect.width = Math.floor(window.innerWidth * 0.75) - 240 - (this.list_padding_x * 2) + 15;  // container-width = 75% of window-width, -240px from nav-bar, -60px padding +15px negative-margins of the list
        }
        return el_rect ? Math.floor(rect.width / el_rect.width) : 1;
    };

    // calculate number of rows visible in the viewport, rounded up
    // add 1 extra since its possible to scroll "in between" elements, to see elements only partially at top and bottom
    this.calcMaxVisibleRows = function (el_rect) {
        var rect = this.__viewContainer.getBoundingClientRect();
        if (rect.height === 0) {      // fallback in case the element isnt visible yet
            // dynamically calculate how large the list "should" be, based on window-size
            rect.height = Math.floor(window.innerHeight * 0.9); // container-height = 90% of window-height, no paddings or margins.
        }
        return el_rect ? Math.ceil(rect.height / el_rect.height) + 1 : 1;
    };

    this.getElementSize = function () {
        var el = document.querySelector("#mediamanager #mm_items .mm-file-container:not(.template)");
        if (!el) {
            // no element found in the list, make the element-template visible temporarily to get boundingRects of it, then hide it again
            var temp = document.querySelector(".mm-file-container.template");
            temp.style.display = "block";
            var rect = temp.getBoundingClientRect();
            temp.style.removeProperty("display");
            return rect;
        } else {
            return el.getBoundingClientRect();
        }
    };

    this.isScrollLoading = false;
    // calculate which row should currently be the "top" visible one depending on the current scroll position
    this.calculateCurrentElements = function (scrollTop) {
        if (!this.rowHeight) {    // not initialised yet
            return;
        }

        if (this.isScrollLoading) {
            return;     // already loading other elements
        }

        var newTopVisibleRow = Math.ceil(Math.max(scrollTop, 1) / this.rowHeight);  // math.max(scrollTop,1) because the result needs to be rounded up to atleast 1
        //var newBottomVisibleRow = Math.min(Math.ceil((Math.max(scrollTop,0) + this.__viewContainer.offsetHeight) / this.rowHeight),this.total_rows);
        var newBottomVisibleRow = Math.min(Math.ceil((scrollTop + this.__viewContainer.offsetHeight) / this.rowHeight), this.total_rows);

        var fullRefreshBuffer = this.maxVisibleRows * 3;

        // new visible boundaries are far from the current loaded elements, so we re-load the entire view completely instead of loading everything until there
        if (newTopVisibleRow > this.currentBottomRow + fullRefreshBuffer || newBottomVisibleRow < this.currentTopRow - fullRefreshBuffer) {
            //todo
            this.clearElementList();
        }

        // calculate which rows are visible at the current scroll position
        this.mostTopVisibleRow = newTopVisibleRow;
        this.mostBottomVisibleRow = newBottomVisibleRow;

        this.checkElementsToUpdate();
    };

    this.clearElementList = function () {
        if (this.element_list) {
            // add the height of the elements that will be removed to the bottom-padding,
            // BEFORE they get removed, to keep the current correct scroll position
            // (otherwise scrollTop will be adjusted automatically to the maximum possible if the content gets shorter)
            this.__list.style.paddingBottom = (this.listPaddingBottom + this.currentListHeight) + "px";
            for (var i = 0; i < this.element_list.length; i++) {
                this.element_list[i].remove();
            }
            this.currentTopRow = null;
            this.currentBottomRow = null;
        }
    };


    this.updateCustomScrollBarPosition = function () {
        var scrollTop = this.__viewContainer.scrollTop;
        if (this.scrollBarRatio && this.scrollBarTrackRatio) {
            this.__scroller.style.top = (scrollTop * this.scrollBarRatio * this.scrollBarTrackRatio) + "px";
        }
    };

    this.moveCustomScrollBarToPosition = function (top) {
        if (this.scrollBarRatio && this.scrollBarTrackRatio) {
            this.__viewContainer.scrollTop = top / this.scrollBarRatio / this.scrollBarTrackRatio;
        }
    };


    this.scrollEventHandler = function () {
        var self = this;
        self.updateCustomScrollBarPosition();

        self.__viewContainer.querySelector('.mm-content-header').classList.toggle('bg-white', self.__viewContainer.scrollTop > 0);

        if (self.scrollListenerEnabled) {
            window.clearTimeout(window.mm_is_scrolling);
            window.mm_is_scrolling = setTimeout(function () {
                // substract top- margin/padding from scroll position for the elements to display
                // height of list header (in listview only): 45px
                var scrollTop = self.__viewContainer.scrollTop - self.header_height - (self.isListView() ? self.listview_header_height : 0);
                self.calculateCurrentElements(scrollTop);
            }, 100);
        }
    };

    // scroll event listener
    this.initScrollEvent = function (element, scrollTop) {
        if (element) {
            var self = this;
            self.scrollListenerEnabled = false;
            element.removeEventListener("scroll", self.scrollEventHandler);
            element.scrollTop = scrollTop || 0;
            element.addEventListener("scroll", self.scrollEventHandler.bind(self), false);
            self.scrollListenerEnabled = true;
            if (!scrollTop) { // weird special case...
                self.scrollEventHandler();
            }
        }
    };
    this.initScrollEvent(this.__viewContainer);


    this.initCustomScrollBar = function (totalListHeight) {
        if (totalListHeight !== 0) {
            this.viewContainerHeight = this.__viewContainer.clientHeight;
            this.scrollBarRatio = this.viewContainerHeight / (totalListHeight + this.header_height + this.list_padding_y + (this.isListView() ? this.listview_header_height : 0));

            if (this.scrollBarRatio >= 1) {   // no scrollbar needed, content fits container
                this.__scrollBar.classList.add("disabled");
                this.__scroller.style.height = 0;
                this.scrollBarTrackRatio = 0;
            } else {
                this.__scrollBar.classList.remove("disabled");
                this.scrollBarTrackRatio = this.__scrollBar.querySelector(".track").clientHeight / this.viewContainerHeight;    // the scrollbar-track might be slightly smaller than its container due to styling
                this.__scroller.style.height = (this.scrollBarRatio * (this.viewContainerHeight - 10) * this.scrollBarTrackRatio) + "px";   // -10px negative margin on track
            }
            initScrollBarDraggable(this.__scroller);
        } else {
            this.__scrollBar.classList.add("disabled");
            this.scrollBarRatio = null;
            this.scrollBarTrackRatio = null;
        }
    };


    // check for elements to load  which should be visible but not loaded yet,
    // and elements to remove which arent visible anymore but still loaded
    this.checkElementsToUpdate = function () {
        if (!this.element_list) return; // elements not yet loaded, cant do anything
        // loop through all elements in the array and toggle their visibility = true|false
        for (var i in this.element_list) {
            var row = Math.ceil(this.element_list[i].index / this.elements_per_row);
            this.element_list[i].visible = row >= this.mostTopVisibleRow && row <= this.mostBottomVisibleRow;
        }

        var additionalBuffer = 2 * this.elements_per_row;   // whoopdeedoo ??? ...

        this.scrollListenerEnabled = false; // temporarily disable scroll event until loading & appending new items is finished

        // if currentTopRow and/or currentBottomRow are null, it means the list just got cleared
        // jump directly to the specific position instead of appending/prepending
        if (this.currentBottomRow === null || this.currentTopRow === null) {
            this.currentTopRow = this.mostTopVisibleRow;
            this.currentBottomRow = this.currentTopRow - 1; // will be added after appending the items
            this.appendItems(Math.max((this.mostTopVisibleRow - 1) * this.elements_per_row, 0), this.initialPaging.limit);

            return;
        }


        // check for scrolling down
        var appendOffset = false;
        var appendLimit = 0;
        var currentMaxOffset = this.currentBottomRow * this.elements_per_row;
        for (var j = (this.currentBottomRow - 1) * this.elements_per_row; j < currentMaxOffset; j++) {
            var app_RowEl = this.element_list[j];
            if (app_RowEl && !app_RowEl.loaded) {
                if (appendOffset === false) appendOffset = j;
                appendLimit++;
            }
        }
        if (this.currentBottomRow < this.mostBottomVisibleRow) {
            if (appendOffset === false) appendOffset = currentMaxOffset;
            appendLimit += (this.mostBottomVisibleRow - this.currentBottomRow) * this.elements_per_row;
        }
        if (appendOffset !== false) {
            this.isScrollLoading = true;
            this.appendItems(appendOffset, appendLimit + (this.currentBottomRow < this.mostBottomVisibleRow ? additionalBuffer : 0));

            return; // no need to check for scroll down on scrolling up
        }


        // check for scrolling up
        var prependOffset = false;
        var prependLimit = 0;
        var currentMinOffset = (this.currentTopRow - 1) * this.elements_per_row;
        for (var j = currentMinOffset; j < this.currentTopRow * this.elements_per_row; j++) {
            var prep_rowEl = this.element_list[j];
            if (prep_rowEl && !prep_rowEl.loaded) {
                if (prependOffset === false) prependOffset = j;
                prependLimit++;
            }
        }
        if (this.currentTopRow > this.mostTopVisibleRow) {
            prependOffset = (this.mostTopVisibleRow - 1) * this.elements_per_row;
            prependLimit += (this.currentTopRow - this.mostTopVisibleRow) * this.elements_per_row;
        }
        if (prependOffset !== false) {
            this.isScrollLoading = true;
            if (additionalBuffer <= prependOffset) {
                prependOffset -= additionalBuffer;
                prependLimit += additionalBuffer;
            } else {
                prependLimit += prependOffset;
                prependOffset = 0;
            }
            this.prependItems(prependOffset, prependLimit);

            return;
        }

        this.scrollListenerEnabled = true;  // re-enable scroll-event
    };

    this.appendItems = function (offset, limit) {
        var self = this;
        // flag all the elements which are to be loaded (to prevent loading the same items multiple times on scroll)
        for (var i = offset; i < offset + limit && i < self.element_list.length; i++) {
            self.element_list[i].loaded = true;
        }
        window.MediaManager.updateListView(window.MediaManager.listCriteria, {
            offset: offset,
            limit: limit
        }, "append", function (data) {
            var rowsAdded = data.data.length / self.elements_per_row;
            // this number should usually be even, but in some cases only a partial row is fetched (last row, or after changing the view or zoom-slider)
            // round this value down or up depending on this.
            if (data.data.length + offset < self.total_elements && (limit % self.elements_per_row !== 0)) {
                rowsAdded = Math.floor(rowsAdded);
            } else {
                rowsAdded = Math.ceil(rowsAdded);
            }

            self.currentBottomRow += rowsAdded;
            self.checkRowsToRemove();
            self.recalculate();
            self.scrollListenerEnabled = true;
            self.isScrollLoading = false;
        });
    };
    this.prependItems = function (offset, limit) {
        var self = this;
        // flag all the elements which are to be loaded (to prevent loading the same items multiple times on scroll)
        for (var i = offset; i < offset + limit && i < self.element_list.length; i++) {
            self.element_list[i].loaded = true;
        }
        window.MediaManager.updateListView(window.MediaManager.listCriteria, {
            offset: offset,
            limit: limit
        }, "prepend", function (data) {
            var rowsAdded = data.data.length / self.elements_per_row;
            // this number should usually be even, but in some cases only a partial row is fetched (last row, or after changing the view or zoom-slider)
            // round this value down or up depending on this.
            if (offset === 0 && (limit % self.elements_per_row !== 0)) {
                rowsAdded = Math.floor(rowsAdded);
            } else {
                rowsAdded = Math.ceil(rowsAdded);
            }

            self.currentTopRow -= rowsAdded;
            self.checkRowsToRemove();
            self.recalculate();
            self.scrollListenerEnabled = true;
            self.isScrollLoading = false;
        });
    };

    this.checkRowsToRemove = function () {
        var maxBuffer = this.maxVisibleRows * 3;

        for (var i = this.currentTopRow; i < this.mostTopVisibleRow - maxBuffer; i++) {
            for (var x = (i - 1) * this.elements_per_row; x < i * this.elements_per_row; x++) {
                if (this.element_list[x] && this.element_list[x].loaded) {
                    this.element_list[x].remove();
                }
            }
            this.currentTopRow++;
        }

        for (var j = this.currentBottomRow; j > this.mostBottomVisibleRow + maxBuffer; j--) {
            for (var y = (j - 1) * this.elements_per_row; y < j * this.elements_per_row; y++) {
                if (this.element_list[y] && this.element_list[y].loaded) {
                    this.element_list[y].remove();
                }
            }
            this.currentBottomRow--;
        }
    };

    // GridItem pseudo-class
    function GridItem(index) {
        this.index = index;
        this.visible = false;
        this.loaded = false;
        this.html_element = null;

        this.remove = function () {
            if (this.html_element) {
                this.html_element.parentNode.removeChild(this.html_element);
                this.html_element = null;
            }
            this.loaded = false;
        };
    }
}


$(function () {

    $(window).on("resize", function () {
        if (null !== document.querySelector("#mediamanager")) {
            window.MediaManager.recalculateNavScrollBar();

            // recalculate infinite scroll with 0.5s delay after stopping resize
            window.clearTimeout(window.mm_windowResizeCalc);
            window.mm_windowResizeCalc = setTimeout(function () {
                window.MediaManager.recalculateScroll();
            }, 500);
        }
    });

    /**
     * Name Search
     */
    $(document).on("change", "#mmSearchInput", function () {
        window.MediaManager.updateSearchNameCriteria($(this).val());
    });

    /**
     * switching between list- & grid-view
     */
    $(document).on("click", ".mmToggleView button", function () {
        if (!$(this).hasClass("active")) {
            $(".mmToggleView button").removeClass("active");
            $(this).addClass("active");
            var view = $(this).attr("data-view");
            // hide sort-dropdown & img-zoom slider in list-view
            $("#mm-slider-wrapper, .mm-content-header .mediaSorter").toggleClass("hidden", view === "listview");
            $("ul#mm_items").removeClass("listview gridview").addClass(view);

            window.MediaManager.recalculateScroll();

            $.post(
                window.MediaManager.controllerUrl + 'usersettings',
                {
                    view: view
                },
                function (data) {
                }
            );
        }
    });

    /**
     * event listener for all filter/sort/search changes
     */
    $(document).on("change", "#mediamanager input.mm_applysettings", function () {
        switch ($(this).attr("data-setting")) {
            case "mime":
                window.MediaManager.updateMimesCriteria($(this).val(), $(this).prop("checked"));
                break;
            case "tag":
                $(".mm-tag-selector").removeClass("active");
                window.MediaManager.updateTagsCriteria($(this).val(), $(this).prop("checked"));
                break;
            case "date_from":
            case "date_to":
                window.MediaManager.updateDateCriteria(
                    $("input.mm_applysettings[data-setting='date_from']").val(),
                    $("input.mm_applysettings[data-setting='date_to']").val()
                );
                break;
            default:
                break;
        }
        return null;
    });

    $(document).on("click", ".mediaSorter a.mm-sort", function (e) {
        e.stopPropagation();        // keep dropdown open

        var order = $(this).attr("data-order") || "";
        var newOrder = order === "asc" ? "desc" : order === "desc" ? "" : "asc";    // toggle values between "asc", "desc" and "" (none)
        $(".mediaSorter a.mm-sort[data-sort='" + $(this).attr("data-sort") + "']").removeClass(order).attr("data-order", newOrder).addClass(newOrder);

        window.MediaManager.updateSortCriteria($(this).attr("data-sort"), newOrder);
    });


    /**
     * selecting/de-selecting files
     */
    $(document).on("click", ".mm-file-container", function (e) {
        // clicked element is either a toggle-button for a bootstrap dropdown/modal or any element inside a dropdown/modal menu
        if ($(e.target).attr("data-toggle") || $(e.target).closest(".dropdown-menu").length > 0) {
            return; // prevent EventPropagation inside dropdowns/modals
        }

        var dataId = $(this).find(".mm-file").data('id');


        var selected_files = $(".mm-file-container.selected");
        var count = window.MediaManager.selectedFiles.length;
        var $el = $(this).find(".mm-file");
        if ($(this).hasClass("selected")) {
            var index = window.MediaManager.selectedFiles.indexOf(dataId);
            if (index > -1) {
                window.MediaManager.selectedFiles.splice(index, 1);
            }

            var current_pos = parseInt($el.attr("data-count"));
            selected_files.each(function () {
                var $container = $(this).find(".mm-file");
                var pos = parseInt($container.attr("data-count"));
                if (pos > current_pos) {
                    $container.attr("data-count", pos - 1);
                }
            });
            count--;
            $el.removeAttr("data-count");
        } else {
            if (window.MediaManager.targetMaxFiles < count + 1) {
                alert('Es können nicht mehr als ' + window.MediaManager.targetMaxFiles + ' Dateien ausgewählt werden');
                return false;
            }
            if (window.MediaManager.targetExistingFiles && window.MediaManager.targetMaxFiles - window.MediaManager.targetExistingFiles < count + 1) {
                alert('Es können nicht mehr als ' + window.MediaManager.targetMaxFiles + ' Dateien ausgewählt werden, ' + window.MediaManager.targetExistingFiles + ' sind bereits eingefügt.');
                return false;
            }
            count++;
            $el.attr("data-count", count);
            window.MediaManager.selectedFiles.push(dataId);
            window.MediaManager.selectedFilesObject[dataId] = $el;
        }

        $(this).toggleClass("selected");
        $(this).find(".mm-file").toggleClass("selected");
        window.MediaManager.updateButtonsAndSelectedFiles(true);
    });

    /**
     * override default behaviour of bootstrap dropdown (keep filter-dialogue open)
     */
    $("#mediamanager").on("click", "#mmSearchFilterDropdown", function (e) {
        e.stopPropagation();
    });

    // events for bootstrap-dropdowns, to check and adjust element positioning inside the viewport
    $("#mediamanager").on("shown.bs.dropdown", ".mm-file-col.menu.dropdown, .mm", function () {
        checkElementBoundaries($(this).find(".dropdown-menu").get(0), "#mm_overlay_wrapper");
    });
    $("#mediamanager").on("shown.bs.dropdown", ".mm-nav-sublist .menu.dropdown", function () {
        checkElementBoundaries($(this).find(".dropdown-menu").get(0), ".mm-nav .scroll-wrapper.mm-nav-scrollable");
    });

    // close dropdowns on mouse leave
    $("#mediamanager").on("mouseleave", ".menu.dropdown.show", function () {
        $(this).find('.dropdown-menu').removeClass('show');
    });
    // close dropdowns also on scroll in nav, (otherwise looks ugly due to fixed positions of the menus)
    $(".mm-nav, .mm-nav-scrollable, .mm-content").scroll(function () {
        $(this).find(".dropdown-menu").removeClass("show");
    });

    // check element boundaries for file-info-container in minimized view on hover
    $(document).on("mouseenter", "#mm_items.minimized .mm-file-container", function () {
        checkElementBoundaries($(this).find(".info-container").get(0), "#mm_overlay_wrapper");
    });

    // init menu scroll
    $("#mediamanager .mm-nav-scrollable.scrollbar-macosx").scrollbar({
        disableBodyScroll: true
    });


    // delete single file
    $(document).on("click", ".mm-file-menu .delete", function () {
        deleteFiles($(this).closest(".mm-file"));
    });

    // edit file info
    $(document).on("click", ".mm-file-menu .edit-info", function () {
        editFileInfo($(this).closest(".mm-file"));
    });

    // edit single file
    $(document).on("click", ".mm-file-menu .edit-image", function () {
        openImageEditor($(this).closest(".mm-file"));
    });

    // delete selected files
    $(document).on("click", ".btn-mediamanager.delete", function () {
        deleteFiles(true);
    });

    // edit selected files
    $(document).on("click", ".btn-mediamanager.edit", function () {
        openImageEditor($(".mm-file.selected"));
    });

    // edit tag
    $(document).on("click", ".mm-tag-menu .edit", function () {
        editTag($(this).closest(".mm-tag-selector"));
    });

    // create tag
    $(document).on("click", "#mm-create-tag", function () {
        modalCreateTag();
    });

    // delete tag
    $(document).on("click", ".mm-tag-menu .delete", function () {
        deleteTag($(this).closest(".mm-tag-selector"));
    });


    // create directory
    $(document).on("click", "#mm-create-dir", function () {
        modalCreateDir();
    });

    // create subdirectory
    $(document).on("click", ".mm-dir-menu .create_sub", function () {
        modalCreateDir($(this).closest("div.item").closest('li').attr("data-id"));
    });

    // edit directory
    $(document).on("click", ".mm-dir-menu .edit", function () {
        editDir($(this).closest("div.item").closest('li'));
    });

    // delete directory
    $(document).on("click", ".mm-dir-menu .delete", function () {
        deleteDir($(this).closest("div.item").closest('li'));
    });


    /**
     * Category Filter
     */
    $(document).on("click", "#mediacategoryFilter li", function () {
        $("#mediacategoryFilter").find("li").not($(this)).removeClass("active");
        $(this).toggleClass("active");

        window.MediaManager.updateCategoryCriteria($("#mediacategoryFilter").find("li.active").attr("data-value"));
    });

    /**
     * Tag Filter
     */
    $(document).on("click", ".mm-tag-selector", function (e) {
        // clicked element is either a toggle-button for a bootstrap dropdown/modal or any element inside a dropdown/modal menu
        if ($(e.target).attr("data-toggle") || $(e.target).closest(".dropdown-menu").length > 0) {
            return; // prevent EventPropagation inside dropdowns/modals
        }

        $(".mm-tag-selector").not($(this)).removeClass("active");
        $(this).toggleClass("active");
        window.MediaManager.updateTagsCriteria($(this).attr("data-tag"), $(this).hasClass("active"), true);
    });

    $(document).on("click", ".mm-filedir-treestructure span.tree-item-title", function (e) {
        // clicked element is either a toggle-button for a bootstrap dropdown/modal or any element inside a dropdown/modal menu
        if ($(e.target).attr("data-toggle") || $(e.target).closest(".dropdown-menu").length > 0) {
            return; // prevent EventPropagation inside dropdowns/modals
        }

        var $parent = $(this).closest("li");
        $(".mm-filedir-treestructure li").not($parent).find('> div.item').removeClass("active");
        $parent.find('> div.item').toggleClass("active");

        window.MediaManager.updateFiledirsCriteria($parent.attr("data-id"), $parent.find('> div.item').hasClass("active"));
    });

    // reset listCriterias
    $(document).on("click", "#mmResetCriteria", function () {
        window.MediaManager.setDefaultCriteria();
        window.MediaManager.criteriaChanged();
    });

});


function openImageEditor($items) {
    if (window.MediaEditor)
        window.MediaEditor.startImageEditor($items);
    else console.log('MediaEditor not installed.')
}


function modalCreateDir(parentDir) {
    if (!parentDir) {
        parentDir = $('#mm-dir-list li.root-dir').attr('data-root-id');
    }

    var content = $("#dir-edit-form");
    content.find(".edit-dir_name").attr("value", "");

    fancyboxConfirm(
        content.html(),
        [
            {
                label: "Abbrechen",
                callback: function () {
                },
                btnClass: "btn-light btn"
            },
            {
                label: "Erstellen",
                callback: function (e) {
                    var $box = $(e.target).closest(".fancyconfirm").find(".fancyconfirm_message");
                    var new_name = $box.find(".edit-dir_name").val();

                    var params = {
                        Filediri18n: {
                            name: new_name
                        }
                    };
                    if (parentDir) params['parent_id'] = parentDir;  // should now always be passed

                    let frontendusergroups = [];
                    if ($box.find('.__accesscontrol input.accesscontrol_toggle').prop("checked")) {
                        $box.find('.__accesscontrol input.accesscontrol_group:checked').each(function () {
                            frontendusergroups.push($(this).val());
                        });
                    }

                    params['frontendusergroups'] = frontendusergroups;

                    $.post(window.MediaManager.controllerUrl + 'createfiledir', params)
                        .done(function (data) {
                            data = JSON.parse(data);
                            if (data.success) {
                                $(".fancyconfirm_message .error-message").text("");

                                window.MediaManager.updateFileDirectories();
                                $.fancybox.close();
                            } else {
                                $(".fancyconfirm_message .error-message").text(data.error);
                                $.fancybox.update();
                            }
                        });
                },
                closesModal: false,
                btnClass: "btn-success btn"
            }
        ],
        {
            afterLoad: function () {
                window.requestAnimationFrame(function () {
                    let form = $(".fancyconfirm_message").find('.__accesscontrol');

                    // no inherits needed for new created, empty directory
                    form.find('.form-group.accesscontrol_inherit').remove();

                    // change all input id's and associated label 'for' attributes to maintain unique id's and label/css functionalities
                    form.find('input').each(function () {
                        $(this).attr('id', $(this).attr('id') + '_modal');
                    });
                    form.find('label').each(function () {
                        $(this).attr('for', $(this).attr('for') + '_modal');
                    });

                    initMediaAccessControlForm(form, []);
                });
            },
            maxWidth: 500,
            minWidth: 420,
            autoHeight: true,
        },
        {
            header: "Neuen " + (parentDir ? "Untero" : "O") + "rdner erstellen"
        }
    );
}

function initMediaAccessControlForm(container, frontendusergroups = []) {
    console.log('TODO: init AC form');
    console.log(container);
    console.log(frontendusergroups);


    // toggle checkboxlist
    container.find('input.accesscontrol_toggle').on('change', function () {
        container.find('.form-group.accesscontrol_groups').toggleClass('open', this.checked);

        $.fancybox.update();
    });


    // always disable the "inherit for all subelements" toggle first
    container.find('input.accesscontrol_inherit').prop('checked', false);

    // set "main" toggle button and checkboxlist visibility
    container.find('input.accesscontrol_toggle').prop('checked', frontendusergroups.length > 0);
    container.find('.form-group.accesscontrol_groups').toggleClass('open', frontendusergroups.length > 0);

    // set checkboxlist values for active frontendusergroups
    container.find('input.accesscontrol_group').each(function () {
        $(this).prop('checked', frontendusergroups.includes(parseInt($(this).attr('value'))));
    });
}

function modalCreateTag() {
    var content = $("#tag-edit-form");
    content.find(".edit-tag_name").attr("value", "");
    content.find(".edit-tag_color").attr("value", "");

    fancyboxConfirm(
        content.html(),
        [
            {
                label: "Abbrechen",
                callback: function () {
                },
                btnClass: "btn-light btn"
            },
            {
                label: "Erstellen",
                callback: function (e) {
                    var $box = $(e.target).closest(".fancyconfirm").find(".fancyconfirm_message");
                    var new_name = $box.find(".edit-tag_name").val();
                    var new_color = $box.find(".edit-tag_color").val();

                    var postData = {
                        'color': new_color,
                        'Filetagi18n': {
                            'name': new_name
                        }
                    };

                    $.post(window.MediaManager.controllerUrl + "createfiletag", postData)
                        .done(function (data) {
                            data = JSON.parse(data);
                            if (data.success) {
                                $(".fancyconfirm_message .error-message").text("");

                                // append new created tag to the list (so we dont have to reload the page)
                                var clone = $(".mm-tag-selector.template").clone();
                                clone.removeClass("template");
                                clone.attr("data-tag", data.success);
                                clone.find(".tag-name").text(new_name);
                                clone.find(".tag-color").attr("data-color", new_color).css("backgroundColor", new_color);
                                clone.appendTo("#mm-tag-list > ul");

                                // add new created tag element to containers for image-dragging
                                if (window.MediaManager.drake_dirs) {
                                    window.MediaManager.drake_dirs.containers.push(clone[0]);
                                } else {
                                    window.MediaManager.drake_dirs = window.MediaManager.initDragula_dirs([clone[0]]);
                                }

                                // append new created tag to the list in the advanced searchFilters Dropdown
                                var searchDropdownTags = $("#mmSearchFilterDropdown .filterTags");
                                var clone2 = searchDropdownTags.find(".filterAttrTag").last().clone();
                                clone2.find("input").prop("checked", false).val(data.success).attr("id", "filterTag_" + data.success);
                                clone2.find("label").attr("for", "filterTag_" + data.success).text(new_name);
                                clone2.appendTo(searchDropdownTags);

                                $.fancybox.close();
                            } else {
                                $(".fancyconfirm_message .error-message").text(data.error);
                                $.fancybox.update();
                            }
                        });
                },
                closesModal: false,
                btnClass: "btn-success btn"
            }
        ],
        {
            afterLoad: function () {
                window.requestAnimationFrame(function () {
                    var el = $(".fancyconfirm_message .colorpicker");

                    // ugly workaround, fancybox adds inline style overflow: auto
                    el.closest('.fancybox-inner').css('overflow', 'visible');

                    initColorpicker(el, '');
                });
            },
            maxWidth: 500,
            minWidth: 420,
            autoHeight: true,
        },
        {
            header: "Neuen Tag erstellen"
        }
    );
}


function editDir($el) {
    let dir_id = $el.attr("data-id");
    let dir_name = $el.find('> .item span.tree-item-title').text();

    let content = $("#dir-edit-form");
    content.find(".edit-dir_name").attr("value", dir_name);

    $.post(window.MediaManager.controllerUrl + 'getfiledirinfo', {id: dir_id}, function (filedir_data) {
        if (filedir_data !== "") {
            filedir_data = JSON.parse(filedir_data);

            fancyboxConfirm(
                content.html(),
                [
                    {
                        label: "Abbrechen",
                        callback: function () {
                        },
                        btnClass: "btn-light btn"
                    },
                    {
                        label: "Speichern",
                        callback: function (e) {
                            var $box = $(e.target).closest(".fancyconfirm").find(".fancyconfirm_message");
                            var new_name = $box.find(".edit-dir_name").val();

                            var params = {
                                id: dir_id,
                            };
                            if (new_name !== dir_name) {      // only pass name if it was changed
                                params['name'] = new_name;
                            }

                            let frontendusergroups = [];
                            if ($box.find('.__accesscontrol input.accesscontrol_toggle').prop("checked")) {
                                $box.find('.__accesscontrol input.accesscontrol_group:checked').each(function () {
                                    frontendusergroups.push($(this).val());
                                });
                            }

                            params['frontendusergroups'] = frontendusergroups;
                            if ($box.find('.__accesscontrol input.accesscontrol_inherit').prop("checked")) {
                                params['frontendusergroups_inherit'] = true;
                            }

                            $.post(window.MediaManager.controllerUrl + 'editfiledir', params)
                                .done(function (data) {
                                    data = JSON.parse(data);
                                    if (data.success) {
                                        // update the tag in the list without reloading the page
                                        $(".fancyconfirm_message .error-message").text("");
                                        //$el.find("> .item span.tree-item-title").text(new_name);

                                        //window.MediaManager.updateListView();
                                        window.MediaManager.updateFileDirectories();
                                        $.fancybox.close();
                                    } else {
                                        $(".fancyconfirm_message .error-message").text(data.error);
                                        $.fancybox.update();
                                    }
                                });
                        },
                        closesModal: false,
                        btnClass: "btn-success btn"
                    }
                ],
                {
                    afterLoad: function () {
                        window.requestAnimationFrame(function () {
                            let form = $(".fancyconfirm_message").find('.__accesscontrol');

                            // change all input id's and associated label 'for' attributes to maintain unique id's and label/css functionalities
                            form.find('input').each(function () {
                                $(this).attr('id', $(this).attr('id') + '_modal');
                            });
                            form.find('label').each(function () {
                                $(this).attr('for', $(this).attr('for') + '_modal');
                            });

                            initMediaAccessControlForm(form, filedir_data.frontendusergroups);
                        });
                    },
                    maxWidth: 500,
                    minWidth: 420,
                    autoHeight: true,
                },
                {
                    header: 'Ordner <b>"' + dir_name + '"</b> bearbeiten'
                }
            );
        }
    });
}

function editTag($el) {
    var tag_id = $el.attr("data-tag");
    var tag_name = $el.find(".tag-name").text();
    var tag_color = $el.find(".tag-color").attr("data-color");

    var content = $("#tag-edit-form");
    content.find(".edit-tag_name").attr("value", tag_name);
    content.find(".edit-tag_color").attr("value", tag_color);

    fancyboxConfirm(
        content.html(),
        [
            {
                label: "Abbrechen",
                callback: function () {
                },
                btnClass: "btn-light btn"
            },
            {
                label: "Speichern",
                callback: function (e) {
                    var $box = $(e.target).closest(".fancyconfirm").find(".fancyconfirm_message");
                    var new_name = $box.find(".edit-tag_name").val();
                    var new_color = $box.find(".edit-tag_color").val();
                    var params = {
                        id: tag_id,
                        color: new_color,
                    };
                    if (new_name !== tag_name) {      // only pass name if it was changed
                        params['name'] = new_name;
                    }

                    $.post(window.MediaManager.controllerUrl + 'editfiletag', params)
                        .done(function (data) {
                            data = JSON.parse(data);
                            if (data.success) {
                                // update the tag in the list without reloading the page
                                $(".fancyconfirm_message .error-message").text("");
                                $el.find(".tag-name").text(new_name);
                                $el.find(".tag-color").attr("data-color", new_color).css("backgroundColor", new_color);

                                // update the tag in the advanced searchFilter dropdown
                                $("#mmSearchFilterDropdown .filterTags").find("#filterTag_" + tag_id).parent(".filterAttrTag").find("label").text(new_name);

                                window.MediaManager.updateListView();   //TODO ?
                                $.fancybox.close();
                            } else {
                                $(".fancyconfirm_message .error-message").text(data.error);
                                $.fancybox.update();
                            }
                        });
                },
                closesModal: false,
                btnClass: "btn-success btn"
            }
        ],
        {
            afterLoad: function () {
                window.requestAnimationFrame(function () {
                    var el = $(".fancyconfirm_message .colorpicker");

                    // ugly workaround, fancybox adds inline style overflow: auto
                    el.closest('.fancybox-inner').css('overflow', 'visible');

                    initColorpicker(el, tag_color);
                });
            },
            maxWidth: 500,
            minWidth: 420,
            autoHeight: true,
        },
        {
            header: 'Tag <b>"' + tag_name + '"</b> bearbeiten'
        }
    );
}

function initColorpicker(element, value = '') {
    let pageeditor_insert = new CustomEvent('Pageeditor:insert', {
        bubbles: false,
    });

    element.closest('.form-group').empty().append(
        '<input class="edit-tag_color form-control" value="' + value + '" data-modelname="" data-modelclass="" data-modelid="" data-attributename="" data-is-colorpicker="1" data-options="{}" data-type="dribble" placeholder="Farbe">'
    ); //data-options="{"id": "w0"}"

    window.dispatchEvent(pageeditor_insert);
}


function deleteTag($el) {
    var tag_id = $el.attr("data-tag");
    var tag_name = $el.find(".tag-name").text();

    fancyboxConfirm(
        '<label>Der Tag <i class="red">"' + tag_name + '"</i> wird unwiderruflich gelöscht!</label>',
        [
            {
                label: "Abbrechen",
                callback: function () {
                },
                btnClass: "btn-light btn"
            },
            {
                label: "Löschen",
                callback: function () {
                    $.post(window.MediaManager.controllerUrl + 'deletefiletag', {id: tag_id})
                        .done(function (data) {
                            // remove the tag element from the dom without reloading the page
                            $el.remove();
                            $("#mmSearchFilterDropdown .filterTags").find("input#filterTag_" + tag_id).parent(".filterAttrTag").remove();

                            // remove the deleted tag from the current search criterias, if it was selected
                            window.MediaManager.updateTagsCriteria(tag_id, false);
                            window.MediaManager.updateListView();
                        });
                },
                btnClass: "btn-danger btn"
            }
        ],
        {
            minWidth: 420,
        },
        'Tag <b>"' + tag_name + '"</b> löschen?'
    );
}

function deleteDir($el) {
    var dir_id = $el.attr("data-id");
    var dir_name = $el.find('> .item span.tree-item-title').text();

    fancyboxConfirm(
        '<label>Das Verzeichnis <i class="red">"' + dir_name + '"</i> mit allen Inhalten wird unwiderruflich gelöscht!</label>',
        [
            {
                label: "Abbrechen",
                callback: function () {
                },
                btnClass: "btn-light btn"
            },
            {
                label: "Löschen",
                callback: function () {
                    $.post(window.MediaManager.controllerUrl + 'deletefiledir', {id: dir_id})
                        .done(function (data) {
                            window.MediaManager.updateFileDirectories();
                        });
                },
                btnClass: "btn-danger btn"
            }
        ],
        {
            minWidth: 420,
        },
        'Tag <b>"' + dir_name + '"</b> löschen?'
    );
}

function editFileInfo($el) {
    var file_id = $el.attr("data-id");

    $.post(window.MediaManager.controllerUrl + 'getfileinfo', {id: file_id}, function (data) {
        if (data !== "") {
            data = JSON.parse(data);

            var file_name = data.name.split(".");
            var ending = file_name.pop();
            var old_name = file_name.join(".");
            var dim = (data.width && data.height) ? data.width + " x " + data.height + "px" : "-";

            var form = $("#media-edit-info-form");

            form.find(".media-edit-info-form")
                .toggleClass("image", data.isImage === 1)
                .toggleClass("pdf", data.isPdf === 1)
                .toggleClass("video", data.isVideo === 1);

            if (data.isImage === 1) {
                form.find(".mediaEdit_preview .imgContainer img").attr("src", data.thumb);
            }
            if (data.isVideo === 1) {
                form.find(".mediaEdit_preview .videoThumbContainer video")[0].crossOrigin = "anonymous";
                form.find(".mediaEdit_preview .videoThumbContainer video").attr("src", data.path).attr("poster", data.thumb);

                form.find("button.getVideoFrame").attr("data-fileid", data.id);
                initFormVideoPlayer(form);
            }
            if (data.isPdf === 1) {
                form.find(".mediaEdit_preview .pdfThumbContainer img").attr("src", data.thumb);

                form.find("button.savePdfThumb").attr("data-fileid", data.id);
                initFormPdfPreview(form);
            }

            form.find(".meta.file_type").text(ending);
            form.find(".meta.file_dim").text(dim);
            form.find(".meta.file_size").text(humanFileSize(data.size));
            form.find(".meta.file_path a").attr("href", data.path).text(data.path);
            form.find(".meta.file_created_at").text(data.created_at);
            form.find(".meta.file_updated_at").text(data.updated_at);

            form.find("input.mediaEdit_fileLorem").prop("checked", data.lorem === 1);
            form.find("input.mediaEdit_fileDeveloper").prop("checked", data.developer === 1);

            form.find(".mediaEdit_fileRename").val(old_name);
            form.find(".mediaEdit_fileRename_ending").text(ending);

            form.find(".mediaEdit_fileTitle").val(data.label);
            form.find(".mediaEdit_fileSubTitle").val(data.sublabel);
            form.find(".mediaEdit_fileDesc").text(data.description);
            form.find(".mediaEdit_fileLink").val(data.link);
            form.find(".mediaEdit_fileLinkText").val(data.linktitle);
            form.find(".mediaEdit_fileAlt").val(data.alt);

            form.find("#mediaEdit_tags").empty();
            for (let key in data.availableTags) {
                let t = data.availableTags[key];
                let parent = (t.parent) ? "data-parent='" + t.parent + "'" : "";
                let selected = data.selectedTags.includes(t.id) ? " selected " : "";
                form.find("#mediaEdit_tags").append("<option value='" + t.name + "' data-tag-id='" + t.id + "' data-tag-color='" + t.color + "' " + parent + " " + selected + " >" + t.name + "</option>");
            }

            form.find("#mediaEdit_tags").select2({
                templateResult: function (el) {
                    let color = el.element && el.element.dataset.tagColor ? el.element.dataset.tagColor : '';
                    return $(
                        '<span class="with-color"><i class="select2-tag-color" style="background:' + color + '"></i> ' + el.text + '</span>'
                    );
                },
                templateSelection: function (el) {
                    let color = el.element && el.element.dataset.tagColor ? el.element.dataset.tagColor : '';
                    if (color !== '') {
                        return $(
                            '<span class="with-color"><i class="select2-tag-color" style="background:' + color + '"></i> ' + el.text + '</span>'
                        );
                    } else {
                        return $(
                            '<span>' + el.text + '</span>'
                        );
                    }
                }
            });

            form.modal({
                backdrop: 'static',
            });

            initMediaAccessControlForm(form.find('.__accesscontrol'), data.frontendusergroups);

            form.find('.btn.save-media-info').off('click').on('click', function () {
                form.find(".error-message").text("");

                let frontendusergroups = [];
                if (form.find('.__accesscontrol input.accesscontrol_toggle').prop("checked")) {
                    form.find('.__accesscontrol input.accesscontrol_group:checked').each(function () {
                        frontendusergroups.push($(this).val());
                    });
                }

                $.post(window.MediaManager.controllerUrl + 'updatemetadata', {
                    id: data.id,
                    description: form.find("textarea.mediaEdit_fileDesc").val(),
                    title: form.find("input.mediaEdit_fileTitle").val(),
                    subtitle: form.find("input.mediaEdit_fileSubTitle").val(),
                    link: form.find("input.mediaEdit_fileLink").val(),
                    linktext: form.find("input.mediaEdit_fileLinkText").val(),
                    alt: form.find("input.mediaEdit_fileAlt").val(),
                    lorem: form.find("input.mediaEdit_fileLorem").prop("checked") ? "true" : "false",
                    developer: form.find("input.mediaEdit_fileDeveloper").prop("checked") ? "true" : "false",
                    tags: form.find("#mediaEdit_tags").select2("val"),
                    name: form.find(".mediaEdit_fileRename").val(),
                    frontendusergroups: frontendusergroups,
                }, function (data) {
                    if (data !== '') {
                        form.find(".rename_error.error-message").text(data);
                    } else {
                        window.MediaManager.updateListView();   //TODO ?
                        form.modal("hide");
                    }
                });
            });

        }
    });
}

function initFormVideoPlayer($form) {
    var video = $form.find(".mediaEdit_preview .videoThumbContainer video").get(0);

    var frameRate = 30;

    $form.find(".videoControls button").off('click').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    // play/pause toggle
    $form.find(".videoControls .play").off('click').on('click', function (e) {
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    });

    // export current Videoframe as base64 in original-size
    $form.find(".videoControls .getVideoFrame").off('click').on('click', function (e) {
        var video_thumb = getVideoFrame(video);
        var file_id = $(this).attr("data-fileid");
        $.post(window.MediaManager.controllerUrl + 'savethumbfile', {
            id: file_id,
            data: video_thumb,
        }, function (data) {
            // todo highlight that the image has been saved?
            try {
                data = JSON.parse(data);
                if (data.file_id && data.src) {
                    $("#mm_items .mm-file[data-id='" + data.file_id + "']").find(".mm-file-thumb img").attr("src", data.src);
                }
            } catch (e) {
                //return;
            }
        });
    });

    // pause video + go 1 frame (1/30 s) back
    $form.find(".videoControls .prevFrame").off('click').on('click', function (e) {
        video.pause();
        video.currentTime = video.currentTime - 1 / frameRate;
    });

    // pause video + go 1 frame (1/30 s) forward
    $form.find(".videoControls .nextFrame").off('click').on('click', function (e) {
        video.pause();
        video.currentTime = video.currentTime + 1 / frameRate;
    });

    // jump to specific position in video
    $form.find(".videoControls .seek_bar").off('click').on('click', /*"update, change",*/ function (e) {
        var resume = !video.paused;
        if (resume) {
            video.pause();      // to prevent "rubberbanding" and overlapping time-update events
        }
        video.currentTime = (video.duration / 100) * $(this).val();
        if (resume) {
            video.play();       // resume playing, if it was playing previously
        }
    });


    $form.find(".videoContainer video")
        .off("timeupdate").on("timeupdate", function (e) {
        var progress = (video.currentTime / video.duration) * 100;
        $form.find(".videoControls .background-bar .background-progress").css("width", progress + "%");
        $form.find(".videoControls .seek_bar").val(Math.round(progress));
        $form.find(".videoControls .getVideoFrame").prop("disabled", false);
        $form.find(".videoControls .play").toggleClass("playing", !video.paused);
    })
        .off("ended").on("ended", function (e) {
        $form.find(".videoControls .play").removeClass("playing");
    });
}

function initFormPdfPreview($form) {
    $form.find(".page_select .pdfThumbPage").val('');
    $form.find(".page_select .pdfThumbPage").on("change", function () {
        let fileUrl = $form.find(".file_info .file_path a").attr("href");

        getPDFThumbnailFromServer(fileUrl, (dataUrl) => {
            $form.find('.pdfThumbContainer .thumb_preview img').attr("src", dataUrl);
        }, parseInt($form.find(".page_select .pdfThumbPage").val()));
    });

    $form.find(".page_select .savePdfThumb").on("click", function () {
        let fileId = $form.find(".page_select .savePdfThumb").attr("data-fileid");
        $.post(window.MediaManager.controllerUrl + 'savethumbfile', {
            id: fileId,
            data: $form.find('.pdfThumbContainer .thumb_preview img').attr("src")
        }, (data) => {
            try {
                data = JSON.parse(data);
                if (data.file_id && data.src) {
                    $("#mm_items .mm-file[data-id='" + data.file_id + "']").find(".mm-file-thumb img").attr("src", data.src);
                }
            } catch (e) {
                //return;
            }
        });
    });
}

function deleteFiles($items) {
    var file_ids = [];
    if ($items === true) {
        file_ids = window.MediaManager.selectedFiles;
    } else {
        $items.each(function () {
            file_ids.push($(this).attr("data-id"));
        });
    }

    let confirmText;
    if (file_ids.length > 1) {
        confirmText = 'Wollen Sie diese ' + file_ids.length + ' Dateien wirklich löschen?' + '<br>' +
            'Sie werden auch aus allen Seiten und Modulen entfernt!';   //Yii.t
    } else {
        confirmText = 'Wollen Sie die Datei wirklich löschen?' + '<br>' +
            'Sie wird auch aus allen Seiten und Modulen entfernt!'; //Yii.t
    }

    $.post(window.MediaManager.controllerUrl + 'usedon', {file_ids: file_ids}, function (data) {
        var count = 0;
        data = JSON.parse(data);
        for (var file in data) {
            if (data[file]['usedon'].length) count += data[file]['usedon'].length;
        }

        if (count > 0) {
            confirmText += "<br><br>" + 'Folgende Dateien werden noch verwendet in: ' + "<br>";   //Yii.t
            confirmText += '<div class="usedonList" style="max-height:250px;overflow:auto;">';
            for (var file_id in data) {
                confirmText += "<br>" + data[file_id]['name'] + "<br>";
                for (var usedon in data[file_id]['usedon']) {
                    var url = data[file_id]['usedon'][usedon];
                    confirmText += "<a href='" + url + "' target='_blank'>" + url.substr(0, url.indexOf('&web')) + "</a>";
                }
            }
            confirmText += "</div>";
        }

        fancyboxConfirm(
            confirmText,
            [
                {
                    label: "Abbrechen",
                    callback: function () {
                    },
                    btnClass: "btn-light btn"
                },
                {
                    label: "Löschen",
                    callback: function () {
                        $.post(window.MediaManager.controllerUrl + 'delete', {ids: file_ids})
                            .done(function (data) {
                                //$items.parent(".mm-file-container").remove();
                                //window.MediaManager.updateButtonsAndSelectedFiles(true);
                                window.MediaManager.updateListView();
                            });
                    },
                    btnClass: "btn-danger btn"
                }
            ]
        );
    });
}

function initTagDragNDrop(container) {
    var items = $("ul.mm-tag-structurelist").get(0);
    var mirrorContainer = $(".mm-content #mm_items").get(0);

    var drake = dragula([items, container], {
        copy: function (el, source) {
            return source === items;
        },
        mirrorContainer: mirrorContainer,
        accepts: function (el, target, source) {
            //return target.classList.contains("mm-file-container");
            return true;
        },
        moves: function (el, source, handle, sibling) {
            return (el.classList.contains("mm-tag-selector") && !el.classList.contains("no-drag"))
                || ($(el).closest(".mm-tag-selector").length > 0 && !$(el).closest(".mm-tag-selector").hasClass("no-drag"));
        },
        invalid: function (el, handle) {
            return !el.classList.contains("mm-tag-selector") && $(el).closest(".mm-tag-selector").length <= 0;
        }
    })
        .on("drop", function (el, target, source, sibling) {
            var file = $(target).find(".mm-file");
            if (file.length > 0) {
                addTagToFiles([file.attr("data-id")], $(el));
            }
            $(el).remove();
        })
        .on('dragend', function (el) {
            //
        })
        .on("drag", function (el, source) {
            //
        });

    return drake;
}

function addTagToFiles(file_ids, $tag) {
    $.post(window.MediaManager.controllerUrl + 'addfiletag', {
        file_ids: file_ids,
        tag_id: $tag.attr("data-tag")
    }, function (data) {
        data = JSON.parse(data);
        if (data.success) {
            let color = $tag.find(".tag-color").attr("data-color");
            if (color !== "") {
                for (let file_id in file_ids) {
                    let tag_id = $tag.attr("data-tag");
                    let $file_tag_list = $('.mm-file[data-id="' + file_ids[file_id] + '"]').find(".info-container .mm-file-col.tags ul");

                    if ($file_tag_list.find('li[data-tag-id=' + tag_id + ']').length <= 0) {
                        $file_tag_list.append("<li data-tag-id='" + tag_id + "' style='background-color: " + color + "'></li>");
                    }
                }
            }
        }
    });
}

function addFilesToDir(files, $dir) {
    let $li = $dir.closest("li");

    let can_inherit = false;
    let modal_message = '';

    let file_ids = [];
    for (let i in files) {
        if (files[i].find('.mm-file-col.hasPermissions').hasClass('active')) {
            can_inherit = true;
            modal_message = 'Dieser Ordner ist öffentlich, möchten Sie diese Dateien ebenfalls veröffentlichen?'
        }
        file_ids.push(files[i].attr('data-id'));
    }

    if ($li.find('> .item span.tree-item-title .hasPermissions').length > 0) {
        can_inherit = true;
        modal_message = 'Dieser Ordner ist geschützt, möchten Sie die Zugriffsrechte dieses Ordners für diese Dateien übernehmen?';
    }

    // either directory has access control, or it does not but some of the selected files have
    if (can_inherit) {
        fancyboxConfirm(
            modal_message,
            [
                {
                    label: "Ja",
                    callback: function () {
                        moveFilesToDir(file_ids, $li.attr("data-id"), true);
                    },
                    btnClass: "btn-success btn"
                },
                {
                    label: "Nein",
                    callback: function () {
                        moveFilesToDir(file_ids, $li.attr("data-id"), false);
                    },
                    btnClass: "btn-warning btn"
                },
                {
                    label: "Abbrechen",
                    callback: function () {
                    },
                    btnClass: "btn-secondary btn"
                },
            ]
        );
    } else {
        moveFilesToDir(file_ids, $li.attr("data-id"), false);
    }


    function moveFilesToDir(files, dir_id, inheritAC = false) {
        let params = {
            file_ids: files,
            filedir_id: dir_id
        };
        if (inheritAC) {
            params['inherit_ac'] = true;
        }

        $.post(window.MediaManager.controllerUrl + 'movefiletodir', params, function (data) {
            data = JSON.parse(data);
            if (data.success) {
                window.MediaManager.updateListView();
            }
        });
    }
}

function initImageDragNDrop(container) {
    if (typeof (container) === "undefined") container = [];
    $("#mm-dir-list .mm-filedir-treestructure li > .item").each(function () {
        container.push(this);
    });
    $('#mm-tag-list .mm-tag-selector').each(function () {
        container.push(this);
    });

    let drake = dragula(container, {
        offset: function (offset, e, item) {
            offset.y = 16;
            offset.x = 15;
            return offset;
        },
        copy: function (el, source) {
            return true;
        },
        accepts: function (el, target, source) {
            //(target.classList.contains("dir-name") || $(target).closest(".dir-name").length > 0) ||
            return (target.classList.contains("item") || $(target).closest("div.item").length > 0) ||
                (target.classList.contains("mm-tag-selector") || $(target).closest(".mm-tag-selector").length > 0);
        },
        moves: function (el, source, handle, sibling) {
            return (el.classList.contains("mm-file-container") && !el.classList.contains("no-drag"))
                || ($(el).closest(".mm-file-container").length > 0 && !$(el).closest(".mm-file-container").hasClass("no-drag"));
        },
        invalid: function (el, handle) {
            return !el.classList.contains("mm-file-container") && $(el).closest(".mm-file-container").length <= 0;
        }
    })
        .on("drop", function (el, target, source, sibling) {
            let source_items = [$(source).find('.mm-file')];
            let source_ids = [$(source).find('.mm-file').attr("data-id")];
            $('ul#mm_items li.mm-file-container.selected').each(function () {
                if (!source_ids.includes($(this).find('.mm-file').attr("data-id"))) {
                    source_items.push($(this).find('.mm-file'));
                    source_ids.push($(this).find('.mm-file').attr("data-id"));
                }
            });

            var dir = $(target).hasClass("item") ? $(target) : $(target).closest("div.item");
            if (dir.length > 0) {
                addFilesToDir(source_items, dir);
            } else {
                var tag = $(target).hasClass('mm-tag-selector') ? $(target) : $(target).closest(".mm-tag-selector");
                if (tag.length > 0) {
                    addTagToFiles(source_ids, tag);
                }
            }

            $(el).remove();
        })
        .on('dragend', function (el) {
            //
        })
        .on("drag", function (el, source) {
            // if multiple files were selected before dragging,
            // display the number of selected files instead of the file-name of the clicked element.
            let source_items = [$(source).find('.mm-file').attr("data-id")];
            $('ul#mm_items li.mm-file-container.selected').each(function () {
                let id = $(this).find('.mm-file').attr("data-id");
                if (!source_items.includes(id)) {
                    source_items.push(id);
                }
            });
            if (source_items.length > 1) {
                el.classList.add('multiselect_drag');
                el.dataset.multiselect_count = source_items.length + ' Dateien';
            }
        });

    return drake;
}


function initScrollBarDraggable(scrollBar) {
    var trackHeight = $(scrollBar).parent().innerHeight();

    $(scrollBar).draggable({
        containment: $(scrollBar).parent(),
        helper: "clone",
        start: function (e, ui) {
            ui.helper.addClass("draggable-helper");
        },
        drag: function (e, ui) {
            ui.helper.find(".scroll-tooltip").text(Math.floor((ui.position.top / trackHeight) * window.MediaManager.InfScroll.total_elements));
        },
        drop: function (e, ui) {
            //console.log("drop:"+ui.position);
        },
        stop: function (e, ui) {
            window.MediaManager.InfScroll.moveCustomScrollBarToPosition(ui.position.top);
        }
    });
}