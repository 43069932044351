import Vue from 'vue'
import BackendstructureModule from './backendstructure_module.vue'

const configuratorElement = document.getElementById('backendstructure-module-configurator');

if (configuratorElement) {
    new Vue({
        name: 'BackendstructureModuleRoot',
        el: configuratorElement,
        components: {
            BackendstructureModule
        }
    });
}