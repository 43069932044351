var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _c("div", { staticClass: "h5 text-muted mb-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$translations["element.input"]) +
              " - " +
              _vm._s(_vm.capitalize(_vm.item.data.inputtype)) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-content",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.content"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-content" } },
          [
            _c("form-checkbox", {
              attrs: {
                id: "mandatory-checkbox",
                label: _vm.$translations["label.mandatory"],
              },
              model: {
                value: _vm.item.data.mandatory,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "mandatory", $$v)
                },
                expression: "item.data.mandatory",
              },
            }),
            _vm._v(" "),
            _vm.item.data.mandatory
              ? _c("form-input", {
                  attrs: {
                    id: "item.data.id",
                    label: _vm.$translations["label.mandatory-warning"],
                  },
                  model: {
                    value: _vm.item.data.mandatory_warning,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "mandatory_warning", $$v)
                    },
                    expression: "item.data.mandatory_warning",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: {
                id: "disabled",
                label: _vm.$translations["label.disabled"],
              },
              model: {
                value: _vm.item.data.disabled,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "disabled", $$v)
                },
                expression: "item.data.disabled",
              },
            }),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: {
                id: "disabled",
                label: _vm.$translations["label.readonly"],
              },
              model: {
                value: _vm.item.data.readonly,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "readonly", $$v)
                },
                expression: "item.data.readonly",
              },
            }),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: { id: "disabled", label: "Skip rendering" },
              model: {
                value: _vm.item.data.skipRendering,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "skipRendering", $$v)
                },
                expression: "item.data.skipRendering",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "question",
                label: _vm.$translations["label.label"],
              },
              model: {
                value: _vm.item.data.question,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "question", $$v)
                },
                expression: "item.data.question",
              },
            }),
            _vm._v(" "),
            _c("form-textarea", {
              attrs: {
                id: "description",
                label: _vm.$translations["label.description"],
              },
              model: {
                value: _vm.item.data.description,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "description", $$v)
                },
                expression: "item.data.description",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "placeholder",
                label: _vm.$translations["label.placeholder"],
              },
              model: {
                value: _vm.item.data.placeholder,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "placeholder", $$v)
                },
                expression: "item.data.placeholder",
              },
            }),
            _vm._v(" "),
            _c("form-select", {
              attrs: {
                id: "input-type",
                label: _vm.$translations["label.inputtype"],
                options: _vm.inputTypes,
              },
              model: {
                value: _vm.item.data.inputtype,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "inputtype", $$v)
                },
                expression: "item.data.inputtype",
              },
            }),
            _vm._v(" "),
            ["text", "textarea"].includes(_vm.item.data.inputtype)
              ? _c("form-input", {
                  attrs: {
                    id: "input-length",
                    label: _vm.$translations["label.length"],
                    type: "number",
                  },
                  model: {
                    value: _vm.item.data.inputmaxlength,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "inputmaxlength", $$v)
                    },
                    expression: "item.data.inputmaxlength",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            ["hidden"].includes(_vm.item.data.inputtype)
              ? _c("form-input", {
                  attrs: {
                    id: "input-length",
                    label: _vm.$translations["label.default"],
                    type: "text",
                  },
                  model: {
                    value: _vm.item.data.default,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "default", $$v)
                    },
                    expression: "item.data.default",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            ["number", "range"].includes(_vm.item.data.inputtype)
              ? _c("form-input", {
                  attrs: {
                    id: "input-length",
                    label: _vm.$translations["label.min"],
                    type: "number",
                  },
                  model: {
                    value: _vm.item.data.inputslidermin,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "inputslidermin", $$v)
                    },
                    expression: "item.data.inputslidermin",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            ["number", "range"].includes(_vm.item.data.inputtype)
              ? _c("form-input", {
                  attrs: {
                    id: "input-length",
                    label: _vm.$translations["label.max"],
                    type: "number",
                  },
                  model: {
                    value: _vm.item.data.inputslidermax,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "inputslidermax", $$v)
                    },
                    expression: "item.data.inputslidermax",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            ["range"].includes(_vm.item.data.inputtype)
              ? _c("form-input", {
                  attrs: {
                    id: "input-length",
                    label: _vm.$translations["label.steps"],
                    type: "number",
                  },
                  model: {
                    value: _vm.item.data.inputslidersteps,
                    callback: function ($$v) {
                      _vm.$set(_vm.item.data, "inputslidersteps", $$v)
                    },
                    expression: "item.data.inputslidersteps",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-dependencies",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.dependencies"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("LogicSection", {
          attrs: { item: _vm.item, "logic-options": _vm.logicOptions },
          on: {
            "add-rule": _vm.addRule,
            "remove-rule": _vm.removeRule,
            "enable-logic": _vm.enableLogic,
          },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass: "text-light p-3 mb-1 bg-secondary rounded collapsed",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#section-advanced",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$translations["section.advanced"]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "collapse", attrs: { id: "section-advanced" } },
          [
            _c("form-input", {
              attrs: {
                id: "unique-label",
                label: _vm.$translations["label.uniqlabel"],
              },
              model: {
                value: _vm.item.data.uniqlabel,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "uniqlabel", $$v)
                },
                expression: "item.data.uniqlabel",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "match-prefill-key",
                label: _vm.$translations["label.prefill-key"],
              },
              model: {
                value: _vm.item.data.match_prefill_key,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "match_prefill_key", $$v)
                },
                expression: "item.data.match_prefill_key",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "htmloptions",
                label: _vm.$translations["label.css-class"],
              },
              model: {
                value: _vm.item.data.htmloptions,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmloptions", $$v)
                },
                expression: "item.data.htmloptions",
              },
            }),
            _vm._v(" "),
            _c("form-checkbox", {
              attrs: {
                id: "html-purify",
                label: _vm.$translations["label.html-purify"],
              },
              model: {
                value: _vm.item.data.htmlpurify,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "htmlpurify", $$v)
                },
                expression: "item.data.htmlpurify",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "custom-validation",
                label: _vm.$translations["label.regex"],
              },
              model: {
                value: _vm.item.data.customvalidation,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "customvalidation", $$v)
                },
                expression: "item.data.customvalidation",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "custom-errormessage",
                label: _vm.$translations["label.regex-warning"],
              },
              model: {
                value: _vm.item.data.customerrormessage,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "customerrormessage", $$v)
                },
                expression: "item.data.customerrormessage",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-class",
                label: _vm.$translations["label.class-for-default"],
              },
              model: {
                value: _vm.item.data.defaultvalueclass,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "defaultvalueclass", $$v)
                },
                expression: "item.data.defaultvalueclass",
              },
            }),
            _vm._v(" "),
            _c("form-input", {
              attrs: {
                id: "dynamic-value-function",
                label: _vm.$translations["label.function-for-default"],
              },
              model: {
                value: _vm.item.data.defaultvaluefunction,
                callback: function ($$v) {
                  _vm.$set(_vm.item.data, "defaultvaluefunction", $$v)
                },
                expression: "item.data.defaultvaluefunction",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [_c("FooterActions", { on: { save: _vm.save } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }