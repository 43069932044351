<template>
    <label class="toggle-check w-100 my-3">
        {{ label }}
        <input
            type="checkbox"
            class="mandatory toggle-check-input"
            :checked="value"
            @change="update($event.target.checked)">
        <span class="toggle-check-text" />
    </label>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        value: {
            type: Boolean
        }
    },
    methods: {
        update(value) {
            this.$emit('input', value);
        }
    }
};
</script>