<!--
This is the same as MediaForm.php, just as a Vue Single-File-Component
-->

<template>
  <div class="mediaformwidget onecolumn">
    <div class="mm-dropzone" :class="{'disabled': widgetOptions.uploadDisabled}">
      <div class="dropzone-overlay"></div>
      <div class="badge badge-auto badge-alternate-primary">
        <span>Dateien hier ablegen</span>
      </div>
    </div>
    <div class="medialist_wrapper" :class="{'empty': widgetOptions.medias.length <= 0}">
      <ul class="medialist" :data-maxfiles="widgetOptions.maxFiles" :data-model="widgetOptions.modelName"
          :data-attribute="widgetOptions.attributeName">
        <MediaFormItem class="template"
                       :widget="widgetOptions"
                       :media="{}"
                       :index="-1"
        ></MediaFormItem>
        <MediaFormItem v-for="(media,index) in widgetOptions.medias" :key="index"
                       :widget="widgetOptions"
                       :media="media"
                       :index="index"
        ></MediaFormItem>
      </ul>
    </div>
    <div class="mediaform_wrapper">
      <div class="mediaform_dropzone">
        <input :form="widgetOptions.dummyFormName" type="file" multiple name="Media"
               :disabled="widgetOptions.uploadDisabled"
               class="mediaform_upload_input uploadFile"
               :class="{'disabled': (widgetOptions.uploadDisabled || widgetOptions.readonly)}"
               :id="uploadInputId" :data-attribute="uploadInputId">
        <label :for="uploadInputId">
          <div class="upload_media_icon">
            <i class="far fa-plus"></i>
          </div>
          <div class="upload_media_title">
            Dateien hinzufügen<br>
            <span>Oder einen Ordner auswählen</span>
          </div>
        </label>
      </div>
      <div class="loadMediaManager btn btn-primary" :class="{'disabled': widgetOptions.readonly}">Mediamanager</div>
    </div>
  </div>
</template>
<script>
import MediaFormItem from './MediaFormItem.vue';

export default {
  components: {MediaFormItem},
  props: {
    'widget': Object,
  },
  data() {
    return {
      widgetDefaultOptions: {
        // required to be set by caller
        modelName: '',
        attributeName: '',
        medias: [],

        // should not be touched
        maxFiles: 1,        // currently not supporting more than 1
        withPointer: false,
        editable: true,
        dummyFormName: null,
        uploadDisabled: false,
        readonly: false,
      },
    }
  },
  computed: {
    uploadInputId() {
      return 'file_input_' + this.widgetOptions.attributeName;
    },
    widgetOptions() {
      return Object.assign(this.widgetDefaultOptions, this.widget)
    }
  },
  methods: {},
  mounted() {
    let vm = this;

    // ugly hacks to pass input change events to the parent vue components
    // (because those containers are cloned and appended via external js, not handled through vue)
    $(vm.$el).find('.medialist').on('input change', '.modelmedia_edit input', (e) => {
      vm.$emit('inputChanged', {
        index: $(e.target).closest('li').attr('data-sort-index'),
        attr: e.target.dataset.name,
        value: e.target.value
      });
      return false;
    });

    // init file upload
    window.initFileUpload('#' + this.uploadInputId);
  }
}
</script>
<style scoped lang="scss">
.mediaformwidget {
  padding-right: 0.5em;
}
</style>