var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card shadow mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        " + _vm._s(_vm.$translations["element.scale"])),
      _vm.element.items[0].data.mandatory ? _c("sup", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v(_vm._s(_vm.element.items[0].data.question))]),
        _vm._v(" "),
        _c("div", { staticClass: "scale" }, [
          _c(
            "div",
            {
              on: {
                mouseleave: function ($event) {
                  _vm.hover = 0
                },
              },
            },
            _vm._l(Number(_vm.element.items[0].data.scaleSteps), function (n) {
              return _c(
                "label",
                {
                  key: n,
                  on: {
                    mouseenter: function ($event) {
                      _vm.hover = n
                    },
                  },
                },
                [
                  _c("i", {
                    class: [
                      `fa-${_vm.element.items[0].data.scaleIcon}`,
                      _vm.iconStyle(n),
                    ],
                  }),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "scale-min-label hasi18n" }),
          _vm._v(" "),
          _c("div", { staticClass: "scale-max-label hasi18n" }),
        ]),
        _vm._v(" "),
        _c(
          "small",
          {
            staticClass: "form-text text-muted mt-3",
            attrs: { id: "emailHelp" },
          },
          [_vm._v(_vm._s(_vm.element.items[0].data.description))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }