var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "switch" },
    [
      _vm._t("before"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checked,
            expression: "checked",
          },
        ],
        class: _vm.classes,
        attrs: { type: "checkbox", name: _vm.name, disabled: _vm.disabled },
        domProps: {
          checked: _vm.checked,
          checked: Array.isArray(_vm.checked)
            ? _vm._i(_vm.checked, null) > -1
            : _vm.checked,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.checked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checked = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }