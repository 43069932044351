import Vue from "vue"
import PreviewModule from "../../../../../fire-module-commerce2/src/backend/assets/views/PreviewModule.vue"
import vSelect from "vue-select"

const previewModuleElement = document.getElementById("preview-module-topics")

if (previewModuleElement) {
    Vue.component("v-select", vSelect)
    new Vue({
        name: "PreviewModuleRoot",
        el: previewModuleElement,
        components: {
            PreviewModule
        }
    });
}