var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { hidden: _vm.hidden } },
    [
      _vm.selection.length === 0
        ? [
            _vm.loading
              ? _c("loading-element", {
                  attrs: { center: true, absolute: false, "z-index": 100 },
                })
              : _c("div", { staticClass: "selection__empty" }, [
                  _c("div", { staticClass: "selection__empty__inner" }, [
                    _vm.selection.length === 0
                      ? _c("input", {
                          attrs: { type: "hidden", name: _vm.inputNamePrefix },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h2", [
                      _vm._v(_vm._s(_vm.translations.create_selection)),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.emptySelectionText))]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("go-to-product-catalog")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.to_catalogue))]
                    ),
                  ]),
                ]),
          ]
        : _c(
            "div",
            [
              !_vm.loading
                ? [
                    _c("Table", {
                      attrs: {
                        fields: _vm.fields,
                        "sort-by": _vm.sortBy,
                        items: _vm.selection,
                        translations: _vm.translations,
                        sortable: true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (slotProps) {
                              return [
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.itemIdName(slotProps.item),
                                  },
                                  domProps: {
                                    value: _vm.itemIdValue(slotProps.item),
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.sortOrderName(slotProps.item),
                                  },
                                  domProps: { value: slotProps.order },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.isFavoriteName(slotProps.item),
                                  },
                                  domProps: {
                                    value: _vm.isFavoriteValue(slotProps.item),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-outline-secondary",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItem(slotProps.item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.translations.remove))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        842605877
                      ),
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }