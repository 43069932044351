<template>
    <div class="topic--container">
        <div class="topic--details">
            <div class="details--image">
                <div
                    :key="item.id"
                    class="topic--detail--image">
                    <ImageLoader :src="getAssetPathOfTargetAttr('product_image', item)"/>
                </div>
                <div class="detail__section-image-background"/>
            </div>

            <div class="details--content">
                <div
                    :key="item.id"
                    class="topic--detail--content">
                    <div class="content__image content__image--first">
                        <div class="v-responsive__sizer"/>
                        <div class="content__image--reveal"/>
                        <ImageLoader
                            :src="getAssetPathOfTargetAttr('main_image', item)"
                            class="topic--detail_image"/>
                    </div>
                    <div class="content__image content__image--second">
                        <div class="v-responsive__sizer"/>
                        <ImageLoader
                            :src="getAssetPathOfTargetAttr('left_image', item)"
                            class="topic--detail_image"/>
                    </div>
                    <div class="content__image content__image--third">
                        <div class="v-responsive__sizer"/>
                        <ImageLoader
                            :src="getAssetPathOfTargetAttr('right_image', item)"
                            class="topic--detail_image"/>
                    </div>
                    <div class="textbox-wrapper">
                        <div class="content--title">
                            {{ item.title }}
                            <div class="content--year">
                                {{ item.subtitle }}
                            </div>
                        </div>
                        <div class="content--description">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
                <div class="detail__section-content-background"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, defineProps, onMounted, onUnmounted} from 'vue'
import {gsap} from 'gsap'
import {getAssetPathOfTargetAttr} from '../js/helpers'

const props = defineProps(['content'])

import ImageLoader from './ImageLoader.vue'

const item = computed(() => {
    if (props.content) {
        return props.content
    }
    return null
})

const musicFilename = computed(() => props.content?.item?.music)

const DOM = {
    topic_list: null,
    details_image: null,
    details_content: null,
    items: {},
    el: null
}

const tween = {
    sliderGapBetween: null,
    firstItem: {
        width: null,
        height: null
    },
    target: {
        width: null,
        height: null,
        locationX: null,
        locationY: null,
        centerX: null,
        centerY: null,
        scale: 1.4
    },
    items: []
}

const timelines = {
    gridToSlider: null,
    slideOutSlider: null,
    sliderToDetail: null,
    nextTopic: null,
    sliderDetail: null
}

const animDetailIn = () => {
    if (!DOM.el) {
        DOM.el = document.querySelector('.topic--container')
    }

    tween.firstInit = true
    timelines.sliderDetail = gsap
        .timeline({
            onComplete: () => {
                tween.firstInit = false
                tween.isAnimating = false
            }
        })
        .timeScale(1.5)

    let imageBg = document.querySelector('.detail__section-image-background')
    let contentBg = document.querySelector('.detail__section-content-background')

    let currentImage = DOM.el.querySelector('.topic--detail--image')
    let contentTitle = DOM.el.querySelector('.content--title')
    let contentText = DOM.el.querySelector('.content--description')

    let contentImageFirst = DOM.el.querySelector('.content__image--first')
    let contentImageSecond = DOM.el.querySelector('.content__image--second')
    let contentImageThird = DOM.el.querySelector('.content__image--third')

    let revealImage = DOM.el.querySelector('.content__image--reveal')

    let delay = 0
    if (tween.firstInit) {
        timelines.sliderDetail
            .to(
                imageBg,
                {
                    duration: 0.5,
                    ease: 'power3.inOut',
                    height: '100%'
                },
                0
            )
            .to(
                contentBg,
                {
                    duration: 0.5,
                    ease: 'power3.inOut',
                    height: '100%'
                },
                0
            )
    }

    timelines.sliderDetail
        .set(
            contentImageFirst,
            {
                opacity: 1
            },
            0
        )
        .to(
            currentImage,
            {
                duration: 1.5,
                ease: 'power3.inOut',
                top: '0',
                opacity: 1
            },
            delay
        )
        .to(
            revealImage,
            {
                duration: 1,
                ease: 'power3.inOut',
                x: '-100%'
            },
            delay
        )
        .to(
            contentImageSecond,
            {
                duration: 1,
                ease: 'power3.inOut',
                // x: '-9vw',
                // y: '3vh',
                x: '-130%',
                y: '20%',
                opacity: 1
            },
            delay + 0.75
        )
        .to(
            contentImageThird,
            {
                duration: 1,
                ease: 'power3.inOut',
                // x: '9vw',
                // y: '4vh',
                x: '110%',
                y: '20%',
                opacity: 1
            },
            delay + 0.75
        )
        .to(
            contentTitle,
            {
                duration: 1,
                ease: 'power3.inOut',
                opacity: 1,
                y: '-25%'
            },
            delay
        )
        .to(
            contentText,
            {
                duration: 1,
                ease: 'power3.inOut',
                opacity: 1,
                y: '-25%'
            },
            delay + 0.25
        )
}
const animDetailOut = (direction, onlyOut = false) => {
    if (tween.isAnimating) {
        return
    }

    tween.isAnimating = true

    let currentImage = DOM.el.querySelector('.topic--detail--image')
    let contentTitle = DOM.el.querySelector('.content--title')
    let contentText = DOM.el.querySelector('.content--description')

    let contentImageScale = DOM.el.querySelector('.content__image--first .v-image__image')
    let contentImageSecond = DOM.el.querySelector('.content__image--second')
    let contentImageThird = DOM.el.querySelector('.content__image--third')

    let revealImage = DOM.el.querySelector('.content__image--reveal')

    timelines.nextTopic = gsap.timeline().timeScale(2)

    timelines.nextTopic
        .to(
            currentImage,
            {
                duration: 1,
                ease: 'power3.inOut',
                y: -100,
                opacity: 0,
                onComplete: function () {
                    gsap.set(currentImage, {clearProps: 'all'})
                }
            },
            0
        )
        .set(
            revealImage,
            {
                x: '+100%'
            },
            0
        )
        .to(
            contentText,
            {
                duration: 0.5,
                ease: 'power3.inOut',
                opacity: 0,
                y: -50
            },
            0.25
        )
        .to(
            contentTitle,
            {
                duration: 0.5,
                ease: 'power3.inOut',
                opacity: 0,
                y: -50
            },
            0
        )
        .to(
            contentImageSecond,
            {
                duration: 1,
                ease: 'power3.inOut',
                x: 0,
                y: 0,
                opacity: 0
            },
            0
        )
        .to(
            contentImageThird,
            {
                duration: 1,
                ease: 'power3.inOut',
                x: 0,
                y: 0,
                opacity: 0
            },
            0
        )
        .to(
            contentImageScale,
            {
                duration: 1,
                scale: 1.2
            },
            0
        )
        .to(
            revealImage,
            {
                duration: 1,
                ease: 'power3.inOut',
                x: '0%'
            },
            0.5
        )
}
const closeAnimBackground = () => {
    let imageBg = document.querySelector('.detail__section-image-background')
    let contentBg = document.querySelector('.detail__section-content-background')

    let contentImageScale = DOM.el.querySelector('.content__image--first .topic--detail_image')
    let contentImageFirst = DOM.el.querySelector('.content__image--first')
    let contentImageSecond = DOM.el.querySelector('.content__image--second')
    let contentImageThird = DOM.el.querySelector('.content__image--third')

    let closeTimeline = gsap.timeline({
        onComplete: () => {
            tween.firstInit = true
            tween.isAnimating = false
        }
    })
    closeTimeline
        .set(
            [contentImageScale, contentImageFirst, contentImageSecond, contentImageThird],
            {
                opacity: 0
            },
            0
        )
        .to(
            imageBg,
            {
                duration: 0.5,
                ease: 'power3.inOut',
                height: '0%'
            },
            0
        )
        .to(
            contentBg,
            {
                duration: 0.5,
                ease: 'power3.inOut',
                height: '0%'
            },
            0
        )
}

const changeDetail = (args) => {
    props.content.item = args.item // TODO Fix prop mutation
    isMuted.value = args.isMuted
    volume.value = args.volume

    animDetailOut('left')

    setTimeout(() => {
        if (musicFilename.value) {
            playSound(musicFilename.value)
        }

        animDetailIn()
    }, 50)
}

const detailOut = () => {
    animDetailOut()
}

const closeBackground = () => {
    closeAnimBackground()
}

const onMessage = (message) => {
    if (message.event === 'emit') {
        callEmit(message.data.emit.method, message.data.emit.args)
    }
}

onMounted(() => {
    animDetailIn()

    if (props.content.playSound && musicFilename.value) {
        playSound(musicFilename.value)
    }

})

onUnmounted(() => {
})
</script>

<style scoped lang="scss">
.topic--container {
    width: 100%;
    height: 100%;
    background-color: #000;
}

.topic--details {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .details--image {
        position: relative;
        width: 50%;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;

        .details--background {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .topic--detail--image {
            position: absolute;
            //width: 100%;
            top: 100%;
            height: 100%;
            max-width: 100%;
            opacity: 0;
            z-index: 10;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .details--content {
        position: relative;
        width: 50%;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;

        .topic--detail--content {
            display: flex;

            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;
            padding: 15%;
            box-sizing: border-box;

            position: absolute;
            top: 0;

            .content__image {
                position: absolute;
                overflow: hidden;
                top: 10%;

                &--first {
                    width: 25%;
                    z-index: 2;
                }

                &--second {
                    //width: 7%;
                    width: 14%;
                    z-index: 1;
                }

                &--third {
                    width: 18%;
                    transition: 1s z-index ease-in-out;
                    z-index: 1;
                }

                .v-responsive__sizer {
                    padding-bottom: 133%;
                }

                img {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 0;
                    will-change: transform;
                    width: 100%;
                }

                .content__image--reveal {
                    position: absolute;
                    top: 0;
                    background: white;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    transform: translate(0);
                    will-change: transform;
                }
            }

            .textbox-wrapper {
                position: absolute;
                top: 50%;
                z-index: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .content--title {
                z-index: 100;
                font-weight: 200;
                font-size: 1.5vw;
                text-align: center;
                opacity: 0;
                color: #000;
                letter-spacing: 0.05vw;

                span {
                    font-weight: 100;
                    padding-left: 1%;
                }

                .content--year {
                    //font-size: 14px;
                    font-size: 0.7vw;
                    color: #5F5F5F;
                    font-weight: 600;
                    text-transform: uppercase;
                    padding-top: 2%;
                    margin: 0 3%;
                    color: #000;
                    letter-spacing: 0.09vw;
                }
            }

            .content--description {
                font-weight: 200;
                display: flex;
                //font-size: 12px;
                font-size: 0.85vw;
                text-align: center;
                justify-content: center;
                //position: absolute;
                top: 65%;
                z-index: 100;
                width: 75%;
                //will-change: transform;
                line-height: 1.5;
                opacity: 0;
                //margin-top: 30px;
                margin-top: 1.1vw
            }
        }
    }
}

.detail__section-image-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 0;
    background: whitesmoke;
}

.detail__section-content-background {
    position: absolute;
    background: white;
    width: 100%;
    height: 0;
    right: 0;
    top: 0;
}
</style>
