<template>
    <div class="type-segment-rule">
        <v-select v-model="operator" :options="operators" :reduce="label => label.key" class="segment-rule-operator" :clearable="false"></v-select>
        <v-select v-if="showContent" :options="contentOptions()" :reduce="label => label.key" v-model="content" class="segment-rule-content segment-rule-content-large" :clearable="false">
            <template v-slot:option="option">
                <span>{{option.label}}</span>
                <span v-if="option.sublabel" class="small text-muted ml-1">{{option.sublabel}}</span>
            </template>
            <template v-slot:selected-option="option">
                <span>{{option.label}}</span>
                <span v-if="option.sublabel" class="small text-muted ml-1">{{option.sublabel}}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
import SegmentRuleBase from "./SegmentRuleBase.vue"

export default {
    extends: SegmentRuleBase,
    data() {
        return {
            contentOptions() {
                const items =  this.rules[this.attribute].items;
                return Object.entries(items).map((value) => {
                    return value[1]
                });
            },
            operators: [
                {
                    label: this.labels.equal,
                    key: "equal"
                },
                {
                    label: this.labels.not_equal,
                    key: "not_equal"
                },
                {
                    label: this.labels.is_null,
                    key: "is_null"
                },
                {
                    label: this.labels.is_not_null,
                    key: "is_not_null"
                }
            ]
        }
    },
    watch: {
        attribute: function(val, oldVal) {
            // Clear content if it does not exist.
            let clearContent = true;

            const contentOptions = this.contentOptions()
            for (const option in contentOptions) {
                if (this.content === contentOptions[option].key) {
                    clearContent = false
                    break
                }
            }
            if (clearContent) {
                this.content = ""
            }
        }
    },
    mounted() {
        // Set equal operator if it does  not exist
        if (!['equal', 'not_equal', 'is_null', 'is_not_null'].includes(this.operator)) {
            this.operator = 'equal'
        }
    },
};
</script>