var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: _setup.transitionName } }, [
    _c("img", {
      style: _setup.cssStyles,
      attrs: {
        src: _vm.src,
        width: _vm.imageWidth,
        height: _vm.imageHeight,
        alt: "#",
      },
      on: { load: _setup.loaded },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }