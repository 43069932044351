<template>
    <transition name="scroll-y-transition" mode="in-out">
        <div :key="id" class="form-group label-inside">
            <input
                :id="id"
                :value="value"
                :type="type"
                class="form-control"
                :placeholder="label"
                @input="update($event.target.value)">
            <label :for="id">{{ label }}</label>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        value: {
            type: [String, Number]
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    methods: {
        update(value) {
            this.$emit('input', value);
        }
    }
};
</script>