<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.instruction'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div class="form-group">
                <label>{{ element.items[0].data.question }}</label>
                <small id="emailHelp" class="form-text text-muted">{{ element.items[0].data.description }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Instruction",
    mixins: [previewItemMixin],
};
</script>