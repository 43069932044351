var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_setup.isLoading
    ? _c(
        "div",
        { staticClass: "preview-module" },
        [
          _c("div", { staticClass: "preview-module__section-top" }, [
            _c("div", [
              _c("h1", { staticClass: "title" }, [
                _vm._v(_vm._s(_setup.content.title || _setup.content.name)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "controls" },
              [
                _setup.hasVideo
                  ? _c(_setup.VolumeToggleMute, {
                      attrs: { muted: _setup.isVideoMuted },
                      on: { "toggle-sound-clicked": _setup.toggleMute },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _setup.hasHueLight
                  ? _c(_setup.GlowLightToggle, {
                      on: { clicked: _setup.toggleLight },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _setup.previewType === "Intros"
            ? _c(_setup.Intro, {
                attrs: {
                  content: _setup.content,
                  muted: _setup.isVideoMuted,
                  "light-is-on": _setup.lightIsOn,
                  "bg-glow-color": _setup.bgGlowColor,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _setup.previewType === "Looks"
            ? _c(_setup.Look, {
                attrs: {
                  content: _setup.content,
                  "active-tab": _setup.activeTab,
                  muted: _setup.isVideoMuted,
                  "light-is-on": _setup.lightIsOn,
                  "bg-glow-color": _setup.bgGlowColor,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _setup.previewType === "Topics"
            ? _c(_setup.Topic, {
                attrs: {
                  content: _setup.content,
                  "active-tab": _setup.activeTab,
                  muted: _setup.isVideoMuted,
                  "light-is-on": _setup.lightIsOn,
                  "bg-glow-color": _setup.bgGlowColor,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _setup.previewType === "Looks"
            ? _c(_setup.Tabs, {
                attrs: {
                  tabs: ["Detail", "Video"],
                  "available-tabs-by-index": [true, _setup.hasVideo],
                },
                on: { "tab-changed": _setup.updateTabs },
              })
            : _vm._e(),
          _vm._v(" "),
          _setup.previewType === "Topics"
            ? _c(_setup.Tabs, {
                attrs: {
                  tabs: ["Detail", "Products", "Video"],
                  "available-tabs-by-index": [
                    true,
                    _setup.hasProducts,
                    _setup.hasVideo,
                  ],
                },
                on: { "tab-changed": _setup.updateTabs },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }