/**
 * Select all elements that contain the specified text - case insensitive.
 *
 * https://api.jquery.com/contains-selector/
 *
 * Usage:
 * import "./jquery.contains_ci";
 * let cci = $(":contains_ci");
 */
jQuery.extend(jQuery.expr[":"], {
    contains_ci: function (a, i, m) {
        const sText = a.textContent || a.innerText || "",
            zRegExp = new RegExp(m[3], "i");
        return zRegExp.test(sText);
    }
});