/**
 *
 * @param data
 * @param count
 * @returns {*}
 */
export function spliceItems(data, count = 50) {
    let items = data

    if (items && items.length > count) {
        return items.slice(0, count)
    }
    return items
}
