<template>
  <div class="unsplashContainer">
    <div class="unsplashOverlay"></div>
    <div class='unsplashBox'>
      <div class='unsplashHeader'>
        <div class="unsplashInputWrapper">
          <form class="unsplashForm">
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" class="unsplashSearchField" v-model="searchterm"
                   placeholder="Search free high-resolution photos"/>
          </form>
        </div>
        <div class="unsplashClose">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 180.607 180.607" style="enable-background:new 0 0 180.607 180.607;"
               xml:space="preserve">
                    <path d="M180.607,10.607l-79.696,79.697l79.696,79.697L170,180.607l-79.696-79.696l-79.696,79.696L0,170.001l79.696-79.697L0,10.607
                            L10.607,0.001l79.696,79.696L170,0.001L180.607,10.607z"></path>
                    </svg>
        </div>
      </div>
      <div class="unsplashBoxInner">
        <div v-for="(list,index ) in imagelist.lists" class="unsplashResults normal fadeInUp">
          <div v-for="item in list.images" :data-url="/*item.customizedUrl*/item.urls.full"
               class="unsplashItem normal fadeIn">
            <img :src="item.urls.small"/>
            <div class="unsplashname">
              <a :href="item.user.links.html+ '?utm_source=fgtest&utm_medium=referral&utm_campaign=api-credit'"
                 target="_blank">{{ item.user.first_name }} {{ item.user.last_name }}</a> / <a target="_blank"
                                                                                               href="http://unsplash.com?utm_source=fgtest&utm_medium=referral&utm_campaign=api-credit">Unsplash</a>
            </div>

          </div>
          <div style="display:none;" class="unsplashPage animated fadeInUp">
            {{ index + 1 }}
          </div>
        </div>
        <div class="unsplashLoading">
          <div class="sk-double-bounce">
            <div class="sk-child sk-double-bounce1"></div>
            <div class="sk-child sk-double-bounce2"></div>
          </div>
        </div>
        <div class="unsplashprogress">
          <div class="progressWrapper">
            <div class="progressInner"></div>
          </div>
        </div>
        <div class="unsplashError">
          <div class="unsplahErrorText">
            {{ i18n_text.errortext }}
          </div>
        </div>
        <div class="unsplashNoresults">
          {{ i18n_text.noresults }}
        </div>
      </div>
      <div class="unsplashFooter normal slideInUp">
        <div class="col-md-6">
          <button class="unsplashDownload">{{ i18n_text.download }}</button>
          <button class="unsplashCancel">{{ i18n_text.cancel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imagelist: {lists: [{images: []}]},
      searchterm: "",
      page: 1,
      failcount: 0,
      apikey: "4cf9075157f3fef261a01e0a83babae19795def6af17047d70b9888976567739",
    }
  },
  props: ['i18n_text'],
  mounted() {
    let self = this;

    $(".unsplashPage").show();
    $(".unsplashLoading").hide();

    $("#unsplashSearch").click(function () {
      $(".unsplashContainer").css("display", "flex");

      self.firstload();
    });

    $(document).on("click", ".unsplashOverlay, .unsplashClose", function () {
      self.closeOverlay();
    });

    $(".unsplashBoxInner").on("scroll", function () {
      if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
        self.page = self.page + 1;
        $(".unsplashLoading").show();
        $(".unsplashPage").show();
        self.scrollload();
      }
    });

    $(document).on("submit", ".unsplashForm", function (event) {
      event.preventDefault();

      $(".unsplashLoading").show();
      $(".unsplashBoxInner").scrollTop(0);
      $(".unsplashFooter").hide();
      self.searchload();
    });

    $(document).on("click", ".unsplashItem", function () {
      $(this).toggleClass("active");
      $(".unsplashFooter").toggle($(".unsplashItem.active").length > 0);
    });

    $(document).on("click", ".unsplashCancel", function () {
      $(".unsplashItem").removeClass("active");
      $(".unsplashFooter").hide();
    });

    $(document).on("click", ".unsplashDownload", function () {
      let _files = [];
      $(".unsplashprogress").show();
      $(".unsplashItem.active").each(function (index) {
        _files.push($(this).attr("data-url"));
      });
      let percentagepart = 100 / _files.length;
      let dlcounter = 0;
      $.each(_files, function (idx, obj) {
        $.post(window.MediaManager.controllerUrl + 'download', {'files': [obj]}).done(function (data) {
          dlcounter = dlcounter + 1;
          $(".progressInner").css("width", Math.ceil($(".progressInner").width() / $(".progressWrapper").width() * 100) + Math.ceil(percentagepart) + "%");
          if (dlcounter >= _files.length) {
            self.closeOverlay();
            $(".unsplashprogress").hide();
            $(".progressInner").css("width", "0%");
            window.MediaManager.updateListView();
          }
        }).fail(function () {
          self.closeOverlay();
          $(".unsplashprogress").hide();
          $(".progressInner").css("width", "0%");
          window.MediaManager.updateListView();
        });
      });
    });
  },
  methods: {
    scrollload() {
      let self = this;
      this.unsplashGet({
        query: self.searchterm,
        page: self.page
      }, data => {
        if (typeof data.results !== "undefined") {
          data = data.results;
        }
        $(".unsplashLoading").hide();
        if (data.length) {
          self.imagelist.lists.push({images: data});
          self.updateImageUrlParams();
          self.page++;
        }
      });
    },
    firstload() {
      let self = this;
      this.unsplashGet({}, data => {
        self.imagelist = {lists: [{images: data}]};
        self.updateImageUrlParams();
      });
    },
    searchload() {
      let self = this;
      this.unsplashGet({
        query: self.searchterm,
      }, data => {
        if (data.total <= 0) {
          $(".unsplashNoresults").show();
          $(".unsplashResults").hide();
        } else {
          $(".unsplashNoresults").hide();
          $(".unsplashResults").show();
        }
        self.imagelist = {lists: [{images: data.results}]};
        self.updateImageUrlParams();

        $(".unsplashLoading").hide();
        self.page = 1;
      });
    },
    unsplashGet(args = {}, callback = (data) => {
    }) {
      let self = this;

      let data = {
        client_id: self.apikey,
        per_page: 50,
      };
      if (args.query) data.query = args.query;
      if (args.page) data.page = args.page;

      // https://api.unsplash.com/photos/curated/ is deprecated
      let url = data.query && data.query !== "" ? "https://api.unsplash.com/search/photos/" : "https://api.unsplash.com/photos/";

      $.ajax({
        url: url,
        dataType: "jsonp",
        crossDomain: true,
        type: "GET",
        dataType: "json",
        headers: {'Access-Control-Allow-Origin': '*'},
        data: data,
        jsonpCallback: 'callback',
        success: function (data) {
          callback(data);
        },
        error: function (error) {
          self.failcount++;
          if (self.failcount < 5) {
            setTimeout(function () {
              self.unsplashGet(args, callback);
            }, 1000);
          } else {
            $(".unsplashError").show();
            $(".unsplashLoading").hide();
          }
        }
      });
    },
    updateImageUrlParams(args = {}) {
      // default parameters for custom image conversion, resizing, cropping, ect.
      // https://unsplash.com/documentation#supported-parameters
      // parameters: https://docs.imgix.com/apis/url
      let params = {
        q: 85,
        fm: 'jpg',
        w: 2000,
        //crop: 'entropy',  // used with fit: 'crop'
        cs: 'tinysrgb',
        fit: 'max',
      };
      // mass assign args
      for (let arg in args) {
        if (params[arg]) params[arg] = args[arg];
      }
      let url_params = '';
      for (let p in params) {
        url_params += '&' + p + '=' + params[p];
      }

      for (let i = 0; i < this.imagelist.lists.length; i++) {
        for (let j = 0; j < this.imagelist.lists[i].images.length; j++) {
          this.imagelist.lists[i].images[j]['customizedUrl'] = this.imagelist.lists[i].images[j].urls.raw + url_params;
        }
      }
    },
    closeOverlay() {
      $(".unsplashContainer").hide();
      $(".unsplashItem").removeClass("active");
      $(".unsplashFooter").hide();
      $(".unsplashError").hide();
      $(".unsplashLoading").hide();
    }
  }
}

</script>

<style>
.unsplashPage {
  position: absolute;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: #f1f1f1;
  color: #959595;
  left: 50%;
  bottom: -5px;
  text-align: center;
  line-height: 30px;
  font-size: 11px;
}

.unsplashNoresults {
  float: left;
  width: 100%;
  text-align: center;
  padding: 20px;
  display: none;
}

.unsplashError {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  display: none;
}

.unsplahErrorText {
  width: 400px;
  max-width: 100%;
  margin: auto;
  margin-top: 50%;
  color: #959595;
  font-size: 25px;
  text-align: center;
}

.unsplashprogress {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  display: none;
}

.progressWrapper {
  width: 400px;
  max-width: 100%;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  margin: auto;
  margin-top: 50%;
  background-color: #f1f1f1;
}

.progressInner {
  height: 40px;
  width: 0%;
  background-color: #39d0cd;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.unsplashLoading {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 1111;
}

.unsplashDownload {
  background-color: #37d0cd;
  color: #ffffff !important;
  width: 140px;
  height: 46px;
  border-width: 0px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 7px !important;
}

.unsplashCancel {
  background-color: #959595;
  color: #ffffff !important;
  width: 140px;
  height: 46px;
  border-width: 0px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 7px !important;
}

.unsplashFooter {
  height: 60px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: #ffffff;
  position: absolute;
  display: none;
}

.unsplashClose {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  padding: 15px;
}

.unsplashClose svg {
  width: 100%;
}

.unsplashClose svg path {
  fill: #959595;
}

.unsplashHeader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1024px;
  height: 140px;
  max-width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 1111;
}

.unsplashContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(42, 53, 53, 0.9);
  z-index: 9999;
  display: none;
}

.unsplashOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}

.unsplashBox {
  width: 1024px;
  height: 100vh;
  border-radius: 4px;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.unsplashBoxInner {
  background-color: #ffffff;
  float: left;
  width: 100%;
  padding: 60px;
  padding-top: 140px;
  overflow-y: auto;
  height: 100%;
}

.unsplashResults {
  //grid-template-columns: repeat(3, 1fr);
  line-height: 0;
  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 15px;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;

  padding-bottom: 20px;
  margin-bottom: 20px;
  //border-bottom: 10px solid #ececec;
  padding-bottom: 40px;
  position: relative;
}

.unsplashItem {
  position: relative;
  margin-bottom: 20px;
}

.unsplashItem:hover {
  //-webkit-box-shadow: 0 0 0 4px #37d0cd;
  //box-shadow: 0 0 0 4px #37d0cd;
}

.unsplashItem:hover:before, .unsplashItem.active:before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(55, 208, 205, 0.2);
  content: " ";
}

.unsplashItem:hover:after, .unsplashItem.active:after {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(55, 208, 205, 1);
  color: #ffffff;
  content: "+";
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.unsplashItem.active:before {
  background: rgba(55, 208, 205, 0.8);
}

.unsplashItem.active:after {
  content: "\e80f";
  font-family: "Fontello";
  -webkit-box-shadow: 0 0 0 2px #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
}

.unsplashItem img {
  width: 100% !important;
  height: auto !important;

}

.unsplashname {
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
}

.unsplashname a {
  color: #ffffff;
}

.unsplashname a:hover {
  color: #d4d2d2;
  text-decoration: none;
}

.unsplashSearchField {
  height: 46px;
  width: 452px;
  background-color: #f1f1f1;
  border-radius: 2px;
  border-width: 0px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.unsplashInputWrapper {
  margin-left: auto !important;
  margin-right: auto !important;
  display: inline-block;
  margin-top: 44px;
  position: relative;
}

.unsplashInputWrapper i {
  color: #959595;
  position: absolute;
  left: 15px;
  top: 15px;
}

#unsplashSearch {
  font-size: 12px;
}

.sk-double-bounce {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 40px auto;
  top: 50%;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #38d0cd;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
  animation: sk-doubleBounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>