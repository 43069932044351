import 'select2'
import 'select2/dist/css/select2.min.css'

$(function() {
    $('.select2').select2({
        language: {
            noResults: function (params) {
                return "Keine Resultate gefunden";
            }
        }
    });
});