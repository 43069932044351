<template>
    <KenburnsImage v-if="props.hasKenburnsEffect" :src="props.src"/>
    <div v-else class="image-container" :style="'background: url(' + src + ')' "></div>
</template>

<script setup>
import { defineProps } from 'vue'
import KenburnsImage from "./KenburnsImage.vue";

const props = defineProps({
    src: {
        type: String
    },
    hasKenburnsEffect: {
        type: Boolean,
        default: false
    }
})
</script>

<style scoped lang="scss">
.image-container {
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
</style>