<template>
  <div class="row">
    <div class="col-6">
      <input type="hidden" :name="backendstuctureConfig" :value="jsonString">
      <input type="hidden" name="Backendstructurei18nInheritance[config][inherit]" value="1">
      <draggable class="dragArea list-group"
                 :scroll-sensitivity="190"
                 :force-fallback="true"
                 handle=".handle"
                 :group="{name: 'configuration', pull: true, put: ['tabs']}"
                 :list="listConfiguration"
      >
        <div v-for="(el, idx) in listConfiguration" :key="idx" :class="funcClassname(el)">
          <i class="fa fa-fw fa-sort handle"></i>
          <i :class="el.icon"></i>
          <i class="fa fa-times close" @click="removeTabAt(idx)"></i>
          <input class="outerInput" type="text" v-model="el.name">
          <input class="outerInputRight" type="text" placeholder="fa fa-file-alt" v-model="el.icon">
          <draggable class="dragArea list-group"
                     :list="el.tasks"
                     :scroll-sensitivity="190"
                     :force-fallback="true"
                     handle=".handle"
                     :group="{name: 'fields', pull: true, put: ['attributes', 'fields']}"
          >
            <div v-for="(el2, id2x) in el.tasks"
                 :key="id2x"
                 :class="funcClassname(el2)"
                 :data-type="el2.type"
            >
              <i class="fa fa-fw fa-sort handle"></i>
              <i :class="funcIcon(el2)"></i>
              <input v-if="el2.input !== 'input' && el2.input !== 'textarea'" type="checkbox" v-model="el2.readonly">
              <i class="fa fa-fw fa-pencil unchecked"
                 v-if="el2.input !== 'input' && el2.input !== 'textarea' && !el2.readonly" title="Writeable"></i>
              <i class="fa fa-fw fa-ban checked" v-if="el2.readonly" title="Readonly"></i>
              <span v-if="el2.type!=='extra'" class="text">{{ el2.name }} </span>
              <i class="fa fa-fw fa-xs fa-times close" @click="removeAt(idx, id2x)"></i>
              <input v-if="el2.input === 'input'" type="text" v-model="el2.name">
              <textarea v-if="el2.input === 'textarea'" v-model="el2.name"></textarea>
              <small v-if="el2.hint" class="form-text text-muted" :title="el2.hint"
                     data-toggle="tooltip">{{ el2.hint }}</small>
              <div v-if="el2.extraFields">
                <div v-for="(extra, idx) in el2.extraFields">
                  <label>{{ idx }}</label>
                  <input type="text" v-model="el2.extraFields[idx]">
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
    </div>

    <div class="col-6">
      <div class="sticky-top-backendstructurei18n">
        <draggable
            :scroll-sensitivity="190"
            :force-fallback="true"
            :list="listTabs"
            :disabled="false"
            :sort="false"
            handle=".handle"
            :group="{name: 'tabs', pull: 'clone', put: false}"
            class="d-flex-backendstructurei18n list-group"
            ghost-class="ghost"
            :clone="cloneTab"
            @end="endDrag"
        >
          <div
              :class="funcClassname(element)"
              v-for="(element, elementId) in listTabs"
              :key="elementId"
          >
            <i class="fa fa-fw fa-grip-vertical handle"></i>
            <i :class="funcIcon(element)"></i>
            <span class="text">{{ element.name }} </span>
          </div>
        </draggable>
        <p></p>
        <draggable
            :scroll-sensitivity="190"
            :force-fallback="true"
            :list="listAttributes"
            :disabled="false"
            :sort="false"
            handle=".handle"
            :group="{name: 'attributes', pull: pullFunctionConfiguration , put: false}"
            class="d-flex-backendstructurei18n list-group lock-height"
            :clone="cloneAttribute"
            ghost-class="ghost"
            @end="endDrag"
        >
          <div
              :class="funcClassname(element)"
              v-for="(element, elementId) in listAttributes"
              :key="elementId"
              :data-type="element.type"
              :data-allowed="element.allowed"
          >
            <i class="fa fa-fw fa-grip-vertical handle"></i>
            <i :class="funcIcon(element)"></i>
            <span class="text">{{ element.name }} </span>
            <small v-if="element.hint" class="form-text text-muted" :title="element.hint"
                   data-toggle="tooltip">{{ element.hint }}</small>
            <div v-if="element.extraFields">
              <div v-for="(extra, idx) in element.extraFields">
                <label>{{ idx }}</label>
                <input type="text" v-model="element.extraFields[idx]">
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

function uniqueId() {
  return '_' + performance.now().toString().replace('.', '');
}

let lastElement;
const ElementStyles = {
  extra: 'primary',
  tab: 'secondary',
  attribute: 'success',
  feature: 'warning'
}
const ElementIcons = {
  extra: {
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    // p: 'paragraph',
    div: 'code'
  },
  tab: {
    tab: 'file-alt'
  },
  attribute: 'quote-right',
  feature: 'star'
}

export default {
  name: "BackendstructureModule",
  display: "Backendstructure Module",
  order: 0,
  components: {
    draggable,
  },
  props: {
    /**
     * The current configuration of this backendstructure
     */
    passConfiguration: {
      required: true,
      type: Array
    },
    /**
     * The attributes including dynamic features which are available
     */
    passAttributes: {
      required: true,
      type: Array
    },
    /**
     * The tab sections, currently just one element
     */
    passTabs: {
      required: true,
      type: Array
    },
    templateId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      listConfiguration: this.passConfiguration,
      listAttributes: this.passAttributes,
      listTabs: this.passTabs,
    }
  },
  mounted() {

  },
  computed: {
    /**
     * Creates the json string we save into db
     */
    jsonString() {
      return JSON.stringify(this.listConfiguration, null, null);
    },
    backendstuctureConfig() {
      return 'Backendstructurei18n[config][' + this.templateId + ']';
    }
  },
  methods: {
    /**
     * Removes a element at tab id, and the element with index id2.
     */
    removeAt(id, id2) {
      const code = this.listConfiguration[id].tasks[id2].code;
      this.listAttributes.forEach(function (attribute) {
        if (attribute.code === code) {
          attribute.allowed = true;
        }
      });
      this.listConfiguration[id].tasks.splice(id2, 1);
    },
    /**
     * Removes a tab on clicking X
     * When the tab has elements it will reactivate them before removing itself.
     */
    removeTabAt(id) {
      // if list is empty we just remove it, else we activate each and remove it.
      if (this.listConfiguration[id].tasks.length === 0) {
        this.listConfiguration.splice(id, 1);
      } else {
        const that = this;
        this.listConfiguration[id].tasks.forEach(function (el, i) {
          let code = that.listConfiguration[id].tasks[i].code;
          that.listAttributes.forEach(function (attribute) {
            if (attribute.code === code) {
              attribute.allowed = true;
            }
          });
        });
        this.listConfiguration.splice(id, 1);
      }
    },
    /**
     * Returns a clone of the tab
     */
    cloneTab: function ({name, code}) {
      return {
        id: uniqueId(),
        name: name,
        icon: '',
        code: code,
        type: 'tab',
        inherit: true,
        allowed: true,
        exist: true,
        tasks: [],
      }
    },

    /**
     * If the element is allowed to be used, make a clone, else deny.
     */
    pullFunctionConfiguration: function () {
      return lastElement.allowed ? 'clone' : false;
    },

    /**
     * On drag end from the attributes, it checks if the element has actually been moved to another list
     * If yes, it will disable it, if it is not a extra field.
     */
    endDrag: function (data) {
      if (lastElement && lastElement.allowed) {
        lastElement.allowed = lastElement.type === 'extra' || data.from === data.to;
      }
      lastElement = null;
    },

    /**
     * Sets the global variable "currentAllowed" to prevent moving an element which has already been add,
     * but only if its not one of the extras.
     * Returns a new object - e.g. clone.
     * @param element
     * @returns {object}
     */
    cloneAttribute: function (element) {
      lastElement = element;

      return {
        id: uniqueId(),
        name: element.name,
        icon: element.icon,
        readonly: element.readonly,
        hint: element.hint,
        type: element.type,
        code: element.code,
        inherit: true,
        hidden: false,
        exist: element.exist || false,
        allowed: true,
        input: element.input || '',
        extraFields: element.extraFields || undefined
      }
    },

    /**
     * Returns the class names for the element
     * @param element
     * @returns {string}
     */
    funcClassname: function (element) {
      if (element.exist) {
        const allowed = element.allowed ? 'allowed' : 'notallowed d-none';
        return `list-group-item list-group-item-${ElementStyles[element.type]} p-1 ${allowed}`;
      } else {
        return `list-group-item list-group-item-danger p-1`;
      }
    },

    /**
     * Returns the icon for the i tag of the element
     * @param element
     * @returns {string}
     */
    funcIcon: function (element) {
      const prefix = 'fa fa-fw fa-align-justify fa-';
      if (!element.exist) {
        return `${prefix}trash`
      }
      if (element.type in ElementIcons) {
        if (typeof ElementIcons[element.type] === 'string') {
          return `${prefix}${ElementIcons[element.type]}`
        } else if (element.code in ElementIcons[element.type]) {
          return `${prefix}${ElementIcons[element.type][element.code]}`
        }
      }
      return `${prefix}exclamation-triangle`
    }
  }
};
</script>

<style scoped lang="scss">
.sticky-top-backendstructurei18n {
  position: sticky;
  top: 159px;
}

.sortable-drag {
  opacity: 0.5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.dragArea {
  min-height: 34px;
  margin-left: 0;

  .dragArea {
    min-height: 34px;
    margin-left: 23px;
    margin-right: 7px;
  }
}

input:not([type='checkbox']), textarea {
  width: 80%;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #ffffff5c;
  appearance: none;
  border: none;
  border-bottom: 1px dashed grey;

  &[readonly] {
    border-bottom: 1px dashed transparent;
    background: none;
    outline: none;
  }
}

input.outerInput {
  margin-bottom: 5px;
  width: 39%
}

input.outerInputRight {
  margin-bottom: 5px;
  width: 39%;
}

.outerInputRightIcon {
  background-color: rgba(255, 255, 255, 0.36078);
  width: 32px;
  height: 32px;
  border: 2px solid #9c9c9c;
  padding: 1px 3px 1px 3px;
  border-radius: 3px;
}

textarea {
  margin-top: -1px;
  border-radius: 2px;
  max-height: 300px;
  min-height: 24px;
}

.d-flex-backendstructurei18n {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  &.lock-height {

    height: calc(100vh - 200px);
  }

  .list-group-item {
    margin-right: 5px;
  }

  .notallowed {
    filter: saturate(0.3);
    text-decoration: line-through;
  }

  .list-group-item {
    border-radius: 0.25em;
  }
}

.close {
  font-size: 1em;
  cursor: pointer;
}

.handle {
  cursor: grab;
  opacity: 0.5;
  color: #000;
  text-shadow: 0 1px 0 #fff;

  &:hover {
    opacity: 1;
  }
}


.form-text[data-toggle] {
  float: right;

  &:after {
    background: #ffffff7d;
  }
}

i {
  vertical-align: top;
  top: 4px;
  position: relative;
}
</style>