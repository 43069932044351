<template>
    <div class="exist-segment-rule">
        <v-select v-model="operator" :options="operators" :reduce="label => label.key" class="segment-rule-operator" :clearable="false"></v-select>
        <v-select v-if="showContent" :options="contentOptions()" :reduce="label => label.key" v-model="content" class="segment-rule-content segment-rule-content-large" :clearable="false"></v-select>
    </div>
</template>

<script>
import SegmentRuleBase from "./SegmentRuleBase.vue"

export default {
    extends: SegmentRuleBase,
    data() {
        return {
            contentOptions() {
                const items =  this.rules[this.attribute].items;
                if (items instanceof Object) {
                    return Object.entries(items).map((value) => {
                        return {key: value[0], label: value[1]}
                    });
                } else {
                    return items.map((currentValue, index) => {
                        return {key: index, label: currentValue}
                    });
                }
            },
            operators: [
                {
                    label: this.labels.equal,
                    key: "equal"
                },
                {
                    label: this.labels.not_equal,
                    key: "not_equal"
                },
                {
                    label: this.labels.is_null,
                    key: "is_null"
                },
                {
                    label: this.labels.is_not_null,
                    key: "is_not_null"
                }
            ]
        }
    }
};
</script>