var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "scroll-y-transition", mode: "in-out" } },
    [
      _c("div", { key: _vm.id, staticClass: "form-group label-inside" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { id: _vm.id, type: _vm.type, placeholder: _vm.label },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.update($event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }