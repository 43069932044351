<template>
    <div class="fg__colorpicker">
        <div class="input">
            <div class="colorpicker-icon" v-if="color === ''" :title="color">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" role="img">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
                        <circle cx="10" cy="10.5" r="10" fill="#C4C4C4"></circle>
                    </mask>
                    <g mask="url(#mask0)">
                        <rect x="10" y="0.5" width="10" height="20" fill="#3A8BBB"></rect>
                        <path d="M20 4.5L10 -2.5V10.5L20 4.5Z" fill="#00B6E3"></path>
                        <path d="M20 16.5L10 24.5V10.5L20 16.5Z" fill="#C142A0"></path>
                        <path d="M0 0.5H10V10.5L0 16.5V0.5Z" fill="#FCC870"></path>
                        <path d="M-7 0.5H10V10.5L-7 0.5Z" fill="#EF875B"></path>
                        <path d="M0 16.5L10 24.5V10.5L0 16.5Z" fill="#FF5555"></path>
                    </g>
                    <circle cx="10" cy="10.5" r="3" fill="#F5F6F7"></circle>
                </svg>
            </div>
            <div class="colorpicker-icon" v-if="color !== ''" :title="color">
                <div :style="`background-color: ${color}; width: 20px; height: 20px; border-radius: 50%;`"></div>
            </div>
            <input  type="text" :title="inputValue" v-bind:value="color" :class="inputClass"
                    :placeholder="inputPlaceholder" @change="setColor()" ref="inputColor">
            <swatches
                v-model="color"
                :colors="colors"
                :swatches="colors"
                v-bind="options"
                @input="setSwatchColor(color)"
                ref="swatches"
                inline
            >
            </swatches>
        </div>

        <input class="form-control" :name="inputName" aria-invalid="false" style="display: none;"
               v-bind:value="color"/>
    </div>
</template>

<script>
import Base from './Base.vue';
import fireDefaultColors from '../fireDefaultColors'

export default {
    name: 'FiregroupColorpicker',
    extends: Base,

    props: [
        'parentEventName',
    ],

    created() {
        this.colors = fireDefaultColors.colors;

        // Parse value and options from widget
        this.color = this.inputValue ? this.color = this.inputValue : '';
        if(this.options['show-fallback']) {
            delete this.options['show-fallback'];
        }

        if(!this.options['swatch-size']) {
            this.options['swatch-size'] = 17;
        }

        if(!this.options['swatch-style']) {
            this.options['swatch-style'] = {
                'margin': '0',
                'border-radius': '0',
                'float': 'left',
                'height': '20px',
                'width': '20px'
            }
        }

        if(!this.options['spacing-size']) {
            this.options['spacing-size'] = 0;
        }
    },
    watch: {
        /**
         * Watch for inputValue changes from outside
         **/
        inputValue(value) {
            this.color = typeof value === 'string' ? value : '';
        }
    },
    methods: {
        /**
         * Switch color on input change
         */
        setColor(that) {
            if(typeof this.$refs['inputColor'].value === 'string' &&
                this.isColor(this.$refs['inputColor'].value)) {

                this.color = this.$refs['inputColor'].value;
                this.emitEvent();

                // Close swatches with click
                this.$refs['inputColor'].click();
            } else if(this.$refs['inputColor'].value === '' || this.$refs['inputColor'].value === 'undefined') {

                this.color = '';
                this.emitEvent();
                this.$refs['inputColor'].click();
            }
        },

        /**
         * Switch color on swatch change
         * @param color
         */
        setSwatchColor(color) {
            this.color = typeof color === 'string' ? color : '';
            this.emitEvent();
        },

        /**
         * Emit event if set from parent
         */
        emitEvent() {
            if(this.parentEventName) {
                this.$emit(this.parentEventName, {color: this.color});
            }
        },
        test() {
            console.log(this);
        },
        /**
         * Focus input on open swatches
         */
        focusInput() {
            let that = this;

            // Handle submit on input.
            let forms = window.document.querySelectorAll('form');
            for(let form of forms){
                form.addEventListener('keypress', handleInput);
                form.addEventListener('keyup', handleInput);
            }

            /**
             * Disable form submit on enter and set color if enter hit cause focus is on input
             * @param e
             * @returns {boolean}
             */
            function handleInput(e){
                let keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();

                    that.setColor();

                    // Remove listener after color is set
                    for(let form of forms){
                        form.removeEventListener('keypress', handleInput);
                        form.removeEventListener('keyup', handleInput);
                    }

                    return false;
                }
            }

            this.$refs.inputColor.focus();
        },

        /**
         * https://stackoverflow.com/a/2819658/9015191
         * @param str
         * @returns {boolean}
         */
        isColor(str) {
            return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(str) ||
                /^rgb\s*(\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*,\s*[012]?[0-9]{1,2}\s*)$/i.test(str) ||
                this.colorNames.includes(str.toLowerCase());
        }
    }
}
</script>

<style lang="scss">
    .color__picker--content {
        padding: 15px !important;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.2);
        width: 230px;
        position: absolute;
        top: 100%;
        left: -15px;
        z-index: 50;
    }

    .vue-swatches__wrapper {
        width: 300px !important;
    }

    .fg__colorpicker {
        .colorpicker-icon {
            position: absolute;
            top: 20px;
            left: 10px;
            min-width: 20px;
            z-index: 10;
            transform: translateY(-50%);
        }
        .input {
            position: relative;
        }

        .input.input {
            input {
                padding-left: 45px;
                min-height: 40px;
            }
        }
        .vue-swatches {
            margin-top: 15px;
        }
        .vue-swatches__wrapper {
            display: flex;
            flex-flow: wrap row;
            > div {
                margin: 2.5px !important;
                &:last-child {
                    border: 1px solid #dcdcdc;
                }
            }
        }

        .vue-swatches__swatch:focus, .vue-swatches__swatch:hover {
            opacity: .9;
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
            outline: none;
            transform: scale(1);
            z-index: 10;
        }
    }
</style>