<template>
    <div class="empty-state">
        <h2 class="headline">{{ emptyHeadline }}</h2>
        <div class="subline">{{ emptySubline }}</div>

        <draggable :group="{ name: 'filter' }" :list="active" v-if="active.length === 0" class="hint" :class="{ isDragging }">
            <div class="hint-text" v-if="!isDragging">{{ emptyHint }}</div>
        </draggable>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: 'EmptyState',
    components: {
        draggable
    },
    props: {
        emptyHeadline: {
            type: String
        },
        emptySubline: {
            type: String
        },
        emptyHint: {
            type: String
        },
        active: {
            type: Array,
            default: []
        },
        isDragging: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-state {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAFBFB;
}
.headline {
    font-size: 24px;
    color: #4C5E64;
    margin-bottom: 2.8rem;
}
.subline {
    max-width: 430px;
    margin-bottom: 6rem;
    font-size: 18px;
    color: #4C5E64;
    text-align: center;
}
.hint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26rem;
    height: 50px;
    border: 1px dashed #D6D8D8;
    background-color: #ffffff;
    font-size: 11px;
    border-radius: 10px;

    &.isDragging {
        border-width: 2px;
    }
}
.hint {}
</style>