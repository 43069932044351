"use strict";
/**
 * Created by prueg on 17.07.2017.
 */

import WebGLCanvas from './WebGLCanvas';
import {PaintCanvas} from './paintCanvas';
import * as Cropping from './cropping';
import {displayFilterPreviewsFor,updateFilterFormFields,_webGLPresetFilters,_customFilters} from './filters';

//media "class"
export default function Media(item,previewEl,cropContainer){
    this.item = item;                                           // the original selected <li> item from the mediamanager
    this.preview = previewEl;                                   // html dom <img> element       -> here we display the current cropped & filtered image
    this.cropContainer = cropContainer;                         // <div> - container for the cropper

    function calcContainerWidth(cropContainer){
        let _containerStyle = getComputedStyle($(cropContainer).closest(".container")[0]);
        let _modalBodyStyle = getComputedStyle($(cropContainer).closest(".modal-body")[0]);

        return (
            parseInt(_containerStyle["max-width"]) -
            parseInt(_containerStyle["padding-left"]) -
            parseInt(_containerStyle["padding-right"]) -
            parseInt(_modalBodyStyle["padding-left"]) -
            parseInt(_modalBodyStyle["padding-right"])
        );
    }

    this.containerWidth = calcContainerWidth(cropContainer);
    this.containerHeight = 600; //static

    this.isCurrentMedia = false;

    this.metaDataChanged = false;
    this.metaDataLoaded = false;
    this.metaData = {
        tags: [],
        description: "",
        link: "",
        linktext: "",
        title: "",
        subtitle: "",
        developer: "",
        lorem: ""
    };

    this.dataUrlMime = "image/jpeg";
    this.dataUrlQuality = 0.9;

    // if the image is a png and has an alphachannel, keep it as png without compressing & converting to jpg, to preserve alpha
    if(this.item.data("mime") === "image/png" && this.item.find(".mm-file-preview").hasClass("alphachannel")){
        this.dataUrlMime = "image/png";
        this.dataUrlQuality = 1.0;
    }

    this.cropper = false;                                       // the cropper.js - object      https://github.com/fengyuanchen/cropperjs

    //this.picaFeaturesDefault =  ["js","wasm","ww"];             // features for pica resizing      https://github.com/nodeca/pica
    this.picaFeaturesDefault =  ["js"];             // features for pica resizing      https://github.com/nodeca/pica
    this.picaOptionsDefault = {                                 // options for pica resizing       https://github.com/nodeca/pica
        quality: 3,
        alpha: true,
        unsharpAmount: 0,
        unsharpRadius: 0,
        unsharpThreshold: 0
    };
    this.picaFeatures = this.picaFeaturesDefault;
    // needs to be assigned this way, otherwise only a reference will be assigned, not an individual object
    this.picaOptions = JSON.parse(JSON.stringify(this.picaOptionsDefault));



    this.filterOptions = {};                                    // filter options
    this.presetFilter = false;                                  // name of the preset filter (if set, will be applied instead of filterOptions when drawn with camanjs)

    // instead of rerendering & loading the same images, keep track of when something about filters or cropper changed and only then re-render them
    this.cropperChanged = false;
    this.cropCount = 0; // count amount of crops done, ugly fix to determine when crop() is called for the first time (cropperjs doesnt provide a proper event callback)
    this.filtersChanged = false;
    this.cropperfiltersChanged = false;

    this.unsavedChanges = false;
    this.saveButton = $("#saveMedia");


    // we need all these img-elements seperated to be able to go back certain steps and revert/reset cropping area & selected filters
    // and to render the filters on downsized images for the user, instead of the fullsize original images which would take way longer but then would be displayed to the user downsized anyway
    // when the user saves his changes, then the filters should be applied on the original fullsize image in the background
    // dont ask!

    // source & cropped images in their original size
    this.sourceImage = document.createElement("img");   // needed as source to crop from, when cropping area changes
    this.croppedImage = document.createElement("img");  // needed as base to apply filters to when finalizing

    // source & cropped images downsized to fit the screen (save time when filtering)
    this.sourceImageScreenFit = document.createElement("img");  // downsized uncropped image, needed as background image for the cropper
    this.croppedImageScreenFit = document.createElement("img"); // downsized cropped image, base to apply filters to

    // downsized source & cropped images with filters applied
    this.sourceImageScreenFitFiltered = document.createElement("img");  // displayed inside the cropping area (uncropped, filtered)
    this.croppedImageScreenFitFiltered = document.createElement("img"); // displayed for filtering or editing metadata (looks like final, but is downsized to save memory)

    // used for camanjs to render filters into -> these will be extracted as dataURL into the 2 img elements above
    this.sourceImageScreenFitCanvas = document.createElement("canvas");
    this.croppedImageScreenFitCanvas = document.createElement("canvas");

    // this is used to render filters on the original-size images ONLY once the user wants to save, as it takes a lot of time to render filters on large images
    this.finalImage = document.createElement("canvas");



    // thumbnail images to be used for the small previews for the preset filters
    this.filterThumbnails = {
        count: 0,                // number of thumbnails
        loaded: 0,               // number of thumbnails finished & loaded (filtered)
        ready: false,            //
        source: false,           // source-image, dataURL
        sourceImage: new Image(),// source-image
        sourceGLCanvas: false,   // webGL Canvas
        sourceLoaded: false,
        width: 100,              // size for the thumbnails
        height: 120,             // size for the thumbnails
        custom: {
            // <img> -elements loaded dynamically, will be replaced by canvas elements through camanjs
        },
        preset: {
            // <img> -elements loaded dynamically, will be replaced by canvas elements through camanjs
        }
    };


    this.targetWidth = false;           // width to resize the result to
    this.targetHeight = false;          // height to resize the result to

    // various stored data from the UI, needed to restore back to these when switching back to this media from another
    this.selectedRatioIndex = false;    // selected ratio from the dropdownlist
    this.selectedSizeIndex = false;     // selected ratio & size from the dropdownlist
    this.scaleX = 1;                    // flip horizontal, -1/1
    this.scaleY = 1;                    // flip vertical, -1/1

    this.newFileName = false;




    this.webGL = true;
    this.webGLCanvas = new WebGLCanvas();

    this.initGLCanvas = function(img){
        this.webGLCanvas.init(img);
        if(this.webGLCanvas.canvas){
            this.webGLCanvas.canvas.classList.add("imgPreview");
            this.webGLCanvas.canvas.id = this.preview.id;
            $(this.webGLCanvas.canvas).insertAfter(this.preview);
            $(this.preview).remove();
            this.preview = this.webGLCanvas.canvas;
            if(!this.isCurrentMedia) $(this.preview).parent().hide();

            // init painting & fabric
            this.paintCanvas.init(this.webGLCanvas.canvas);
            this.paintCanvas.initFabric();
        } else {
            this.webGL = false; // should never go here because we alrdy checked webGL support before
        }
    };

    this.paintCanvas = new PaintCanvas(this);




    /************************************************
     *  initialising
     */

    this.init = function(callback){

        var that = this;
        // load the source image
        this.changeSourceImage(window.MediaEditor.getItemImageSource(this.item),function(){

            if(that.webGL){
                that.initGLCanvas(that.sourceImageScreenFit);
            }

            //initialise the cropper
            that.initCropper();
            that.disableSave();
            if(typeof(callback) === "function") callback();
        },function(err){
            console.log('an error occured trying to load this file');
            if(typeof(callback) === "function") callback();
        });
        this.readMetaData(function(){});
    };

    this.initCropper = function(){
        if(!this.cropper) {
            var that = this;
            startLoading("initCropper", "Initialisiere Cropper…");    //Yii.t
            // load & append the source image for the cropper
            var cropSrcImg = document.createElement("img");
            cropSrcImg.crossOrigin = "use-credentials";
            cropSrcImg.src = this.sourceImage.src;
            this.cropContainer.appendChild(cropSrcImg);
            $(cropSrcImg).one("load",function(){

                // and once that source image is loaded, init the cropper
                that.cropper = new Cropper(cropSrcImg, {
                    dragMode: "crop",           // create a new crop box
                    viewMode: 1,                // restrict the minimum canvas size to fill fit the container. If the proportions of the canvas and the container are different, the container will not be able to fit the whole canvas in one of the dimensions.
                    responsive: false,          // Re-render the cropper when resize the window.
                    zoomable: false,            //todo create a custom zoom-interface
                    movable: false,
                    rotatable: true,
                    scaleable: true,
                    highlight: false,           // Show the white modal above the crop box (highlight the crop box).
                    modal: true,                // Show the black modal above the image and under the crop box.
                    autoCrop: true,             // Enable to crop the image automatically when initialize.
                    autoCropArea: 1,            // A number between 0 and 1. Define the automatic cropping area size (percentage).
                    background: true,           // Show the grid background of the container.
                    guides: false,              // Show the dashed lines above the crop box.
                    center: false,              // Show the center indicator above the crop box.

                    minContainerHeight: that.containerHeight,
                    minContainerWidth: that.containerWidth,

                    // event fired every time when something in the cropper changed
                    crop: function(e) {
                        Cropping.updateSizeText(that,e.detail.width,e.detail.height);

                        // ugly fix to determine if this was called for the first time, since cropperjs doesnt provide a proper initial callback event
                        // and calls crop() once automatically after initialising
                        that.cropCount++;
                        if(that.cropCount > 1){
                            that.allowSave();
                            that.cropperChanged = true; // flag the preview & canvas elements to be reloaded after changes to the cropper
                            $(that.cropContainer).find(".applyCrop").addClass("active");
                        }
                    }
                });

                /* fired when the cropper is initialised and ready */
                $(cropSrcImg).one("ready", function () {
                    finishedLoading("initCropper");

                    that.disableSave();    //disable the save button
                    //change the displayed cropper image with the downsized version (because the downsized img with pica has a better quality)
                    that.updateCropContainerImage(that.sourceImageScreenFit.src);
                });
            });
        }
    };


    /************************************************
     *  image manipulation, updating crop area & filters
     */

    // change source image
    this.changeSourceImage = function(src,callback,errorCallback){
        var that = this;
        startLoading("changeSourceImage", "Bild laden…"); //Yii.t
        this.sourceImage.crossOrigin = "use-credentials";
        this.croppedImage.crossOrigin = "use-credentials";
        this.sourceImage.src = this.croppedImage.src = src;
        $(this.sourceImage).one("load", function(){
            // init the source image & cropped image to be displayed to the user, downsized to fit the container
            that.downSizeToScreen(that.sourceImage,function(dataURL){
                that.preview.crossOrigin = "use-credentials";
                that.sourceImageScreenFit.crossOrigin = "use-credentials";
                that.croppedImageScreenFit.crossOrigin = "use-credentials";
                that.preview.src = dataURL;
                that.sourceImageScreenFit.src = dataURL;
                that.croppedImageScreenFit.src = dataURL;
                $(that.sourceImageScreenFit).one("load", function(){
                    // create the thumbnails for the filter-previews
                    // use the already downsized image for these to save a little more time
                    that.updateFilterThumbnailSource(that.sourceImageScreenFit,function(){
                        that.allowSave();
                        finishedLoading("changeSourceImage");
                        if(typeof(callback) === "function") callback();
                    });

                    // re-init the cropper
                    if(that.cropper) {  // cropper wont be initialised at start, this is only called when the source image is replaced f.e. on video thumbnails
                        Cropping.changeCropperImage(that,that.sourceImage.src);
                    }

                });

            });
        });
        $(this.sourceImage).one("error", function(){
            finishedLoading("changeSourceImage");
            if(typeof(errorCallback) === "function") errorCallback();
        });
    };

    // when something about the cropping area changed, update the croppedImage and filteredCroppedImage
    this.updateCropping = function(callback, extractOnly){
        // only update & rerender when something in the cropper changed
        if(this.cropperChanged){
            var that = this;
            this.allowSave();
            startLoading("updateCropping", "Bild zuschneiden…");  //Yii.t

            // extract cropped area from the Cropper and load it
            this.extractCroppedArea(function(src){
                that.croppedImage.crossOrigin = "use-credentials";
                that.croppedImage.src = src;
                $(that.croppedImage).one("load", function(){
                    // resize the new cropped image to fit the screen

                    if(!extractOnly){
                        that.downSizeToScreen(that.croppedImage,function(dataURL){
                            that.croppedImageScreenFit.crossOrigin = "use-credentials";
                            that.croppedImageScreenFit.src = dataURL;
                            $(that.croppedImageScreenFit).one("load", function(){

                                if(that.webGL){
                                    that.webGLCanvas.changeTexture(that.croppedImageScreenFit);
                                    that.webGLCanvas.draw();

                                    finishedLoading("updateCropping");
                                    if(typeof callback === "function") callback();
                                    that.cropperChanged = false;

                                    if(that.paintCanvas.initialized){
                                        that.paintCanvas.updateSize(that.croppedImageScreenFit.width,that.croppedImageScreenFit.height);
                                    }
                                }
                            });
                        });
                    } else {
                        finishedLoading("updateCropping");
                        if(typeof callback === "function") callback();
                        that.cropperChanged = false;
                    }
                });
            });
        } else {
            if(typeof callback === "function") callback();
        }
    };

    // when something about the filter options was changed, update the filteredSourceImage and filteredCroppedImage
    this.updateFilters = function(){
        if(this.filtersChanged){
            var that = this;
            this.cropperfiltersChanged = true;
            this.allowSave();
            startLoading("updateFilters", "Filter anwenden…");    //Yii.t

            if(that.webGL){
                that.webGLCanvas.draw();

                finishedLoading("updateFilters");
                that.filtersChanged = false;
            }
        }
    };

    this.updateFiltersForCropper = function(){
        var that = this;
        if(that.cropperfiltersChanged) {
            if(that.webGL){
                var glcanvas = new WebGLCanvas();
                glcanvas.init(this.sourceImageScreenFit);
                glcanvas.filters = that.webGLCanvas.filters;
                glcanvas.values = that.webGLCanvas.values;
                glcanvas.draw();

                that.updateCropContainerImage(glcanvas.toDataURL(that.dataUrlMime,that.dataUrlQuality));
                that.cropperfiltersChanged = false;

                glcanvas = null;
            }
        }
    };

    // display the downsized & filtered uncropped! image to the user in the cropping area
    // ONLY CALL THIS IF YOURE SURE THESE IMAGES ARE UPDATED AND FINISHED LOADING!
    this.updateCropContainerImage = function(src){
        startLoading("updateCropContainerImage", "Bild aktualisieren…");  //Yii.t
        var cropContainerImg = $(this.cropContainer).find(".cropper-view-box").find("img");
        cropContainerImg.attr("src", src);
        cropContainerImg.one("load", function(){
            finishedLoading("updateCropContainerImage");
        });


        var cropperCanvas = $(this.cropContainer).find(".cropper-wrap-box");
        cropperCanvas.find(".cropper-canvas").hide();
        var clone = cropperCanvas.find(".cropper-canvas-clone");
        if(clone.length <= 0){
            clone = cropperCanvas.find(".cropper-canvas").clone();
            clone.removeClass("cropper-canvas").addClass("cropper-canvas-clone").show();
            clone.appendTo(cropperCanvas);
        }
        clone.find("img").attr("src", src);

    };

    this.updateCropperImageStyle = function(){
        var cropperCanvas = $(this.cropContainer).find(".cropper-wrap-box .cropper-canvas");
        var cropperCanvasClone = $(this.cropContainer).find(".cropper-wrap-box .cropper-canvas-clone");

        cropperCanvasClone.attr("style", cropperCanvas.attr("style"));
        cropperCanvasClone.show();
        cropperCanvasClone.find("img").attr("style",cropperCanvas.find("img").attr("style"));
    };

    // resize an image to fit the container, keeping its aspect ratio (dont render the original-size image to the user)
    // returns base 64 dataURL
    this.downSizeToScreen = function(img,callback){
        // only downsize if necessary
        if(img.width > this.containerWidth || img.height > this.containerHeight){
            // calculate dimensions of the previewCanvas to fit the img inside
            startLoading("downSizeToScreen", "Bild laden…");  //Yii.t
            var canvas = document.createElement("canvas");
            var cSize = limitSize(img,this.containerWidth,this.containerHeight);
            canvas.width = cSize["width"];
            canvas.height = cSize["height"];
            var that = this;
            picaResize(
                img,
                canvas,
                this.picaFeaturesDefault, // use the default pica options here, since this is only to downsize the preview image, not the final render
                this.picaOptionsDefault,
                function(canvas){
                    finishedLoading("downSizeToScreen");
                    callback(canvas.toDataURL(that.dataUrlMime,that.dataUrlQuality));
                }
            );
        } else {
            callback(img.src);
        }

    };

    // extract the cropped area from cropperjs, resize it to target size (if set) and pass it as dataurl to the callback function
    this.extractCroppedArea = function(callback){
        var canvas = this.cropper.getCroppedCanvas({
        });

        if(this.targetHeight && this.targetWidth) {

            var offScreenCanvas = document.createElement('canvas')
            offScreenCanvas.width  = this.targetWidth;
            offScreenCanvas.height = this.targetHeight;
            var that = this;
            picaResize(
                canvas,
                offScreenCanvas,
                this.picaFeatures,
                this.picaOptions,
                function(){
                    callback(offScreenCanvas.toDataURL(that.dataUrlMime,that.dataUrlQuality));
                }
            );

        } else {
            callback(canvas.toDataURL(this.dataUrlMime,this.dataUrlQuality));
        }
    };

    // update target size
    this.updateTargetSize = function(w,h){
        this.targetWidth = w;
        this.targetHeight = h;

        // flag the preview element to be reloaded
        this.cropperChanged = true;
        this.allowSave();

        $("#resizeWidth").val(Math.round(w) || "");
        $("#resizeHeight").val(Math.round(h) || "");

        $(this.cropContainer).find(".applyCrop").addClass("active");

        Cropping.updateTargetSizeText(this);
    };

    // reset (clear) target size
    this.resetTargetSize = function(){
        this.updateTargetSize(false,false);
        document.getElementById("sizeSelect").selectedIndex = 0;
        this.selectedSizeIndex = 0;
    };

    // return ratio of the cropped area (targetratio, if set)
    this.getCroppedRatio = function(){
        var ratio = this.cropper.options.aspectRatio;
        if(!ratio) {
            var cropperData = this.cropper.getData();
            ratio = cropperData.width / cropperData.height;
        }
        return ratio;
    };

    /************************************************
     *  Exporting / Saving
     */


    // exporting finalised image(Cropped & filtered)
    //
    // parameters passed to callback function: callback(src)
    // src: final image as base64 dataURL
    // (needs to be base64-dataURL for writing on the server as a new file)
    this.exportFinal = function(callback){
        // if there were changes made, apply the changes and filters ect. on the original source image, and resize, if specified
        startLoading("exportFinal", "Bild exportieren…"); //Yii.t
        var that = this;

        this.updateCropping(function(){
            function drawPaints(){
                // draw custom painted lines & elements
                if(that.paintCanvas.initialized && that.paintCanvas.drawnElements.length > 0){
                    var canvas = document.createElement("canvas");
                    canvas.width = that.croppedImage.width;
                    canvas.height = that.croppedImage.height;
                    picaResize(
                        that.paintCanvas.canvas,
                        canvas,
                        that.picaFeaturesDefault, // use the default pica options here, since this is only to downsize the preview image, not the final render
                        that.picaOptionsDefault,
                        function(canvas){
                            finalCtx.drawImage(canvas,0,0);
                            painted = true;
                            drawOverlay();
                        }
                    );
                } else {
                    drawOverlay();
                }
            }

            function drawOverlay(){
                if(that.paintCanvas.fabric.getObjects().length > 0 || that.paintCanvas.fabric.overlayImage){
                    var canvas = document.createElement("canvas");
                    canvas.width = that.croppedImage.width;
                    canvas.height = that.croppedImage.height;

                    that.paintCanvas.stopFabric(function(img){
                        picaResize(
                            img,
                            canvas,
                            that.picaFeaturesDefault, // use the default pica options here, since this is only to downsize the preview image, not the final render
                            that.picaOptionsDefault,
                            function(canvas){
                                finalCtx.drawImage(canvas,0,0);
                                stickered = true;
                                finishExport();
                            }
                        );
                    });
                } else {
                    finishExport();
                }
            }

            function finishExport(){
                if(painted || stickered){
                    callbackFunction(finalCanvas.toDataURL(that.dataUrlMime,that.dataUrlQuality));
                } else {
                    callbackFunction(final);    // already finished without painting
                }
            }
            if(that.webGL){
                var glcanvas = new WebGLCanvas();
                glcanvas.init(that.croppedImage);
                glcanvas.filters = that.webGLCanvas.filters;
                glcanvas.values = that.webGLCanvas.values;

                // final image with filters & effects applied & resized
                var final = glcanvas.toDataURL(that.dataUrlMime,that.dataUrlQuality);

                // prepare a canvas to draw final modifications (drawn elements, stickers & border) on

                var painted = false;
                var stickered = false;

                var finalCanvas = document.createElement("canvas");
                finalCanvas.width = that.croppedImage.width;
                finalCanvas.height = that.croppedImage.height;
                var finalCtx = finalCanvas.getContext("2d");


                if(that.paintCanvas.initialized){
                    that.paintCanvas.updateBorder(false,function(){
                        if( that.paintCanvas.drawnElements.length > 0 || that.paintCanvas.fabric.getObjects().length > 0 || that.paintCanvas.fabric.overlayImage){
                            var finalImg = document.createElement("img");
                            finalImg.src = final;
                            $(finalImg).on("load", function(){
                                finalCtx.drawImage(this,0,0);
                                drawPaints();
                            });
                        } else {
                            finishExport();
                        }
                    });
                } else {
                    finishExport();
                }

            }
        }, true);   // true flag to only extract cropping, dont update the preview

        // callback wrapper
        function callbackFunction(src){
            finishedLoading("exportFinal");
            if(typeof(callback) === "function") callback(src);
        }
    };

    // save Image (upload to server & update file-object on db)
    // if name is set (and if filetype is image), save as a new copy of that image instead
    this.saveImage = function(callback){
        var that = this;
        startLoading("saveImage", "Bild speichern…"); //Yii.t

        var id = $(this.item).data("id");
        var type = $(this.item).data("type");


        if(this.unsavedChanges) {   // changes to image done
            if(type === "image" || type === "video" || type === 'pdf'){
                this.exportFinal(function(src){
                    if(type === "image"){
                        if(that.newFileName){   // name set = save as new file ->
                            $.post(window.MediaManager.controllerUrl + 'saveasnewimage',{
                                id: id,         // file id
                                data: src,          // base64 of the image to save
                                name: that.newFileName       // new name for the file to be saved as
                            },callbackWrapper);
                        } else {    // name not set = overwrite existing file ->
                            $.post(window.MediaManager.controllerUrl+'saveimage',{
                                id: id,
                                data: src,
                            },callbackWrapper);
                        }
                    } else {    //(type === "video" || type === 'pdf')   ->  save image as thumbnail for the file
                        $.post(window.MediaManager.controllerUrl + 'savethumbfile',{
                            id: id,         //original file id
                            data: src           //base64 of the thumbnail to save
                        },callbackWrapper);
                    }
                    that.updatedImgSrc = src;
                });
            } else {    // on other types but image or video, image-editing is not possible -> do nothing
                callbackWrapper(null);
            }
        } else {    // no changes to image
            if(that.newFileName){   // name set = save as new file -> make exact copy of file
                $.post(window.MediaManager.controllerUrl + 'copyfile',{
                    id: id,         // file id
                    name: that.newFileName       // new name for the file to be saved as
                },callbackWrapper);
            } else {    // no changes, not saving as new file -> do nothing
                callbackWrapper(null);
            }
        }

        // wrapper function for the callback
        function callbackWrapper(data){
            var result = true;
            // display errors/alerts from saving
            var d = $(data);
            $("#saveAlert").empty();
            if (d.hasClass("attachment") || d.hasClass("alert")) {
                $("#saveAlert").css("display","inline-block").append(data);
                result = false;
            }

            if(typeof(callback) === "function") callback(result);
            finishedLoading("saveImage");

            that.disableSave();
        }
    };

    // save metadata (update file-object on db)
    this.saveMetaData = function(callback){
        startLoading("saveMetaData", "Metadaten speichern…"); //Yii.t
        var that = this;
        if(this.metaDataChanged){
            $.post(
                window.MediaManager.controllerUrl + 'updatemetadata',
                {
                    id: $(this.item).data("id"),
                    description: this.metaData["description"],
                    title: this.metaData["title"],
                    subtitle: this.metaData["subtitle"],
                    link: this.metaData["link"],
                    linktext: this.metaData["linktext"],
                    lorem: this.metaData["lorem"],
                    developer: this.metaData["developer"],
                    tags: JSON.stringify(this.metaData["tags"])
                },
                function(data) {
                    finishedLoading("saveMetaData");
                    that.metaDataChanged = false;
                    callback();

                }
            );
        } else {
            finishedLoading("saveMetaData");
            callback(null);
        }
    };



    this.allowSave = function(){
        this.unsavedChanges = true;
        this.saveButton.attr("disabled", false);
    };

    this.disableSave = function(){
        this.unsavedChanges = false;
        this.saveButton.attr("disabled", true);
    };


    /************************************************
     *  Meta data
     */
    this.updateTags = function(tags){
        if(tags.sort().toString() !== this.metaData["tags"].sort().toString()){
            this.metaDataChanged = true;
            this.metaData["tags"] = tags;
        }
    };

    this.updateMetaData = function(key,value){
        this.metaDataChanged = true;
        this.metaData[key] = value;
    };

    this.readMetaData = function(callback) {
        var that = this;
        if(!this.metaDataLoaded){
            $.post(window.MediaManager.controllerUrl+'getfileinfo',{id: $(that.item).data("id")},function(data) {
                that.metaDataLoaded = true;
                let metaData = JSON.parse(data);

                that.metaData["description"] = metaData["description"];
                that.metaData["link"] = metaData["link"];
                that.metaData["linktext"] = metaData["linktitle"];
                that.metaData["title"] = metaData["label"];
                that.metaData["subtitle"] = metaData["sublabel"];
                if (that.metaData["lorem"] == "") {
                    that.metaData["lorem"] = metaData["lorem"] == 1 ? true : false;
                }
                if (that.metaData["developer"] == "") {
                    that.metaData["developer"] = metaData["developer"] == 1 ? true : false;
                }

                that.metaData["mime"] = metaData["mime"];
                that.metaData["dimensions"] = metaData["width"] + "x" + metaData['height'] + " Px";
                that.metaData["size"] = metaData["size"] + " Byte";
                that.metaData["full_url"] = metaData["path"];
                that.metaData["created_at"] = metaData["created_at"];
                that.metaData["updated_at"] = metaData["updated_at"];
                that.metaData["thumb"] = metaData["thumb"];

                that.metaData["tags"] = metaData["selectedTags"];

                callback(metaData);
            });
        } else {
            callback(this.metaData);
        }
    };

    this.getMetaData = function(key) {
        return this.metaData[key];
    };


    /************************************************
     *  initialising preset Filters & thumbnails
     */

    // change/reload the thumbnail source image for the filters
    // (all filtered thumbnails should also be redone after this)
    // this should only be called at first initialisation,
    // or when the original source image for a media changes (f.e. in video thumbnails)
    this.updateFilterThumbnailSource = function(img,callback){
        startLoading("updateFilterThumbnailSource", "Filtervorschau erstellen…"); //Yii.t
        var that = this;
        generateThumbnail(
            img,
            this.filterThumbnails["width"],
            this.filterThumbnails["height"],
            true,
            this.dataUrlMime,
            this.dataUrlQuality,
            this.picaFeaturesDefault,
            this.picaOptionsDefault,
            function(dataURL){
                that.filterThumbnails["source"] = dataURL;
                if(that.webGL){
                    that.filterThumbnails["sourceImage"].crossOrigin = "use-credentials";
                    that.filterThumbnails["sourceImage"].src = dataURL;
                    $(that.filterThumbnails["sourceImage"]).one("load",function(){

                        that.filterThumbnails["sourceGLCanvas"] = new WebGLCanvas();
                        that.filterThumbnails["sourceGLCanvas"].init(that.filterThumbnails["sourceImage"]);

                        that.filterThumbnails["sourceLoaded"] = true;
                        finishedLoading("updateFilterThumbnailSource");
                        that.generateFilterThumbnails(function(){
                            if(typeof(callback) === "function") callback();
                        });
                    });
                }
            }
        );
    };

    // generate thumbnails for all custom + preset filters
    this.generateFilterThumbnails = function(callback){
        var that = this;
        startLoading("generateFilterThumbnails", "Filtervorschau erstellen…");    //Yii.t

        // count total number of filters to generate thumbnails for (presets + customs)

        that.filterThumbnails["loaded"] = 0;
        that.filterThumbnails["ready"] = false;

        that.filterThumbnails["count"] = Object.keys(_webGLPresetFilters).length + Object.keys(_customFilters).length;

        if(this.webGL){
            if(that.filterThumbnails["count"] === 0){
                finishFilterThumbnails();
            } else {
                for(var name in _customFilters){
                    that.generateFilterThumbnailWebGL("custom",_customFilters[name]["options"],name).then(()=>{
                        that.filterThumbnails["loaded"]++;
                        finishFilterThumbnails();
                    });
                }
                for(var name in _webGLPresetFilters){
                    that.generateFilterThumbnailWebGL("preset",_webGLPresetFilters[name]["options"],name).then(()=>{
                        that.filterThumbnails["loaded"]++;
                        finishFilterThumbnails();
                    });
                }
            }

        }

        // check if all thumbnails are finished, then call callback function and flag the thumbnails as ready
        function finishFilterThumbnails(){
            if(that.filterThumbnails["loaded"] >= that.filterThumbnails["count"]){
                that.filterThumbnails["ready"] = true;
                that.filterThumbnails["sourceGLCanvas"] = false;    // manual garbage collection to free up the webgl context
                finishedLoading("generateFilterThumbnails");
                if(that.isCurrentMedia){
                    displayFilterPreviewsFor(that);
                }
                if(typeof(callback) === "function") callback();
            }
        }
    };

    /**
     * generate a thumbnail for specified preset Filter & options
     * works for either presetFilters, or custom-saved Filters, depending which parameters are passed
     */
    this.generateFilterThumbnailWebGL = function(type, values, name){
        return new Promise((resolve,reject)=>{
            if(this.filterThumbnails["sourceLoaded"]){
                if(!this.filterThumbnails["sourceGLCanvas"]){
                    this.filterThumbnails["sourceGLCanvas"] = new WebGLCanvas();
                    this.filterThumbnails["sourceGLCanvas"].init(this.filterThumbnails["sourceImage"]);
                }

                this.filterThumbnails["sourceGLCanvas"].values = values;
                this.filterThumbnails["sourceGLCanvas"].updateFilters();
                this.filterThumbnails["sourceGLCanvas"].draw();

                this.filterThumbnails[type][name] = new Image();
                this.filterThumbnails[type][name].src = this.filterThumbnails["sourceGLCanvas"].toDataURL(this.dataUrlMime,this.dataUrlQuality);
            }
            resolve();
        });
    };



    /************************************************
     *  Filter settings
     */

    // add a custom filter value
    this.addFilterOption = function(filter,value){
        this.filtersChanged = true;
        this.allowSave();
        // add the current selected filter option to the media object
        this.filterOptions[filter] = value;
        // redraw the canvas with all filters applied
        this.updateFilters();
    };

    // remove a custom filter option
    this.removeFilterOption = function(filter){
        this.filtersChanged = true;
        this.allowSave();
        delete this.filterOptions[filter];
        this.updateFilters();
    };

    // apply a preset Filter to this media (overrides custom filter options if set)
    this.applyFilter = function(filter,options){
        this.filtersChanged = true;
        this.allowSave();
        this.presetFilter = filter;
        this.filterOptions = options || {};
        this.updateFilters();
    };

    // apply webgl filters
    this.applyGLFilter = function(values){
        this.filtersChanged = true;
        this.allowSave();

        if(values) this.webGLCanvas.values = values;
        this.webGLCanvas.updateFilters();

        updateFilterFormFields(this);
        this.updateFilters();
    };


    // reset filters
    this.resetFilters = function(){
        this.filterOptions = {};
        this.presetFilter = false;

        this.webGLCanvas.filters = [];
        this.webGLCanvas.setDefaultValues();

        this.allowSave();
        this.filtersChanged = true;
        this.updateFilters();
    };


    /************************************************
     *  Pica (resize) settings
     */

    //update Pica options
    this.updatePicaOptions = function(features,options){
        this.picaFeatures = features;
        this.picaOptions = options;

        this.allowSave();
        this.cropperChanged = true; // force to re-extract the cropped area, since the resize happens there
        this.updateCropping(function(){
            //
        });
    };

    //reset Pica options
    this.resetPicaOptions = function(){
        this.updatePicaOptions(this.picaFeaturesDefault,JSON.parse(JSON.stringify(this.picaOptionsDefault)))
    };



    //manual garbage collection (browsers s**k...)
    this.garbageCollect = function(){
        this.filterThumbnails["sourceGLCanvas"] = false;
        this.webGLCanvas = false;
    };
}