var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "preview-module__screens-container" }, [
    _c("div", { staticClass: "screen preview-module__screen-left" }, [
      _c("div", { staticClass: "screen-inner" }, [_vm._t("left_screen")], 2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "screen preview-module__screen-center" }, [
      _c("div", { staticClass: "screen-inner" }, [_vm._t("middle_screen")], 2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "screen preview-module__screen-right" }, [
      _c("div", { staticClass: "screen-inner" }, [_vm._t("right_screen")], 2),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "hue-light",
      style:
        _vm.lightIsOn && _vm.bgGlowColor
          ? "background: " + _vm.bgGlowColor
          : "",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }