var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.accessor(_vm.item) === 0
    ? _c("i", {
        staticClass: "far fa-star",
        on: { click: _vm.toggleIsFavorite },
      })
    : _vm.field.accessor(_vm.item) === 1
    ? _c("i", {
        staticClass: "fas fa-star",
        on: { click: _vm.toggleIsFavorite },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }