/**
 * This does replace functions in yii.js "confirm"
 * @see C:\laragon\www\fire\vendor\yiisoft\yii2\assets\yii.js
 *
 * The default implementation simply displays a js confirmation dialog.
 * You may override this by setting `yii.confirm`.
 */
import bootbox from "bootbox";

/**
 * Displays a confirmation dialog.
 * @param message the confirmation message.
 * @param ok a callback to be called when the user confirms the message
 * @param cancel a callback to be called when the user cancels the confirmation
 * @returns {boolean}
 */
window.yii.confirm = function (message, ok, cancel) {
    let okLabel = this.dataset.ok;
    let cancelLabel = this.dataset.cancel;
    let secondconfirm = this.dataset.secondconfirm || '';
    let confirmBtnClass = 'btn-success';
    let title = this.dataset.title || '';
    let modalType = this.dataset.type || '';
    let cancelBtnClass = 'btn-outline-secondary';

    let pageLoaderMessage = this.dataset.pageLoaderText || false;
    if (pageLoaderMessage) {
        $.fn.pageLoader.defaults.content = pageLoaderMessage;
    }

    // add second confirmation checkbox
    if (secondconfirm !== '') {
        secondconfirm = '<div class="yiiconfirm_secondconfirm custom-control custom-checkbox d-flex">' +
            '<input type="checkbox" id="secondconfirm" class="_secondconfirm-checkbox custom-control-input"><label for="secondconfirm" class="custom-control-label">' + secondconfirm + '</label>' +
            '</div>';
    }

    let modalClassName = '';
    if (modalType === 'alert') {
        /* Add custom div containers for better styling alert/delete modal with icon */
        modalClassName = 'modal--' + modalType;
        message = '<div class="modal__delete-icon">' +
            '<i class="fontello-icon-attention"></i>' +
            '</div>' +
            '<div class="modal__delete-message">' +
            '<div class="message-headline">' + message + '</div>';
        if (secondconfirm) {
            message += '<div class="message-confirm">' + secondconfirm + '</div>'
        }
        message += '</div>'

        /* Change button color to danger */
        confirmBtnClass = 'btn-danger';
    } else {
        message += secondconfirm;
    }
    // disable delete btn on init if there is a second confirm
    if (secondconfirm !== '') {
        confirmBtnClass += ' disabled';
    }

    // Get either .item from nested tree, or tr from grid view
    let item = this.closest(".item") || this.closest("tr");

    if (item) {
        item.classList.add("active-confirm");
    }

    let dialog = bootbox.confirm(
        {
            centerVertical: true,
            title: title,
            className: modalClassName,
            message: message,
            buttons: {
                confirm: {
                    label: okLabel,
                    className: confirmBtnClass,
                },
                cancel: {
                    label: cancelLabel,
                    className: cancelBtnClass
                }
            },
            callback: function (confirmed) {
                if (item) {
                    item.classList.remove("active-confirm");
                }
                if (confirmed) {
                    !ok || ok();
                } else {
                    !cancel || cancel();
                }
            }
        }
    );

    if (secondconfirm !== '') {
        // change listener to toggle confirm-button depending on second confirmation
        dialog.on("shown.bs.modal", function () {
            let dialog = this;
            dialog.querySelector('.yiiconfirm_secondconfirm ._secondconfirm-checkbox').addEventListener('change', function () {
                dialog.querySelector('.modal-footer .btn-success, .modal-footer .btn-danger').classList.toggle('disabled', !this.checked);
            });
        })
    }
    // confirm will always return false on the first call
    // to cancel click handler
    return false;
};