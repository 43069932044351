import bootbox from "bootbox";

(function () {

    init();

    $(document).ready(function () {
        init();
    });

    $(document).on('pjax:success', function () {
        init();
    });

    function init() {
        $(".bootbox-open-modal").off("click").on("click", function () {
            let optionKey = $(this).data("bootbox-options");

            if (window.bootboxOptions && window.bootboxOptions[optionKey]) {
                let options = window.bootboxOptions[optionKey];
                let modal = bootbox.dialog(options);

                if (options.ajaxUrl || options.ajaxUrl === "") {
                    const selector = ".modal-content";
                    let href;
                    if (options.ajaxUrl === "") {
                        href = $(this).attr("href");
                    } else {
                        href = options.ajaxUrl || $(this).attr("href");
                    }

                    let loading = modal.find(selector).html();
                    options.onHidden = function (/*e*/) {
                        $(selector).html(loading);
                    };

                    modal.find(selector).load(href, options.ajaxData,
                        function (responseText, textStatus, XMLHttpRequest) {
                            if (textStatus === 'error') {
                                try {
                                    let res = JSON.parse(responseText);
                                    modal.find(selector).html('<div class="modal-header">' +
                                        '<h5 class="modal-title">' + res.status + '</h5>' +
                                        '<button type="button" class="bootbox-close-button close" aria-hidden="">×</button>' +
                                        '</div>' +
                                        '<div class="modal-body">' + res.message + '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button type="button" class="btn btn-secondary bootbox-close-button">×</button>' +
                                        '</div>'
                                    );
                                } catch (e) {
                                    modal.find(selector).html('<div class="modal-header">' +
                                        '<button type="button" class="bootbox-close-button close" aria-hidden="">×</button>' +
                                        '</div>' +
                                        '<div class="modal-body">' + responseText + '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button type="button" class="btn btn-secondary bootbox-close-button">×</button>' +
                                        '</div>'
                                    );
                                }
                            } else {
                                window.fireInheritance.bind(modal);
                                window.fireInheritance.initTooltip();
                                modal.find('.select2').select2();
                            }
                        }
                    );


                } else if (options.target) {
                    modal.find(".modal-content").html($(options.target).html());
                } else {
                    if (console) {
                        console.error("BSModal: Neither target nor ajax is given.");
                    }
                }
            } else {
                if (console) {
                    console.error("BSModal: Could not find bootbox options for key '" + optionKey + "'.");
                }
            }
            return false;
        });
    }
})();

