<template>
    <div
        :id="data.id"
        class="drop-element"
        :class="[element.type === 'formsection' ? 'formsection' : '']"
        @mouseenter.stop="mouseover"
        @mouseleave.stop="mouseleave">
        <component
            :is="view"
            v-if="element.type === 'formitem'"
            :item="element" />
        <Section
            v-else
            :item="element"
            :put-section-area="putSectionArea" />

        <drop-element-tools
            v-if="hover"
            @edit="edit"
            @duplicate="duplicate"
            @remove="remove" />
    </div>
</template>

<script>
import eventBus from "../util/EventBus";
import DropElementTools from "./DropElementTools.vue";

import capitalize from "../util/capitalize";

import Button from "./elements/Button.vue";
import Checkbox from "./elements/Checkbox.vue";
import Country from "./elements/Country.vue";
import File from "./elements/File.vue";
import Html from "./elements/Html.vue";
import Input from "./elements/Input.vue";
import Instruction from "./elements/Instruction.vue";
import Scale from "./elements/Scale.vue";
import Numericscale from "./elements/Numericscale.vue";
import Radio from "./elements/Radio.vue";
import Section from "./elements/Section.vue";
import Select from "./elements/Select.vue";
import Terms from "./elements/Terms.vue";
import Paymentmethod from "./elements/Paymentmethod.vue";
import Shippingmethod from "./elements/Shippingmethod.vue";
import Address from "./elements/Address.vue";

export default {
    name: 'DropElement',
    components: {
        DropElementTools,
        Button,
        Checkbox,
        Country,
        File,
        Html,
        Input,
        Instruction,
        Scale,
        Numericscale,
        Radio,
        Section,
        Select,
        Terms,
        Paymentmethod,
        Shippingmethod,
        Address
    },
    props: {
        element: {
            type: Object
        },
        nested: {
            type: Boolean,
            default: false
        },
        parentId: {
            type: String,
            default: "no-parent"
        },
        putSectionArea: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            hover: false
        };
    },
    computed: {
        view() {
            return capitalize(this.element.items[0].data.view);
        },
        data() {
            if (this.element.type === 'formitem') {
                return this.element.items[0].data;
            }

            if (this.element.type === 'formsection') {
                return this.element.data;
            }

            return null;
        }
    },
    mounted() {
        if (!this.nested) {
            eventBus.$on('parentHoverIn', (parentId) => {
                if (parentId === this.data.id) {
                    this.hover = true;
                }
            });
            eventBus.$on('parentHoverOut', (parentId) => {
                if (parentId === this.data.id) {
                    this.hover = false;
                }
            });
        }
    },
    methods: {
        edit() {
            eventBus.$emit('editElement', this.element);
        },
        duplicate() {
            eventBus.$emit('duplicateElement', this.element);
        },
        remove() {
            eventBus.$emit('removeElement', this.element);
        },
        mouseover(event) {
            this.hover = true;

            if (this.nested) {
                eventBus.$emit('parentHoverOut', this.parentId);
            }
        },
        mouseleave() {
            this.hover = false;

            if (this.nested) {
                eventBus.$emit('parentHoverIn', this.parentId);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.drop-element {
    position:relative;
    // min-height: 200px;

    &.temporary {
        border: 1px dashed #747474;
    }
}
</style>