var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-item", class: { draggable: !_vm.readonly } },
    [
      _c(
        "header",
        {
          staticClass: "collapsed d-flex align-items-center",
          attrs: {
            "data-target": `#item-${_vm.identifier}-${_vm.index}`,
            "data-toggle": "collapse",
          },
        },
        [
          _c("span", { staticClass: "handle" }),
          _vm._v(" "),
          _c("div", { staticClass: "text-truncate mr-5" }, [
            _vm._v(
              _vm._s(_vm.elementTitle) +
                " " +
                _vm._s(_vm.index + 1) +
                ": " +
                _vm._s(_vm.tabTitle())
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "collapse",
          attrs: {
            id: `item-${_vm.identifier}-${_vm.index}`,
            "data-toggle": "collapse",
          },
        },
        [
          _vm.groups.length > 0
            ? _c(
                "div",
                _vm._l(_vm.groups, function (group, index) {
                  return _c("div", { key: index, staticClass: "field-group" }, [
                    _c("label", [_vm._v(_vm._s(group.label))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "wrapper" },
                      _vm._l(
                        _vm.getFieldsByGroup(group),
                        function (field, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "form-group label-inside",
                            },
                            [
                              _c("accordion-field", {
                                attrs: {
                                  field: field,
                                  index: index,
                                  item: _vm.item,
                                  readonly: _vm.readonly,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.valueUpdate(field.key)
                                  },
                                },
                                model: {
                                  value: _vm.fieldModel[field.key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fieldModel, field.key, $$v)
                                  },
                                  expression: "fieldModel[field.key]",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ])
                }),
                0
              )
            : _c(
                "div",
                _vm._l(_vm.fields, function (field, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "form-group label-inside" },
                    [
                      _c("accordion-field", {
                        attrs: {
                          field: field,
                          identifier: _vm.identifier,
                          index: index,
                          item: _vm.item,
                          readonly: _vm.readonly,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.valueUpdate(field.key)
                          },
                        },
                        model: {
                          value: _vm.fieldModel[field.key],
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldModel, field.key, $$v)
                          },
                          expression: "fieldModel[field.key]",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
          _vm._v(" "),
          _vm._l(_vm.mediaForms, function (mediaForm) {
            return _c("media-form", {
              key: `${_vm.identifier}-${mediaForm.key}`,
              attrs: {
                "disable-editable-fields": _vm.disableEditableFields,
                identifier: _vm.identifier,
                "item-index": _vm.index,
                "media-form": mediaForm,
                medias: _vm.medias,
                offset: _vm.offsets[mediaForm.key],
                readonly: _vm.readonly,
                widget: _vm.mediaFormConfig(mediaForm),
              },
              on: {
                "media-add": _vm.mediaAdd,
                "media-update": _vm.mediaUpdate,
                "media-remove": _vm.mediaRemove,
                "media-sort": _vm.mediaSort,
              },
            })
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-danger w-100",
              attrs: {
                disabled: _vm.disableRemove,
                title: _vm.removeTitle(),
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("remove-item", _vm.id)
                },
              },
            },
            [_vm._v("Löschen")]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }