import Vue from 'vue';
import FormEditor from './formeditor/FormEditor.vue';
import DropElement from "./formeditor/content/DropElement.vue";

(function() {
    document.addEventListener('DOMContentLoaded', () => {
        if(document.querySelector('#vue-form-editor')) {
            Vue.component("drop-element", DropElement);

            Vue.prototype.$translations = JSON.parse(document.querySelector('#vue-form-editor').getAttribute('data-translations'));

            new Vue({
                el: '#vue-form-editor',
                components: {
                    FormEditor
                },
                props: {
                    formStructure: {
                        type: Object
                    },
                    locale: {
                        type: String
                    },
                    formId: {
                        type: String
                    },
                    formName: {
                        type: String
                    }
                }
            });
        }
    });
})();