var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ScreensContainer, {
    attrs: { "light-is-on": _vm.lightIsOn, "bg-glow-color": _vm.bgGlowColor },
    scopedSlots: _vm._u([
      {
        key: "left_screen",
        fn: function () {
          return [
            _vm.content.leftView === "view_left_image"
              ? _c(_setup.ImageViewer, {
                  attrs: {
                    src: _setup.getAssetPathOfTargetAttr(
                      "left_image",
                      _setup.props.content
                    ),
                    "has-kenburns-effect":
                      _setup.props.content.showroomEffect === "kenburns",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.props.content.leftView === "view_left_image"
              ? _c(_setup.DetailBoxBlurred, {
                  attrs: {
                    caption: _setup.props.content.leftCaption,
                    title: _vm.content.leftTitle,
                    subtitle: _setup.props.content.leftSubtitle,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.props.content.leftView === "view_left_video"
              ? _c(_setup.VideoPlayer, {
                  attrs: {
                    content: _setup.props.content,
                    "target-attr": "left_video",
                    muted:
                      _setup.props.content.leftMuted === 1 || _setup.muteState,
                    loop: _setup.props.content.leftLoop === 1,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "middle_screen",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "intro-middle-screen-wrapper" },
              [
                _setup.props.content.middleView === "view_middle_image"
                  ? _c(_setup.ImageViewer, {
                      attrs: {
                        src: _setup.getAssetPathOfTargetAttr(
                          "middle_image",
                          _vm.content
                        ),
                        "has-kenburns-effect":
                          _setup.props.content.showroomEffect === "kenburns",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _setup.props.content.middleView === "view_middle_video"
                  ? _c(_setup.VideoPlayer, {
                      attrs: {
                        content: _setup.props.content,
                        "target-attr": "middle_video",
                        muted:
                          _setup.props.content.middleMuted === 1 ||
                          _setup.muteState,
                        loop: _setup.props.content.middleLoop === 1,
                        "fill-screen": true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "right_screen",
        fn: function () {
          return [
            _setup.props.content.rightView === "view_right_image"
              ? _c(_setup.ImageViewer, {
                  attrs: {
                    src: _setup.getAssetPathOfTargetAttr(
                      "right_image",
                      _vm.content
                    ),
                    "has-kenburns-effect":
                      _setup.props.content.showroomEffect === "kenburns",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.props.content.rightView === "view_right_image"
              ? _c(_setup.DetailBoxBlurred, {
                  attrs: {
                    caption: _setup.props.content.rightCaption,
                    title: _setup.props.content.rightTitle,
                    subtitle: _setup.props.content.rightSubtitle,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.props.content.rightView === "view_right_video"
              ? _c(_setup.VideoPlayer, {
                  attrs: {
                    content: _setup.props.content,
                    "target-attr": "right_video",
                    muted:
                      _setup.props.content.rightMuted === 1 || _setup.muteState,
                    loop: _setup.props.content.leftLoop === 1,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }