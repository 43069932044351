<template>
    <div class="segment-rule">
        <i class="segment-rule-handler fa fa-fw fa-grip-vertical handle"></i>
        <v-select v-model="attribute" :filter="search" :options="attributeOptions()" :reduce="rule => rule.key" class="segment-rule-attribute" :clearable="false">
            <template v-slot:option="option">
                <span >{{option.label}}</span>
                <span v-if="option.code" class="small text-muted ml-1">{{option.code}}</span>
            </template>
            <template v-slot:selected-option="option">
                <span >{{option.label}}</span>
                <span v-if="option.code" class="small text-muted ml-1">{{option.code}}</span>
            </template>
        </v-select>
        <component
            v-if="rules[attribute]"
            :is="rules[attribute].component + '-segment-rule'"
            :labels="labels"
            :rules="rules"
            :attribute="attribute"
            v-model="dataValue"
            class="segment-rule-component"
        ></component>
        <button type="button" @click="remove" class="segment-rule-remove"><i class="fa fa-times" ></i></button>
    </div>
</template>

<script>
import DateSegmentRule from "./DateSegmentRule.vue"
import ExistSegmentRule from "./ExistSegmentRule.vue"
import NumberSegmentRule from "./NumberSegmentRule.vue"
import SegmentRuleBase from "./SegmentRuleBase.vue"
import TextSegmentRule from "./TextSegmentRule.vue"
import TypeSegmentRule from "./TypeSegmentRule.vue"

export default {
    extends: SegmentRuleBase,
    components: {
        DateSegmentRule,
        ExistSegmentRule,
        NumberSegmentRule,
        TextSegmentRule,
        TypeSegmentRule
    },
    props: {
        /** The rules to filter for, along with there type */
        rules: {
            required: true,
            type: Object
        },
        /** The translated labels */
        labels: {
            required: true,
            type: Object
        },
        /** The value */
        value: {
            required: true,
            type: Object
        },
        index: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            attributeOptions() {
               return Object.entries(this.rules).map((value, index) => {
                   return {key: value[0], label: value[1].label, code: value[1].code}
                });
            },
            dataValue: this.value
        }
    },
    computed: {
        attribute: {
            get() {
                return this.value.attribute
            },
            set(value) {
                this.value.attribute = value
                this.$emit("input", this.value)
            }
        }
    },
    methods: {
        remove() {
            this.$emit("removeAt", this.index)
        },
        search(options, search) {

            return options.filter(option =>
                (option.label.toLowerCase().includes(search.toLowerCase())
                    ||  (option.code && option.code.toLowerCase().includes(search.toLowerCase()))
                )
            )
        },
    }
};
</script>
<style lang="scss">
$handler: 32px;
$remover: 32px;
$subSpacing: 20px;

.segment-rule-attribute {
    width: calc((100% - (#{$handler} + #{$remover}))/3);
    display:inline-block;
}

button.segment-rule-remove {
    position:absolute;
    right:0;
    top:0;
    appearance: none;
    width: $remover;
    height:$remover;
    border:none;
    background:none;
    opacity:0.4;
    color: #000;
    transition: opacity 250ms ease-in-out;
    &:hover {
        opacity:1;
    }
}
.segment-rule-component {
    display:inline-block;
    width: calc(((100% - (#{$handler} + #{$remover}))/3) * 2);

    .segment-rule-operator,
    .segment-rule-content,
    .segment-rule-content2 {
        width: calc((100% - #{$subSpacing}) / 3);
        display:inline-block;
    }
    .segment-rule-content-large {
        width: calc(2 * ((100% - #{$subSpacing}) / 3));
    }
    input {
        &.segment-rule-content,
        &.segment-rule-content2 {
            padding: 4px 9px 5px 9px;
            border: 1px solid #c6cdc8;
            border-radius: 0.2rem;
            outline: none;

            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            &:focus {
                box-shadow: 0 0 3px rgba(34, 164, 174, 15%);
                border-color: #22a4ae;
            }
        }
    }

    .segment-rule-content,
    .segment-rule-content2 {
        input.vdatetime-input {
            padding: 4px 9px 5px 9px;
            border: 1px solid #c6cdc8;
            border-radius: 0.2rem;
            outline: none;
            width: 100%;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            &:focus {
                box-shadow: 0 0 3px rgba(34, 164, 174, 15%);
                border-color: #22a4ae;
            }
        }
    }
}

</style>