<template>
    <div class="preview-module"
         v-if="!isLoading">
        <!-- >>>>>>>>>>>>>> TOP SECTION <<<<<<<<<<<<<<<-->
        <div class="preview-module__section-top">
            <div>
                <h1 class="title">{{ content.title || content.name }}</h1>
            </div>
            <div class="controls">
                <VolumeToggleMute v-if="hasVideo" @toggle-sound-clicked="toggleMute" :muted="isVideoMuted"/>
                <GlowLightToggle v-if="hasHueLight" @clicked="toggleLight"/>
            </div>
        </div>

        <!-- >>>>>>>>>>>>>> PREVIEW TYPE & SCREENS <<<<<<<<<<<<<<< -->
        <Intro v-if="previewType === 'Intros'" :content="content" :muted="isVideoMuted" :light-is-on="lightIsOn" :bg-glow-color="bgGlowColor"/>
        <Look v-if="previewType === 'Looks'" :content="content" :active-tab="activeTab" :muted="isVideoMuted" :light-is-on="lightIsOn"
              :bg-glow-color="bgGlowColor"/>
        <Topic v-if="previewType === 'Topics'" :content="content" :active-tab="activeTab" :muted="isVideoMuted" :light-is-on="lightIsOn"
               :bg-glow-color="bgGlowColor"/>


        <!-- >>>>>>>>>>>>>> TABS <<<<<<<<<<<<<<< -->
        <!-- Tabs for Looks-->
        <Tabs v-if="previewType === 'Looks'"
              @tab-changed="updateTabs" :tabs="['Detail', 'Video']"
              :available-tabs-by-index="[true, hasVideo]"/>

        <!-- Tabs for Topics-->
        <Tabs v-if="previewType === 'Topics'"
              @tab-changed="updateTabs"
              :tabs="['Detail', 'Products', 'Video']"
              :available-tabs-by-index="[true, hasProducts, hasVideo]"/>
    </div>
</template>

<script setup>
import {defineProps, computed, ref, watch, onUnmounted, defineEmits, nextTick, onMounted} from 'vue'
import VolumeToggleMute from '../components/VolumeToggleMute.vue'
import GlowLightToggle from '../components/GlowLightToggle.vue'
import Intro from "./types/Intro.vue";
import Look from "./types/Look.vue";
import Topic from "./types/Topic.vue";
import {getAssetPathOfTargetAttr, checkIfTargetAttrIsAvailable} from '../js/helpers'
import Tabs from "../components/Tabs.vue";
import ImageViewer from "../components/ImageViewer.vue";
import ScreensContainer from "../components/ScreensContainer.vue";

const content = ref(null)
const middleScreenData = ref(null)
const rightScreenData = ref(null)
const isLoading = ref(true)
const lightIsOn = ref(true)
const activeTab = ref('Detail')
const videoMuted = ref(true)
const previewType = ref('')

const hasDetails = ref(false)
const hasVideo = ref(false)
const isVideoMuted = ref(true)
const hasProducts = ref(false)
const hasHueLight = ref(false)

const props = defineProps(['api', 'query', 'variables'])

const setAvailableContent = (content) => {

    hasVideo.value = checkIfTargetAttrIsAvailable('video', content) ||
                     checkIfTargetAttrIsAvailable('middle_video', content) && content.middleMuted === 0 && content.middleView === "view_middle_video" ||
                     checkIfTargetAttrIsAvailable('left_video', content) && content.leftMuted === 0 && content.leftView === "view_left_video" ||
                     checkIfTargetAttrIsAvailable('right_video', content) && content.rightMuted === 0 && content.rightView === "view_right_video" || false
    hasProducts.value = content.products ? content.products.length > 0 : false
    hasHueLight.value = content.light || content.hueLight ? (content.light || content.hueLight) : false
}

const lightAvailableAndActive = computed(() => {
    return lightIsOn.value && data.value.light
})

const bgGlowColor = computed(() => {
    return content.value.light || content.value.hueLight
})

const updateTabs = (tab) => {
    activeTab.value = tab
}

const loadData = async () => {
    const body = {
        query: props.query,
        variables: JSON.parse(props.variables)
    };

    const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': yii.getCsrfToken()
    };

    await fetch(props.api, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    })
        .then(res => res.json())
        .then((res) => {
            // ASSIGN DATA TO PREVIEW-TYPE: INTROS <<<<<<<<<<
            if (res.data.showroomIntro) {
                previewType.value = "Intros"
                content.value = res.data.showroomIntro
                setAvailableContent(content.value)
                isLoading.value = false
            }
            // ASSIGN DATA TO PREVIEW-TYPE: LOOKS <<<<<<<<<<
            if (res.data.look) {
                previewType.value = "Looks"
                content.value = res.data.look
                setAvailableContent(content.value)
                isLoading.value = false
            }
            // ASSIGN DATA TO PREVIEW-TYPE: TOPICS  <<<<<<<<<<
            if (res.data.themeworld) {
                previewType.value = "Topics"
                content.value = res.data.themeworld
                setAvailableContent(content.value)

                isLoading.value = false
            }
            // for testing, delete later
            console.log('content')
            console.log(content.value)
        })
        .catch(e => {
            console.log(e)
        })
}

const toggleLight = () => {
    lightIsOn.value = !lightIsOn.value
}

const toggleMute = () => {
    isVideoMuted.value = !isVideoMuted.value
}

const listenForReloadFromCms = () => {
    // NOTE: this method listens to the reload trigger defined in
    // "update.php" file in each preview module

    // Get the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve the value of the "id" parameter
    const idValue = urlParams.get('id');

    // Listen for changes to the localStorage
    window.addEventListener('storage', function (event) {

        if (event.key === idValue && event.newValue === 'true') {
            // Clear the flag
            localStorage.removeItem(idValue);

            // Reload the page
            location.reload();
        }
    });
}

onMounted(async () => {
    listenForReloadFromCms()
    await loadData()
})
</script>

<style scoped lang="scss">
@font-face {
    font-family: Inter-Light;
    src: url('../fonts/Inter-Light.woff');
}

@font-face {
    font-family: Inter-Medium;
    src: url('../fonts/Inter-Medium.woff2');
}

@font-face {
    font-family: Inter-Regular;
    src: url('../fonts/Inter-Regular.otf');
}

@font-face {
    font-family: Inter-Bold;
    src: url('../fonts/Inter-Bold.otf');
}

@font-face {
    font-family: Inter-Medium;
    src: url('../fonts/Inter-Medium.woff2');
}


.preview-module {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    box-shadow: none;
    padding: 0 2vw;
    display: flex;
    align-items: center;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

    &__section-top {
        display: flex;
        width: 100%;
        margin-bottom: 50px;
        width: 100%;
        justify-content: center;
        position: relative;
        z-index: 1;

        .title {
            font-size: 28px;
            color: #fff;
            font-weight: 600;
            font-family: Inter-Medium;
        }

        .controls {
            position: absolute;
            right: 0;
            display: flex;
            gap: 8px;
        }
    }
}

.preview-module-main-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 2vw;
}
</style>

<!--GLOBAL STYLES-->
<style lang="scss">
.preview-module-fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 100;
    font-family: Inter-Light;
    color: #000;
    background: #1E2022;
}
</style>