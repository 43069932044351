<template>
    <div :class="{draggable: !readonly}" class="slider-item">
        <header :data-target="`#item-${identifier}-${index}`" class="collapsed d-flex align-items-center" data-toggle="collapse">
            <span class="handle"></span>
            <div class="text-truncate mr-5">{{elementTitle}} {{ index + 1}}: {{ tabTitle() }}</div>
        </header>

        <section :id="`item-${identifier}-${index}`" class="collapse" data-toggle="collapse">

            <!-- Grouped fields -->
            <div v-if="groups.length > 0">
                <div v-for="(group, index) in groups" :key="index" class="field-group">
                    <label>{{ group.label}}</label>

                    <div class="wrapper">

                        <div v-for="(field, index) in getFieldsByGroup(group)" :key="index" class="form-group label-inside">
                            <accordion-field v-model="fieldModel[field.key]" :field="field" :index="index" :item="item" :readonly="readonly" @input="valueUpdate(field.key)" />
                        </div>

                    </div>
                </div>
            </div>
            <!-- Without group -->
            <div v-else>
                <div v-for="(field, index) in fields" :key="index" class="form-group label-inside">
                    <accordion-field v-model="fieldModel[field.key]" :field="field" :identifier="identifier" :index="index" :item="item" :readonly="readonly" @input="valueUpdate(field.key)" />
                </div>
            </div>

            <media-form
                v-for="mediaForm in mediaForms"
                :key="`${identifier}-${mediaForm.key}`"
                :disable-editable-fields="disableEditableFields"
                :identifier="identifier"
                :item-index="index"
                :media-form="mediaForm"
                :medias="medias"
                :offset="offsets[mediaForm.key]"
                :readonly="readonly"
                :widget="mediaFormConfig(mediaForm)"
                @media-add="mediaAdd"
                @media-update="mediaUpdate"
                @media-remove="mediaRemove"
                @media-sort="mediaSort"
            />

            <button :disabled="disableRemove" :title="removeTitle()" class="btn btn-danger w-100" type="button" @click="$emit('remove-item', id)">Löschen</button>
        </section>
    </div>
</template>

<script>
import MediaForm from './MediaForm.vue';
import AccordionField from './AccordionField.vue';

export default {
    name: 'AccordionItem',
    props: [
        'elementTitle',
        'index',
        'id',
        'identifier',
        'modelName',
        'medias',
        'offsets',
        'readonly',
        'fields',
        'groups',
        'item',
        'mediaForms',
        'disableEditableFields',
        'itemLabelKey',
        'disableRemove',
        'min',
        'itemsLength',
    ],
    components: {
        AccordionField,
        MediaForm
    },
    data() {
        return {
            mounted: false,
            fieldModel: {},
            removeTitle() {
                let title = false;
                if (this.min > 0) {
                    if (this.itemsLength <= this.min) {
                        if (this.min > 1) {
                            title = `Mindestens ${this.min} Elemente`
                        } else {
                            title = `Mindestens 1 Element`
                        }
                    }
                }
                return title;
            },
        }
    },
    methods: {
        tabTitle() {
            if (this.fieldModel[this.fields[0].key]) {
                return this.fieldModel[this.itemLabelKey !== '' ? this.itemLabelKey : this.fields[0].key];
            }

            if (this.mediaForms.length > 0 && this.medias[this.mediaForms[0].key].length > 0 && this.medias[this.mediaForms[0].key][0].label) {
                return this.medias[this.mediaForms[0].key][0].label;
            }

            return "";
        },
        mediaFormConfig(mediaForm) { return {
            modelName: this.modelName,
            attributeName: `${mediaForm.key}`,
            medias: this.medias[mediaForm.key],
            dummyFormName: 'pageeditor-dummy-form',
            uploadDisabled: this.readonly,
            readonly: this.readonly,
            maxFiles: mediaForm.maxFiles,
        }},
        valueUpdate(key) {
            this.$emit('value-update', this.id, key, this.fieldModel[key]);
        },
        tiptapUpdate(value, key) {
            this.fieldModel[key] = value;
            this.valueUpdate(key);
        },
        mediaAdd(mediaForm, fileID, data) {
            this.$emit('media-add', mediaForm, this.id, fileID, data);
        },
        mediaUpdate(mediaForm, mediaId, data, offset, index) {
            this.$emit('media-update', mediaForm, this.id, mediaId, data, offset, index);
        },
        mediaRemove(mediaForm, data) {
            this.$emit('media-remove', mediaForm, this.id, data);
        },
        mediaSort(mediaForm, data, newIndex, oldIndex) {
            this.$emit('media-sort', mediaForm, this.id, data, newIndex, oldIndex);
        },
        getFieldsByGroup(group) {
            const filtered = this.fields.filter(field => field.group === group.key);
            return filtered;
        }
    },
    beforeMount() {
        this.fields.forEach(field => {
            this.fieldModel[field.key] = this.item[field.key];
        });
    },
    mounted() {
        this.mounted = true;
    },
    watch: {
        item(val, old) {
            this.fields.forEach(field => {
                this.fieldModel[field.key] = val[field.key];
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.handle {
    font-size: 16px;
    margin-right: 10px;
    cursor: move;

    &:after {
        content: '\e807';
        font-family: "fontello";
    }
}

.field-group {
    .wrapper {
        padding: 15px 15px 10px;
        background: #f6f7f6;
        border: 1px solid #e0e4e1;
        border-radius: .25rem;
    }
    margin-bottom: 3rem;
}
</style>