<template>
    <tr class="filter-settings__item">
        <td>
            <div class="handle"></div>
        </td>
        <td class="filter-title">{{ filter.filterData.label }}</td>
        <td class="filter-position">
            {{ position }}
            <input type="hidden" :name="`${inputNamePrefix}[filters][${filter.filterId}][position]`" :value="position" />
        </td>
        <td>
            <div class="custom-control custom-switch">
                <input type="checkbox" :id="`CategoryFilter_favorite_${filter.filterId}`" class="custom-control-input" :name="`${inputNamePrefix}[filters][${filter.filterId}][isFavorite]`" value="1" :checked="filter.isFavorite === 1">
                <label class="custom-control-label" :for="`CategoryFilter_favorite_${filter.filterId}`"></label>
            </div>
        </td>
        <td v-if="showShowroomToggle">
            <div class="custom-control custom-switch">
                <input type="checkbox" :id="`CategoryFilter_showroom_${filter.filterId}`" class="custom-control-input" :name="`${inputNamePrefix}[filters][${filter.filterId}][displayShowroom]`" value="1" :checked="filter.displayShowroom === 1">
                <label class="custom-control-label" :for="`CategoryFilter_showroom_${filter.filterId}`"></label>
            </div>
        </td>
        <td>
            <select class="form-control" :name="`${inputNamePrefix}[filters][${filter.filterId}][displayOptionValues]`">
                <option v-for="(value, key) in displayOptionValues" :key="key" :value="key" :selected="parseInt(key) === filter.displayOption">{{ value }}</option>
            </select>
        </td>
        <td>
            <select class="form-control" :name="`${inputNamePrefix}[filters][${filter.filterId}][additionalOptionValues]`">
                <option v-for="(value, key) in additionalOptionValues" :key="key" :value="key" :selected="parseInt(key) === filter.additionalOption">{{ value }}</option>
            </select>
        </td>
        <td class="text-right">
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="remove">Entfernen</button>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'Item',
    props: {
        filter: {
            type: Object
        },
        position: {
            type: Number
        },
        displayOptionValues: {
            type: Object
        },
        additionalOptionValues: {
            type: Object
        },
        inputNamePrefix: {
            type: String
        },
        showShowroomToggle: {
            type: Boolean
        }
    },
    methods: {
        remove() {
            this.$emit('remove', this.filter);
        },
        isSelected(key) {
            console.log(key);
            return false;
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-settings__item {
    height: 65px;
    border-bottom: 1px solid #D6D8D8;

    &:last-child {
        border: none;
    }
}
div.handle {
    margin-right: 16px;

    &:after {
        content: '\e807';
        font-family: "fontello";
        font-size: 10px;
        cursor: move;
    }
}
td {
    font-size: 10px;
    padding: 0 10px;

    &.filter-title {
        font-weight: bold;
    }

    &.filter-position {
        font-weight: bold;
    }

    .form-control {
        font-size: 9px;
    }

    .btn {
        font-size: 10px;
    }
}
</style>