import {Node} from 'tiptap'
import ContextMenu from "../components/ContextMenu.vue";
import Icon from "../components/Icon.vue"

export default class Anchor extends Node {

    get name() {
        return 'anchor'
    }

    get schema() {
        return {
            attrs: {
                id: {
                    default: null,
                },
            },
            group: 'block',
            selectable: true,
            draggable: true,
            parseDOM: [{
                tag: 'a',
                getAttrs: dom => ({
                    id: dom.getAttribute('id'),
                }),
            }],
            toDOM: node => ['a', {
                id: node.attrs.id
            }],
        }
    }

    commands({type}) {
        return attrs => (state, dispatch) => {
            const {selection} = state;
            const {from, to} = selection;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            let node = false;

            state.doc.nodesBetween(from, to, element => {
                if (element.type.name === this.name) {
                    node = element;
                }
            });

            let transaction = state.tr;

            if (node) {
                selection.node.attrs = attrs;
                transaction = transaction.replaceSelectionWith(selection.node)
            } else {
                node = type.create(attrs);
                transaction = transaction.insert(position, node);
            }

            dispatch(transaction);
        }
    }

    get view() {
        return {
            props: ['name', 'node', 'updateAttrs', 'view', 'getPos'],
            components: {
                ContextMenu,
                Icon
            },
            computed: {
                id: {
                    get() {
                        return this.node.attrs.id
                    },
                    set(id) {
                        this.updateAttrs({
                            id,
                        })
                    },
                },
            },
            methods: {
                handleClick(event, id) {
                    this.$refs.ContextMenu.showMenu(event, id);
                },

                optionClicked(event) {
                    if (event.option.slug === 'remove-anchor') {
                        this.deleteNode();
                    } else if (event.option.slug === 'edit-anchor') {
                        this.$parent.$parent.updateAnchor(this.node.attrs);
                    }
                },

                deleteNode() {
                    let tr = this.view.state.tr;
                    let pos = this.getPos();
                    tr.delete(pos, pos + this.node.nodeSize);
                    this.view.dispatch(tr);
                }
            },
            data() {
                return {
                    uniqueId: 'uniqueId',
                    options: [
                        /*{
                            name: 'Anker Ausschneiden',
                            slug: 'cut-anchor',
                            class: 'contextmenu-item cut',
                            icon: 'cut',
                            hint: {
                                text: 'Strg+X',
                                title: 'Ausschneiden Strg + X'
                            }
                        },
                        {
                            name: 'Anker kopieren',
                            slug: 'copy-anchor',
                            class: 'contextmenu-item copy',
                            icon: 'copy',
                            hint: {
                                text: 'Strg+C',
                                title: 'Ausschneiden Strg + C'
                            }
                        },*/
                        {
                            name: 'Anker bearbeiten',
                            slug: 'edit-anchor',
                            class: 'contextmenu-item edit',
                            icon: 'anchor'
                        },
                        {
                            name: 'Anker entfernen',
                            slug: 'remove-anchor',
                            class: 'contextmenu-item delete',
                            icon: 'anchor'
                        }
                    ]
                }
            },

            mounted() {
              this.uniqueId =  'anchor-contextmenu-' + Math.random();
            },
            template: `
                <div class="anchor" style="position: relative">
                    <div @contextmenu.prevent.stop="handleClick($event, id)">
                        <div class="anchor-icon"><icon name="anchor"/></div>
                        <a :id="id"></a>
                        <input class="anchor__input" @paste.stop type="text" v-model="id" v-if="view.editable"/>
                    </div>

                    <context-menu
                        :elementId="uniqueId"
                        :options="options"
                        ref="ContextMenu"
                        @option-clicked="optionClicked"
                    />
                </div>
            `,
        }
    }
}
