import Vue from 'vue';
import {BasicColorpicker, DribbleColorpicker} from './components/index.js';

// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css"

(function () {

    'use strict';

    window.addEventListener("Pageeditor:insert", (function (tevent) {
            initColorpicker();
        }
    ));
    window.addEventListener('DOMContentLoaded', (function (event) {
            initColorpicker();
        }
    ));

    function initColorpicker() {
        let elements = document.querySelectorAll('[data-is-colorpicker="1"]');
        for (let element of elements) {
            new Vue({
                name: "ColorPickerRoot",
                el: element,
                render: createElement => {
                    const context = {
                        props: {
                            inputName: element.name,
                            modelName: element.dataset.modelname,
                            attributeName: element.dataset.attributename,
                            inputValue: element.value,
                            inputOptions: element.dataset.options,
                            inputClass: element.className,
                            inputPlaceholder: element.placeholder,
                            inputTranslations: element.dataset.translations,
                        },
                    };

                    // Todo: Dynamic component name
                    if (element.dataset.type.toLowerCase() === 'dribble') {
                        return createElement(DribbleColorpicker, context);
                    } else {
                        return createElement(BasicColorpicker, context);
                    }
                }
            });
        }
    }
})();