/**
 */

'use strict';

class ColumnVisibleAsset {

    constructor(options) {
        this.m2mModel = options.m2mModel;
        this.panel = options.panel;
        this.columns = {};

        this.init();
    }

    init() {
        this.addListenersToSaveBtn();
        let labels = this.panel.querySelectorAll('label');
        for (let label of labels) {
            label.addEventListener('click', function (e) {
                e.stopPropagation();
            });
        }
    }

    addListenersToSaveBtn() {
        let btn = this.panel.querySelector('.panel-footer button.btn-success');

        if (btn) {
            let that = this;
            btn.addEventListener('click', function () {
                that.columns[that.m2mModel] = {};

                let checkboxes = this.parentNode.parentNode.querySelector('.panel-body').querySelectorAll('input[type="checkbox"]');

                for (let checkbox of checkboxes) {
                    if (!checkbox.checked) {
                        that.columns[that.m2mModel][checkbox.getAttribute('name')] = 0;
                    } else {
                        that.columns[that.m2mModel][checkbox.getAttribute('name')] = 1;
                    }
                }

                that.httpUpdateInvisibleState(that.columns);
            });
        }
    }

    httpUpdateInvisibleState(columns) {
        let that = this;
        let http = new XMLHttpRequest();
        let currentUrlParts = window.yii.getCurrentUrl().split('/');

        let url = `/${currentUrlParts[3]}/${currentUrlParts[4]}/core/gui/update-gui-settings-visible`;

        columns[window.yii.getCsrfParam()] = window.yii.getCsrfToken();
        let params = $.param(columns);

        http.open('post', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        http.onreadystatechange = function () {//Call a function when the state changes.

            if (http.readyState == 4 && http.status == 200) {
                let res = JSON.parse(http.responseText);

                if (!res['errors'] && res['success']) {
                    console.log(res['success']);

                    $.pjax.reload({container: `#${that.m2mModel}`});

                } else if (res['errors']) {
                    console.error(res['errors']);
                } else {
                    console.log(JSON.stringify(res));
                }
            }
        };

        http.send(params);
    }
}

let columnsSettings = document.querySelectorAll('[id*="columns-visible"]');

// Instantiate grids with keep selection
for (let columnSettings of columnsSettings) {
    let panel = new ColumnVisibleAsset({
        panel: columnSettings,
        m2mModel: columnSettings.id.replace('-columns-visible', '')
    });

    $(document).on('ready pjax:success', function () {
        panel.init();
    });
}