export const ALIGNMENTS = {
    'inline_top': {label: 'Zeile Oben'},
    'inline_center': {label: 'Zeile Mitte'},
    'inline_bottom': {label: 'Zeile Unten'},
    'block_left': {label: 'Block Links'},
    'block_center': {label: 'Block Mitte'},
    'block_right': {label: 'Block Unten'},
    'float_left': {label: 'Links'},
    'float_right': {label: 'Rechts'}
};

export class ImageObject {

    constructor(options) {
        this.options = Object.assign({
            src: '',
            modal_id: '',

            alignment: 'block_center',
            margin_left: 0,
            margin_right: 3,
            margin_top: 3,
            margin_bottom: 3,
            width: 100,
            height: '',
            size_unit: '%',
            ratio_locked: true,

            label: '',
            sublabel: '',
            description: '',
            link: '',
            linktitle: '',

            origin: {
                width: '',
                height: '',
                ratio: 1,
                src: '',
                preview: '',
                uri: '',
                file_id: '',
                file_name: '',
                media_id: '',
            },
        },options);
    }

    static calcStyle(options) {
        return {
            marginTop: options.margin_top + 'px ' ,
            marginBottom: options.margin_bottom + 'px ' ,
            marginLeft: options.margin_left + 'px ' ,
            marginRight: options.margin_right + 'px ' ,
            width: options.width + options.size_unit,
            height: options.ratio_locked ? 'auto' : options.height + 'px',
            display: !options.src ? 'none' : '',
        }
    }

    static calcInlineStyleString(options) {
        let style = this.calcStyle(options);
        return `margin: ${style.marginTop} ${style.marginRight} ${style.marginBottom} ${style.marginLeft}; width: ${style.width}; height: ${style.height}`;
    }

    static getAttrsFromEl(el) {
        return {
            src: el.getAttribute('src'),

            alignment: el.getAttribute('class'),
            margin_left: parseFloat(el.style.marginLeft),
            margin_right: parseFloat(el.style.marginRight),
            margin_top: parseFloat(el.style.marginTop),
            margin_bottom: parseFloat(el.style.marginBottom),
            width: parseFloat(el.style.width),
            height: el.style.height === 'auto' ? '' : parseFloat(el.style.height),
            size_unit: el.style.width.match(/(%|px)/gi)[0],
            ratio_locked: el.style.height === 'auto',

            modal_id: el.dataset.modal_id,

            label: el.hasAttribute('alt') ? el.getAttribute('alt') : '',
            //sublabel: '',
            //description: '',
            //link: '',
            //linktitle: '',
        }
    }

    setAttrsFromMedia(media) {
        this.options.label = media.label;
        this.options.sublabel = media.sublabel;
        this.options.description = media.description;
        this.options.link = media.link;
        this.options.linktitle = media.linktitle;

        this.options.origin.media_id = media.id;
        this.options.origin.file_id = media.file_id;
        this.options.origin.file_name = media.file_name;
        this.options.origin.width = media.file.width;
        this.options.origin.height = media.file.height;
        this.options.origin.src = media.file.src;
        this.options.origin.preview = media.file.preview;
        this.options.origin.uri = media.file.uri;
    }
}