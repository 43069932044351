var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: [{ disabled: _vm.widget.readonly }],
      attrs: {
        "data-file-id": _vm.media.file_id,
        "data-item-index": _vm.itemIndex,
        "data-media-id": _vm.media.id || "",
        "data-sort-index": _vm.index,
      },
    },
    [
      _c("div", { staticClass: "modelmedia_row" }, [
        _c(
          "a",
          {
            staticClass: "preview",
            attrs: { target: "_blank", title: "Vorschau" },
          },
          [
            _c("img", {
              staticClass: "media_img",
              attrs: { src: _vm.file.preview || "", alt: "" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "file_name" }, [
          _vm._v("\n            " + _vm._s(_vm.file.name || "") + "\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "media_actions" }, [
          _c("a", {
            staticClass: "file-remove fontello-icon-ui-close",
            class: { disabled: _vm.widget.readonly },
            attrs: { title: "Entfernen" },
          }),
          _vm._v(" "),
          _c("a", {
            staticClass:
              "changeMediaButton loadMediaManager fontello-icon-menu-mediamanager",
            class: { disabled: _vm.widget.readonly },
            attrs: { title: "Bild ersetzen>" },
          }),
          _vm._v(" "),
          _vm.widget.maxFiles > 1
            ? _c("a", {
                staticClass: "file-sort far fa-arrows",
                class: { disabled: _vm.widget.readonly },
                attrs: { title: "Verschieben" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.widget.withPointer
            ? _c("a", {
                staticClass: "mediapointer far fa-bullseye",
                class: { disabled: _vm.widget.readonly },
                attrs: { title: "Zeiger bearbeiten" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.widget.editable
            ? _c("a", {
                staticClass: "file-edit far fa-pencil",
                class: { disabled: _vm.widget.readonly },
                attrs: { title: "Bearbeiten" },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "break" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modelmedia_edit" },
          _vm._l(_vm.fields, function (field, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "form-group label-inside",
                class: { "d-none": field.type === "hidden" },
              },
              [
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    id: `${_vm.widget.identifier}_${_vm.widget.attributeName}_${
                      field.name || key
                    }_${_vm.index < 0 ? "" : _vm.index}`,
                    "data-name": field.name || key,
                    disabled: _vm.widget.readonly,
                    form: _vm.widget.dummyFormName
                      ? _vm.widget.dummyFormName
                      : null,
                    name: `${_vm.namePrefix}[${field.name || key}]`,
                    type: field.type || "text",
                  },
                  domProps: { value: _vm.media[key] || "" },
                }),
                _vm._v(" "),
                field.label
                  ? _c("label", [_vm._v(_vm._s(field.label))])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }