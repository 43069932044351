var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "filter-settings__item" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("td", { staticClass: "filter-title" }, [
      _vm._v(_vm._s(_vm.filter.filterData.label)),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "filter-position" }, [
      _vm._v("\n        " + _vm._s(_vm.position) + "\n        "),
      _c("input", {
        attrs: {
          type: "hidden",
          name: `${_vm.inputNamePrefix}[filters][${_vm.filter.filterId}][position]`,
        },
        domProps: { value: _vm.position },
      }),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("div", { staticClass: "custom-control custom-switch" }, [
        _c("input", {
          staticClass: "custom-control-input",
          attrs: {
            type: "checkbox",
            id: `CategoryFilter_favorite_${_vm.filter.filterId}`,
            name: `${_vm.inputNamePrefix}[filters][${_vm.filter.filterId}][isFavorite]`,
            value: "1",
          },
          domProps: { checked: _vm.filter.isFavorite === 1 },
        }),
        _vm._v(" "),
        _c("label", {
          staticClass: "custom-control-label",
          attrs: { for: `CategoryFilter_favorite_${_vm.filter.filterId}` },
        }),
      ]),
    ]),
    _vm._v(" "),
    _vm.showShowroomToggle
      ? _c("td", [
          _c("div", { staticClass: "custom-control custom-switch" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                id: `CategoryFilter_showroom_${_vm.filter.filterId}`,
                name: `${_vm.inputNamePrefix}[filters][${_vm.filter.filterId}][displayShowroom]`,
                value: "1",
              },
              domProps: { checked: _vm.filter.displayShowroom === 1 },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "custom-control-label",
              attrs: { for: `CategoryFilter_showroom_${_vm.filter.filterId}` },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          staticClass: "form-control",
          attrs: {
            name: `${_vm.inputNamePrefix}[filters][${_vm.filter.filterId}][displayOptionValues]`,
          },
        },
        _vm._l(_vm.displayOptionValues, function (value, key) {
          return _c(
            "option",
            {
              key: key,
              domProps: {
                value: key,
                selected: parseInt(key) === _vm.filter.displayOption,
              },
            },
            [_vm._v(_vm._s(value))]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "select",
        {
          staticClass: "form-control",
          attrs: {
            name: `${_vm.inputNamePrefix}[filters][${_vm.filter.filterId}][additionalOptionValues]`,
          },
        },
        _vm._l(_vm.additionalOptionValues, function (value, key) {
          return _c(
            "option",
            {
              key: key,
              domProps: {
                value: key,
                selected: parseInt(key) === _vm.filter.additionalOption,
              },
            },
            [_vm._v(_vm._s(value))]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-outline-secondary",
          attrs: { type: "button" },
          on: { click: _vm.remove },
        },
        [_vm._v("Entfernen")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("div", { staticClass: "handle" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }