var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "drop-element",
      class: [_vm.element.type === "formsection" ? "formsection" : ""],
      attrs: { id: _vm.data.id },
      on: {
        mouseenter: function ($event) {
          $event.stopPropagation()
          return _vm.mouseover.apply(null, arguments)
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          return _vm.mouseleave.apply(null, arguments)
        },
      },
    },
    [
      _vm.element.type === "formitem"
        ? _c(_vm.view, { tag: "component", attrs: { item: _vm.element } })
        : _c("Section", {
            attrs: {
              item: _vm.element,
              "put-section-area": _vm.putSectionArea,
            },
          }),
      _vm._v(" "),
      _vm.hover
        ? _c("drop-element-tools", {
            on: {
              edit: _vm.edit,
              duplicate: _vm.duplicate,
              remove: _vm.remove,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }