$(document).bind('dragover', function (e) {
    var dropZone = $('.mm-dropzone:not(.disabled)'),
        foundDropzone,
        timeout = window.dropZoneTimeout,
        found = false,
        node = e.target;

    if (!timeout) {
        dropZone.addClass('in');
    } else {
        clearTimeout(timeout);
    }
    do {
        if ($(node).hasClass('mm-dropzone')) {
            found = true;
            foundDropzone = $(node);
            break;
        }
        node = node.parentNode;
    } while (node != null);
    dropZone.removeClass('hover');
    if (found) {
        foundDropzone.addClass('hover');
    }
    window.dropZoneTimeout = setTimeout(function () {
        window.dropZoneTimeout = null;
        dropZone.removeClass('in hover');
    }, 100);
});

//Prevents default action when dropping a file on browser
$(document).bind('drop dragover', function (e) {
    e.preventDefault();
});

//File upload management..
window.initFileUpload = function ($selector = '.uploadFile') {
    let $uploadFile = $($selector);

    if (typeof window.initFileUploadDone != "undefined") {
        var $uploadFileWidgets = $uploadFile.filter('.initialized');
        $uploadFileWidgets.unbind('fileuploaddone');
        $uploadFileWidgets.unbind('fileuploadsubmit');
        $uploadFileWidgets.fileupload('destroy');
        $uploadFileWidgets.removeClass('initialized');
    }

    function addFile(data, file, tag, that, modelMedias) {
        var fileSize = file.size,
            fileType = file.type,
            fileName = file.name,
            fileMD5 = false;

        var image = false;
        var movie = false;
        var extension = '_blank';
        var invalidFiletype = false;
        if (fileType.match(/image\/(png|jpe?g|gif|webp|svg\+xml)/)) {
            image = true;
        }
        if (fileType.match(/video\/(.*)/)) {
            movie = true;
        }

        var matches = fileType.match(/\w*\/(\w*)/);
        if (matches && matches.length > 1) {
            var ext = matches[1];
            if (ext.length > 2) {
                extension = ext;
            }
        }
        if (extension == '_blank') {
            extension = fileName.substr(fileName.lastIndexOf('.') + 1);
        }

        if (extension == 'dxf') {
            invalidFiletype = false;
        }
        //add the file to the upload list
        var $uploadList = $('#uploadList');
        if ($('#uploadList').length == 0) {
            $uploadList = $('<div id="uploadList">' +
                '<div class="upload-list-container">' +
                '<div class="upload-list-header">' +
                '<span class="bitrate"></span>' +
                '   <span class="count"></span>' +
                '</div>' +
                '<div class="upload-list">' +
                '   <ul>' +
                '</ul>' +
                '</div>' +
                '<div class="upload-list-footer">' +
                '   <button class="btn upload-list-close">' + 'Abbrechen' + '</button>' + //Yii.t
                '</div>' +
                '</div>' +
                '</div>').appendTo($('body'));
        }

        $uploadList.find('.upload-list-close').click(function (e) {
            $uploadList.fadeOut(200, function () {
                var count = $(this).find('li').length;
                setCountBack(modelMedias, count)
                $(this).remove();
            });
        });
        var $list = $uploadList.find('ul'),
            $uploadItem = $('<li class="upload-item" data-context="' + fileName + '">' +
                '<div class="upload-content"></div>' +
                '<div class="upload-details">' +
                '<div class="upload-title">' + fileName + '</div>' +
                '<div class="progress-container">' +
                '<div class="upload-progress-info">' +
                '<span class="current"></span>' +
                '<span class="total">' + humanFileSize(fileSize) + '</span>' +
                '<span class="percent"></span>' +
                '<span class="speed"></span>' +
                '<div class="upload-bar"><div class="upload-progress"></div></div>' +
                '</div>' +
                '</div>' +
                '<div class="upload-info"></div>' +
                '</div>' +
                '<div class="upload-buttons"></div>' +
                '</li>').appendTo($list);

        if (image) {
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var contents = e.target.result;
                    $('<img src="' + contents + '">').appendTo($uploadItem.find('.upload-content'));
                };
            }
            reader.readAsDataURL(file);
        } else if (movie) {
            getVideoThumbnail(data.files[0], (dataUrl) => {
                let img = new Image();
                img.src = dataUrl;
                $uploadItem.find('.upload-content').append(img);
                $uploadItem.addClass('has_thumbfile');
            });
        } else {
            $('<img onerror="$(this).attr(\'src\',\'/assets/mediamanager/32px/_blank.png\'); " src="/assets/mediamanager/32px/' + extension + '.png">').appendTo($uploadItem.find('.upload-content'));
        }

        if (invalidFiletype) {
            $uploadItem.addClass('upload-error');
            $uploadItem.find('.upload-info').empty().append('<div><p>Dateityp <b>' + extension + '</b> ist nicht zugelassen.</p></div>');
            //var allUploadItems = $('.upload-item').not('.upload-done').not('.upload-error');
            // if (allUploadItems.length == 0) {
            //     $('#uploadList .upload-list-close').prop('disabled', false);
            // }
            var $cancel = $('<button class="upload-cancel"><i class="fa fa-times"></i></button>').appendTo($uploadItem.find('.upload-buttons'));
            $cancel.click(function (e) {
                $uploadItem.remove();
                setCountBack(modelMedias, 1)
                var $lis = $list.find('li');
                if ($lis.length < 1) {
                    $uploadList.fadeOut(200, function () {
                        $(this).remove();
                    });
                } else {
                    closeIfDone();
                }
            });
        } else {
            $.getJSON(window.MediaManager.controllerUrl + 'uploadstatus', {file: data.files[0].name}, function (result) {
                data.context = data.files[0].name;

                if (result.exists) {
                    if (window.keyShiftPressed) {
                        data.replace = true;
                        data.submit();
                    } else {
                        $uploadItem.find(".upload-info").empty().append('<div class="exists-message"><p><b>' + fileName + '</b></p><p>Bereits vorhanden</p></div>');    //Yii.t
                        $uploadItem.addClass("result-exists");
                        $uploadItem.find(".upload-info").append('<div class="exists-origin"><div class="img-container"><img src="' + result.url + '"></div><div>Datei ersetzen</div></div>');
                        //$('#uploadList .upload-list-close').prop('disabled', false);
                        var $cancel = $('<button class="upload-cancel"><i class="fontello-icon-close"></i></button>').appendTo($uploadItem.find('.upload-buttons'));
                        var $button = $('<button class="upload-replace"><i class="fontello-icon-tick"></i></button>').appendTo($uploadItem.find('.upload-buttons'));    //Yii.t

                        $button.click(function (e) {
                            $uploadItem.removeClass("result-exists");
                            $uploadItem.addClass("uploading");
                            $uploadItem.find(".upload-title").empty().append(fileName);

                            data.replace = true;
                            data.submit();
                            closeIfDone();

                        });
                        $cancel.click(function (e) {
                            $uploadItem.remove();
                            setCountBack(modelMedias, 1)
                            var $lis = $list.find('li');
                            if ($lis.length < 1) {
                                $uploadList.fadeOut(200, function () {
                                    $(this).remove();
                                });
                            } else {
                                closeIfDone();
                            }
                        });
                    }
                } else {
                    data.submit();
                }
            });
        }
    }

    function setCountBack(modelMedias, count) {
        var uploadingCount = modelMedias.data('uploadingCount');
        uploadingCount = uploadingCount - count;
        modelMedias.data('uploadingCount', uploadingCount);
    }

    function closeIfDone() {
        var $lis = $('#uploadList ul').find('li').not('.upload-done').not('.upload-error');
        if ($lis.length < 1) {
            $('#uploadList').fadeOut(200, function () {
                $(this).remove();
                if ($('#mediamanager.overlay').hasClass('open') || $('#mediamanager').hasClass('standalone')) {
                    window.MediaManager.updateListView();
                }
            });
        }
    }

    $uploadFile.each(function () {
        let $this = $(this);
        let $dropzone = $this.parents('.mediaformwidget').find('.mm-dropzone');
        if ($dropzone.length <= 0) {
            $dropzone = $('#mediamanager .mm-dropzone');
        }
        $this.addClass('initialized');
        var formData = {};
        formData[window.csrfTokenName] = window.csrfData;
        $this.fileupload({
            // maxChunkSize: 1024 * 1024 * 100, //almost 10mb
            dataType: 'json',
            formData: formData,
            url: window.MediaManager.controllerUrl + 'upload',
            dropZone: $dropzone,
            add: function (e, data) {
                if ($this.hasClass('disabled')) {
                    return;
                }

                var $modelMedias = $(this).closest('.mediaformwidget').find('.medialist'),
                    maxfiles = $modelMedias.data('maxfiles'),
                    elements = $modelMedias.find('li:not(.template)'),
                    count;

                if ($modelMedias.length > 0) {
                    var uploadingCount = $modelMedias.data('uploadingCount');
                    if (typeof uploadingCount == 'undefined') {
                        uploadingCount = 0;
                    }
                    count = uploadingCount + elements.length;

                } else {
                    count = 0;
                    maxfiles = 1;
                    data.filedir = window.MediaManager.listCriteria.filedir || null;    // save uploaded image in the current selected directory
                }
                if (count < maxfiles) {
                    uploadingCount = uploadingCount + 1;
                    $modelMedias.data('uploadingCount', uploadingCount);
                    addFile(data, data.files[0], data.tag, this, $modelMedias);
                }
            }
        });
    });

    $uploadFile.bind('fileuploadprogress', function (e, data) {
        var fileName = data.context,
            uploadItem = $('.upload-item[data-context="' + fileName + '"]'),
            progress = parseInt(data.loaded / data.total * 100, 10);

        uploadItem.find('.upload-progress').css({
            width: progress + '%'
        });
        uploadItem.find(".upload-progress-info .current").text(humanFileSize((data.loaded)) + " / ");
        uploadItem.find(".upload-progress-info .percent").text(" (" + progress + "%)");
        uploadItem.find(".upload-progress-info .speed").text(humanFileSize((data.bitrate / 8)) + '/s');

    });

    $uploadFile.bind('fileuploaddone', function (e, data) {
        var uploadItem = $('.upload-item[data-context="' + data.context + '"]');
        uploadItem.removeClass("uploading");

        if (data.result.error) {
            uploadItem.addClass('upload-error');
            uploadItem.find(".upload-info").text(data.result.error);
        } else {
            uploadItem.addClass('upload-done');
            uploadItem.find(".upload-progress-info .current").addClass("upload_done").text('Fertig');  //Yii.t

            if (uploadItem.hasClass('has_thumbfile')) {
                let img = uploadItem.find('.upload-content img');
                // upload & save the client-side js generated thumbnail (videos, pdf's, ect.)
                $.post(
                    window.MediaManager.controllerUrl + 'savethumbfile',
                    {
                        id: data.result.id,
                        data: img.attr('src')
                    }
                );
            }

            var src = data.result.preview;

            var $list = $('#mediamanager .mediamanager-list'),
                $el = $list.find('li:first').clone();

            $el.attr('data-id', data.result.id);
            $el.find('img').attr('src', src)
                .attr('data-file', data.result.path + '/' + data.result.name)
                .attr('data-original', src);
            $el.find('.title a').text(data.result.name)
                .attr('href', data.result.path + '/' + data.result.name);
            $el.find('.imgSize').text(data.result.width + " x " + data.result.height);
            if (data.result.fileicon) {
                $el.find('img').addClass('fileicon');
                $el.find('div.centered').addClass('fileicon');
            }


            $el.removeClass().addClass(data.result.tag + ' attachment ui-draggable ui-draggable-handle');
            $el.show();
            $el.appendTo($list);
            if (typeof window.loadDraggable != "undefined") {
                window.loadDraggable($el);
                window.selectImages($el.find('.attachment-preview'));
            }

            if (data.result.attr != "undefined") {
                //drag&drop in attr ziel
                var $input = $('input[data-attribute="' + data.result.attr + '"]');
                var $medialist = $input.closest(".mediaformwidget").find("ul.medialist");

                addModelMediaById($medialist, data.result.id);
            }

            closeIfDone();
        }

        // var allUploadItems = $('.upload-item').not('.upload-done').not('.upload-error');
        // if (allUploadItems.length == 0) {
        //     $('#uploadList .upload-list-close').prop('disabled', '');
        // }

    });

    $uploadFile.bind('fileuploadsubmit', function (e, data) {
        let $target = $(e.delegatedEvent.currentTarget);
        if ($target.hasClass('mm-dropzone')) {
            $target = $target.parents('.mediaformwidget').find('input.uploadFile');
        }
        let modelId = $target.attr('data-id');
        let modelClass = $target.attr('data-class');
        let tag = $target.attr('data-tag');
        let modelAttribute = $target.attr('data-attribute');
        if (typeof tag === "undefined") {
            //drop/upload in Mediathek
            tag = $('#tag').val();
            if (tag == 'all') {
                tag = 'mediathek';
            }
        }
        if ($("#tag").val() !== "all" && typeof $("#tag").val() !== "undefined") {
            tag = $("#tag").val();
        }

        data.formData = {};
        if (modelId) data.formData.modelId = modelId;
        if (modelClass) data.formData.modelClass = modelClass;
        if (modelAttribute) data.formData.modelAttribute = modelAttribute;
        if (tag) data.formData.tag = tag;
        if (data.filedir) data.formData.filedir = data.filedir;

        if (typeof data.replace !== "undefined") {
            data.formData['replace'] = 'true';
        }
        data.formData[window.csrfTokenName] = window.csrfToken;
    });

    window.initFileUploadDone = true;
};

// Used to figure out if shift is pressed, if shift is pressed replace files directly.
window.keyShiftPressed = false;

function keyShiftDetector(event) {
    window.keyShiftPressed = event.shiftKey;
    if (window.keyShiftPressed) {
        removeEventListener("keydown", keyShiftDetector);
        addEventListener("keyup", keyShiftDetector, {passive: true});
    } else {
        removeEventListener("keyup", keyShiftDetector);
        addEventListener("keydown", keyShiftDetector, {passive: true});
    }
}

addEventListener("keydown", keyShiftDetector, {passive: true});
