export const defaultProps = {
    activeTab: {
        type: String,
        default: 'Detail'
    },
    content: {
        type: Object
    },
    muted: {
        type: Boolean
    },
    lightIsOn: {
        type: Boolean,
        default: true
    },
    bgGlowColor: {
        type: String,
        default: ''
    }
}
