/**
 * Returns a debounce function.
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 *
 * @link https://davidwalsh.name/javascript-debounce-function
 * @param func function The function to call
 * @param wait int milliseconds to wait
 * @param immediate boolean If true trigger the function on the leading edge, instead of the trailing.
 * @return  {function}
 */
export function debounce(func, wait, immediate) {
    "use strict";
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}

/**
 * Checks if a variable is a function
 * @param functionToCheck function
 * @return {boolean}
 */
export function isFunction(functionToCheck) {
    "use strict";
    //new code taken from https://api.jquery.com/jQuery.isFunction/
    return typeof functionToCheck === "function";
    //old code:
    //return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

/**
 * Checks if current browser is Chrome
 * @returns {boolean}
 */
export function isChrome() {
    "use strict";
    return !!window.chrome;
}

/**
 * Checks if current browser is from Apple
 * @returns {boolean}
 */
export function isApple() {
    "use strict";
    return /iPhone|iPod|iPad/.test(navigator.userAgent);
}

/**
 *
 * @param func function function to execute
 * @param fps integer frames per second
 * @param iFrame object Pass an iframe
 * @returns {{getInterval: (function(): number), stop: stop, start: start, toggle: toggle}}
 */
export function loopAtFps(func, fps, iFrame) {
    "use strict";
    fps = fps || 60;
    iFrame = iFrame || window.top;
    let then = Date.now(),
        rafId = false,
        interval = 1000 / fps,
        now,
        delta,
        tick = function () {
            rafId = iFrame.requestAnimationFrame(tick);
            now = Date.now();
            delta = now - then;
            if (delta >= interval) {
                // update time stuffs

                // Just `then = now` is not enough.
                // Lets say we set fps at 10 which means
                // each frame must take 100ms
                // Now frame executes in 16ms (60fps) so
                // the loop iterates 7 times (16*7 = 112ms) until
                // delta > interval === true
                // Eventually this lowers down the FPS as
                // 112*10 = 1120ms (NOT 1000ms).
                // So we have to get rid of that extra 12ms
                // by subtracting delta (112) % interval (100).
                // Hope that makes sense.
                then = now - delta % interval;
                func(delta);
            }
        };
    return {
        /**
         * Stop the loop
         */
        stop: function () {
            iFrame.cancelAnimationFrame(rafId);
            rafId = false;
            // TODO Diego scrollModifier needed?
            //scrollModifier = 0;
        },

        /**
         * Start the loop
         */
        start: function () {
            if (rafId === false) {
                tick();
            }
        },

        /**
         * Toggle the loop
         */
        toggle: function () {
            if (rafId === false) {
                this.start();
            } else {
                this.stop();
            }
        },

        /**
         * Set fps
         * @param fps int how many fps per second
         */
        setFps: function (fps) {
            interval = 1000 / fps;
        },

        /**
         * Returns the interval in milliseconds.
         * @returns {number}
         */
        getInterval: function () {
            return interval;
        }
    };
}

/**
 * Triggers callback once Element is in the View, if callback gives back true, the observer is not removed.
 *
 * @param element element A valid HTML Element
 * @param callback function A js function to be executed
 */
export function respondToVisibility(element, callback) {
    "use strict";
    let observer = new IntersectionObserver(function (entries, observer) {
        if (entries[0].isIntersecting) {
            let re = callback();
            //console.log(re);
            if (re !== true) {
                //console.log('done');
                observer.unobserve(element);
            }
        }
    }, {
        root: document.documentElement
    });
    observer.observe(element);
}


/**
 * Returns a parameter by name
 *
 * @link http://stackoverflow.com/a/901144/5542121
 * @param name string The parameter to retrieve
 * @param url string The Url to process
 * @returns {string|null}
 */
export function getParameterByName(name, url) {
    "use strict";
    url = url || window.location.href;
    url = url.toLowerCase(); // This is just to avoid case sensitiveness
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();// This is just to avoid case sensitiveness for query parameter name
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Returns the ScrollTop of current window - e.g. position how far has been scrolled.
 *
 * @param window
 * @returns {number}
 */
export function getScrollTop(window)
{
    let scrOfY = 0;
    if (typeof window.pageYOffset === "number") {
        //Netscape compliant
        scrOfY = window.pageYOffset;
    } else if (window.document.body && window.document.body.scrollTop) {
        //DOM compliant
        scrOfY = window.document.body.scrollTop;
    } else if (window.document.documentElement && window.document.documentElement.scrollTop) {
        //IE6 standards compliant mode
        scrOfY = window.document.documentElement.scrollTop;
    }
    return scrOfY;
}

/**
 * Creates a object which fills the window (iframe), and the object can trigger resize changes.
 *
 * We need to arrange for self.scrollbar.update to be called whenever the DOM is changed resulting in a size-change
 * for our div. To make this happen, we use a technique described here:
 * http://www.backalleycoder.com/2013/03/18/cross-browser-event-based-element-resize-detection/.
 *
 * The idea is that we create an <object> element in our div, which we arrange to have the same size as that div,
 * this is done in a separate CSS file. The <object> element contains a Window object, to which we can attach an
 * onresize handler.
 *
 * (React appears to get very confused by the object (we end up with Chrome windows which only show half of the
 * text they are supposed to), so we always do this manually.)
 * @deprecated
 * @param window The main window object
 * @param iframeWindow The Iframe window object
 * @param updateScrollbarHandle The update handler
 * @param updateDragDrop  Update handler to define the autoscroll area
 * @param updateToolbarCentering Update handler to recenter the toolbar
 */
export function createResizeTrigger(window, iframeWindow, updateScrollbarHandle, updateDragDrop, updateToolbarCentering) {
    let obj = iframeWindow.document.createElement("object"),
        agent = navigator.userAgent.toLowerCase(),
        isIE = agent.indexOf("msie") !== -1 || agent.indexOf("trident") !== -1 || agent.indexOf(" edge/") !== -1;


    obj.classList.add("iframe-resize-trigger");
    obj.type = "text/html";
    obj.setAttribute("tabindex", "-1");
    obj.onload = function () {
        let win = obj.contentDocument.defaultView;
        // Resize trigger for the inner content of the iframe
        win.addEventListener("resize", updateScrollbarHandle, {passive: true});
        win.addEventListener("resize", updateDragDrop, {passive: true});
    };

    //IE: Does not like that this happens before, even if it is also added after.
    if (!isIE) {
        obj.data = "about:blank";
    }

    iframeWindow.document.body.appendChild(obj);

    //IE: This must occur after adding the object to the DOM.
    if (isIE) {
        obj.data = "about:blank";
    }
}


/**
 * Disables default action, useful to disable selection on drag&drop
 *
 * @param event
 * @returns {boolean}
 */
export function disableSelection(event) {
    event.preventDefault();
    return false;
}

/**
 * Triggers custom event, in the namespace of the element
 * Element can be from main window or from iframe, we create the CustomEvent based on the elements window.
 * DefaultView returns the window.
 *
 * @param name {string} The event name
 * @param element {HTMLElement|jQuery} The HTML element on which the event shall be triggered
 * @param data {object} The detail data to be passed with the event
 * @param bubbles {boolean} Shall the event bubble up
 * @param cancelable {boolean} Can the even be canceled
 */
export function triggerEvent(name, element = document.body, data = {}, bubbles = true, cancelable = false)  {
    //if (element instanceof jQuery) {
    // This is not reliable, as we get have 2 jQuery instances, one from main window and another from iframe.
    // So we check for "jquery" property which contains the version.
    if (element.jquery) {
        element = element.get(0);
    }

    //data.element = element;
    element.dispatchEvent(new element.ownerDocument.defaultView.CustomEvent(name, {
        bubbles: bubbles,
        cancelable: cancelable,
        detail: data
    }));
}