<!--
This is the same as MediaForm.php, just as a Vue Single-File-Component
-->

<template>
    <div ref="mediaformwidget" class="mediaformwidget onecolumn my-3">
        <label class="small-headline">{{ mediaForm.label }}</label>
        <div :class="{'empty': widgetOptions.medias.length <= 0}" class="medialist_wrapper">
            <ul v-if="medias[mediaForm.key].length === 0" :data-attribute="widgetOptions.attributeName" :data-maxfiles="widgetOptions.maxFiles" :data-model="widgetOptions.modelName" class="medialist" data-disable-jqueryui-sort="1" data-update-by-event="1"></ul>
            <draggable v-else v-model="mutableMedias[mediaForm.key]" :component-data="getComponentData()" draggable=".draggable" tag="ul">
                <MediaFormItem v-for="(media,index) in mutableMedias[mediaForm.key]" :key="index"
                    :class="{draggable: !readonly}"
                    :editable-fields="mediaForm.editableFields"
                    :identifier="identifier"
                    :index="index + offset"
                    :item-index="itemIndex"
                    :media="media"
                    :widget="widgetOptions"
                    @update="update"
                ></MediaFormItem>
            </draggable>
        </div>
        <div class="mediaform_wrapper">
            <div :class="{'disabled': widgetOptions.uploadDisabled}" class="mm-dropzone">
                <div class="dropzone-overlay"></div>
                <div class="badge badge-auto badge-alternate-primary">
                    <span>Dateien hier ablegen</span>
                </div>
            </div>
            <div class="mediaform_dropzone">
                <input :id="uploadInputId" :class="{'disabled': (widgetOptions.uploadDisabled || widgetOptions.readonly)}" :data-attribute="uploadInputId" :disabled="widgetOptions.uploadDisabled"
                       :form="widgetOptions.dummyFormName"
                       class="mediaform_upload_input uploadFile" multiple
                       name="Media" type="file">
                <label :for="uploadInputId">
                    <div class="upload_media_icon">
                        <i class="far fa-plus"></i>
                    </div>
                    <div class="upload_media_title">
                        <span>Dateien hinzufügen</span>
                        <span>Oder einen Ordner auswählen</span>
                    </div>
                </label>
            </div>
            <div :class="{'disabled': widgetOptions.readonly}" class="loadMediaManager btn btn-primary">Mediamanager</div>
        </div>
    </div>
</template>
<script>
    import MediaFormItem from './MediaFormItem.vue';
    import draggable from "vuedraggable";

    export default {
        components: {
            MediaFormItem,
            draggable
        },
        props: {
            'widget': Object,
            'offset': Number,
            'readonly': Boolean,
            'itemIndex': Number,
            'medias' : Object,
            'mediaForm': Object,
            'identifier': String,
            'disableEditableFields': Boolean
        },
        data(){
            return{
                mutableMedias: this.medias,
                widgetDefaultOptions: {
                    withPointer: false,
                    editable: !this.disableEditableFields,
                    dummyFormName: null,
                    uploadDisabled: this.readonly,
                    readonly: this.readonly,
                },
            }
        },
        computed:{
            uploadInputId(){
                return 'file_input_' + this.identifier +  '_' + this.widgetOptions.attributeName + '_' + this.itemIndex;
            },
            widgetOptions(){
                return Object.assign(this.widgetDefaultOptions, this.widget)
            }
        },
        methods: {
            update() {
                $(this.$refs.mediaformwidget).trigger('mediamanager:change');
            },
            getComponentData() {
                return {
                    attrs: {
                        class: "medialist",
                        "data-maxfiles": this.widgetOptions.maxFiles,
                        "data-disable-jqueryui-sort": 1,
                        "data-update-by-event": 1,
                        "data-model": this.widgetOptions.modelName,
                        "data-attribute": this.widgetOptions.attributeName,
                    },
                    on: {
                        end: (event) => {
                            this.$emit('media-sort', this.mediaForm, this.mutableMedias, event.newIndex, event.oldIndex);
                            return true;
                        }
                    }
                };
            },
        },
        mounted(){
            let vm = this;

            // ugly hacks to pass input change events to the parent vue components
            // (because those containers are cloned and appended via external vue, not handled through vue)
            $(vm.$el).find('.medialist').on('input change', '.modelmedia_edit input', (e)=>{
                vm.$emit('inputChanged',{ index: $(e.target).closest('li').attr('data-sort-index'), attr: e.target.dataset.name, value: e.target.value });
                return false;
            });

            // init file upload
            window.initFileUpload('#'+this.uploadInputId);

            $(this.$refs.mediaformwidget).on('mediamanager:data', function(event, data) {
                console.log('add')
                vm.$emit('media-add', vm.mediaForm, data.file_id, data);
            });

            $(this.$refs.mediaformwidget).on('mediamanager:update', function(event, data) {
                console.log('update')
                let index = undefined;
                let li = event.target.querySelectorAll('li');
                for (let i = 0; i < li.length; i++) {
                    if (li[i].dataset.fileId == data.file_id_before) {
                        index = i;
                        break;
                    }
                }
                vm.$emit('media-update', vm.mediaForm, data.media_id, data, vm.offset, index);
            });

            $(this.$refs.mediaformwidget).on('mediamanager:remove', function(event, data) {
                console.log('remove')
                vm.$emit('media-remove', vm.mediaForm, data);
            });
        },
        watch: {
            medias(val) {
                this.mutableMedias = val;
            }
        }
    }
</script>