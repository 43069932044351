var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "unsplashContainer" }, [
    _c("div", { staticClass: "unsplashOverlay" }),
    _vm._v(" "),
    _c("div", { staticClass: "unsplashBox" }, [
      _c("div", { staticClass: "unsplashHeader" }, [
        _c("div", { staticClass: "unsplashInputWrapper" }, [
          _c("form", { staticClass: "unsplashForm" }, [
            _c("i", {
              staticClass: "fa fa-search",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchterm,
                  expression: "searchterm",
                },
              ],
              staticClass: "unsplashSearchField",
              attrs: {
                type: "text",
                placeholder: "Search free high-resolution photos",
              },
              domProps: { value: _vm.searchterm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchterm = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "unsplashClose" }, [
          _c(
            "svg",
            {
              staticStyle: { "enable-background": "new 0 0 180.607 180.607" },
              attrs: {
                version: "1.1",
                id: "Capa_1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 180.607 180.607",
                "xml:space": "preserve",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M180.607,10.607l-79.696,79.697l79.696,79.697L170,180.607l-79.696-79.696l-79.696,79.696L0,170.001l79.696-79.697L0,10.607\n                          L10.607,0.001l79.696,79.696L170,0.001L180.607,10.607z",
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "unsplashBoxInner" },
        [
          _vm._l(_vm.imagelist.lists, function (list, index) {
            return _c(
              "div",
              { staticClass: "unsplashResults normal fadeInUp" },
              [
                _vm._l(list.images, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass: "unsplashItem normal fadeIn",
                      attrs: {
                        "data-url": /*item.customizedUrl*/ item.urls.full,
                      },
                    },
                    [
                      _c("img", { attrs: { src: item.urls.small } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "unsplashname" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                item.user.links.html +
                                "?utm_source=fgtest&utm_medium=referral&utm_campaign=api-credit",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item.user.first_name) +
                                " " +
                                _vm._s(item.user.last_name)
                            ),
                          ]
                        ),
                        _vm._v(" / "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "http://unsplash.com?utm_source=fgtest&utm_medium=referral&utm_campaign=api-credit",
                            },
                          },
                          [_vm._v("Unsplash")]
                        ),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "unsplashPage animated fadeInUp",
                    staticStyle: { display: "none" },
                  },
                  [_vm._v("\n          " + _vm._s(index + 1) + "\n        ")]
                ),
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "unsplashError" }, [
            _c("div", { staticClass: "unsplahErrorText" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.i18n_text.errortext) + "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "unsplashNoresults" }, [
            _vm._v("\n        " + _vm._s(_vm.i18n_text.noresults) + "\n      "),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "unsplashFooter normal slideInUp" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("button", { staticClass: "unsplashDownload" }, [
            _vm._v(_vm._s(_vm.i18n_text.download)),
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "unsplashCancel" }, [
            _vm._v(_vm._s(_vm.i18n_text.cancel)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unsplashLoading" }, [
      _c("div", { staticClass: "sk-double-bounce" }, [
        _c("div", { staticClass: "sk-child sk-double-bounce1" }),
        _vm._v(" "),
        _c("div", { staticClass: "sk-child sk-double-bounce2" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unsplashprogress" }, [
      _c("div", { staticClass: "progressWrapper" }, [
        _c("div", { staticClass: "progressInner" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }