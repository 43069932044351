<template>
    <div>
        <div class="mb-5">
            <div class="h5 text-muted mb-3">
                {{ $translations['element.section'] }}
            </div>
            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-dependencies">
                {{ $translations['section.dependencies'] }}
            </header>

            <LogicSection
                :item="item"
                :logic-options="logicOptions"
                @add-rule="addRule"
                @remove-rule="removeRule"
                @enable-logic="enableLogic" />

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-advanced">
                {{ $translations['section.advanced'] }}
            </header>
            <section id="section-advanced" class="collapse">
                <form-input
                    id="htmloptions"
                    v-model="item.data.htmloptions"
                    :label="$translations['label.css-class']" />
                <form-input
                    id="settings"
                    v-model="item.data.settings"
                    :label="$translations['label.settings']" />
            </section>
            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-settings">
                {{ $translations['section.step_settings'] }}
            </header>
            <section id="section-settings" class="collapse">
                <form-input
                    id="ident"
                    v-model="item.data.ident"
                    :label="$translations['label.ident']" />
                <form-input
                    id="htmloptions"
                    v-model="item.data.title"
                    :label="$translations['label.title']" />
                <form-input
                    id="htmloptions"
                    v-model="item.data.buttonLabel"
                    :label="$translations['label.button_next_Step']" />
            </section>
        </div>

        <div>
            <FooterActions @save="save" />
        </div>
    </div>
</template>

<script>
import eventBus from "../../util/EventBus";
import FooterActions from "./parts/FooterActions.vue";

import formInput from "./formparts/textinput.vue";

import LogicSection from "./formparts/LogicSection.vue";

import previewMixin from '../../mixins/preview';

export default {
    name: "Section",
    components: {
        FooterActions,
        formInput,
        LogicSection
    },
    mixins: [previewMixin],
    props: {
        element: {
            type: Object
        },
        logicOptions: {
            type: Array
        }
    },
    data: function() {
        return {
            item: {
                data: {
                    view: '',
                    id: '',
                    buttontext: '',

                    // extended form
                    htmloptions: '',
                },
                logic: {}
            },
            stepOptions: []
        };
    },
    mounted() {
        // set step options
        for (let i = 3; i <= 10; i++) {
            this.stepOptions.push( { value: i, label: `${i}` } );
        }

        for (const key in this.element.data) {
            this.$set(this.item.data, key, this.element.data[key]);
        }

        this.$set(this.item, 'logic', this.element.data.logic);
    },
    methods: {
        save() {
            eventBus.$emit("saveElement", this.item);
        },
        enableLogic() {
            this.item.data.logic = {
                hide_show: "hide",
                and_or: "all",
                rules: [
                    {
                        source: this.logicOptions[0].id,
                        compare: "is",
                        value: this.logicOptions[0].answers[0].id
                    }
                ]
            };
        },
        addRule() {
            this.item.data.logic.rules.push({
                source: this.logicOptions[0].id,
                compare: "is",
                value: this.logicOptions[0].answers[0].id
            });
        },
        removeRule(index) {
            this.item.data.logic.rules.splice(index, 1);

            if (this.item.data.logic.rules.length === 0) {
                this.item.data.logic = {};
            }
        }
    },
};
</script>