<template>
    <!--<a v-if="!!options.link" :href="options.link" target="_blank">
        <img :class="options.alignment"
             :style="style"
             :src="options.src"
             :alt="options.alt"
             :data-modal_id="options.modal_id"
             @load="imgLoaded"
        >
    </a>-->
    <img :class="options.alignment"
         :style="style"
         :src="options.src"
         :alt="options.label"
         :data-modal_id="options.modal_id"
         @dblclick="handleDoubleClick"
         @load="imgLoaded"
    >
</template>
<script>
    import { ImageObject } from './ImageObject.js';

    export default {
        props: [
            // there are some props available
            // `node` is a Prosemirror Node Object
            // `updateAttrs` is a function to update attributes defined in `schema`
            // `view` is the ProseMirror view instance
            // `options` is an array of your extension options
            // `selected`
            'node', 'updateAttrs', 'view',
            'attrs',
        ],
        data() {
            return {

            }
        },
        computed: {
            options() {
                return this.attrs || this.node.attrs;
            },
            style() {
                return ImageObject.calcStyle(this.options);
            },
            isPreview() {
                return !!this.attrs;    // if attrs prop is set, its the preview image in the modal, else its the node in the texteditor
            }
        },
        methods:{
            imgLoaded(e) {
                this.$emit('load',e.target);
            },
            handleDoubleClick(e) {
                let vm = this;
                if (vm.isPreview || !vm.updateAttrs) {
                    return false;
                }
                $('#'+vm.options.modal_id).off('imagenode:update').on('imagenode:update',(e)=>{
                    vm.updateAttrs(e.detail);
                }).modal('show');
            }
        },
    }
</script>
<style scoped lang="scss">
    img{
        height: auto;
        max-width: 100%;
        border: none;

        &.inline_top{
            display: inline;
            vertical-align: top;
        }
        &.inline_center{
            display: inline;
            vertical-align: middle;
        }
        &.inline_bottom{
            display: inline;
            vertical-align: bottom;
        }
        &.block_left{
            display: block;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
        &.block_center{
            display: block;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        &.block_right{
            display: block;
            margin-left: auto !important;
            margin-right: 0 !important;
        }
        &.float_left{
            display: block;
            float: left;
        }
        &.float_right{
            display: block;
            float: right;
        }
    }
</style>