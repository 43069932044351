var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("Notification"),
        _vm._v(" "),
        _c("div", { staticClass: "mb-5" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.saveForm } },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$translations["button.saveform"]) +
                  "\n        "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("DropArea", { attrs: { structure: _vm.structure } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar" }, [
      !_vm.active
        ? _c(
            "div",
            { staticClass: "sidebar-inner" },
            [
              _c("SidebarFormSettings", {
                attrs: {
                  "csrf-token": _vm.csrfToken,
                  "email-url": _vm.emailUrl,
                  "form-id": _vm.formId,
                  ident: _vm.formIdent,
                  name: _vm.formName,
                },
                on: {
                  "update-formname": _vm.updateFormname,
                  "update-formident": _vm.updateFormIdent,
                },
              }),
              _vm._v(" "),
              _c("SidebarElements", { attrs: { structure: _vm.structure } }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "sidebar-inner p-2" },
            [
              _c(_vm.activeCapitalized, {
                tag: "component",
                attrs: {
                  element: _vm.active,
                  "logic-options": _vm.logicOptions,
                },
              }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }