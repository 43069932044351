<template>
    <div class="form-group label-inside">

        <!-- Headline -->
        <h6
            v-if="field.type === 'headline'"
            :id="getFieldId('headline')"
            class="heading"
            v-text="field.label"
        ></h6>

        <!-- Text -->
        <input
            v-if="field.type == 'text'"
            :id="getFieldId('input')"
            v-model="content"
            :readonly="readonly"
            class="form-control pageeditor-ignore-updatestructure"
            type="text"
            @input="handleInput"
        >
        <label
            v-if="field.type === 'text'"
            :for="getFieldId('input')"
            v-text="field.label"
        ></label>

        <!-- Checkbox -->
        <div
            v-if="field.type === 'checkbox'"
            class="custom-control custom-checkbox"
        >
            <input
                :id="getFieldId('checkbox')"
                v-model="content"
                :readonly="readonly"
                class="custom-control-input pageeditor-ignore-updatestructure"
                type="checkbox"
                @change="handleInput"
            >
            <label
                :for="getFieldId('checkbox')"
                class="custom-control-label"
                v-text="field.label"
            ></label>
        </div>

        <!-- Textarea -->
        <textarea
            v-if="field.type == 'textarea'"
            :id="getFieldId('textarea')"
            v-model="content"
            :readonly="readonly"
            class="form-control pageeditor-ignore-updatestructure"
            type="textarea"
            @input="handleInput"
        ></textarea>
        <label
            v-if="field.type === 'textarea'"
            :for="getFieldId('textarea')"
            v-text="field.label"
        ></label>

        <!-- Select -->
        <select
            v-if="field.type == 'select'"
            :id="getFieldId('select')"
            v-model="content"
            :readonly="readonly"
            class="form-control pageeditor-ignore-updatestructure"
            type="select"
            @change="handleInput"
        >
            <option
                v-for="(option, index) in field.options"
                :key="index"
                :value="option.value"
                v-text="option.text"
            ></option>
        </select>
        <label
            v-if="field.type === 'select'"
            :for="getFieldId('select')"
            v-text="field.label"
        ></label>

        <!-- TipTap -->
        <tip-tap
            v-if="field.type === 'tiptap'"
            :hide-from-page-editor="true"
            :init-content="content"
            :textarea-id="getFieldId('tabeditor')"
            :textarea-name="getFieldId('tabeditor')"
            :tools="[]"
            @editor-update="(content) => tiptapUpdate(content)"
        />
    </div>
</template>

<script>

import TipTap from '/vendor/firegroup/fire-widget-tiptap/src/tiptap/tiptap.vue';

export default {
    name: 'AccordionField',
    components: {
        'tip-tap': TipTap
    },
    props: {
        field: {
            type: Object
        },
        item: {
            type: Object
        },
        index: {
            type: Number
        },
        readonly: {
            type: Boolean
        },
        value: {
            type: [String, Boolean]
        },
        identifier: {
            type: String
        }
    },
    data: function() {
        return {
            content: this.value
        };
    },
    methods: {
        getFieldId(prefix) {
            return `${prefix ? prefix : 'formitem'}-${this.identifier}-${this.index}-${this.item.id}`
        },
        handleInput() {
            this.$emit('input', this.content);
        },
        tiptapUpdate(value) {
            this.content = value;
            this.handleInput();
        },
    }
}
</script>

<style lang="scss" scoped>

.heading {
    font-size: 0.8em;
    font-weight: 600;
    margin: 1em 0 0;
}
</style>