var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ScreensContainer, {
    attrs: { "light-is-on": _vm.lightIsOn, "bg-glow-color": _vm.bgGlowColor },
    scopedSlots: _vm._u([
      {
        key: "left_screen",
        fn: function () {
          return [
            _c(_setup.ImageViewer, {
              attrs: {
                src: _setup.getAssetPathOfTargetAttr(
                  _setup.getLeftScreenImageAttribute,
                  _vm.content
                ),
                "has-kenburns-effect":
                  _setup.props.content.showroomEffect === "kenburns",
              },
            }),
            _vm._v(" "),
            _setup.props.activeTab === "Products"
              ? _c(_setup.DetailBoxBlurred, {
                  attrs: {
                    caption: "Topic",
                    title: _vm.content.title,
                    subtitle: "",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "middle_screen",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "topic-middle-screen-wrapper" },
              [
                _setup.props.activeTab === "Detail"
                  ? _c(_setup.TopicsDetail, { attrs: { content: _vm.content } })
                  : _vm._e(),
                _vm._v(" "),
                _setup.props.activeTab === "Products"
                  ? _c(_setup.ProductsMoodGrid, {
                      attrs: { content: _setup.props.content },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _setup.props.activeTab === "Video"
                  ? _c(_setup.VideoPlayer, {
                      attrs: {
                        content: _setup.props.content,
                        loop: true,
                        muted: _setup.muteState,
                        "fill-screen": true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "right_screen",
        fn: function () {
          return [
            _c(_setup.ImageViewer, {
              attrs: {
                src: _setup.getAssetPathOfTargetAttr(
                  _setup.getRightScreenImageAttribute,
                  _vm.content
                ),
                "has-kenburns-effect":
                  _setup.props.content.showroomEffect === "kenburns",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }