<template>
    <ScreensContainer :light-is-on="lightIsOn" :bg-glow-color="bgGlowColor">
        <template v-slot:left_screen>
            <ImageViewer :src="getAssetPathOfTargetAttr('left_screen', content)" :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
        </template>

        <template v-slot:middle_screen>
            <div class="look-middle-screen-wrapper">
                <ProductsMoodGrid v-if="props.activeTab === 'Detail'" :content="props.content" :show-details-manually="true"/>
                <VideoPlayer v-else-if="props.activeTab === 'Video'" :content="props.content" :loop="true" :muted="muteState" :fill-screen="true"/>
            </div>
        </template>

        <template v-slot:right_screen>
            <ImageViewer :src="getAssetPathOfTargetAttr('right_screen', props.content)" :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
        </template>
    </ScreensContainer>
</template>

<script setup>
import {defineProps, computed, ref, watch, onUnmounted, defineEmits, nextTick, onMounted} from 'vue'
import {getAssetPathOfTargetAttr} from '../../js/helpers'
import ImageViewer from "../../components/ImageViewer.vue";
import VideoPlayer from "../../components/VideoPlayer.vue";
import ScreensContainer from "../../components/ScreensContainer.vue";
import ProductsMoodGrid from "../../components/ProductsMoodGrid/ProductsMoodGrid.vue";
import KenburnsImage from "../../components/KenburnsImage.vue";
import ImageLoader from "../../components/ImageLoader.vue";
import Intro from "./Intro.vue";
import {defaultProps} from "../../js/mixins";

const kenburns = ref(false)

const props = defineProps(defaultProps)

const muteState = computed(() => {
    return props.muted
})

</script>

<style scoped lang="scss">
.look-middle-screen-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
</style>