import eventBus from "../util/EventBus";

export default {
    methods: {
        updatePreviewData() {
            eventBus.$emit('previewupdate', this.item);
        }
    },
    watch: {
        item: {
            handler(){
                this.updatePreviewData();
            },
            deep: true
        }
    }
};