<template>
    <div v-if="items.length > 0" class="mood-board">
        <div
            id="grid"
            class="mood-grid"
            :class="{ 'less-items': items?.length === 3 }"
            :data-grid="dataGridAttr"
            :style="getGridStyle">
            <div
                v-for="(item, x) in items"
                :key="item.id"
                class="grid--item border-transparent"
                :style="`grid-area: i-${x + 1};`"
                :class="[
          'i-' + (x + 1),
              showDetails ? '--has-details' : '']">
                <div
                    v-if="showDetails"
                    class="image-container"
                    style="position: relative">
                    <BgImageLoader
                        :src="getAssetPathOfTargetAttr( 'image', item.productData)"
                        background-size="contain"/>
                </div>
                <BgImageLoader
                    v-else
                    :src="getAssetPathOfTargetAttr('image', item.productData)"
                    background-size="contain"/>
                <div
                    v-if="showDetails"
                    class="flex-grow-0">
                    <ProductDetails :show-image="false"
                                    has-info
                                    :item="item"
                                    zoom="small"
                                    :data-grid="dataGridAttr"
                                    :font-size="items.length < 4 ? 'large' : 'normal'"
                                    :margin-top="false"/>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="no-products-fallback">
        No items found
    </div>

</template>

<script setup>
import {defineProps, computed, onMounted} from 'vue'
import GridConstructor from './GridConstructor'
import {getAssetPathOfTargetAttr} from "../../js/helpers";
import {spliceItems} from './items'
import BgImageLoader from "./BgImageLoader.vue";
import ProductDetails from "./ProductDetails.vue";

const props = defineProps({
    content: {
        type: Object,
        default: {}
    },
    showDetailsManually: {
        type: Boolean
    }
})

const items = computed(() => {
    const filteredActiveProducts = props.content?.products.filter((product)=>{
        return product.productData.status === 1;
    })
    return spliceItems(filteredActiveProducts)
})

const showDetails = computed(()=>{
    return (props.content.showDetails === 1 && items.value.length <= 8) || (props.showDetailsManually && items.value.length <= 8)
})

const getGridStyle = computed(() => {
    let contentItems = items.value
    let grid

    if (props.state && props.state?.gridLayout == 'landscape') {
        grid = new GridConstructor().getLandscape()
    } else {
        grid = new GridConstructor().getLandscape()
    }
    if (contentItems && contentItems.length > 0) {
        return {'grid-template-areas': grid[contentItems.length]}
    }
    return {}
})


const dataGridAttr = computed(() => {
    const localItems = items.value
    if (localItems.length <= 3) {
        return 'large'
    } else {
        return 'medium'
    }
})

onMounted(() => {})

</script>

<style lang="scss" scoped>
.mood-board {
    width: 100%;
    height: 100%;
}

.mood-grid {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 4px;
    background: white;
    padding: 8px;
}

.transition--container {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container {
    flex-grow: 1;
}

.less-items {
    .image-container {
        flex-grow: 1;
        aspect-ratio: 1;
    }
}

.grid--item {
    background-color: #F5F5F5;
    position: relative;
    display: flex;
    &.--has-details {
        flex-direction: column;
        place-content: center;

        .bg--image {
            background-position: bottom;
        }
    }

    .bg--image {
        background-repeat: no-repeat;
        background-position: center;
    }
}

.no-products-fallback {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    font-family: Inter-Bold;
}

</style>