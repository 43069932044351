var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.logicOptions.length === 0
      ? _c("div", { staticClass: "alert alert-primary" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$translations["logic.hint"]) + "\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }