<template>
    <div>
        <div class="mb-5">
            <div class="h5 text-muted mb-3">
                {{ $translations['element.instruction'] }}
            </div>
            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-content">
                {{ $translations['section.content'] }}
            </header>
            <section id="section-content" class="collapse">
                <form-input
                    id="question"
                    v-model="item.data.question"
                    :label="$translations['label.label']" />

                <form-textarea
                    id="description"
                    v-model="item.data.description"
                    :label="$translations['label.description']" />
            </section>

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-dependencies">
                {{ $translations['section.dependencies'] }}
            </header>

            <LogicSection
                :item="item"
                :logic-options="logicOptions"
                @add-rule="addRule"
                @remove-rule="removeRule"
                @enable-logic="enableLogic" />

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-advanced">
                {{ $translations['section.advanced'] }}
            </header>
            <section id="section-advanced" class="collapse">
                <form-input
                    id="htmloptions"
                    v-model="item.data.htmloptions"
                    :label="$translations['label.css-class']" />
            </section>
        </div>

        <div>
            <FooterActions @save="save" />
        </div>
    </div>
</template>

<script>
import eventBus from "../../util/EventBus";
import FooterActions from "./parts/FooterActions.vue";

import formInput from "./formparts/textinput.vue";
import formTextarea from "./formparts/textarea.vue";

import LogicSection from "./formparts/LogicSection.vue";

import logicMixin from '../../mixins/logic';
import previewMixin from '../../mixins/preview';

export default {
    name: "Instruction",
    components: {
        FooterActions,
        formInput,
        formTextarea,
        LogicSection
    },
    mixins: [logicMixin, previewMixin],
    props: {
        element: {
            type: Object
        }
    },
    data: function() {
        return {
            item: {
                data: {
                    view: '',
                    id: '',
                    question: '',
                    description: '',
                    // extended form
                    htmloptions: '',
                },
                logic: {}
            }
        };
    },
    mounted() {
        for (const key in this.element.items[0].data) {
            this.$set(this.item.data, key, this.element.items[0].data[key]);
        }

        this.$set(this.item, 'logic', this.element.items[0].logic);
    },
    methods: {
        save() {
            eventBus.$emit("saveElement", this.item);
        }
    }
};
</script>