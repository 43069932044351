<template>
    <li :class="classes"
        role="treeitem">
        <div :class="itemClasses"
             class="treeview-node__item"
        >
            <div class="form-group">
                <div :class="{'is-intermediate': isIntermediate}"
                     class="custom-control custom-checkbox"
                >
                    <input
                        :id="`checkbox_${data.id}`"
                        :checked="isChecked"
                        :indeterminate.prop="isIntermediate"
                        :value="data.id"
                        class="custom-control-input"
                        type="checkbox" @change="handleItemToggle"
                    >
                    <label :for="`checkbox_${data.id}`" class="custom-control-label">&nbsp;</label>
                </div>
            </div>
            <div :style="spanStyle" class="item-content" @click="handleItemClick">
                <div class="leaf-handler"><i v-if="getLeafIcon" :class="getLeafIcon"></i></div>
                <div class="label">{{ getLabel }}</div>
            </div>
        </div>
        <ul v-if="hasChildren && isOpen" ref="group" class="tree-children" role="group">
            <TreeviewNode
                v-for="item in data.items"
                :key="item.id"
                v-model="value"
                :data="item"
                :depth="+(depth + 1)"
                :disable-inheritance="disableInheritance"
                :item-disabled="itemDisabled"
                :item-key="itemKey"
                :item-text="itemText"
                :leaf-icon-close="leafIconClose"
                :leaf-icon-open="leafIconOpen"
            ></TreeviewNode>
        </ul>
    </li>
</template>

<script>
import { eventBus } from './helper/EventBus';
import { getAllChildren } from "../assets/js/helpers";

export default {
    name: 'TreeviewNode',
    props: {
        activeClass: {
            type: String
        },
        data: {
            type: Object
        },
        depth: {
            type: Number,
            defalt: 0
        },
        itemDisabled: {
            type: String
        },
        itemKey: {
            type: String
        },
        itemText: {
            type: String
        },
        leafIconOpen: {
            type: String
        },
        leafIconClose: {
            type: String
        },
        value: {
            tpye: Array
        },
        disableInheritance: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        indeterminate: function (el, binding) {
            el.indeterminate = Boolean(binding.value);
        }
    },
    data: () => ({
        isOpen: false,
        indeterminate: true
    }),
    computed: {
        classes() {
            return {
                'treeview-node': true,
                'treeview-node--leaf': this.hasChildren,
                'treeview-node--open': this.isOpen,
            }
        },
        itemClasses() {
            return {
                'treeview-node--active': this.isChecked,
                'treeview-node--disabled': false
            }
        },
        getLeafIcon() {
            if (this.hasChildren) {
                return this.isOpen ? this.leafIconClose : this.leafIconOpen
            }
            return false
        },
        getLabel() {
            return this.data[this.itemText] || '-'
        },
        hasChildren() {
            return !!this.data.items && this.data.items.length
        },
        spanStyle() {
            return {'paddingLeft': `${+(this.depth * 10)}px`}
        },
        isChecked() {
            // cast to string to check string against string
            return this.value.includes(this.data[this.itemKey] +'')
        },
        isIntermediate() {
            if (this.isChecked && !this.disableInheritance) {
                let children = getAllChildren(this.data.items)
                let selected = this.value
                let check = children.every(r => selected.indexOf(r) >= 0)
                return check === false ? true : ''
            }
            return ''
        }
    },
    methods: {
        handleItemToggle(e) {
            const checked = e.target.checked
            eventBus.$emit('toggleItem', {
                id: this.data[this.itemKey] +'',
                node: this.data.items,
                state: checked
            })
        },
        handleItemClick() {
            if (this.hasChildren) {
                this.isOpen = !this.isOpen
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.treeview-node {
    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            background-color: #FAFBFB;
        }
    }

    &--active {
        background-color: rgba(#44909E, 0.05);
    }
}

.form-group {
    width: 2.2rem;
    margin-bottom: 0;
    margin-right: 0;

    .custom-control-label {
        padding: 0 !important;
    }
}

.item-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;
    cursor: pointer;
}

.leaf-handler {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    line-height: 1.8rem;
    font-size: 0.8rem;
    font-weight: 400;
}

.custom-control.custom-checkbox {
    max-width: auto;
}

span {
    display: block;
}
</style>