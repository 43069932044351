<script>
export default {
    props: {
        /** The rules to filter for, along with there type */
        rules: {
            required: true,
            type: Object
        },
        /** The translated labels */
        labels: {
            required: true,
            type: Object
        },
        /** The value */
        value: {
            required: true,
            type: Object
        }
    },
    computed: {
        showContent: {
            get() {
                return this.value.operator !== "is_null" && this.value.operator !== "is_not_null"
            }
        },
        showContent2: {
            get() {
                return this.value.operator === "between"
            }
        },
        attribute: {
            get() {
                return this.value.attribute
            }
        },
        operator: {
            get() {
                return this.value.operator
            },
            set(value) {
                this.value.operator = value
                this.$emit("input", this.value)
            }
        },
        content: {
            get() {
                return this.value.value
            },
            set(value) {
                this.value.value = value
                this.$emit("input", this.value)
            }
        },
        content2: {
            get() {
                return this.value.value2
            },
            set(value) {
                this.value.value2 = value
                this.$emit("input", this.value)
            }
        }
    }
}
</script>