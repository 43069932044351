import { render, staticRenderFns } from "./CheckboxSwitch.vue?vue&type=template&id=af63a62a&scoped=true"
import script from "./CheckboxSwitch.vue?vue&type=script&lang=js"
export * from "./CheckboxSwitch.vue?vue&type=script&lang=js"
import style0 from "./CheckboxSwitch.vue?vue&type=style&index=0&id=af63a62a&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af63a62a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\brinkmann-b2c-shop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('af63a62a')) {
      api.createRecord('af63a62a', component.options)
    } else {
      api.reload('af63a62a', component.options)
    }
    module.hot.accept("./CheckboxSwitch.vue?vue&type=template&id=af63a62a&scoped=true", function () {
      api.rerender('af63a62a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "vendor/firegroup/fire-module-segment/src/backend/assets/js/components/CheckboxSwitch.vue"
export default component.exports