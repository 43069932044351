<template>
    <div :class="{hidden: hidden}">
        <template v-if="selection.length === 0">
            <loading-element
                v-if="loading"
                :center="true"
                :absolute="false"
                :z-index="100" />
            <div v-else class="selection__empty">
                <div class="selection__empty__inner">
                    <input type="hidden" :name="inputNamePrefix" v-if="selection.length === 0" />
                    <h2>{{ translations.create_selection }}</h2>
                    <p>{{ emptySelectionText }}</p>
                    <a class="btn btn-primary" href="javascript:void(0)" @click="$emit('go-to-product-catalog')">{{ translations.to_catalogue }}</a>
                </div>
            </div>
        </template>
        <div v-else>
            <!--div class="search-form">
                <div class="search-form-header">
                    <span class="search-string-wrapper">
                        <input
                            type="text"
                            class="search-string"
                            :placeholder="searchPlaceholder"
                            @input="inputSearchQuery($event)">
                    </span>
                </div>
            </div -->

            <template v-if="!loading">
                <Table
                    :fields="fields"
                    :sort-by="sortBy"
                    :items="selection"
                    :translations="translations"
                    :sortable="true">
                    <template #default="slotProps">
                        <input
                            type="hidden"
                            :name="itemIdName(slotProps.item)"
                            :value="itemIdValue(slotProps.item)">
                        <input
                            type="hidden"
                            :name="sortOrderName(slotProps.item)"
                            :value="slotProps.order">
                        <input
                            type="hidden"
                            :name="isFavoriteName(slotProps.item)"
                            :value="isFavoriteValue(slotProps.item)">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-secondary"
                            @click="removeItem(slotProps.item)">{{ translations.remove }}</a>
                    </template>
                </Table>
            </template>
        </div>
    </div>
</template>

<script>
import LoadingElement from "./LoadingElement.vue";
import Table from "./Table.vue";

export default {
    name: 'Selection',
    components: {
        LoadingElement,
        Table
    },
    props: {
        api: {
            type: String
        },
        hidden: {
            type: Boolean
        },
        selection: {
            type: Array
        },
        fields: {
            type: Object
        },
        sortBy: {
            type: String
        },
        searchPlaceholder: {
            type: String
        },
        inputNamePrefix: {
            type: String
        },
        itemIdName: {
            type: Function
        },
        itemIdValue: {
            type: Function
        },
        sortOrderName: {
            type: Function
        },
        isFavoriteName: {
            type: Function
        },
        isFavoriteValue: {
            type: Function
        },
        emptySelectionText: {
            type: String
        },
        translations: {
            type: Object
        }
    },
    data: function() {
        return {
            searchQuery: '',
            json: null,
            loading: false
        };
    },
    computed: {
        selectionFiltered() {
            return this.selection.filter(selectionItem => selectionItem.productData.name.includes(this.searchQuery) || selectionItem.productData.sku.includes(this.searchQuery));
        }
    },
    mounted() {
        // this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;

            const body = {
                query: this.query,
                variables: {
                    limit: 10,
                    searchQuery: this.searchQuery
                }
            };

            if (this.sortBy !== undefined) {
                body.variables.sortBy = this.sortBy;
            }

            const headers = {
                'Content-Type': 'application/json'
            };
            headers['X-CSRF-Token'] = yii.getCsrfToken();

            fetch(this.api, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })
                .then(res => res.json())
                .then(res => {
                    this.json = res;
                    this.loading = false;
                });
        },
        inputSearchQuery(event) {
            if (event.target.value.length > 2 || event.target.value.length === 0) {
                this.searchQuery = event.target.value;

                // this.loadData();
            }
        },
        removeItem(item) {
            this.$emit('remove-item', item);
        }
    }
};
</script>

<style lang="scss" scoped>
.selection__empty {
    width: 100%;
    min-height: 400px;
    margin-top: 2rem;
    background-color: #FAFBFB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.selection__empty__inner {
    max-width: 450px;
    text-align: center;
}
h2 {
    font-size: 28px !important;
    line-height: 38px;
    color: #4C5E64;
    margin-bottom: 2.5rem;
}
p {
    color: #4C5E64;
    font-size: 20px;
    margin-bottom: 50px;
}
.hidden {
    display: none;
}
</style>

<style lang="scss" scoped>
@import "frontend/styles/framework";
@import "frontend/styles/global/loader";

.filter-bar {
    margin-top: 20px;

    .query {
        max-width: 320px;
    }
}

</style>