<template>
  <li :class="[{'disabled': widget.readonly}]" :data-media-id="(media.id || '')" :data-sort-index="index">
    <div class="modelmedia_row">
      <a class="preview" target="_blank"
         title="Vorschau">
        <img class="media_img" :src="(file.preview || '')" alt="">
      </a>
      <!-- :href="'/'+(file.uri || '')" -->
      <div class="file_name">
        {{ file.name || '' }}
      </div>
      <div class="media_actions">
        <a class="file-remove fontello-icon-ui-close"
           :class="{'disabled': widget.readonly}"
           title="Entfernen">
        </a>
        <a class="changeMediaButton loadMediaManager fontello-icon-menu-mediamanager"
           :class="{'disabled': widget.readonly}"
           title="Bild ersetzen>">
        </a>
        <a class="file-sort far fa-arrows"
           v-if="widget.maxFiles > 1"
           :class="{'disabled': widget.readonly}"
           title="Verschieben">
        </a>
        <a class="mediapointer far fa-bullseye"
           v-if="widget.withPointer"
           :class="{'disabled': widget.readonly}"
           title="Zeiger bearbeiten">
        </a>
        <a class="file-edit far fa-pencil"
           v-if="widget.editable"
           :class="{'disabled': widget.readonly}"
           title="Bearbeiten">
        </a>
      </div>
      <div class="break"></div>
      <div class="modelmedia_edit">
        <div v-for="(field,key) in fields" :key="key">
          <label v-if="field.label">{{ field.label }}</label>
          <input
              :type="(field.type || 'text')"
              :data-name="(field.name || key)"
              :name="`${namePrefix}[${(field.name || key)}]`"
              :id="`${widget.attributeName}_${(field.name || key)}_${(index<0 ? '': index)}`"
              :disabled="widget.readonly"
              :form="widget.dummyFormName ? widget.dummyFormName : null"
              :value="(media[key] || '')"
          >
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: [
    'media',
    'index',
    'widget',
  ],
  data() {
    return {
      fields: {
        id: {
          type: 'hidden',
          name: 'media_id',
        },
        file_id: {
          type: 'hidden',
        },
        file_name: {
          type: 'hidden',
        },
        category: {
          type: 'hidden',
        },
        label: {
          label: 'Titel',
        },
        sublabel: {
          label: 'Untertitel',
        },
        description: {
          label: 'Beschreibung',
        },
        link: {
          label: 'Link',
        },
        linktitle: {
          label: 'Linktext',
        },
      }
    }
  },
  computed: {
    namePrefix() {
      return `${this.widget.modelName}[medias][${this.widget.attributeName}][${this.index}]`;
    },
    file() {
      return this.media.file || {};
    },
  },
}
</script>
<style scoped lang="scss">

</style>