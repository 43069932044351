<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.button'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <a href="javascript:void(0)" class="btn btn-primary">{{ element.items[0].data.buttontext }}</a>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Button",
    mixins: [previewItemMixin],
};
</script>