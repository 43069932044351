<style lang="scss">
    @import "../sass/main";
</style>

<template>
    <div>
        <vue-tags-input
            v-model="tag"
            :tags="tags"
            :autocomplete-items="filteredItems"
            :placeholder="placeholder"
            @tags-changed="newTags => tags = newTags"
            @before-adding-tag="addTag"
            @before-deleting-tag="deleteTag"
        />
    </div>
</template>

<script>
   import VueTagsInput from '@johmun/vue-tags-input';

   export default {
      components: {
         VueTagsInput,
      },

      props: {
         elementId: {
            type: String,
            required: true
         },
         initModel: {
            type: String,
            required: false
         },
         initElement: {
            type: String,
            required: false
         },
         initPlaceholder: {
            type: String,
            required: false
         },
         checkedItems : {
            type: Array,
            default: () => []
         },
         initAutocompleteItems: {
            type: Array,
            default: () => []
         }
      },

      data() {
         return {
            tag: '',
            tags: [],
            model : '',
            element: '',
            fieldContainer : '',
            autocompleteItems: [],
            placeholder: 'Add Tag'
         };
      },
      methods: {
         /**
          * Add tag hidden field
          * @param obj
          */
         addTag(obj) {

            // Just add new tag if not already in autocomplete
            if(!this.tags.find(o => o.text === obj.tag.text) && !this.autocompleteItems.find(o => o.text === obj.tag.text)){
               // Insert tag and update hidden field
               this.addTagHttp(obj);
            } else {
               this.addHiddenTagField(obj.tag);
               obj.addTag();
            }
         },

         /**
          * Remove tag hidden field
          * @param obj
          */
         deleteTag(obj) {
            this.removeHiddenTagField(obj.tag);
            obj.deleteTag();
         },

         /**
          * Add new tag to tag to db
          * @param obj
          */
         addTagHttp(obj){
            let that = this;
            let http = new XMLHttpRequest();
            //let currentUrlParts = yii.getCurrentUrl().split('/');

            //todo: may adjust controller path to your add tag method
            let url = `add-tag`;

            let params = `${yii.getCsrfParam()}=${yii.getCsrfToken()}&${that.model}Tag[text]=${obj.tag.text}`;

            http.open('post', url, true);
            http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            http.setRequestHeader("X-Requested-With", "XMLHttpRequest");

            http.onreadystatechange = function() {//Call a function when the state changes.

               if(http.readyState == 4 && http.status == 200) {
                  let res = JSON.parse(http.responseText);

                  if(!res['errors'] && res[`${that.element.replace(/s+$/,'')}_id`]){

                     // Add tag id to item obj
                     obj.tag.id = res[`${that.element.replace(/s+$/,'')}_id`];

                     // Add tag to tag list
                     obj.addTag();

                     // Add tag to autocomplete items
                     if(that.autocompleteItems.find(o => o.text === obj.tag.text)){
                        that.autocompleteItems.push(obj.tag);
                     }

                     // Add hidden field for tag
                     that.addHiddenTagField({id: res[`${that.element.replace(/s+$/,'')}_id`]});

                  } else if(res['errors']){
                     console.error(res['errors']);
                  } else {
                     console.log(res);
                  }
               }
            };

            http.send(params);
         },

         /**
          * Add a hidden field to save this blog tag
          * @param obj
          */
         addHiddenTagField(obj) {
            let input = document.querySelector(`input[type="hidden"][name="${this.model}[${this.element}][]"][value="${obj.id}"]`);

            if(!input) {
               input = document.createElement('input');
               input.type = 'hidden';
               input.name = `${this.model}[${this.element}][]`;
               input.value = obj.id;
               this.fieldContainer.appendChild(input);
            }
         },

         /**
          * Remove hidden field for this blog tag
          * @param obj
          */
         removeHiddenTagField(obj) {
            let input = document.querySelector(`input[type="hidden"][name="${this.model}[${this.element}][]"][value="${obj.id}"]`);
            input.parentNode.removeChild(input);
         },
      },

      computed: {
         /**
          * Find autocomplete items
          */
         filteredItems() {
            return this.autocompleteItems.filter(i => {
               return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
         },
      },

      /**
       * Load tags to tag list and autocomplete
       * @param e
       */
      beforeCreate(e) {
         let splittedName = this.$options.propsData['elementId'].split('-');
         this.$options.propsData['initModel'] = splittedName[0].charAt(0).toUpperCase() + splittedName[0].slice(1);
         this.$options.propsData['initElement'] = splittedName[1];

         this.$options.propsData['initPlaceholder'] = document.querySelector(`[data-${this.$options.propsData['initElement']}-placeholder]`).getAttribute(`data-${this.$options.propsData['initElement']}-placeholder`);

         let checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${this.$options.propsData['initModel']}[${this.$options.propsData['initElement'] }][]"]`);

         for(let checkbox of checkboxes) {

            // Collect values
            let label = document.querySelector(`[for="${checkbox.id}"]`);
            let obj = {id: checkbox.value, text: label.textContent};
            // Add tag to autocomplete
            this.$options.propsData['initAutocompleteItems'].push(obj);

            // Add tag to tag list
            if(checkbox.checked) {
               this.$options.propsData['checkedItems'].push(obj);
            }
         }
      },

      /**
       * Assign init values to data
       */
      created() {

         this.model = this.$options.propsData['initModel'];
         this.element = this.$options.propsData['initElement'];

         this.fieldContainer = document.querySelector(`[class*="field-${this.model.toLowerCase()}-${this.element}"]`);

         this.placeholder = this.$options.propsData['initPlaceholder'];

         if(this.$options.propsData['initAutocompleteItems'].length > 0) {
            this.autocompleteItems = this.$options.propsData['initAutocompleteItems'];
         }

         if(this.$options.propsData['checkedItems'].length > 0) {
            this.tags = this.$options.propsData['checkedItems'];

            for(let item of this.$options.propsData['checkedItems']){
               this.addHiddenTagField(item);
            }
         }
      }
   };
</script>