<script>
import Swatches from 'vue-swatches'
//Todo: Make more flexible
// import {COLORS} from '../../../../../../backend/styles/constants.js';
//  console.log(COLORS);
export default {
  components: {Swatches},
  data() {
    return {
      color: '',
      colors: [],
      options: [],
      translations: [],
      colorNames: [
        'indianred',
        'lightcoral',
        'salmon',
        'darksalmon',
        'lightsalmon',
        'crimson',
        'red',
        'firebrick',
        'darkred',
        'pink',
        'lightpink',
        'hotpink',
        'deeppink',
        'mediumvioletred',
        'palevioletred',
        'lightsalmon',
        'coral',
        'tomato',
        'orangered',
        'darkorange',
        'orange',
        'gold',
        'yellow',
        'lightyellow',
        'lemonchiffon',
        'lightgoldenrodyellow',
        'papayawhip',
        'moccasin',
        'peachpuff',
        'palegoldenrod',
        'khaki',
        'darkkhaki',
        'lavender',
        'thistle',
        'plum',
        'violet',
        'orchid',
        'fuchsia',
        'magenta',
        'mediumorchid',
        'mediumpurple',
        'rebeccapurple',
        'blueviolet',
        'darkviolet',
        'darkorchid',
        'darkmagenta',
        'purple',
        'indigo',
        'slateblue',
        'darkslateblue',
        'mediumslateblue',
        'greenyellow',
        'chartreuse',
        'lawngreen',
        'lime',
        'limegreen',
        'palegreen',
        'lightgreen',
        'mediumspringgreen',
        'springgreen',
        'mediumseagreen',
        'seagreen',
        'forestgreen',
        'green',
        'darkgreen',
        'yellowgreen',
        'olivedrab',
        'olive',
        'darkolivegreen',
        'mediumaquamarine',
        'darkseagreen',
        'lightseagreen',
        'darkcyan',
        'teal',
        'aqua',
        'cyan',
        'lightcyan',
        'paleturquoise',
        'aquamarine',
        'turquoise',
        'mediumturquoise',
        'darkturquoise',
        'cadetblue',
        'steelblue',
        'lightsteelblue',
        'powderblue',
        'lightblue',
        'skyblue',
        'lightskyblue',
        'deepskyblue',
        'dodgerblue',
        'cornflowerblue',
        'mediumslateblue',
        'royalblue',
        'blue',
        'mediumblue',
        'darkblue',
        'navy',
        'midnightblue',
        'cornsilk',
        'blanchedalmond',
        'bisque',
        'navajowhite',
        'wheat',
        'burlywood',
        'tan',
        'rosybrown',
        'sandybrown',
        'goldenrod',
        'darkgoldenrod',
        'peru',
        'chocolate',
        'saddlebrown',
        'sienna',
        'brown',
        'maroon',
        'white',
        'snow',
        'honeydew',
        'mintcream',
        'azure',
        'aliceblue',
        'ghostwhite',
        'whitesmoke',
        'seashell',
        'beige',
        'oldlace',
        'floralwhite',
        'ivory',
        'antiquewhite',
        'linen',
        'lavenderblush',
        'mistyrose',
        'gainsboro',
        'lightgray',
        'silver',
        'darkgray',
        'gray',
        'dimgray',
        'lightslategray',
        'slategray',
        'darkslategray',
        'black'
      ]
    }
  },
  props: [
    'inputName',
    'modelName',
    'modelId',
    'modelClass',
    'attributeName',
    'inputValue',
    'inputOptions',
    'inputClass',
    'inputPlaceholder',
    'inputTranslations',
  ],

  created() {
    console.log(this.$props)
    // Parse value and options from widget
    this.color = this.$props.inputValue;
    this.options = typeof this.$props.inputOptions === 'object' ? this.$props.inputOptions : JSON.parse(this.$props.inputOptions);
    this.translations = typeof this.$props.inputTranslations === 'object' ? this.$props.inputTranslations : JSON.parse(this.$props.inputTranslations);

    // Overwrite colors with widget options and delete property from options to avoid render conflicts
    if (this.options['colors']) {
      this.colors = this.options['colors'];

      delete this.options['colors'];
    }

    if (this.options['class']) {
      delete this.options['class'];
    }

    if (!this.options['row-length']) {
      this.options['row-length'] = 15;
    }
  },
}
</script>