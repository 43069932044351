var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      class: {
        pointer: _vm.field.hasOwnProperty("sort"),
        active: _vm.isActive,
      },
      on: { click: _vm.sort },
    },
    [
      _vm._v("\n    " + _vm._s(_vm.field.label) + "\n    "),
      Object.prototype.hasOwnProperty.call(_vm.field, "sort") &&
      _vm.sortBy === _vm.field.sort.desc
        ? _c("i", { staticClass: "fas fa-angle-up" })
        : Object.prototype.hasOwnProperty.call(_vm.field, "sort")
        ? _c("i", { staticClass: "fas fa-angle-down" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }