var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "segment-rule" },
    [
      _c("i", {
        staticClass: "segment-rule-handler fa fa-fw fa-grip-vertical handle",
      }),
      _vm._v(" "),
      _c("v-select", {
        staticClass: "segment-rule-attribute",
        attrs: {
          filter: _vm.search,
          options: _vm.attributeOptions(),
          reduce: (rule) => rule.key,
          clearable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (option) {
              return [
                _c("span", [_vm._v(_vm._s(option.label))]),
                _vm._v(" "),
                option.code
                  ? _c("span", { staticClass: "small text-muted ml-1" }, [
                      _vm._v(_vm._s(option.code)),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "selected-option",
            fn: function (option) {
              return [
                _c("span", [_vm._v(_vm._s(option.label))]),
                _vm._v(" "),
                option.code
                  ? _c("span", { staticClass: "small text-muted ml-1" }, [
                      _vm._v(_vm._s(option.code)),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.attribute,
          callback: function ($$v) {
            _vm.attribute = $$v
          },
          expression: "attribute",
        },
      }),
      _vm._v(" "),
      _vm.rules[_vm.attribute]
        ? _c(_vm.rules[_vm.attribute].component + "-segment-rule", {
            tag: "component",
            staticClass: "segment-rule-component",
            attrs: {
              labels: _vm.labels,
              rules: _vm.rules,
              attribute: _vm.attribute,
            },
            model: {
              value: _vm.dataValue,
              callback: function ($$v) {
                _vm.dataValue = $$v
              },
              expression: "dataValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "segment-rule-remove",
          attrs: { type: "button" },
          on: { click: _vm.remove },
        },
        [_c("i", { staticClass: "fa fa-times" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }