var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.items.length > 0
    ? _c("div", { staticClass: "mood-board" }, [
        _c(
          "div",
          {
            staticClass: "mood-grid",
            class: { "less-items": _setup.items?.length === 3 },
            style: _setup.getGridStyle,
            attrs: { id: "grid", "data-grid": _setup.dataGridAttr },
          },
          _vm._l(_setup.items, function (item, x) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "grid--item border-transparent",
                class: [
                  "i-" + (x + 1),
                  _setup.showDetails ? "--has-details" : "",
                ],
                style: `grid-area: i-${x + 1};`,
              },
              [
                _setup.showDetails
                  ? _c(
                      "div",
                      {
                        staticClass: "image-container",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c(_setup.BgImageLoader, {
                          attrs: {
                            src: _setup.getAssetPathOfTargetAttr(
                              "image",
                              item.productData
                            ),
                            "background-size": "contain",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(_setup.BgImageLoader, {
                      attrs: {
                        src: _setup.getAssetPathOfTargetAttr(
                          "image",
                          item.productData
                        ),
                        "background-size": "contain",
                      },
                    }),
                _vm._v(" "),
                _setup.showDetails
                  ? _c(
                      "div",
                      { staticClass: "flex-grow-0" },
                      [
                        _c(_setup.ProductDetails, {
                          attrs: {
                            "show-image": false,
                            "has-info": "",
                            item: item,
                            zoom: "small",
                            "data-grid": _setup.dataGridAttr,
                            "font-size":
                              _setup.items.length < 4 ? "large" : "normal",
                            "margin-top": false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ])
    : _c("div", { staticClass: "no-products-fallback" }, [
        _vm._v("\n    No items found\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }