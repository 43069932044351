<template>
    <ScreensContainer :light-is-on="lightIsOn" :bg-glow-color="bgGlowColor">
        <template v-slot:left_screen>
            <ImageViewer v-if="content.leftView === 'view_left_image'" :src="getAssetPathOfTargetAttr('left_image', props.content)"
                         :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
            <DetailBoxBlurred v-if="props.content.leftView === 'view_left_image'" :caption="props.content.leftCaption" :title="content.leftTitle" :subtitle="props.content.leftSubtitle"/>
            <VideoPlayer v-if="props.content.leftView === 'view_left_video'" :content="props.content" :target-attr="'left_video'" :muted="props.content.leftMuted === 1 || muteState"
                         :loop="props.content.leftLoop === 1"/>
        </template>

        <template v-slot:middle_screen>
            <div class="intro-middle-screen-wrapper">
                <ImageViewer v-if="props.content.middleView === 'view_middle_image'" :src="getAssetPathOfTargetAttr('middle_image', content)"
                             :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
                <VideoPlayer v-if="props.content.middleView === 'view_middle_video'" :content="props.content" :target-attr="'middle_video'" :muted="props.content.middleMuted === 1 || muteState"
                             :loop="props.content.middleLoop === 1" :fill-screen="true"/>
            </div>
        </template>

        <template v-slot:right_screen>
            <ImageViewer v-if="props.content.rightView === 'view_right_image'" :src="getAssetPathOfTargetAttr('right_image', content)"
                         :has-kenburns-effect="props.content.showroomEffect === 'kenburns'"/>
            <DetailBoxBlurred v-if="props.content.rightView === 'view_right_image'" :caption="props.content.rightCaption" :title="props.content.rightTitle"
                              :subtitle="props.content.rightSubtitle"/>
            <VideoPlayer v-if="props.content.rightView === 'view_right_video'" :content="props.content" :target-attr="'right_video'" :muted="props.content.rightMuted === 1 || muteState"
                         :loop="props.content.leftLoop === 1"/>
        </template>
    </ScreensContainer>
</template>

<script setup>
import {defineProps, computed} from 'vue'
import VideoPlayer from "../../components/VideoPlayer.vue";
import ScreensContainer from "../../components/ScreensContainer.vue";
import {getAssetPathOfTargetAttr} from '../../js/helpers'
import ImageViewer from "../../components/ImageViewer.vue";
import DetailBoxBlurred from "../../components/DetailBoxBlurred.vue";
import {defaultProps} from "../../js/mixins";

const props = defineProps(defaultProps)

const muteState = computed(() => {
    return props.muted
})
</script>

<style scoped lang="scss">
.intro-middle-screen-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
</style>