import { Node } from 'tiptap';
import ImageNode from "./ImageNode.vue";
import { ImageObject } from "./ImageObject.js";

const _imageAttrs = new ImageObject().options;

export class MediamanagerNode extends Node {
    get name() {
        return 'mediamanager'
    }

    get schema() {
        return {
            inline: true,
            group: 'inline',
            draggable: true,
            attrs: _imageAttrs,
            parseDOM: [{
                tag: 'img[src]',
                getAttrs: function getAttrs(dom) {
                    return Object.assign(_imageAttrs,ImageObject.getAttrsFromEl(dom));
                }
            }],
            toDOM: (node) => {
                return ['img', {
                    src: node.attrs.src,
                    alt: node.attrs.alt,
                    style: ImageObject.calcInlineStyleString(node.attrs),
                    class: node.attrs.alignment,
                    'data-modal_id': node.attrs.modal_id,
                }];
            },
        }
    }

    commands({ type, schema, attrs }) {
        return attrs => (state, dispatch) => {
            const {selection} = state;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
        }
    }

    get view() {
        return ImageNode;
    }
}