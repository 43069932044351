var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "context-menu",
      attrs: { id: _vm.elementId },
    },
    _vm._l(_vm.options, function (option, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "context-menu__item",
          class: option.class,
          on: {
            click: function ($event) {
              return _vm.optionClicked(option)
            },
          },
        },
        [
          option.icon ? _c("icon", { attrs: { name: option.icon } }) : _vm._e(),
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(option.name))]),
          option.hint
            ? _c(
                "span",
                {
                  class: option.hint.class ? option.hint.class : "hint",
                  attrs: { title: option.hint.title },
                },
                [_vm._v(_vm._s(option.hint.text))]
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }