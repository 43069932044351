export default function getNodeAttrs(state, type) {
    const {from, to} = state.selection;
    let node = false;

    state.doc.nodesBetween(from, to, element => {
        if (element.type.name === type) {
           node = element;
        }
    });

    if (node) {
        return node.attrs
    }

    return {}
}
