"use strict";
/**
 * Created by prueg on 17.07.2017.
 */


/***********************************
 * set Cropper Aspect Ratio
 **********************************/
export function setCropperRatio(media,ratio,recrop){
    // show current locked ratio in the ui
    $("#currentLockedRatio").html(Math.round(ratio * 1000) / 1000 || "Free");

    // when target size is specified and doesnt have the specified ratio, adjust it to the new Ratio
    if(media.targetWidth && media.targetHeight && ratio)
        media.updateTargetSize(media.targetWidth,media.targetWidth/ratio);

    transform(media,"setRatio",{
        ratio: ratio,
        recrop: recrop
    });
}

/***********************************
 * set Cropper Size
 **********************************/
export function setCropperSize(media,width,height){
    // get size-difference between the original image + the canvas
    var imgData = media.cropper.getCanvasData();
    var dif = imgData["width"] / imgData["naturalWidth"];

    // get offsets
    var containerData = media.cropper.getContainerData();
    var offsetX = Math.max((containerData["width"] - imgData["width"]) / 2, 0);
    var offsetY = Math.max((containerData["height"] - imgData["height"]) / 2, 0);

    // get centerposition for the original size
    var top = Math.max((imgData["naturalHeight"] - height) / 2, 0);
    var left = Math.max((imgData["naturalWidth"] - width) / 2, 0);

    transform(media,"setCropBoxData",{
        left: (left*dif) + offsetX,
        top: (top*dif) + offsetY,
        width: width*dif,
        height: height*dif
    });
}


/*******************************************
 * toggle, lock/unlock (freeze) the cropper
 ******************************************/
export function toggleCropper(cropper){
    if(cropper.disabled)
        unlockCropper(cropper);
    else
        lockCropper(cropper);
}
export function lockCropper(cropper){
    cropper.disable();
    $(".cropboxCenterText").addClass("cropDisabled");
    $(".cropper-point").addClass("cropDisabled");
}
export function unlockCropper(cropper){
    cropper.enable();
    $(".cropboxCenterText").removeClass("cropDisabled");
    $(".cropper-point").removeClass("cropDisabled");
}

/*******************************************
 * reset the cropper & ui back to initial values
 ******************************************/
export function resetCropper(media){
    unlockCropper(media.cropper);

    media.cropper.clear();
    media.cropper.reset();
    media.cropper.setAspectRatio(NaN);
    media.cropCount = 0;
    media.cropper.crop();

    media.resetTargetSize();

    window.MediaEditor.updateUiInputs(media);

    // flag the preview & canvas elements to be reloaded after this
    media.cropperChanged = true;
}

/*********************************
 * change the image in the cropper
 * & reinitialise cropper
 * ******************************/
export function changeCropperImage(media, imgSrc){
    unlockCropper(media.cropper);
    media.cropper.replace(imgSrc);
    resetCropper(media);
}


/*******************************************
 * Wrapper-Function for all transformations
 * because some additional things have
 * to be done on each transformation
 * (revert the cropper to its previous
 * state after each transformation)
 *
 * when adding new types of transformations,
 * add them here and only use them through
 * this function!
 *
 * current modes:
 *      "scale"
 *      "rotate"
 *      "setRatio"
 *      "setCropBoxData"
 ******************************************/
export function transform(media, mode, options){
    // save previous cropper state (enabled/disabled)
    var cropperDisabled = media.cropper.disabled;
    //enable cropper to be able to transform
    media.cropper.enable();

    // flag the preview & canvas elements to be reloaded after this
    media.cropperChanged = true;

    //transform-mode
    switch(mode){
        case "scale":
            //********************************************************************************
            // x: number        x-scale
            // y: number        y-scale

            media.cropper.scale(options["x"],options["y"]);

            media.updateCropperImageStyle();

            break;
        case "rotate":
            //********************************************************************************
            // fixedValue:  boolean     rotate to exact value if true, if false just 'add' to its current rotation
            // angle:       number      angle to rotate in degrees

            media.cropper.clear();

            if(options["fixedValue"])
                media.cropper.rotateTo(options["angle"]);
            else
                media.cropper.rotate(options["angle"]);

            media.updateCropperImageStyle();

            media.cropper.crop(); // todo restore previously selected croparea after rotating?

            break;
        case "setRatio":
            //********************************************************************************
            // ratio:  text
            // recrop:  boolean

            media.cropper.setAspectRatio(options["ratio"],options["recrop"]);   //options["recrop"] currently not used

            break;
        case "setCropBoxData":
            //********************************************************************************
            // left:  number
            // top:  number
            // width:  number
            // height:  text

            media.cropper.setCropBoxData({
                left:options["left"],
                top:options["top"],
                width:options["width"],
                height:options["height"]
            });

            break;
        default:
            console.log("unknown transformation");
    }
    // revert cropper back to its previous state
    media.cropper.disabled = cropperDisabled;
}


/**********************************************
 * Display the actual size of the source image
 * of the current selected cropping area.
 * And, if set, the specified target size for resizing.
 *********************************************/
export function updateSizeText(media,w,h){
    var sizeText = Math.floor(w) + "x" + Math.floor(h);

    var parentEl = $(media.cropContainer);

    // append the text elements to the cropper, if they dont exist yet
    if(parentEl.find(".cropboxCenterText").length == 0){
        parentEl.find(".cropper-crop-box").append("<div class='cropboxCenterText'><span class='cropboxCenterSize'></span><br><span class='cropboxCenterTargetSize'></span></div>");
    }
    //$('#resizeWidth').val(Math.floor(w));
   // $('#resizeHeight').val(Math.floor(h));
    parentEl.find(".cropboxCenterSize").html(sizeText);
    updateTargetSizeText(media);
}

/*************************************************
 * Display the specified target size for resizing
 * if set and not equal to the size of the current
 * cropping area
 * w: current width of the cropped area
 * h: current height of the cropped area
 ************************************************/
export function updateTargetSizeText(media){

    var parentEl = $(media.cropContainer);

    //very ugly comparison if the specified targetsize is equal to the current size of the cropbox
    var size = parentEl.find(".cropboxCenterSize").html();
    var targetSize = Math.round(media.targetWidth) + "x" + Math.round(media.targetHeight);

    //if the 2 sizes are not equal and a targetsize has been specified, display the targetsize
    if(media.targetHeight && media.targetWidth && size != targetSize)
        parentEl.find(".cropboxCenterTargetSize").html("("+targetSize+")");
    else
        parentEl.find(".cropboxCenterTargetSize").html("");
}


