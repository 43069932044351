"use strict";
/**
 * Created by prueg on 17.07.2017.
 */

export let _webGLPresetFilters = {

};

export let _customFilters = {

};

// initialise filter list view
export function initFilterList(type,callback){
    $.post(window.MediaManager.controllerUrl+'listimagefilter',{},function(data) {
        document.getElementById("ownfilters").innerHTML = "<div id='customFilters'></div>";
        document.getElementById("filterSelection").innerHTML = "<div id='presetFilters' class='submenuRight'></div>";

        data = JSON.parse(data);
        for (let key in data) {
            if(data[key].type === 'webglPreset'){
                _webGLPresetFilters[data[key].name] = JSON.parse(data[key].filter);

                $("#presetFilters").append("<div class='filterWrapper' data-name='"+data[key].name+"' data-options='"+JSON.stringify(_webGLPresetFilters[data[key].name].options)+"'></div>");
            } else if(data[key].type === 'webgl'){
                _customFilters[data[key].name] = JSON.parse(data[key].filter);

                addCustomFilterToList(data[key].name);
            }
        }
        addFilterClickListener();

        if(typeof(callback) === "function") callback();
    });
}


// add a new filter to the list
export function saveNewCustomFilter(name,type,options,preset){
    // save the filter on the db
    $.post(window.MediaManager.controllerUrl + 'saveimagefilter',{
        name: name,
        type: type,
        filter: JSON.stringify({
            options: options,
            presetFilter: preset
        }),
    });
    // add the filter to the object list
    _customFilters[name] = {
        options: options,
        presetFilter: preset
    };
    // add the filter to the filter list view
    addCustomFilterToList(name);
    // generate thumbnails for this new filter for all currently editing medias, and load the new thumbnail for the current media in the list view
    for(let m in window.MediaEditor.medias){
        let media = window.MediaEditor.medias[m];
        media.generateFilterThumbnailWebGL('custom', options,name).then(()=>{
            if(media.isCurrentMedia) displayFilterPreviewsFor(window.MediaEditor.currentMedia);
        });
    }
}

// add an existing filter to the list
function addCustomFilterToList(key){
    var parent = $("#customFilters");
    // only append if it doesnt already exist
    var filter = parent.find("div[data-name='"+key+"']");

    if(filter.length <= 0){
        var preset = "";
        if(_customFilters[key]["presetFilter"]){
            preset = " data-preset='"+_customFilters[key]["presetFilter"]+"'";
        }
        parent.append(
            "<div class='filterWrapper' data-name='"+key+"' data-options='"+ JSON.stringify(_customFilters[key]["options"])+"' "+preset+" >" +
            "<div class='deleteFilter'>&times;</div>" +
            "</div>"
        );
    } else {
        filter = filter.get(0);
        filter.setAttribute("data-options", JSON.stringify(_customFilters[key]["options"]));
        if(typeof(_customFilters[key]["presetFilter"]) !== "undefined"){
            filter.setAttribute("data-preset", _customFilters[key]["presetFilter"]);
        }
    }
}

// load the thumbnails of a specific media into the filter list
export function displayFilterPreviewsFor(media){
    for(var name in _customFilters){
        var el = $("#customFilters").find("div[data-name='"+name+"']");
        el.find("img").remove();
        el.find("canvas").remove();
        el.prepend(media.filterThumbnails["custom"][name]);
    }
    for(var name in _webGLPresetFilters){
        $("#presetFilters").find("div[data-name='"+name+"']").empty().prepend(media.filterThumbnails["preset"][name]);
    }
}

// add click listeners for all filters & delete buttons
function addFilterClickListener(){

    // delete button click
    $("#customFilters").on("click", ".deleteFilter", function(){
        deleteCustomFilter($(this).parent().data("name"),'webgl');
        return false;
    });

    // selecting a filter
    $("#presetFilters, #customFilters").on("click", ".filterWrapper", function(){
        if($(this).hasClass("active")){
            window.MediaEditor.currentMedia.resetFilters();
            updateFilterFormFields(window.MediaEditor.currentMedia);
            $(".filterWrapper .filterCheckWrapper").remove();
            $(".filterWrapper").removeClass("active");
        }else{
            window.MediaEditor.currentMedia.applyGLFilter(JSON.parse(this.getAttribute("data-options")));
            document.getElementById("saveFilterName").value = this.getAttribute("data-name");

            $(".filterWrapper .filterCheckWrapper").remove();
            $(".filterWrapper").removeClass("active");
            $(this).append("<div class='filterCheckWrapper normal zoomIn'><i class='fa fa-check' aria-hidden='true'></i></div>");
            $(this).addClass("active");
        }
    });
}

// delete a custom filter
function deleteCustomFilter(name,type){
    // remove the filter from the object list
    delete _customFilters[name];
    // remove the filter container element from the dom
    $("#customFilters").find("div[data-name='"+name+"']").remove();
    // delete the filter on the db

    $.post(window.MediaManager.controllerUrl + 'deleteimagefilter',{
        name: name,
        type: type
    });
    //note: the thumbnails for deleted custom filters in the Media objects are not deleted, but theyre only temporary and not referenced anymore anyway
}



// update the formfield inputs with the values of the passed media
export function updateFilterFormFields(media){

    // Toggles ...
    if(media.webGLCanvas.values["greyscale"] === 1){
        $("input[data-filter='greyscale']").attr("checked", true);
        $(".submenuBtn[data-filtertoggle='greyscale']").addClass("active");
    } else {
        $("input[data-filter='greyscale']").attr("checked", false);
        $(".submenuBtn[data-filtertoggle='greyscale']").removeClass("active");
    }

    if(media.webGLCanvas.values["invert"] === 1){
        $("input[data-filter='invert']").attr("checked", true);
        $(".submenuBtn[data-filtertoggle='invert']").addClass("active");
    } else {
        $("input[data-filter='invert']").attr("checked", false);
        $(".submenuBtn[data-filtertoggle='invert']").removeClass("active");
    }

    // Input fields
    $("input[data-filter='brightness']").val(media.webGLCanvas.values["brightnessContrast"]["brightness"] * 100);
    $("input[data-filter='contrast']").val(media.webGLCanvas.values["brightnessContrast"]["contrast"] * 10);

    $("input[data-filter='hue']").val(media.webGLCanvas.values["hueSaturation"]["hue"] * 100);
    $("input[data-filter='saturation']").val(media.webGLCanvas.values["hueSaturation"]["saturation"] * 100);

    $("input[data-filter='vibrance']").val(media.webGLCanvas.values["vibrance"] * 100);
    $("input[data-filter='noise']").val(media.webGLCanvas.values["noise"] * 100);
    $("input[data-filter='sepia']").val(media.webGLCanvas.values["sepia"] * 100);
    $("input[data-filter='exposure']").val(media.webGLCanvas.values["exposure"] * 100);

    $("input[data-filter='vignetteSize']").val(media.webGLCanvas.values["vignette"]["size"]);
    $("input[data-filter='vignetteAmount']").val(media.webGLCanvas.values["vignette"]["amount"]);

    $("input[data-filter='denoise']").val(media.webGLCanvas.values["denoise"]);
    $("input[data-filter='triangleBlur']").val(media.webGLCanvas.values["triangleBlur"]);

    $("input[data-filter='lensBlurRadius']").val(media.webGLCanvas.values["lensBlur"]["radius"]);
    $("input[data-filter='lensBlurBrightness']").val(media.webGLCanvas.values["lensBlur"]["brightness"]);
    $("input[data-filter='lensBlurAngle']").val(media.webGLCanvas.values["lensBlur"]["angle"]);


    // red, green & blue inputs need to be assigned in a different way, since they are saved in the curves-option in a specific array.
    // see change listener in imageedit.php:
    // ...
    // var val = parseFloat(this.value) / 100;
    // if (val <= 0) {
    //     window.MediaEditor.currentMedia.webGLCanvas.values["curves"][this.getAttribute("data-filter")] = [[0, 0], [1, val+1]];
    // } else if (val > 0) {
    //     window.MediaEditor.currentMedia.webGLCanvas.values["curves"][this.getAttribute("data-filter")] = [[0, val], [1, 1]];
    // }

    var red, green, blue;

    if(media.webGLCanvas.values["curves"]["red"][0][1] !== 0){
        red = media.webGLCanvas.values["curves"]["red"][0][1] * 100;
    } else {
        red = (media.webGLCanvas.values["curves"]["red"][1][1] -1 ) * 100;
    }

    if(media.webGLCanvas.values["curves"]["green"][0][1] !== 0){
        green = media.webGLCanvas.values["curves"]["green"][0][1] * 100;
    } else {
        green = (media.webGLCanvas.values["curves"]["green"][1][1] -1 ) * 100;
    }

    if(media.webGLCanvas.values["curves"]["blue"][0][1] !== 0){
        blue = media.webGLCanvas.values["curves"]["blue"][0][1] * 100;
    } else {
        blue = (media.webGLCanvas.values["curves"]["blue"][1][1] -1 ) * 100;
    }

    $("input[data-filter='red']").val(red);
    $("input[data-filter='green']").val(green);
    $("input[data-filter='blue']").val(blue);



    //update displayed value text for all range sliders manually, because we only update input values manually, and dont want to trigger change events
    $(".filterContainer input[type='range']").each(function () {
        $(this).parent().parent().find(".currentValue").text($(this).val());
    });

}