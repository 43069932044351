/**
 * Backend Design js
 */

import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.min.css'

(function () {
    "use strict";

    //Scrollbar
    const container = $('.main__content');

    function setUnscrollEvent(scrollTimeout, callback) {
        $(container).on("scroll", function () {
            if (container.scrollTimer) {
                container.addClass('is-scrolling');
                clearTimeout(container.scrollTimer);
            }
            container.scrollTimer = setTimeout(function () {
                callback();
            }, scrollTimeout);
        });
    }


    setUnscrollEvent(250, function () {
        container.removeClass('is-scrolling');
    });

    //////

    /**
     * @param event MouseEvent
     * @param target HtmlElement
     */
    function processClick(event, target) {
        if (event.ctrlKey || event.button === 1) {
            window.open(target.href, '_blank');
        } else if (event.button === 0) {
            target.click();
        }
    }

    // Actionbuttons

    $('.tree-item-title, .tree-item-draft, .tree-item-status, .tree-item-redirect').on("mousedown", function (event) {
        $('.action-buttons-toggle').on('click mousedown', function (e) {
            e.stopPropagation();
        });
        if (!$(event.target).is('.edit-item > a')) {
            let anchor = $(this).closest('li').find('.edit-item > a')[0];
            processClick(event, anchor);
        }
    });

    $('#mediamanager .tree-item-title').off("click");

    // set by EditableColumn, to prevent directly changing page
    // on click we set it to false, so on second click its going to pass.
    // Also on blur of the input field, there is a second delay and its set to false also.
    window.editActive = false;
    $(document).on("mousedown", ".table-responsive td:not(.table-col-checkbox):not(.action-column):not(.editable-column)", function (event) {
        if (window.editActive === false) {
            $('.action-buttons-toggle').on('click mousedown', function (e) {
                e.stopPropagation();
            });
            if (!$(event.target).is('.edit-item > a')) {
                let $tr = $(this).closest('tr');
                let $newTarget = $tr.find('.edit-item > a');
                if ($newTarget.length) {
                    processClick(event, $newTarget[0]);
                }
                $newTarget = $tr.find('.edit-item > button');
                if ($newTarget.length) {
                    processClick(event, $newTarget);
                }
            }
        } else {
            window.editActive = false;
        }
    });
    //////

    // Search form toggle
    var $eventSelect = $(".search-form-collapse-container .select2");
    $eventSelect.on("select2:open", function (e) {
        $('.select2-dropdown').addClass('select2-dropdown--search-filter');
    });
    //////

    // wrap textareas inside a container to display length-counter
    $("textarea /*, input[type=text]*/").each(function () {
        if ($(this).prop("maxlength") > -1) {
            let max = $(this).prop("maxlength"),
                current = $(this).val().length,
                remaining = max - current;
            $(this).wrap(`<div class='textarea_count_wrapper' data-maxlength='${max}' data-currentlength='${current}' data-remaininglength='${remaining}'></div>`);
            /*if($(this).closest('.form-group').hasClass('label-inside')){
                $(this).closest('.form-group').find('> label').appendTo($(this).closest('.textarea_count_wrapper'));
            }*/
        }
        // initial value for 'placeholder-shown' pseudo attribute
        /*if($(this).closest('.form-group').hasClass('label-inside')){
            $(this).toggleClass('placeholder-shown',$(this).val().length<=0);
        }*/
    });

    // listen to textarea changes to update length-counter
    $(document).on("change keyup", ".textarea_count_wrapper textarea", function () {
        let max = $(this).prop("maxlength"),
            current = $(this).val().length,
            remaining = max - current;
        $(this).closest(".textarea_count_wrapper")
            .attr("data-currentlength", current)
            .attr("data-remaininglength", remaining)
            .attr("data-maxlength", max);
    });
    // toggle 'placeholder-shown' pseudo attribute on textareas
    /*$(document).on("change keyup",".form-group.label-inside textarea",function(){
        $(this).toggleClass('placeholder-shown',$(this).val().length<=0);
    });*/

    $('.select2').select2({
        language: {
            noResults: function (params) {
                return "Keine Resultate gefunden";
            }
        }
    });

    $('[data-toggle="tooltip"]').tooltip();


    $('.menu__client-selected').on('click', function () {
        $('.menu__client').toggleClass('is-open');
    });

    $(document).on('click', function (e) {
        let target = $(e.target);
        if (!target.closest('.menu__client').length && $('.menu__client').hasClass('is-open')) {
            $('.menu__client').removeClass('is-open');
        }
    });

    $.fn.pageLoader = function (action, options) {

        const settings = $.extend({}, $.fn.pageLoader.defaults, options);

        const logo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145 60">' +
            '<path d="M119.7 60c-15.5 0-24.8-8.8-24.8-23.7V23.8C94.9 8.9 104.2 0 119.7 0c15.6 0 24.9 8.9 24.9 23.8v11.3h-33.4v1.2c0 6.9 2.9 10.2 8.8 10.2 6.8 0 11.3-1.9 16-4.2l.7-.3 5.7 11-.6.4a38 38 0 01-22.1 6.6zm8.6-35.1v-1c0-7-2.8-10.4-8.6-10.4-5.9 0-8.8 3.5-8.8 10.4v1h17.4zM59.6 59V1.1h14.1L75 6.5A26.7 26.7 0 0190.9 0h.7l1.5 17.1h-.9c-6.1 0-12.2 1.9-16.3 5.1L75.7 59H59.6zM40.9 1.1h14.3v57.8H40.9V1.1zM0 59V1.1h36.4v12.7H14.3v11.6h18.3v12.7H14.3V59H0z"/>' +
            '</svg>';

        let loaderElement = $('.' + settings.class);
        if (0 === loaderElement.length) {
            loaderElement = $('<div></div>');
            loaderElement.addClass(settings.class)
                .hide()
                .append('<div class="page--loader-content">' +
                    '<div class="page--loader-logo">' +
                    '<div>' + logo + '' +
                    '<div class="page--loader-spinner">' +
                    '<div class="spinner-border text-primary" role="status">' +
                    '<span class="sr-only">Loading...</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '<div class="page--loader-info badge badge-alternate-primary">' + settings.content + '</div>' +
                    '</div>');
            $('.main__content').append(loaderElement);
        } else {
            $('.page--loader-info').html(settings.content);
        }

        if (action === 'show') {
            loaderElement.show();
        } else if (action === 'hide') {
            loaderElement.hide();
        }
    }

    $.fn.pageLoader.defaults = {
        content: 'Loading',
        class: 'page--loader'
    }

    $.fn.pJaxLoader = function (action, options) {
        let settings = $.extend({}, $.fn.pJaxLoader.defaults, options);

        let element = $(this),
            isBody = false;

        if (element.is('body')) {
            isBody = true
        }

        if (action === 'show') {

            if (element.find('.progress__container').length === 0) {
                let loader = '<div class="progress__container">' +
                    '<div class="progress-background"></div>' +
                    '<div class="progress-bar long"></div>' +
                    '<div class="progress-bar short"></div>' +
                    '</div>'

                element.prepend(loader);
                if (isBody) {
                    $('.progress__container').addClass('progress-fixed')
                } else {
                    element.addClass('position-relative');
                }
                if (settings.backdrop) {
                    element.addClass('progress-backdrop');
                }
            }

        } else if (action === 'hide') {

            let check = element.find('.progress__container')
            if (check.length !== 0) {
                element.removeClass('position-relative');
                element.removeClass('progress-backdrop');
                check.remove();
            }
        }

    }

    $.fn.pJaxLoader.defaults = {
        class: 'pjax--loader',
        backdrop: true
    }

    $('form').on('beforeSubmit submit', function (e) {
        // Yii creates a "virtual" form to submit when using pjax, so one contains the real form, the other the virtual.
        // But when using submit button, it uses that.
        if (this === e.target) {
            const options = {};
            const message = $(this).data('page-loader-text');
            if (message) {
                options.content = message;
            }

            let yiiActiveFormData = $(this).data('yiiActiveForm');
            let source;
            if (yiiActiveFormData && 'submitObject' in yiiActiveFormData) {
                source = yiiActiveFormData.submitObject;
            }

            let newText = false;

            //This is for search form
            if ('originalEvent' in e) {
                // For search we detect the class
                let originalSource = $(e.originalEvent.submitter).closest('.search-form');
                if (originalSource.length) {
                    newText = $.fn.pageLoader.defaults.contentSearch;
                }
            }

            //This is for header submit buttons, save, draft, publish
            if (source) {
                // For draft, save of draft, publish we check on the submitter (the button) - if there is a pageloader title
                let buttonTitle = $(source).data('page-loader-text');
                if (buttonTitle) {
                    newText = buttonTitle;
                }
            }

            if (false !== newText) {
                options.content = newText;
                // this gets executed twice on search (and also on any other submit). On first run there is a originalEvent, on second there isn't
                // So as a workaround we write the title into the form here.
                $(this).data('page-loader-text', newText);
            }

            $('body').pageLoader('show', options);

            $(this).one('afterValidate', '', function (event, errorAttributes) {
                let error = false;

                for (const errorAttribute in errorAttributes) {
                    if (errorAttribute.length) {
                        error = true;
                        break;
                    }
                }
                if (error) {
                    $('body').pageLoader('hide');
                }
            });
        }
    });


    $('#media-edit-info-form').on('show.bs.modal', function (e) {
        // do something...
        $('body').addClass('media-modal-info-open');
        if ($(".modal-backdrop").length > -1) {
            $(".modal-backdrop").not(':first').remove();
        }

    })

    $('#media-edit-info-form').on('hidden.bs.modal', function (e) {
        // do something...
        $('body').removeClass('media-modal-info-open');
        if ($(".modal.show").length > 0) {
            $('body').addClass('modal-open');
        }
    })


    $('.modal').on('show.bs.modal', function (e) {

    });
    $('.modal').on('hide.bs.modal', function (e) {

    });

    /*
        <div class="progress__container">
            <div class="progress-background"></div>
            <div class="progress-bar long"></div>
            <div class="progress-bar short"></div>
        </div>
     */

    // Prevents readonly checkboxes from being changed.
    $('input.custom-control-input[type=\"checkbox\"]').on('click keyup keypress keydown', function (event) {
        if (event.keyCode && event.keyCode !== 9) {
            if ($(this).is('[readonly]')) {
                return false;
            }
        }
    });
    $('label.custom-control-label').on('click keyup keypress keydown', function (event) {
        if ($(this).is('[disabled]')) {
            return false;
        }
    });
})();