"use strict";
/**
 * Created by prueg on 08.09.2017.
 */

import fx from '../vendor/glfx/glfx.js';

//import fx from 'glfx-es6';

let _glfxFilters = {
    brightnessContrast: function(canvas,options){
        canvas.brightnessContrast(options["brightnessContrast"]["brightness"], options["brightnessContrast"]["contrast"]);
    },
    noise: function(canvas,options){
        canvas.noise(options["noise"]);
    },
    denoise: function(canvas,options){
        canvas.denoise(options["denoise"]);
    },
    hueSaturation: function(canvas,options){
        canvas.hueSaturation(options["hueSaturation"]["hue"],options["hueSaturation"]["saturation"]);
    },
    sepia: function(canvas,options){
        canvas.sepia(options["sepia"]);
    },
    vibrance: function(canvas,options){
        canvas.vibrance(options["vibrance"]);
    },
    vignette: function(canvas,options){
        canvas.vignette(options["vignette"]["size"], options["vignette"]["amount"]);
    },
    lensBlur: function(canvas,options){
        canvas.lensBlur(options["lensBlur"]["radius"], options["lensBlur"]["brightness"], options["lensBlur"]["angle"]);
    },
    triangleBlur: function(canvas,options){
        canvas.triangleBlur(options["triangleBlur"]);
    },
    curves: function(canvas,options){
        canvas.curves(options["curves"]["red"],options["curves"]["green"],options["curves"]["blue"]);
    },
    invert: function(canvas,options){
        canvas.curves([[0,1],[1,0]]);
    },
    greyscale: function(canvas,options){
        canvas.hueSaturation(0,-1);
    },
    exposure: function(canvas,options){
        var val = parseFloat(options["exposure"])/2;
        var point = [0.5-val,0.5+val];

        canvas.curves([[0,0],point,[1,1]]);
    }
};

let _glfxFilterDefaults = {
    brightnessContrast: {
        brightness: 0,  // -1..1
        contrast: 0     // -1..1
    },
    noise: 0,   // 0..1
    denoise: 50,     //0..50 ?
    hueSaturation: {
        hue: 0,     //-1..1
        saturation: 0       //-1..1
    },
    sepia: 0,        // 0..1
    vibrance: 0,    //-1..1
    vignette: {
        size: 0,
        amount: 0
    },
    exposure: 0,    // -1..1
    lensBlur: {
        radius: 0,      //0..50 ?
        brightness: 0,  //-1..1
        angle: 0        //-Math.PI..Math.PI
    },
    triangleBlur: 0,     //0..200 ?
    curves: {
        red: [[0,0],[1,1]],
        green: [[0,0],[1,1]],
        blue: [[0,0],[1,1]]
    },
    greyscale: 0,   // 0/1 bool
    invert: 0       // 0/1 bool
};

export default function WebGLCanvas(){

    this.texture = null;
    this.canvas = null;

    this.filters = [

    ];

    this.init = function(img){
        try {
            this.canvas = fx.canvas();
        } catch (e) {
            console.log('WebGL not supported or Error loading glfx.js');
            return;
        }
        this.changeTexture(img);
    };

    this.draw = function(){
        var that = this;
        if(this.canvas){
            this.canvas.draw(this.texture);
            for(var filter in this.filters){
                if(typeof(this.filters[filter]) === "function") this.filters[filter](that.canvas,that.values);
            }
            this.canvas.update();
        }
    };

    this.changeTexture = function(img){
        this.texture = this.canvas.texture(img);
        this.draw();
    };

    this.toDataURL = function(mime,quality){
        mime = mime || "image/jpeg";
        quality = quality || 0.9;
        this.draw();
        return this.canvas.toDataURL(mime,quality);
    };

    // update filters in the array,
    // add filters if their value has been changed
    // or remove filters if their value is equal to the default
    this.updateFilters = function(){
        for(var value in this.values){
            if(_glfxFilterDefaults[value] !== this.values[value]){
                this.filters[value] = _glfxFilters[value];
            } else {
                delete this.filters[value];
            }
        }
    };

    this.setDefaultValues = function(){
        this.values = JSON.parse(JSON.stringify(_glfxFilterDefaults));  // copy defaults, instead of referencing
    };

    this.setDefaultValues();
}


