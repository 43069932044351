<template>
  <div>
    <checkbox-switch v-model="discounttype">
      <template #before>{{ labels.absolute }}</template>
      <template #after>{{ labels.percent }}</template>
    </checkbox-switch>
    <input type="hidden" name="ShopCartDiscount[type]" :value="discounttype">

    <div class="form-group">
      <label for="minCondition">{{ labels.minCondition }}</label>
      <input type="hidden" name="ShopCartDiscount[min_condition]" :value="minCondition">
      <v-select id="minCondition" v-model="minCondition" :options="minConditions" :reduce="label => label.key"
                class="segment-rule-operator" :clearable="false"></v-select>
    </div>

    <draggable
        handle=".handle"
        tag="ul"
        v-model="discounts"
        :scroll-sensitivity="190"
        :force-fallback="true"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
    >
      <li v-for="(el,index) in discounts" :key="el.id">
        <i v-if="discounts.length > 1" class="fa fa-fw fa-grip-vertical handle"></i>
        <div v-if="minCondition !== 'amount'" class="form-group label-inside field-shopcartdiscount-value required">
          <input class="form-control is-valid" :name="'ShopCartDiscountStaggered[' + index + '][min_price]'"
                 v-model="el.minPrice">
          <label :for="'min-' + el.id">{{ labels.minPrice }}</label>
          <div class="invalid-feedback"></div>
        </div>
        <div v-if="minCondition !== 'price'" class="form-group label-inside field-shopcartdiscount-value required">
          <input class="form-control is-valid" :name="'ShopCartDiscountStaggered[' + index + '][min_amount]'"
                 v-model="el.minAmount">
          <label :for="'min-' + el.id">{{ labels.minAmount }}</label>
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group label-inside field-shopcartdiscount-value required">
          <input class="form-control is-valid" :name="'ShopCartDiscountStaggered[' + index + '][discount]'"
                 v-model="el.discount">
          <label :for="'discount-' + el.id">{{ labelsDiscount }}</label>
          <div class="invalid-feedback"></div>
        </div>
        <button v-if="discounts.length > 1" type="button" @click="removeAt(index)" class="remove"><i
            class="fa fa-times"></i></button>
      </li>
    </draggable>

    <button class="btn btn-sm btn-secondary add" type="button" @click="add">{{ labels.add }}</button>

    <ul>
      <li>
        <div v-if="minCondition !== 'amount'" class="form-group label-inside field-shopcartdiscount-max_price required">
          <input class="form-control is-valid" id="shopcartdiscount-max_price" name="ShopCartDiscount[max_price]"
                 v-model="maxPrice">
          <label for="shopcartdiscount-max_price">{{ labels.maxPrice }}</label>
          <div class="invalid-feedback"></div>
        </div>
        <div v-if="minCondition !== 'price'" class="form-group label-inside field-shopcartdiscount-max_amount required">
          <input class="form-control is-valid" id="shopcartdiscount-max_amount" name="ShopCartDiscount[max_amount]"
                 v-model="maxAmount">
          <label for="shopcartdiscount-max_amount">{{ labels.maxAmount }}</label>
          <div class="invalid-feedback"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import CheckboxSwitch from "./CheckboxSwitch.vue";

// no magic increments
// const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
//
// function getIncrease(stringNumber) {
//     stringNumber = stringNumber ? stringNumber : 0
//     const l = stringNumber.toString().split('.')
//     let value = 1
//     let precision = 0
//     if (l[1]) {
//         precision = l[1].length
//         value = roundAccurately(value / Math.pow(10, precision) , precision)
//     }
//     return roundAccurately(parseFloat(stringNumber) + value, precision).toFixed(precision)
// }

class StaggeredDiscountLabels {
  constructor(add, maxPrice, maxAmount, minCondition, minAmount, minPrice, minPriceOrAmount, minPriceAndAmount,
              discount, absolute, percent) {
    this.add = add
    this.maxPrice = maxPrice
    this.maxAmount = maxAmount
    this.minCondition = minCondition
    this.minAmount = minAmount
    this.minPrice = minPrice
    this.minPriceOrAmount = minPriceOrAmount
    this.minPriceAndAmount = minPriceAndAmount
    this.maxPrice = maxPrice
    this.maxAmount = maxAmount
    this.discount = discount
    this.absolute = absolute
    this.percent = percent
  }
}

export default {
  components: {
    draggable,
    CheckboxSwitch
  },
  data() {
    return {
      discounts: this.passDiscounts,
      maxPrice: this.passMaxPrice,
      maxAmount: this.passMaxAmount,
      minCondition: this.passMinCondition,
      discounttype: this.passDiscounttype === 'percent',
      minConditions: [
        {
          label: this.labels.minPrice,
          key: "price"
        },
        {
          label: this.labels.minAmount,
          key: "amount"
        },
        {
          label: this.labels.minPriceOrAmount,
          key: "price-or-amount"
        },
        {
          label: this.labels.minPriceAndAmount,
          key: "price-and-amount"
        }
      ]
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false
      };
    },
    // labelsMin() {
    //     return this.mintype ? this.labels.minPrice : this.labels.minAmount
    // },
    labelsDiscount() {
      return this.discounttype ? this.labels.percent : this.labels.absolute
    }
  },
  props: {
    passDiscounts: {
      required: true,
      type: Array
    },
    passMaxAmount: {
      required: true,
      type: String | Number
    },
    passMaxPrice: {
      required: true,
      type: String | Number
    },
    passMinCondition: {
      required: false,
      default: 'price',
      type: String
    },
    passDiscounttype: {
      required: false,
      default: 'percent',
      type: String
    },
    labels: {
      required: true,
      type: StaggeredDiscountLabels,
      validator: function (value) {
        const compare = new StaggeredDiscountLabels()
        let missing = []
        for (const key of Object.keys(compare)) {
          if (!value.hasOwnProperty(key)) {
            missing.push(key)
          }
        }
        if (missing.length) {
          throw new TypeError(`${missing} missing from labels`)
        }
        return true
      }
    }
  },
  methods: {
    removeAt(index) {
      this.discounts.splice(index, 1);
    },
    uniqueId() {
      return "_" + performance.now().toString().replace(".", "");
    },
    add() {
      const last = this.discounts.length
      // no magic increase
      // const lastDiscount = this.discounts[last - 1]
      // let minPrice = 0,
      //      discount = 0,
      //     minAmount = 0
      // if (lastDiscount) {
      //     minPrice = getIncrease(lastDiscount.minPrice)
      //     minAmount = getIncrease(lastDiscount.minAmount)
      //     discount = getIncrease(lastDiscount.discount)
      // }
      this.discounts.splice(last, 0, {
        id: this.uniqueId(),
        minPrice: 0,
        minAmount: 0,
        discount: 0
      });
    },
  }
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  margin-left: 0;
  padding: 0;
  min-height: 34px;
}

li {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

i.handle {
  padding: 5px;
  padding-right: 19px;
}

li .form-group {
  margin-right: 15px;
  max-width: 190px;
}

button.remove {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  opacity: 0.4;
  color: #000;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;

  &:hover {
    opacity: 1;
  }
}

button.add {
  margin-bottom: 1rem;
}

label.switch {
  display: block;
}
</style>

<style lang="scss">

.handle {
  cursor: grab;
  opacity: 0.4;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  user-select: none;
  /*vertical-align: top;*/
  &:hover {
    opacity: 1;
  }
}


:root {
  --vs-dropdown-option--active-bg: rgba(34, 164, 174, 0.06) !important;
  --vs-dropdown-option--active-color: #22a4ae !important;
  --vs-dropdown-box-shadow: 0px 10px 15px 0px rgb(34 44 44 / 10%) !important;
}
</style>