<template>
    <li :class="[{'disabled': widget.readonly}]" :data-file-id="media.file_id" :data-item-index="itemIndex" :data-media-id="(media.id || '')" :data-sort-index="index">
        <div class="modelmedia_row">
            <a class="preview" target="_blank"
               title="Vorschau">
                <img :src="(file.preview || '')" alt="" class="media_img">
            </a>
            <!-- :href="'/'+(file.uri || '')" -->
            <div class="file_name">
                {{file.name || ''}}
            </div>
            <div class="media_actions">
                <a :class="{'disabled': widget.readonly}"
                   class="file-remove fontello-icon-ui-close"
                   title="Entfernen">
                </a>
                <a :class="{'disabled': widget.readonly}"
                   class="changeMediaButton loadMediaManager fontello-icon-menu-mediamanager"
                   title="Bild ersetzen>">
                </a>
                <a v-if="widget.maxFiles > 1"
                   :class="{'disabled': widget.readonly}"
                   class="file-sort far fa-arrows"
                   title="Verschieben">
                </a>
                <a v-if="widget.withPointer"
                   :class="{'disabled': widget.readonly}"
                   class="mediapointer far fa-bullseye"
                   title="Zeiger bearbeiten">
                </a>
                <a v-if="widget.editable"
                   :class="{'disabled': widget.readonly}"
                   class="file-edit far fa-pencil"
                   title="Bearbeiten">
                </a>
            </div>
            <div class="break"></div>
            <div class="modelmedia_edit">
                <!-- :form="widget.dummyFormName ? widget.dummyFormName : null" -->
                <div v-for="(field,key) in fields" :key="key" :class="{'d-none': field.type === 'hidden'}" class="form-group label-inside">
                    <input
                        :id="`${widget.identifier}_${widget.attributeName}_${(field.name || key)}_${(index<0 ? '': index)}`"
                        :data-name="(field.name || key)"
                        :disabled="widget.readonly"
                        :form="widget.dummyFormName ? widget.dummyFormName : null"
                        :name="`${namePrefix}[${(field.name || key)}]`"
                        :type="(field.type || 'text')"
                        :value="(media[key] || '')"
                        class="form-control"
                    >
                    <label v-if="field.label">{{field.label}}</label>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
    export default {
        props: [
            'media',
            'index',
            'widget',
            'itemIndex',
            'identifier',
            'editableFields'
        ],
        data(){
            return{
                dynamicFields: {
                    label: {
                        label: 'Titel',
                    },
                    sublabel: {
                        label: 'Untertitel',
                    },
                    description: {
                        label: 'Beschreibung',
                    },
                    link: {
                        label: 'Link',
                    },
                    linktitle: {
                        label: 'Linktext',
                    },
                }
            }
        },
        computed: {
            fields() {
                const fields = {
                    id: {
                        type: 'hidden',
                        name: 'media_id',
                    },
                    file_id: {
                        type: 'hidden',
                    },
                    file_name: {
                        type: 'hidden',
                    },
                    category: {
                        type: 'hidden',
                    },
                };

                for (let key in this.editableFields) {
                    if (this.dynamicFields[key] && this.editableFields[key]) {
                        fields[key] = this.dynamicFields[key];
                    } else if (this.dynamicFields[key] && !this.editableFields[key]) {
                        fields[key] = {
                            ...this.dynamicFields[key],
                            type: 'hidden'
                        }
                    }
                }

                return fields;
            },
            namePrefix(){
                return `Page[page][${this.widget.modelName}][${this.identifier}][medias][${this.widget.attributeName}][${this.index}]`;
            },
            file(){
                return this.media.file || {};
            },
        },
    }
</script>