<template>
    <div class="slider-wrapper">

        <!-- inheritance toggle -->
        <div class="form-group label-inside">
            <div :data-inherit-enabled="inheritance" :data-inherit-source="itemsJSON"
                 data-inherit="container">
                <div class="i18n-inheritance">
                    <input v-for="(mediaForm, index) in mediaForms"
                           :key="`hidden-${index}`"
                           :name="`Page[page][${modelName}Inheritance][${identifier}][${mediaForm.key}][inherit]`"
                           form="pageeditor-dummy-form"
                           type="hidden"
                           value="0"
                    />
                    <input v-for="(mediaForm, index) in mediaForms"
                           v-if="!isDefaultLocale"
                           :key="`checkbox-${index}`"
                           :checked="inheritanceEnabled"
                           :name="`Page[page][${modelName}Inheritance][${identifier}][${mediaForm.key}][inherit]`"
                           class="d-none"
                           form="pageeditor-dummy-form"
                           type="checkbox"
                           value="1"
                    />
                </div>
            </div>
        </div>

        <draggable v-model="items" :component-data="getComponentData()" draggable=".draggable" handle=".handle" tag="div">
            <accordion-item
                v-for="(item, index) in items"
                v-if="mounted"
                :id="item.id"
                :key="`slider-item-${item.id}`"
                :disable-editable-fields="disableEditableFields"
                :disable-remove="items.length <= min"
                :element-title="elementTitle"
                :fields="fields"
                :groups="groups"
                :identifier="identifier"
                :index="index"
                :item="item"
                :item-label-key="itemLabelKey"
                :items-length="items.length"
                :media-forms="mediaForms"
                :medias="getMedias(item.id)"
                :min="min"
                :model-name="modelName"
                :offsets="getOffsets(item.id)"
                :readonly="inheritanceEnabled"
                @remove-item="removeItem"
                @value-update="valueUpdate"
                @media-add="mediaAdd"
                @media-update="mediaUpdate"
                @media-remove="mediaRemove"

                @media-sort="mediaSort"
            />
        </draggable>

        <section>
            <button :disabled="items.length >= max" :title="addTitle()" class="btn btn-primary w-100 my-4" type="button" @click="addItem">Hinzufügen</button>
        </section>

    </div>
</template>

<script>
import draggable from "vuedraggable";
import { v4 as uuidv4 } from 'uuid';
import AccordionItem from "./AccordionItem.vue";

let itemsBackup;

export default {
    name: 'AccordionOverview',
    components: {
        AccordionItem,
        draggable
    },
    props: [
        'elementTitle',
        'content',
        'identifier',
        'modelId',
        'modelName',
        'attributeItems',
        'medias',
        'isDefaultLocale',
        'inheritance',
        'i18nSource',
        'mediasSource',
        'fields',
        'groups',
        'mediaForms',
        'editableFields',
        'disableEditableFields',
        'itemLabelKey',
        'min',
        'max',
    ],
    data() {
        return {
            items: [],
            itemsJSON: "",
            mounted: false,
            mutableMedias: this.medias, // remove
            mediaFormItems: {},
            i18nBackup: null,
            mediasBackup: null,
            inheritanceEnabled: this.isDefaultLocale ? false : this.inheritance === 1 ? true : false,
            addTitle: () => {
                let title = false;
                if (this.max > 0) {
                    if (this.items.length >= this.max) {
                        if (this.max > 1) {
                            title = `Maximal ${this.max} Elemente`
                        } else {
                            title = 'Maximal 1 Element'
                        }
                    }
                }
                return title;
            },
        };
    },
    methods: {
        addItem() {
            const item = this.emptyItem();

            this.items.push(item);
            this.updateInput();
        },
        valueUpdate(id, key, value) {
            this.items.find(item => item.id == id)[key] = value;
            this.updateInput();
        },
        removeItem(id) {
            // also remove the old medias
            const item = this.items.find(item => item.id == id);
            this.mediaForms.forEach(mediaForm => {
                const index = this.getOffset(id, mediaForm);
                const count = item[mediaForm.key + 'FileIDs'].length;
                this.mutableMedias[mediaForm.key].splice(index, count);
            });
            this.items = this.items.filter(item => item.id !== id);
            this.updateInput();
        },
        mediaAdd(mediaForm, id, fileID, data) {
            const item = this.items.find(item => item.id == id);

            item[mediaForm.key + 'FileIDs'].push(fileID);

            const index = this.getOffset(id, mediaForm) + item[mediaForm.key + 'FileIDs'].length - 1;

            this.mutableMedias[mediaForm.key].splice(index, 0, data);

            this.updateInput();
        },
        mediaUpdate(mediaForm, id, mediaId, data, offset, index) {
            this.mutableMedias[mediaForm.key][offset + index].file_id = data.file_id;
            this.mutableMedias[mediaForm.key][offset + index].file.name = data.file_name;
            this.mutableMedias[mediaForm.key][offset + index].file.preview = data.preview;

            this.items.find(item => item.id == id)[mediaForm.key + 'FileIDs'][index] = data.file_id;;

            this.updateInput();
        },
        mediaRemove(mediaForm, id, data) {
            const item = this.items.find(item => item.id == id);
            const fileId = parseInt(data.fileId);
            item[mediaForm.key + 'FileIDs'].splice(item[mediaForm.key + 'FileIDs'].indexOf(fileId), 1);
            this.mutableMedias[mediaForm.key] = this.mutableMedias[mediaForm.key].filter((media, index) => index != data.sortIndex);

            this.updateInput();
        },
        mediaSort(mediaForm, id, data, newIndex, oldIndex) {

            const fileIDs = [];

            data[mediaForm.key].forEach(item => {
                fileIDs.push(item.file_id);
            });

            this.items.find(item => item.id == id)[mediaForm.key + 'FileIDs'] = fileIDs;

            data[mediaForm.key].forEach((media, index) => {
                this.mutableMedias[mediaForm.key][this.getOffset(id, mediaForm) + index] = media;
            })

            this.updateInput();
        },
        updateInput() {
            this.itemsJSON = JSON.stringify(this.items);
            document.querySelector(`#accordion-${this.identifier}`).value = this.itemsJSON;
            document.querySelector(`#accordion-${this.identifier}`).dispatchEvent(new Event('change', { bubbles: true }));
        },
        getMedias(id) {
            const medias = {};
            const item = this.items.find(item => item.id == id);

            this.mediaForms.forEach(mediaForm => {
                medias[mediaForm.key] = [];

                this.mutableMedias[mediaForm.key].forEach((media, index) => {
                    if (item[mediaForm.key + 'FileIDs'].includes(media.file_id)) {
                        if (item[mediaForm.key + 'FileIDs'].indexOf(media.file_id) + this.getOffset(id, mediaForm) === index) {
                            medias[mediaForm.key].push(media);
                        }
                    }
                });
            });

            return medias;
        },
        getOffset(id, mediaForm) {
            let offset = 0;

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id !== id) {
                    offset = offset + this.items[i][mediaForm.key + 'FileIDs'].length;
                } else {
                    return offset;
                }
            }

            return offset;
        },
        getOffsets(id) {
            const offsets = {};

            this.mediaForms.forEach(mediaForm => {
                offsets[mediaForm.key] = this.getOffset(id, mediaForm);
            });

            return offsets;
        },
        getComponentData() {
            return {
                on: {
                    start: (event) => {
                        itemsBackup = this.items;
                    },
                    end: (event) => {
                        if(event.target.classList.contains("medialist")) {
                            return;
                        }

                        // sort medias array same as file_IDs in this.item
                        const item = this.items[event.newIndex];

                        this.mediaForms.forEach(mediaForm => {
                            const length = item[mediaForm.key + 'FileIDs'].length;

                            let offset = 0;
                            let insertOffset = 0;
                            let removeOffset = 0;

                            for (let i = 0; i < itemsBackup.length; i++) {
                                if (itemsBackup[i].id !== item.id) {
                                    offset = offset + itemsBackup[i][mediaForm.key + 'FileIDs'].length;
                                } else {
                                    break;
                                }
                            }

                            const elements = this.mutableMedias[mediaForm.key].slice(offset, offset + length);

                            if (event.newIndex < event.oldIndex) {
                                for (let i = 0; i < event.newIndex; i++) {
                                    insertOffset = insertOffset + itemsBackup[i][mediaForm.key + 'FileIDs'].length;
                                }

                                for (let i = 0; i < event.oldIndex; i++) {
                                    removeOffset = removeOffset + itemsBackup[i][mediaForm.key + 'FileIDs'].length;
                                }

                                removeOffset += elements.length;
                            }

                            if (event.newIndex > event.oldIndex) {
                                for (let i = 0; i <= event.newIndex; i++) {
                                    insertOffset = insertOffset + itemsBackup[i][mediaForm.key + 'FileIDs'].length;
                                }

                                for (let i = 0; i < event.oldIndex; i++) {
                                    removeOffset = removeOffset + itemsBackup[i][mediaForm.key + 'FileIDs'].length;
                                }
                            }

                            this.mutableMedias[mediaForm.key].splice(insertOffset, 0, ...elements);
                            this.mutableMedias[mediaForm.key].splice(removeOffset, elements.length);
                        });


                        this.updateInput();
                    }
                }
            };
        },
        emptyItem() {
            const item = {
                id: uuidv4(),
            }
            this.mediaForms.forEach(mediaForm => {
                item[mediaForm.key + 'FileIDs'] = []
            });

            this.fields.forEach(field => {
                item[field.key] = ''
            });

            return item;
        }
    },
    mounted() {
        const content = eval(this.content);

        if (typeof content !== 'object') {
            this.items.push(this.emptyItem());
        } else {
            content.forEach(item => {
                this.items.push(item);
            });
        }

        if (this.items.length < this.min) {
            for (let i = this.items.length; i < this.min; i++) {
                this.items.push(this.emptyItem());
            }
        }
        this.updateInput();

        this.mounted = true;

        const vm = this;

        $(`[name="Page[page][${this.modelName}Inheritance][${this.modelId}][${this.attributeItems}][inherit]"]`).on("change", function(e) {
            vm.inheritanceEnabled = e.target.checked;
        });
    },
    watch: {
        inheritanceEnabled(enabled) {
            let content;

            if (enabled) {
                content = this.i18nSource;
                this.i18nBackup = this.items;

                this.mediasBackup = this.mutableMedias;
                this.mutableMedias = this.mediasSource;
            } else {
                if(this.i18nBackup) {
                    content = this.i18nBackup;
                    this.mutableMedias = this.mediasBackup;
                } else {
                    content = eval(this.content);
                    this.mutableMedias = this.medias;
                }
            }

            this.items = [];

            if (typeof content !== 'object') {
                this.items.push(this.emptyItem());
            } else {
                content.forEach(item => {
                    this.items.push(item);
                });
            }

            this.updateInput();
        }
    }
}
</script>