"use strict";

// Brush Class
let Brush = function(imgsrc){
    this.img = new Image();     // image element to be used as the "mask" for this brush
    this.src = imgsrc;             // image-source (loaded later)
    this.cursorOffsetX = 0.5;   // position for the "center" of the image, where the user paints with the cursor, 0.5/0.5 = centered
    this.cursorOffsetY = 0.5;   //
    this.interval = 0;          // interval to delay consistent redrawing on every point (amount of points to skip after each draw), 0 = draw on every point
    this.varRotation = true;   // random rotation
    this.varSpread = 40;         // max distance to spread the drawn points away from the cursor position, 0 = no spread
    this.varOpacity = true;    // random opacity (0-1)
    this.varRadius = true;     // random brush size (1-2 -> 100-200%)
    this.fillPoints = false;     // connect all drawn points in between with more points to generate smoother lines (disable this for stamp-brushes with f.e. spread)

    this.excludeFromList = false;   // dont show this brush in the selectable list for custom brushes
};

// globally defined brushes
let _brushes = {
    brush_pencil: new Brush("/assets/mediaeditor/brushes/other/brush_pencil.png"),

    brush1: new Brush("/assets/mediaeditor/brushes/brush1.png"),
    brush2: new Brush("/assets/mediaeditor/brushes/brush2.png"),
    brush3: new Brush("/assets/mediaeditor/brushes/brush3.png"),
    brush4: new Brush("/assets/mediaeditor/brushes/brush4.png"),
    brush5: new Brush("/assets/mediaeditor/brushes/brush5.png"),
    brush6: new Brush("/assets/mediaeditor/brushes/brush6.png"),
    brush7: new Brush("/assets/mediaeditor/brushes/brush7.png"),
    brush8: new Brush("/assets/mediaeditor/brushes/brush8.png"),
    brush9: new Brush("/assets/mediaeditor/brushes/brush9.png"),
    brush10: new Brush("/assets/mediaeditor/brushes/brush10.png"),
    brush11: new Brush("/assets/mediaeditor/brushes/brush11.png"),
    brush12: new Brush("/assets/mediaeditor/brushes/brush12.png"),
    brush13: new Brush("/assets/mediaeditor/brushes/brush13.png"),
    brush14: new Brush("/assets/mediaeditor/brushes/brush14.png"),
    brush15: new Brush("/assets/mediaeditor/brushes/brush15.png"),
    brush16: new Brush("/assets/mediaeditor/brushes/brush16.png"),
    brush17: new Brush("/assets/mediaeditor/brushes/brush17.png"),
    brush18: new Brush("/assets/mediaeditor/brushes/brush18.png"),
    brush19: new Brush("/assets/mediaeditor/brushes/brush19.png"),
    brush20: new Brush("/assets/mediaeditor/brushes/brush20.png"),
};

_brushes["brush_pencil"].cursorOffsetX = 0.8;
_brushes["brush_pencil"].cursorOffsetY = 0.8;
_brushes["brush_pencil"].varSpread = 0;
_brushes["brush_pencil"].varOpacity = false;
_brushes["brush_pencil"].varRotation = false;
_brushes["brush_pencil"].varRadius = false;
_brushes["brush_pencil"].fillPoints = true;
_brushes["brush_pencil"].excludeFromList = true;

export default _brushes;

$(function(){
    window._stampSelectionContainer = document.createElement("div");

    // load images for all brushes
    let count = 0;
    let loaded = 0;
    for(let key in _brushes){
        let brush = _brushes[key];
        count++;
        brush.img.src = brush.src;
        $(brush.img).on("load", function(){
            loaded++;
            if(loaded >= count){
                $(window._stampSelectionContainer).empty();
                $.each( _brushes, function( key, value ) {
                    if(!value.excludeFromList) {
                        $(window._stampSelectionContainer).append("<div style='background-image:url(" + value.src + ");' data-brush='" + key + "' class='paintStampSelect'></div>");
                    }
                });
            }
        });
    }
});