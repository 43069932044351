<template>
    <div class="handle"></div>
</template>

<script>
export default {
    name: 'SortHandle'
};
</script>

<style lang="scss" scoped>
div.handle {
    &:after {
        content: '\e807';
        font-family: "fontello";
        font-size: 1rem;
        cursor: move;
    }
}
</style>