import Vue from "vue"
import SegmentModule from "./SegmentModule.vue"
import vSelect from "vue-select"

const segmentElement = document.getElementById("segment-module-root")

if (segmentElement) {
    Vue.component("v-select", vSelect)
    new Vue({
        name: "SegmentModuleRoot",
        el: segmentElement,
        components: {
            SegmentModule
        }
    });
}