<template>
    <div class="card shadow mb-3">
        <div class="card-header">
            {{ $translations['element.country'] }}<sup v-if="element.items[0].data.mandatory">*</sup>
        </div>

        <div class="card-body">
            <div v-if="element.items[0].data.answeroptionsclass === '' && element.items[0].data.answeroptionsfunction === ''" class="form-group">
                <label>{{ element.items[0].data.question }}</label>
                <div class="form-group">
                    <select id="" name="">
                        <option v-for="answer in element.items[0].data.answeroptions" :key="answer.id">
                            {{ answer.label }}
                        </option>
                    </select>
                </div>
                <small id="emailHelp" class="form-text text-muted mt-3">{{ element.items[0].data.description }}</small>
            </div>
            <div v-else>
                <div class="form-group">
                    <label>{{ element.items[0].data.question }}</label>
                </div>
                <div>{{ $translations['label.generatedby'] }} <code>{{
                        `${element.items[0].data.answeroptionsclass}::${element.items[0].data.answeroptionsfunction}`
                    }}</code></div>
            </div>
        </div>
    </div>
</template>

<script>
import previewItemMixin from '../../mixins/previewitem';

export default {
    name: "Country",
    mixins: [previewItemMixin],
};
</script>