var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-settings" },
    [
      _vm.showTopBar
        ? _c("top-bar", {
            attrs: {
              filters: _vm.filters,
              "input-name-prefix": _vm.inputNamePrefix,
              active: _vm.initActive,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex w-100" }, [
        _c("div", { staticClass: "d-flex w-100" }, [
          _c(
            "div",
            { staticClass: "filter-settings__content" },
            [
              _vm.activeFiltered.length === 0
                ? _c("EmptyState", {
                    attrs: {
                      "empty-headline": _vm.$attrs["empty-headline"],
                      "empty-subline": _vm.$attrs["empty-subline"],
                      "empty-hint": _vm.$attrs["empty-hint"],
                      active: _vm.activeFiltered,
                      "is-dragging": _vm.isDragging,
                    },
                  })
                : _c(
                    "table",
                    { staticClass: "w-100" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$attrs["label-type"]))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$attrs["label-position"])),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$attrs["label-favorite"])),
                          ]),
                          _vm._v(" "),
                          _vm.showShowroomToggle
                            ? _c("th", [
                                _vm._v(_vm._s(_vm.$attrs["label-showroom"])),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$attrs["label-view"]))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$attrs["label-extension"])),
                          ]),
                          _vm._v(" "),
                          _c("th"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        {
                          attrs: {
                            group: { name: "filter" },
                            list: _vm.active,
                            tag: "tbody",
                            animation: 150,
                            "swap-threshold": 100,
                          },
                        },
                        _vm._l(_vm.active, function (filter, index) {
                          return _c("Item", {
                            key: `filter-${filter.filterId}`,
                            attrs: {
                              filter: filter,
                              position: index + 1,
                              "display-option-values": _vm.displayOptionValues,
                              "additional-option-values":
                                _vm.additionalOptionValues,
                              "shop-category-id": _vm.shopCategoryId,
                              "label-remove-button":
                                _vm.$attrs["button-delete"],
                              "input-name-prefix": _vm.inputNamePrefix,
                              "show-showroom-toggle": _vm.showShowroomToggle,
                            },
                            on: { remove: _vm.remove },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-settings__sidebar" },
            [
              _c("sidebar", {
                attrs: { filters: _vm.filters, active: _vm.active },
                on: { "drag-start": _vm.dragStart, "drag-end": _vm.dragEnd },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.active.length === 0
            ? _c("input", {
                attrs: {
                  type: "hidden",
                  name: `${_vm.inputNamePrefix}[filters][]`,
                },
              })
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }