/**
 * Backend Structure
 */

import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.min.css'

class Backendstructure {

    constructor() {
        this.initNamespaceDropdownSelector = '#backendstructure-namespace';
        this.initPathInputSelector = '#backendstructurei18n-path';

        this.init();
    }

    init() {
        this.initNamespaceDropdown();
    }

    /**
     * Set path on dropdown change
     */
    initNamespaceDropdown() {
        let that = this;

        $(this.initNamespaceDropdownSelector).on("change", function (e) {
            let selectedOption = e.target.options[e.target.selectedIndex];
            let path = e.target.parentNode.parentNode.querySelector(that.initPathInputSelector);

            if (path && selectedOption) {
                if (selectedOption.dataset.path) {
                    path.value = selectedOption.dataset.path;
                } else {
                    path.value = '';
                }
            }
        });
    }

    /**
     * Initialize dashboard checkbox for analytics
     */
    initDashboardCheckbox(controllerPath) {
        const checkbox = $('#backendstructure-is_dashboard');
        const namespaceSelect = $('#backendstructure-namespace');
        const moduleTab = $('[data-content-tab-id="backendstructure-module"]');
        const optionToSelect = controllerPath;

        checkbox.on('change', function () {
            if ($(this).is(':checked')) {
                namespaceSelect.val(optionToSelect).trigger('change');
                namespaceSelect.prop('disabled', true);
                moduleTab.hide();
            } else {
                namespaceSelect.prop('disabled', false);
                moduleTab.show();
            }
        });

        checkbox.trigger('change');
    }
}

(function () {
    "use strict";
    window.backendstructure = new Backendstructure();
})();