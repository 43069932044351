<template>
    <div class="modal fade" aria-hidden="true" data-backdrop="" role="dialog" :id="modal_id">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Media Manager</h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mediamanager_extension_container">

                    <div class="settings">
                        <MediaForm ref="mediaForm"
                            :widget="mediaFormWidget"
                            @inputChanged="mediaFormInputChanged"
                        ></MediaForm>
                        <label class="small-headline">Grösse</label>
                        <div class="size">
                            <div class="form-group label-inside size_unit_select">
                                <select :id="modal_id+'_size_unit'" class="form-control select2"
                                        :form="modal_id"
                                        v-model="preview_image.options.size_unit"
                                        data-placeholder="Einheit"
                                        data-minimum-results-for-search="Infinity"
                                        ref="sizeUnitSelect"
                                >
                                    <option value="%">Prozent</option>
                                    <option value="px">Pixel</option>
                                </select>
                                <label :for="modal_id+'_size_unit'">Einheit</label>
                            </div>

                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_width'" class="form-control" placeholder="Breite" min="0"
                                       v-model="preview_image.options.width" :form="modal_id">
                                <label :for="modal_id+'_width'">Breite</label>
                            </div>
                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_height'" class="form-control" placeholder="Höhe" min="0"
                                       v-model="preview_image.options.height" :form="modal_id" :disabled="preview_image.options.ratio_locked">
                                <label :for="modal_id+'_height'">Höhe</label>
                            </div>

                            <div class="form-group ratio_lock">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" :id="modal_id+'_ratio_lock'" class="custom-control-input is-valid" value="1"
                                           :disabled="preview_image.options.size_unit==='%'"
                                           :readonly="preview_image.options.size_unit==='%'"
                                           v-model="preview_image.options.ratio_locked"
                                           :form="modal_id">
                                    <label :for="modal_id+'_ratio_lock'" class="custom-control-label">
                                        <span :class="{'fontello-icon-lock-1': preview_image.options.ratio_locked, 'fontello-icon-lock-open': !preview_image.options.ratio_locked}"></span>
                                    </label>
                                </div>
                                <span class="ratio_lock_label">Bildverhältnis beibehalten</span>
                            </div>
                        </div>

                        <label class="small-headline">Abstand (Pixel)</label>
                        <div class="margins">
                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_left'" class="form-control" placeholder="Links" min="0"
                                       v-model="preview_image.options.margin_left" :form="modal_id">
                                <label :for="modal_id+'_left'">Links</label>
                            </div>
                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_right'" class="form-control" placeholder="Rechts" min="0"
                                       v-model="preview_image.options.margin_right" :form="modal_id">
                                <label :for="modal_id+'_right'">Rechts</label>
                            </div>
                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_top'" class="form-control" placeholder="Oben" min="0"
                                       v-model="preview_image.options.margin_top" :form="modal_id">
                                <label :for="modal_id+'_top'">Oben</label>
                            </div>
                            <div class="form-group label-inside">
                                <input type="number" :id="modal_id+'_bottom'" class="form-control" placeholder="Unten" min="0"
                                       v-model="preview_image.options.margin_bottom" :form="modal_id">
                                <label :for="modal_id+'_bottom'">Unten</label>
                            </div>
                        </div>

                        <label class="small-headline">Ausrichtung</label>
                        <div class="alignment">
                            <div v-for="(item,key) in alignments"
                                 :class="{'selected': preview_image.options.alignment === key}"
                                 :data-value="key"
                                 @click="selectAlignment(key)">
                                {{item.label}}
                            </div>
                        </div>
                    </div>

                    <div class="preview_container">
                        <div class="preview_header">Vorschau</div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet
                            <ImageNode class="preview_image" :attrs="preview_image.options" @load="previewImgLoaded" />
                            a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,
                        </p>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Abbrechen</button>
                    <button type="button" class="btn btn-success"
                            @click="save"
                            :disabled="( !preview_image.options.src )"
                            :class="{'disabled': ( !preview_image.options.src )}"
                    >Speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageNode from './ImageNode.vue';
    import { ImageObject, ALIGNMENTS } from './ImageObject.js';
    import MediaForm from '../../../../fire-core-mediamanager/src/backend/widgets/views/vue/MediaForm.vue';

    export default {
        components: {
            ImageNode,
            MediaForm,
        },
        props: {
            'modelName' : {type: String, required: true},
            'attributeName' : {type: String, required: false, default: null},   // unique attribute name, concatenated from original_attr & a random uid
            'original_attr' : {type: String, required: true},   // the original attribute name
            'saveCallback': {type: Function},
            'modelmedias': {type: Array, default: []},
            'imageOptions': {type: Object, default: {}},
        },
        data() {
            return {
                handleInsert: !this.attributeName,     // handle insert function only the first time, after that emit an update event
                resetFormOnClose: true,         // always reset fields & medialist when the modal is closed (unless its closed through the save button)
                handlePreviewLoaded: this.modelmedias.length<=0,     // update size_unit, width & height to the image-size only when changing image, not when loading it from modelmedias
                alignments: ALIGNMENTS,
                preview_image: new ImageObject(this.imageOptions),
                formRestoreData: { medialist: [], imageOptions: {} },   // used to keep original form input data & medialist, to reset all fields back when cancelling the modal
            }
        },
        computed: {
            uniq_attr() { return this.attributeName ? this.attributeName : this.createUID() },
            modal_id() { return `mm_modal-${this.modelName}-${this.uniq_attr}` },
            mediaFormWidget() { return {
                modelName: this.modelName,
                attributeName: this.uniq_attr,
                medias: this.modelmedias,
                //dummyFormName: this.modal_id,
            }},
        },
        methods: {
            selectAlignment(val) {
                this.preview_image.options.alignment = val;
            },
            changeImage($el) {
                this.preview_image.options.src = this.preview_image.options.origin.src = $el.find('a.preview').attr('href');

                this.preview_image.options.label = $el.find(".mm-file-col.label").text();
                this.preview_image.options.sublabel = $el.find(".mm-file-col.sublabel").text();
                this.preview_image.options.description = $el.find(".mm-file-col.description").text();
                this.preview_image.options.link = $el.find(".mm-file-col.link").text();
                this.preview_image.options.linktitle = $el.find(".mm-file-col.linktitle").text();

                this.handlePreviewLoaded = true;
            },
            previewImgLoaded(el) {
                this.preview_image.options.origin.width = el.naturalWidth;
                this.preview_image.options.origin.height = el.naturalHeight;
                this.preview_image.options.origin.ratio = el.naturalWidth/el.naturalHeight;

                if (this.handlePreviewLoaded) {
                    // "reset" size settings after changing to a new image
                    // this.preview_image.options.size_unit = 'px';
                    // this.preview_image.options.ratio_locked = true;
                    // this.preview_image.options.width = el.naturalWidth;
                    // this.preview_image.options.height = el.naturalHeight;

                    this.preview_image.options.size_unit = '%';
                    this.preview_image.options.ratio_locked = true;
                    this.preview_image.options.width = 100;
                    this.preview_image.options.height = '';

                    this.handlePreviewLoaded = false;
                }
            },
            save() {
                if (this.handleInsert) {
                    this.preview_image.options.modal_id = this.modal_id;
                    this.saveCallback(this.preview_image.options);
                    this.handleInsert = false;
                } else {
                    this.$el.dispatchEvent(new CustomEvent('imagenode:update',{
                        detail: this.preview_image.options,
                    }));
                }
                this.resetFormOnClose = false;
                $(this.$el).modal('hide');
            },
            mediaFormInputChanged(data) {
                // ugly hack to manually overwrite data properties with values from js input change-events
                this.preview_image['options'][data.attr] = data.value;
            },
            createUID() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }
                return `tiptap_media-${this.original_attr}-${s4()+s4()+s4()}`;
            },
            resetForm() {
                let vm = this;

                this.preview_image.options = JSON.parse(JSON.stringify(vm.formRestoreData.imageOptions));

                if (vm.formRestoreData.medialist[0]) {
                    let el = $(vm.$refs.mediaForm.$el).find('ul.medialist li:not(.template)').first();
                    el.find('input[data-name=media_id]').val(vm.formRestoreData.medialist[0].id);
                    el.find('input[data-name=file_id]').val(vm.formRestoreData.medialist[0].file_id);
                    el.find('input[data-name=file_name]').val(vm.formRestoreData.medialist[0].file_name);
                    el.find('input[data-name=label]').val(vm.formRestoreData.medialist[0].label);
                    el.find('input[data-name=sublabel]').val(vm.formRestoreData.medialist[0].sublabel);
                    el.find('input[data-name=description]').val(vm.formRestoreData.medialist[0].description);
                    el.find('input[data-name=link]').val(vm.formRestoreData.medialist[0].link);
                    el.find('input[data-name=linktitle]').val(vm.formRestoreData.medialist[0].linktitle);
                    el.find('a.preview img').attr('src', vm.formRestoreData.medialist[0].file.preview);
                    el.find('div.file_name').text(vm.formRestoreData.medialist[0].file.name);
                }
            },
            closeModal() {
                $(this.$el).modal('hide');
            }
        },
        mounted() {
            let vm = this;
            // manual input event needed for select2
            $(vm.$refs.sizeUnitSelect).select2().on('input change',function() {
                vm.preview_image.options.size_unit = this.value;
                return false;
            });

            // event trigger when the media was changed
            $(vm.$refs.mediaForm.$el).on('mediamanager:change', function() {
                let $medias = $(this).find('.medialist li:not(.template)');

                // todo handle multiple medias later?
                let $media = $medias.first();

                vm.changeImage($media);
            });

            if (!vm.attributeName) {
                //$('#'+this.modal_id).modal('show');
                $(document.getElementById(this.modal_id)).modal('show');    // fix for jquery selector not finding elements containing '[]' in the id
            }

            // reset form fields & medialist when closing modal without saving
            $(this.$el).on('hidden.bs.modal', function (e) {
                // dont reset if it was closed through the "save" button
                if (vm.resetFormOnClose) {
                    vm.resetForm();
                }
                vm.resetFormOnClose = true;
            });

            // save current formdata & medialist to reset later if the modal gets closed without saving
            $(this.$el).on('shown.bs.modal', function (e) {
                vm.formRestoreData.imageOptions = JSON.parse(JSON.stringify(vm.preview_image.options));
                vm.formRestoreData.medialist = [];
                $(vm.$refs.mediaForm.$el).find('ul.medialist li:not(.template)').each(function() {
                    vm.formRestoreData.medialist.push({
                        id: $(this).find('input[data-name=media_id]').val(),
                        file_id: $(this).find('input[data-name=file_id]').val(),
                        file_name: $(this).find('input[data-name=file_name]').text(),
                        label: $(this).find('input[data-name=label]').val(),
                        sublabel: $(this).find('input[data-name=sublabel]').val(),
                        description: $(this).find('input[data-name=description]').val(),
                        link: $(this).find('input[data-name=link]').val(),
                        linktitle: $(this).find('input[data-name=linktitle]').val(),
                        file: {
                            //uri: $(this).find('a.preview').attr(),
                            preview: $(this).find('a.preview img').attr('src'),
                            name: $.trim($(this).find('div.file_name').text()),
                        }
                    });
                });
            });

            if (vm.modelmedias.length) {
                vm.preview_image.options.modal_id = vm.modal_id;
                vm.preview_image.setAttrsFromMedia(vm.modelmedias[0]);
                vm.preview_image.options.src = this.preview_image.options.origin.src = vm.modelmedias[0].file.src;
            }
        },
        watch: {
            /*'preview_image.options.width'(newval,oldval) {
                if (this.preview_image.options.ratio_locked && this.preview_image.options.size_unit === 'px') {
                    this.preview_image.options.height = newval / this.preview_image.options.origin.ratio;
                }
            },
            'preview_image.options.height'(newval,oldval) {
                if (this.preview_image.options.ratio_locked && this.preview_image.options.size_unit === 'px') {
                    this.preview_image.options.width = newval * this.preview_image.options.origin.ratio;
                }
            },*/
            'preview_image.options.size_unit'(newval,oldval) {
                if (newval==='%') {
                    this.preview_image.options.ratio_locked = true;
                    this.preview_image.options.width = 100;
                    this.preview_image.options.height = '';
                } else {
                    this.preview_image.options.width = this.preview_image.options.origin.width;
                    this.preview_image.options.height = this.preview_image.options.origin.height;
                }
                $(this.$refs.sizeUnitSelect).val(newval).trigger('change');
            },
        },
    }
</script>

<style scoped lang="scss">
    .mm_extension_modal_container {
        .modal-content {
            margin-top: 106px; //header is 90px
            margin-bottom: 16px;
            //box-shadow: #04051bad 0 0 200px 0px;
            box-shadow: #04051b85 0 0 0px 2000px;
          }
    }
    .mediamanager_extension_container{
        display: flex;
        flex-direction: row;

        .form-group{
            .form-control{
                width: 100%;
            }

            // hide browser default number-input field spinners (webkit & mozilla)
            input[type=number]{
                -moz-appearance:textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        .settings{
            display: flex;
            flex-direction: column;

            max-width: 20rem; // ...

            .group-header{
                padding: 0 0 5px 5px;
                margin: 0;
                font-size: 0.8em;
                opacity: 0.7;
            }
            > div{
                flex: 1;
                margin: 0 0 1em;
                display: flex;
                justify-content: space-evenly;
            }
            .loadMediaManager{
                margin-right: 0.5em;
            }
            .alt_text{
                padding-right: 0.5em;
            }
            .size,
            .margins{
                >div{
                    flex: 25%;
                    margin: 0 0.5em 0 0;
                }
                .size_unit_select{
                    flex: 30%;
                    max-width: 30%;
                }
                .ratio_lock{
                    flex: 15%;
                    max-width: 15%;
                }
            }
            .ratio_lock{
                .custom-control.custom-checkbox{
                    margin: 0;
                    height: 100%;
                    label{
                        padding: 0;
                        span{
                            font-size: 1rem;
                            &.fontello-icon-lock{
                                padding-left: 3px; // ugly fix to position open & closed lock
                            }
                        }
                    }
                }
                .ratio_lock_label{
                    font-size: 7pt;
                }
            }
            .alignment{
                flex-wrap: wrap;
                justify-content: space-between;
                >div{
                    flex: 33%;
                    max-width: 33%;
                    padding: 5px;
                    text-align: center;
                    font-size: 0.8rem;
                    cursor: pointer;
                    &:before {
                        content: "";
                        display: block;
                        padding-top: 100%; /* initial ratio of 1:1*/
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 90%;
                        margin-bottom: 5px;
                        opacity: 0.5;
                        transition: opacity 0.2s linear;
                        box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
                    }
                    &:hover:before{
                        opacity: 1;
                    }
                    &.selected{
                        &:before{
                            box-shadow: inset 0 0 0 2px rgba(0,0,0,0.75);
                            opacity: 1;
                        }
                    }

                    &[data-value='inline_top']:before{
                        background-image: url('./assets/ck-mm-inline-top.svg');
                    }
                    &[data-value='inline_center']:before{
                        background-image: url('./assets/ck-mm-inline-middle.png');
                    }
                    &[data-value='inline_bottom']:before{
                        background-image: url('./assets/ck-mm-inline-bottom.png');
                    }
                    &[data-value='block_left']:before{
                        background-image: url('./assets/ck-mm-block-left.png');
                    }
                    &[data-value='block_center']:before{
                        background-image: url('./assets/ck-mm-block-center.png');
                    }
                    &[data-value='block_right']:before{
                        background-image: url('./assets/ck-mm-block-right.png');
                    }
                    &[data-value='float_left']:before{
                        background-image: url('./assets/ck-mm-float-left.png');
                    }
                    &[data-value='float_right']:before{
                        background-image: url('./assets/ck-mm-float-right.png');
                    }
                }
            }
        }

        .preview_container{
            margin-left: 1em;
            flex: 1;
            padding: 1.5rem;
            border: 1px solid #e0e4e1;
            background-color: #fcfdfc;
            border-radius: 0.2rem;
            font-size: 0.9rem;

            overflow-y: scroll;
            overflow-x: hidden;
            position: relative;
            .preview_header{
                font-size: 0.8em;
                opacity: 0.5;
                padding-bottom: 1rem;
            }
            p{
                position: absolute;
                left: 1.5rem;
                right: 1.5rem;
                .preview_image{

                }
            }

        }
    }
</style>