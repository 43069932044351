<template>
    <div class="video-player fullscreen">
        <video
            :key="props.content.id"
            ref="player"
            crossorigin="use-credentials"
            class="fullscreen"
            autoplay
            :loop="props.loop"
            :muted="mute"
            :style="fillScreen ? 'width: 100% !important; height: auto !important' : ''">
            <source
                :key="props.content.id"
                :src="getAssetPathOfTargetAttr( props.targetAttr || 'video', props.content)">
        </video>
    </div>

</template>

<script setup>
import {computed, ref, defineProps, onMounted} from 'vue'
import {getAssetPathOfTargetAttr} from "../js/helpers";

const props = defineProps({
    content: {
        type: Object,
        default: {}
    },
    targetAttr: {
        type: String
    },
    loop: {
        type: Boolean,
        default: false
    },
    muted: {
        type: Boolean,
        default: false
    },
    fillScreen: {
        type: Boolean
    }
})

const player = ref(null)
const loop = ref(false);
const mute = ref(true);

const isMuted = computed(() => {
    return props.muted
})

onMounted(() => {
    mute.value = isMuted
})


</script>

<style scoped lang="scss">
.fullscreen {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}
</style>
