<template>
    <div>
        <div class="mb-5">
            <div class="h5 text-muted mb-3">
                {{ $translations['element.input'] }} - {{ capitalize(item.data.inputtype) }}
            </div>
            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-content">
                {{ $translations['section.content'] }}
            </header>
            <section id="section-content" class="collapse">
                <form-checkbox
                    id="mandatory-checkbox"
                    v-model="item.data.mandatory"
                    :label="$translations['label.mandatory']" />

                <form-input
                    v-if="item.data.mandatory"
                    id="item.data.id"
                    v-model="item.data.mandatory_warning"
                    :label="$translations['label.mandatory-warning']" />

                <form-checkbox
                    id="disabled"
                    v-model="item.data.disabled"
                    :label="$translations['label.disabled']" />

                <form-checkbox
                    id="disabled"
                    v-model="item.data.readonly"
                    :label="$translations['label.readonly']" />

                <form-checkbox
                    id="disabled"
                    v-model="item.data.skipRendering"
                    label="Skip rendering" />

                <form-input
                    id="question"
                    v-model="item.data.question"
                    :label="$translations['label.label']" />

                <form-textarea
                    id="description"
                    v-model="item.data.description"
                    :label="$translations['label.description']" />

                <form-input
                    id="placeholder"
                    v-model="item.data.placeholder"
                    :label="$translations['label.placeholder']" />

                <form-select
                    id="input-type"
                    v-model="item.data.inputtype"
                    :label="$translations['label.inputtype']"
                    :options="inputTypes" />

                <form-input
                    v-if="['text', 'textarea'].includes(item.data.inputtype)"
                    id="input-length"
                    v-model="item.data.inputmaxlength"
                    :label="$translations['label.length']"
                    type="number" />

                <form-input
                    v-if="['hidden'].includes(item.data.inputtype)"
                    id="input-length"
                    v-model="item.data.default"
                    :label="$translations['label.default']"
                    type="text" />

                <form-input
                    v-if="['number', 'range'].includes(item.data.inputtype)"
                    id="input-length"
                    v-model="item.data.inputslidermin"
                    :label="$translations['label.min']"
                    type="number" />

                <form-input
                    v-if="['number', 'range'].includes(item.data.inputtype)"
                    id="input-length"
                    v-model="item.data.inputslidermax"
                    :label="$translations['label.max']"
                    type="number" />

                <form-input
                    v-if="['range'].includes(item.data.inputtype)"
                    id="input-length"
                    v-model="item.data.inputslidersteps"
                    :label="$translations['label.steps']"
                    type="number" />
            </section>

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-dependencies">
                {{ $translations['section.dependencies'] }}
            </header>

            <LogicSection
                :item="item"
                :logic-options="logicOptions"
                @add-rule="addRule"
                @remove-rule="removeRule"
                @enable-logic="enableLogic" />

            <header
                class="text-light p-3 mb-1 bg-secondary rounded collapsed"
                data-toggle="collapse"
                data-target="#section-advanced">
                {{ $translations['section.advanced'] }}
            </header>
            <section id="section-advanced" class="collapse">
                <form-input
                    id="unique-label"
                    v-model="item.data.uniqlabel"
                    :label="$translations['label.uniqlabel']" />
                <form-input
                    id="match-prefill-key"
                    v-model="item.data.match_prefill_key"
                    :label="$translations['label.prefill-key']" />
                <form-input
                    id="htmloptions"
                    v-model="item.data.htmloptions"
                    :label="$translations['label.css-class']" />
                <form-checkbox
                    id="html-purify"
                    v-model="item.data.htmlpurify"
                    :label="$translations['label.html-purify']" />
                <form-input
                    id="custom-validation"
                    v-model="item.data.customvalidation"
                    :label="$translations['label.regex']" />
                <form-input
                    id="custom-errormessage"
                    v-model="item.data.customerrormessage"
                    :label="$translations['label.regex-warning']" />
                <form-input
                    id="dynamic-value-class"
                    v-model="item.data.defaultvalueclass"
                    :label="$translations['label.class-for-default']" />
                <form-input
                    id="dynamic-value-function"
                    v-model="item.data.defaultvaluefunction"
                    :label="$translations['label.function-for-default']" />
            </section>
        </div>

        <div>
            <FooterActions @save="save" />
        </div>
    </div>
</template>

<script>
import eventBus from "../../util/EventBus";
import FooterActions from "./parts/FooterActions.vue";
import inputTypes from "../../config/inputTypes";

import formInput from "./formparts/textinput.vue";
import formCheckbox from "./formparts/checkbox.vue";
import formTextarea from "./formparts/textarea.vue";
import formSelect from "./formparts/select.vue";

import LogicSection from "./formparts/LogicSection.vue";

import logicMixin from '../../mixins/logic';
import previewMixin from '../../mixins/preview';

import capitalize from "../../util/capitalize";

export default {
    name: "Input",
    components: {
        FooterActions,
        formInput,
        formCheckbox,
        formTextarea,
        formSelect,
        LogicSection
    },
    mixins: [logicMixin, previewMixin],
    props: {
        element: {
            type: Object
        }
    },
    data: function() {
        return {
            item: {
                data: {
                    view: '',
                    id: '',
                    mandatory: false,
                    mandatory_warning: '',
                    question: '',
                    description: '',
                    placeholder: '',
                    inputtype: '',
                    inputmaxlength: null,
                    inputnumbermin: 0,
                    inputnumbermax: 0,
                    // extended form
                    uniqlabel: '',
                    htmloptions: '',
                    htmlpurify: false,
                    customvalidation: '',
                    customerrormessage: '',
                    defaultvalueclass: '',
                    defaultvaluefunction: '',
                    readonly: false,
                    disabled: false,
                    skipRendering: false,
                    default: ''
                },
                logic: {}
            },
            inputTypes: inputTypes
        };
    },
    mounted() {
        for (const key in this.element.items[0].data) {
            this.$set(this.item.data, key, this.element.items[0].data[key]);
        }

        this.$set(this.item, 'logic', this.element.items[0].logic);
    },
    methods: {
        save() {
            eventBus.$emit("saveElement", this.item);
        },
        capitalize
    }
};
</script>