export default [
    {
        value: 'text',
        label: "Text"
    },
    {
        value: 'number',
        label: "Zahl"
    },
    {
        value: 'color',
        label: "Farbe"
    },
    {
        value: 'email',
        label: "E-Mail"
    },
    {
        value: 'range',
        label: "Slider"
    },
    {
        value: 'tel',
        label: "Telefon"
    },
    {
        value: 'url',
        label: "Url"
    },
    {
        value: 'date',
        label: "Datum"
    },
    {
        value: 'time',
        label: "Zeit"
    },
    {
        value: 'textarea',
        label: "Textarea"
    },
    {
        value: 'hidden',
        label: "Hidden"
    },
    {
        value: 'password',
        label: "Passwort"
    }
];