var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "mediaformwidget", staticClass: "mediaformwidget onecolumn my-3" },
    [
      _c("label", { staticClass: "small-headline" }, [
        _vm._v(_vm._s(_vm.mediaForm.label)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "medialist_wrapper",
          class: { empty: _vm.widgetOptions.medias.length <= 0 },
        },
        [
          _vm.medias[_vm.mediaForm.key].length === 0
            ? _c("ul", {
                staticClass: "medialist",
                attrs: {
                  "data-attribute": _vm.widgetOptions.attributeName,
                  "data-maxfiles": _vm.widgetOptions.maxFiles,
                  "data-model": _vm.widgetOptions.modelName,
                  "data-disable-jqueryui-sort": "1",
                  "data-update-by-event": "1",
                },
              })
            : _c(
                "draggable",
                {
                  attrs: {
                    "component-data": _vm.getComponentData(),
                    draggable: ".draggable",
                    tag: "ul",
                  },
                  model: {
                    value: _vm.mutableMedias[_vm.mediaForm.key],
                    callback: function ($$v) {
                      _vm.$set(_vm.mutableMedias, _vm.mediaForm.key, $$v)
                    },
                    expression: "mutableMedias[mediaForm.key]",
                  },
                },
                _vm._l(
                  _vm.mutableMedias[_vm.mediaForm.key],
                  function (media, index) {
                    return _c("MediaFormItem", {
                      key: index,
                      class: { draggable: !_vm.readonly },
                      attrs: {
                        "editable-fields": _vm.mediaForm.editableFields,
                        identifier: _vm.identifier,
                        index: index + _vm.offset,
                        "item-index": _vm.itemIndex,
                        media: media,
                        widget: _vm.widgetOptions,
                      },
                      on: { update: _vm.update },
                    })
                  }
                ),
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mediaform_wrapper" }, [
        _c(
          "div",
          {
            staticClass: "mm-dropzone",
            class: { disabled: _vm.widgetOptions.uploadDisabled },
          },
          [
            _c("div", { staticClass: "dropzone-overlay" }),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mediaform_dropzone" }, [
          _c("input", {
            staticClass: "mediaform_upload_input uploadFile",
            class: {
              disabled:
                _vm.widgetOptions.uploadDisabled || _vm.widgetOptions.readonly,
            },
            attrs: {
              id: _vm.uploadInputId,
              "data-attribute": _vm.uploadInputId,
              disabled: _vm.widgetOptions.uploadDisabled,
              form: _vm.widgetOptions.dummyFormName,
              multiple: "",
              name: "Media",
              type: "file",
            },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.uploadInputId } }, [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "loadMediaManager btn btn-primary",
            class: { disabled: _vm.widgetOptions.readonly },
          },
          [_vm._v("Mediamanager")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "badge badge-auto badge-alternate-primary" },
      [_c("span", [_vm._v("Dateien hier ablegen")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload_media_icon" }, [
      _c("i", { staticClass: "far fa-plus" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload_media_title" }, [
      _c("span", [_vm._v("Dateien hinzufügen")]),
      _vm._v(" "),
      _c("span", [_vm._v("Oder einen Ordner auswählen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }